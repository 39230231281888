<mat-card class="flex-row flex-wrap justify-space-between align-center" [style.background-color]="backgroundColor()">
  <div class="flex-col flex-default">
    <div *ngIf="matcher || nominate">
      <span *ngIf="isOnlineRoom()" matTooltip="Online room">💻</span>
      <span *ngIf="isInPersonRoom()" matTooltip="In-person room">🔐</span>
      <span *ngIf="isCompany()" matTooltip="Company">🏢</span>
      <span *ngIf="!nominate && isApproved()" matTooltip="Approved">✅</span>
      <span *ngIf="matcher && isNominee()" matTooltip="Nominee">🎗️</span>
      <span *ngIf="matcher && isFinalist()" matTooltip="Finalist">🏅</span>
      <span *ngIf="!nominate && isIneligible()" matTooltip="Ineligible">❌</span>
      <span *ngIf="matcher && isPermanentlyIneligible()" matTooltip="Permanently ineligible">⛔</span>
      <span *ngIf="!isCompany()"><strong> {{ room.englishName || room.name }}</strong></span>
      <span *ngIf="isCompany()"><strong> {{ room.company }}</strong></span>
      <span *ngIf="matcher"> ({{ numCurrentNominations() }}{{ isAutomaticFinalist() && !isIneligible() ? " - autofinalist" : "" }})</span>
      <span *ngIf="room.horrorLevel">
        {{' '}}<app-horrorlevel [level]="room.horrorLevel"></app-horrorlevel>
      </span>
      <span *ngFor="let l of languages()">
        {{' '}}<app-language [alpha2]="l"></app-language>
      </span>
    </div>
    <div *ngIf="(matcher || nominate) && room.englishName">
      <i><strong>{{ room.name }}</strong></i>
    </div>
    <div *ngIf="ranker">
      <span *ngIf="isOnlineRoom()" matTooltip="Online room">💻</span>
      <span *ngIf="isInPersonRoom()" matTooltip="In-person room">🔐</span>
      <span *ngIf="isCompany()" matTooltip="Company">🏢</span>
      <strong>
        <a href="{{ room.link }}" target="_blank">
          {{ room.englishName || room.name }} <span *ngIf="room.englishName">[{{room.name}}]</span>
        </a>
      </strong>
    </div>
    <div *ngIf="!isCompany()">
      {{ room.company }}
    </div>
    <div>
      <i>{{ location() }}</i>
    </div>
    <div *ngIf="matcher || nominate">
      <a href="{{ room.link }}" target="_blank">{{ room.link }}</a>
    </div>
    <div *ngIf="matcher && room.email">
      <a href="mailto:{{ room.email }}" target="_blank">{{ room.email }}</a>
    </div>
    <div *ngIf="matcher && room.englishLink">
      EN: <a href="{{ room.englishLink }}" target="_blank">{{ room.englishLink }}</a>
    </div>
    <div *ngIf="matcher">
      <div *ngFor="let prevYear of previousYears()">
        <b>{{ prevYear }}</b>:
        <span *ngIf="isApproved(prevYear)" matTooltip="Approved">✅</span>
        <span *ngIf="isNominee(prevYear)" matTooltip="Nominee">🎗️</span>
        <span *ngIf="isFinalist(prevYear)" matTooltip="Finalist">🏅</span>
        <span *ngIf="isWinner(prevYear)" matTooltip="Winner">🏆</span>
        <span *ngIf="isIneligible(prevYear)" matTooltip="Ineligible">❌</span>
        <span *ngIf="isPermanentlyIneligible(prevYear)" matTooltip="Permanently ineligible">⛔</span>
      </div>
    </div>
    <div *ngIf="isIneligible() && room.ineligibilityReason">
      <i>❌ {{ room.ineligibilityReason }}</i>
    </div>
    <div *ngIf="isIneligible() && nominate && !room.ineligibilityReason">
      <i>❌ marked ineligible</i>
    </div>
    <div *ngIf="showNotes && (room.reviewerNotes || room.auditLogEntry)" class="terpeca-multiline">
      <i *ngIf="room.reviewerNotes">📝 {{ room.reviewerNotes }}</i>
      <i *ngIf="room.auditLogEntry">📝 {{ getRoomAuditLogString(room.auditLogEntry) }}
      </i>
    </div>
  </div>
  <div class="flex-col gap-10">
    <button *ngIf="allowSelect && !selected" mat-raised-button color="primary" [disabled]="disableSelect"
            (click)="toggleSelected()">
      {{ selectLabel || 'Select' }}
    </button>
    <button *ngIf="allowSelect && selected" mat-raised-button color="primary" [disabled]="disableSelect"
            (click)="toggleSelected()">
      {{ unselectLabel || 'Unselect' }}
    </button>
    <button *ngIf="allowEdit" mat-raised-button color="secondary"
            (click)="edit()">
      Review/Edit
    </button>
    <button *ngIf="allowProcess && isApproved() && canFinalize() && !isFinalized()" mat-raised-button color="primary"
            (click)="finalize()">
      Finalize
    </button>
    <button *ngIf="allowProcess && !isIneligible() && canUnfinalize() && isFinalized()" mat-raised-button color="warn"
            (click)="unfinalize()">
      Unfinalize
    </button>
  </div>
</mat-card>
