<section *ngIf="auth.currentUser as user" class="flex-col justify-center align-center">
  <mat-card *ngIf="user.disabled">
    <h3>
      Your account has been disabled.
    </h3>
    <div>
      If you would like to re-enable it, please contact admin&#64;terpeca.com.
    </div>
  </mat-card>
  <app-apply *ngIf="(user.status <= Status.PENDING || (user.status === Status.VOTER && requestUpgrade)) && !user.disabled" [user]="user" [home]="this"></app-apply>
  <mat-card *ngIf="user.status === Status.DENIED && !user.disabled">
    <h3>
      Thank you for applying to join the TERPECA team.
    </h3>
    <div *ngIf="user.applicationDenied?.includes(year)">
      Unfortunately, your application to participate this year has been declined.
    </div>
    <div *ngIf="user.applicationDenied?.includes(year)">
      We hope you continue to play more escape rooms and reapply next year!
    </div>
    <div *ngIf="user.applicationDenied?.includes(year)">
      If you have any questions, check out the <a routerLink="/faq">FAQ</a>.
    </div>
    <div *ngIf="!user.applicationDenied?.includes(year)">
      Your application was previously declined, but hope springs eternal.🌷 You are now eligible to reapply!
    </div>
    <div *ngIf="!user.applicationDenied?.includes(year)"
       class="flex-row flex-wrap justify-start align-center gap-10">
      <button mat-raised-button color="primary" (click)="auth.reapply()">Reapply</button>
    </div>
  </mat-card>
  <mat-card *ngIf="user.status >= Status.NOMINATOR && !user.disabled" [style.background-color]="nominatorBackground()">
    <h3>
      You're a nominator!
    </h3>
    <div *ngIf="!settings.isNominationOpen(user)">
      The nomination window for {{year}} is not yet open, but we'll notify you by email when it goes live.
    </div>
    <div *ngIf="!settings.isNominationOpen() && settings.isNominationOpen(user) && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      The nomination window isn't open for everybody, but you get early access because you're special!
    </div>
    <div *ngIf="settings.isNominationOpen() && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      <strong>The nomination window is now open and ready for your input.</strong>
    </div>
    <div *ngIf="settings.isNominationOpen(user) && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      Nominations are due <strong>{{settings.nominationDeadlineString()}}</strong>
      ({{settings.timeToNominationDeadlineString()}} from now).
    </div>
    <div *ngIf="settings.isNominationOpen(user) && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      Check out our <a href="https://www.youtube.com/watch?v=gDaMt8iQNSk" target="_blank">How to Nominate</a>
      video for a walkthrough of the nomination process.
    </div>
    <div *ngIf="settings.isNominationOpen(user) && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      <b style="color: red">You have not yet submitted your nominations.</b>
    </div>
    <div *ngIf="settings.isPastNominationDeadline()">
      Nominations for {{year}} are now closed.
    </div>
    <div *ngIf="!user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
      Unfortunately you missed the nomination deadline.
    </div>
    <div *ngIf="user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
      Your nominations were successfully submitted.
    </div>
    <div *ngIf="user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      Your nominations have been successfully submitted.
    </div>
    <div *ngIf="user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
      If you want to make any changes before the {{settings.nominationDeadlineString()}} deadline
      ({{settings.timeToNominationDeadlineString()}} from now), you may do so until the deadline.
    </div>
    <div *ngIf="user.nominationsSubmitted?.includes(year) || (settings.isNominationOpen(user) && !settings.isPastNominationDeadline())"
        class="flex-row flex-wrap justify-start align-center gap-10">
      <a *ngIf="settings.isNominationOpen(user) && !user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()"
        mat-raised-button color="primary" routerLink="/nominate">Nominate Rooms</a>
      <a *ngIf="user.nominationsSubmitted?.includes(year)"
        mat-raised-button color="primary" routerLink="/nominate">See Nominations</a>
    </div>
  </mat-card>
  <mat-card *ngIf="user.status >= Status.VOTER && !requestUpgrade && !user.disabled" [style.background-color]="voterBackground()">
    <h3>
      You're a voter!
    </h3>
    <div *ngIf="!settings.isVotingOpen(user)">
      The voting window for {{year}} is not yet open, but we'll notify you by email when it goes live.
    </div>
    <div *ngIf="user.status === Status.VOTER && !user.upgradeRequested && (!settings.isVotingOpen(user) || settings.isPastVotingDeadline())">
      In the meantime, if you have reached 200 games played since you joined the project, you are eligible to upgrade to nominator status!
    </div>
    <div *ngIf="!settings.isVotingOpen(user)">
      If you have any questions, check out the <a routerLink="/faq">FAQ</a>.
    </div>
    <div *ngIf="!settings.isVotingOpen() && settings.isVotingOpen(user) && !user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      The voting window isn't open for everybody, but you get early access because you're special!
    </div>
    <div *ngIf="settings.isVotingOpen() && !user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      <strong>The voting window is now open and ready for your input.</strong>
    </div>
    <div *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isVotingOpen(user) && !settings.isPastVotingDeadline()">
      Rankings are due <strong>{{settings.votingDeadlineString()}}</strong>
      ({{settings.timeToVotingDeadlineString()}} from now).
    </div>
    <div *ngIf="settings.isVotingOpen(user) && !user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      Check out our <a href="https://www.youtube.com/watch?v=d3NALWbQyrk" target="_blank">How to Vote</a>
      video for a walkthrough of the voting process.
    </div>
    <div *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isVotingOpen(user) && !settings.isPastVotingDeadline()">
      <b style="color: red">You have not yet submitted your rankings.</b>
    </div>
    <div *ngIf="settings.isPastVotingDeadline()">
      Voting for {{year}} is now closed.
    </div>
    <div *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
      Unfortunately you missed the voting deadline this year.  There's always next year!
    </div>
    <div *ngIf="user.rankingsSubmitted?.includes(year) && settings.isPastVotingDeadline()">
      Your rankings were successfully submitted.
    </div>
    <div *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      Your rankings have been successfully submitted.
    </div>
    <div *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      You now also have access to <b>review the data that will be made public</b> once results are announced.
    </div>
    <div *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()">
      If you want to make any changes before the {{settings.votingDeadlineString()}} deadline
      ({{settings.timeToVotingDeadlineString()}} from now), you may reopen your rankings
      and resubmit up until the deadline.
    </div>
    <div class="flex-row flex-wrap justify-start align-center gap-10">
      <button *ngIf="user.status === Status.VOTER && !user.upgradeRequested && (!settings.isVotingOpen(user) || settings.isPastVotingDeadline())"
              mat-raised-button color="primary" (click)="requestUpgrade = true">Request Upgrade to Nominator Status</button>
      <a *ngIf="!user.rankingsSubmitted?.includes(year) && settings.isVotingOpen(user) && !settings.isPastVotingDeadline()"
        mat-raised-button color="primary" routerLink="/rank">Rank Rooms</a>
      <a *ngIf="user.rankingsSubmitted?.includes(year)"
        mat-raised-button color="primary" routerLink="/rank">See Rankings</a>
      <a *ngIf="user.rankingsSubmitted?.includes(year) && !settings.isPastVotingDeadline()"
        mat-raised-button color="primary" routerLink="/review">Review Public Data</a>
    </div>
  </mat-card>
  <mat-card *ngIf="user.status === Status.VOTER && user.upgradeRequested && !requestUpgrade && !user.disabled" [style.background-color]="voterBackground()">
    <h3>
      Your nominator upgrade request has been submitted
    </h3>
    <div>
      Your request to upgrade to nominator status has been received and is currently under review. If you want to make any changes to your
      request while it is waiting to be reviewed, you may feel free to do so.
    </div>
    <div class="flex-row flex-wrap justify-start align-center gap-10">
      <button mat-raised-button color="primary" (click)="requestUpgrade = true">Edit Upgrade Request</button>
    </div>
  </mat-card>
  <mat-card *ngIf="user.status >= Status.REVIEWER && !user.disabled" class="gap-5">
    <h3>
      You're a reviewer!
    </h3>
    <div>
      As a reviewer, you have additional superpowers.
    </div>
    <div class="flex-row flex-wrap justify-start align-center gap-10">
      <a mat-raised-button routerLink="/approve" color="primary">Review Applicants</a>
      <a mat-raised-button routerLink="/match" color="primary">Process Nominations</a>
    </div>
  </mat-card>
  <mat-card *ngIf="utils.hasPastContributions(user) && !requestUpgrade && !user.disabled" class="gap-5">
    <h3>
      You're a returning contributor!
    </h3>
    <div>
      Thank you for your continued support of the project.
    </div>
    <div class="flex-row flex-wrap justify-start align-center gap-10">
      <a mat-raised-button routerLink="/profile" color="primary">Edit Profile</a>
      <a mat-raised-button routerLink="/history" color="primary">See History</a>
    </div>
  </mat-card>
</section>
