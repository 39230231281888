<mat-card *ngIf="!ready" class="flex-default">
  <p>Loading...</p>
</mat-card>
<mat-card *ngIf="ready" class="flex-col flex-default gap-10">
  <div class="flex-col justify-start align-start gap-10">
    <app-simpleroom [room]=room></app-simpleroom>
    <div *ngIf="currentQuote && !editable" class="yellow-box flex-col gap-5">
      <div class="smaller-text">Your quote about this room:</div>
      <div>
        <i>{{ currentQuote.quote }}</i>
      </div>
      <div *ngIf="currentQuote?.videoInterest && !editable" class="smaller-text">
        🎥 You want to present this room in this year's awards.
      </div>
    </div>
    <form *ngIf="editable" [formGroup]="formGroup">
      <mat-form-field appearance="fill">
        <mat-label>Write a brief quote about this room.</mat-label>
        <textarea matInput formControlName="quote" required
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
        <mat-hint>
          A 1-2 sentence, spoiler-free quote, in English, that captures what makes this room special.
        </mat-hint>
      </mat-form-field>
      <mat-checkbox formControlName="videoInterest">
        I want to present this room in this year's awards.
      </mat-checkbox>
    </form>
    <div *ngIf="!currentQuote && !editable && pastQuotes?.length" class="info-box">
      <div class="smaller-text">Your past quotes about this room:</div>
      <div *ngFor="let quote of pastQuotes">
        {{ quote.year }}: <i>{{ quote.quote }}</i>
      </div>
    </div>
    <div *ngIf="allowEdit && !editable">
      <button *ngIf="!currentQuote && !pastQuotes?.length" mat-raised-button color="primary" (click)="toggleEditable()">
        Add a Quote About This Room!
      </button>
      <button *ngIf="!currentQuote && pastQuotes?.length" mat-raised-button color="secondary" (click)="toggleEditable()">
        Add New Quote
      </button>
      <button *ngIf="currentQuote" mat-raised-button color="secondary" (click)="toggleEditable()">
        Edit Quote
      </button>
    </div>
    <div *ngIf="editable" class="flex-row justify-flex-end align-start gap-5">
      <button *ngIf="formGroup.dirty" mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="save()">
        Save
      </button>
      <button *ngIf="formGroup.dirty" mat-raised-button color="secondary" (click)="reset()">
        Reset
      </button>
      <button *ngIf="!formGroup.dirty" mat-raised-button color="primary" (click)="toggleEditable()">
        Close
      </button>
      <button *ngIf="currentQuote" mat-raised-button color="warn" (click)="delete()">
        Delete
      </button>
    </div>
  </div>
</mat-card>
