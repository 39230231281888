<mat-card *ngIf="user.status === Status.NONE || user.status === Status.VOTER || expanded">
  <h3 *ngIf="user.status !== Status.VOTER">Apply to Contribute!</h3>
  <h3 *ngIf="user.status === Status.VOTER">Upgrade to Nominator Status!</h3>
  <p *ngIf="user.status !== Status.VOTER">
    We are looking for the world's most experienced escape room enthusiasts to help identify the world's best escape rooms.
    If you'd like to join the team, let us know more about yourself!
  </p>
  <p *ngIf="user.status === Status.VOTER">
    Thanks for your interest in upgrading your account to become a nominator!
  </p>
  <p *ngIf="user.status === Status.VOTER && !user.upgradeRequested">
    To set expectations, you will need to have played at least <b>200 rooms</b> to be accepted as a nominator, and
    evidence for your numbers or references to vouch for them will be required.  If you're not quite at that experience
    level yet, keep on escaping and come back once you hit this milestone! Note that you should not request your upgrade
    until <b>after</b> you have played your 200th room; having booked the rooms but not yet played them is not sufficient.
  </p>
  <p *ngIf="user.status === Status.VOTER && !user.upgradeRequested">
    To upgrade, simply update and resubmit your application below with anything that's changed since you last applied.
    In particular, be sure to not only update your room count, but also <b>be sure to provide any evidence you have to
    support that you have reached the 200 rooms played mark.</b>
  </p>
  <p *ngIf="user.status === Status.VOTER && user.upgradeRequested">
    Your upgrade request has been submitted and is now under review, but you are welcome to edit any of the
    details below until a decision is made.
  </p>
  <button *ngIf="user.status === Status.VOTER && !user.upgradeRequested" mat-raised-button class="cancel-button"
          color="secondary" (click)="home.requestUpgrade = false">Cancel Upgrade Request</button>
  <div *ngIf="user.status !== Status.VOTER" class="rules flex-col justify-center align-center">
    <b style="color: red">IMPORTANT: Read this first!</b>
    <ul>
      <li>
        <b>If you have applied or participated in the past, please do not reapply!</b> If you cannot figure out how
        to sign in to the account you used last time, or you want to change the email or sign-in method for your
        account, contact admin&#64;terpeca.com.
      </li>
      <li>
        <b>This is an individual process.</b> If you regularly play with someone else, you must apply
        separately.  Applications submitted on behalf of more than one person will be rejected.
      </li>
      <li>
        To set expectations, you will need to have played at least <b>100 rooms</b> to be accepted as a voter, and
        at least <b>200 rooms</b> to be a nominator, and evidence for your numbers or references to vouch for them
        will be required. If you're not quite at that experience level yet, keep on escaping and come back next year!
      </li>
      <li>
        Please include <b>ALL</b> of your <b>active social media accounts</b> (in Step 1) and <b>room-tracking accounts</b>
        (in Step 2) to make it as easy as possible to find you online and to provide evidence of your experience.
      </li>
      <li *ngIf="settings.isNearVotingDeadline()">
        <b>Note that the voting window is closing soon, so applications submitted now may not have time to
        be reviewed and processed in time to vote this year.</b> We will still review your application,
        and if there is not time left to vote this year, you can still be approved for next year.
      </li>
      <li *ngIf="settings.isPastVotingDeadline()">
        <b>Voting has closed for the current year, but you can still apply to be considered
        as a contributor for next year.</b>
      </li>
      <li>
        If you have any other questions about the process, check out the <a routerLink="/faq">FAQ</a>.
      </li>
    </ul>
  </div>
  <mat-divider></mat-divider>
  <mat-horizontal-stepper [linear]=true labelPosition="bottom">
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup" >
        <ng-template matStepLabel>
          Basic info
        </ng-template>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Real name</mat-label>
            <input type="text" matInput formControlName="realName" required>
            <mat-hint>Your real name (first and last)</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <app-locationpicker [formGroup]="firstFormGroup" [forUser]=true></app-locationpicker>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Email address</mat-label>
            <input type="email" matInput formControlName="appEmail" required>
            <mat-hint>We will use this if we need to contact you, but we won't share it
                with anyone.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Facebook userid</mat-label>
            <input type="text" matInput formControlName="facebookUsername">
            <mat-hint>
              To determine your userid, visit your Facebook profile page.
              If the URL is <i>facebook.com/john.doe7</i>, enter <i>john.doe7</i> here.
            </mat-hint>
            <mat-error *ngIf="firstFormGroup.controls.facebookUsername.hasError('pattern')">
              Your Facebook userid is invalid. To determine your userid, visit your Facebook profile page.
              If the URL is <i>facebook.com/john.doe7</i>, enter <i>john.doe7</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Instagram userid</mat-label>
            <input type="text" matInput formControlName="instagramUsername">
            <mat-hint>
              To determine your userid, visit your Instagram profile page.
              If the URL is <i>instagram.com/john.doe7</i>, enter <i>john.doe7</i> here.
            </mat-hint>
            <mat-error *ngIf="firstFormGroup.controls.instagramUsername.hasError('pattern')">
              Your Instagram userid is invalid. To determine your userid, visit your Instagram profile page.
              If the URL is <i>instagram.com/john.doe7</i>, enter <i>john.doe7</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Discord username</mat-label>
            <input type="text" matInput formControlName="discordUsername">
            <mat-hint>To determine your username, visit your Discord profile.</mat-hint>
            <mat-error *ngIf="firstFormGroup.controls.discordUsername.hasError('pattern')">
              Your Discord username is invalid. To determine your username, visit your Discord profile.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Best contact method</mat-label>
            <select matNativeControl formControlName="bestContactMethod" required>
              <option value=""></option>
              <option *ngIf="firstFormGroup.value.appEmail || firstFormGroup.value.bestContactMethod === 'email'"
                      value="email">email to {{ firstFormGroup.value.appEmail || '(specify above)' }}</option>
              <option *ngIf="(user.email !== firstFormGroup.value.appEmail) || firstFormGroup.value.bestContactMethod === 'verifiedemail'"
                      value="verifiedemail">email to {{ user.email || '(n/a)' }}</option>
              <option *ngIf="firstFormGroup.value.facebookUsername || firstFormGroup.value.bestContactMethod === 'facebook'"
                      value="facebook">Facebook message to {{ firstFormGroup.value.facebookUsername || '(specify above)' }}</option>
              <option *ngIf="firstFormGroup.value.instagramUsername || firstFormGroup.value.bestContactMethod === 'instagram'"
                      value="instagram">Instagram message to {{ firstFormGroup.value.instagramUsername || '(specify above)' }}</option>
              <option *ngIf="firstFormGroup.value.discordUsername || firstFormGroup.value.bestContactMethod === 'discord'"
                      value="discord">Discord message to {{ firstFormGroup.value.discordUsername || '(specify above)' }}</option>
            </select>
            <mat-hint>Choose the most reliable way to get in touch with you if we have questions for you.</mat-hint>
          </mat-form-field>
        </p>
        <p style="margin-bottom: 100px">
        <mat-form-field appearance="fill">
            <mat-label>Bio</mat-label>
            <textarea matInput formControlName="bio" required
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
            <mat-hint>Write a short bio that introduces who you are and what role you play in the escape room community. Your bio must be
              well-written, in English, and will be displayed on the public TERPECA website in our contributors list. Please include
              any escape room related businesses you have any direct association with, past or present. For more details
              about what is expected here, see the <a href="/faq#bio" target="_blank">FAQ</a>.
            </mat-hint>
          </mat-form-field>
        </p>
        <p>
          <button mat-raised-button color="primary" [disabled]="!firstFormGroup.dirty" (click)="save(firstFormGroup)">Save</button>
          <button *ngIf="firstFormGroup.dirty" mat-raised-button matStepperNext color="primary" [disabled]="firstFormGroup.invalid"
                  (click)="save(firstFormGroup)">Save and Next</button>
          <button *ngIf="!firstFormGroup.dirty" mat-raised-button matStepperNext color="primary" [disabled]="firstFormGroup.invalid"
                  (click)="save(firstFormGroup)">Next</button>
        </p>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          Experience
        </ng-template>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Total escape rooms played (as of today)</mat-label>
            <input type="number" matInput formControlName="roomCount" required>
            <mat-hint>Estimate to the nearest round number <i>that you have definitely reached</i> if you don't have an exact count.
              DO include online escape rooms as defined below in this count.
              For clarification, see the <a href="/faq#what-counts" target="_blank">FAQ</a>.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Total online escape rooms played (as of today)</mat-label>
            <input type="number" matInput formControlName="virtualRoomCount" required [errorStateMatcher]="roomCountErrorMatcher">
            <mat-hint>This should include <b>only</b> hosted escape rooms played online, and these games should also
              be included in your total count above. For clarification, see the
              <a href="/faq#what-counts-online" target="_blank">FAQ</a>.</mat-hint>
            <mat-error *ngIf="secondFormGroup.errors?.invalidRoomCounts">
              Online room count cannot exceed total room count.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Room count evidence</mat-label>
            <textarea matInput formControlName="roomCountEvidence" required
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
            <mat-hint>Describe how you determined the numbers reported above. If you keep a list, include
              a link to it here, or send it to admin&#64;terpeca.com. For details, see the <a href="/faq#experience" target="_blank">FAQ</a></mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Regions played</mat-label>
            <textarea matInput formControlName="regionsPlayed" required
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
            <mat-hint>List all countries or regions where you have played 10 or more rooms.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Affiliations</mat-label>
            <textarea matInput formControlName="affiliations"
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
            <mat-hint>List all escape rooms or companies with which you are (or were) affiliated as an owner,
              designer, employee, investor, etc.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Online presence</mat-label>
            <textarea matInput formControlName="onlinePresence"
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
            <mat-hint>List any ER-related Facebook groups or other online communities such as Slack or Discord
              where you are active, including your username where applicable.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Other relevant experience</mat-label>
            <textarea matInput formControlName="otherExperience"
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
            <mat-hint>Anything else we might want to know about you, like blogs or conferences you run, records
              you hold, etc.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Personal references</mat-label>
            <textarea matInput formControlName="references" required
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
            <mat-hint>Provide contact information for 1 to 3 people active in the escape room community that we are
              likely to know and can vouch for your experience.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <mat-label>
            Room-Tracking Accounts
          </mat-label>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>EscapeTheReview.co.uk userid</mat-label>
            <input type="text" matInput formControlName="escapeTheReviewUserId">
            <mat-hint>
              To determine your userid, visit your EscapeTheReview.co.uk profile page.
              If the URL is <i>escapethereview.co.uk/profile/?user_id=1234</i>, enter <i>1234</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.escapeTheReviewUserId.hasError('pattern')">
              Your EscapeTheReview.co.uk userid is invalid. To determine your userid, visit your EscapeTheReview.co.uk profile page.
              If the URL is <i>escapethereview.co.uk/profile/?user_id=1234</i>, enter <i>1234</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>EscapeAll.gr userid</mat-label>
            <input type="text" matInput formControlName="escapeAllGrUserId">
            <mat-hint>
              To determine your userid, visit your EscapeAll.gr profile page.
              If the URL is <i>escapeall.gr/el/User/Profile/1234abc-567</i>, enter <i>1234abc-567</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.escapeAllGrUserId.hasError('pattern')">
              Your EscapeAll.gr userid is invalid. To determine your userid, visit your EscapeAll.gr profile page.
              If the URL is <i>escapeall.gr/el/User/Profile/1234abc-567</i>, enter <i>1234abc-567</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>EscapeGame.fr userid</mat-label>
            <input type="text" matInput formControlName="escapeGameFrUserId">
            <mat-hint>
              To determine your userid, visit your EscapeGame.fr profile page.
              If the URL is <i>escapegame.fr/profil/abcdefg</i>, enter <i>abcdefg</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.escapeGameFrUserId.hasError('pattern')">
              Your EscapeGame.fr userid is invalid. To determine your userid, visit your EscapeGame.fr profile page.
              If the URL is <i>escapegame.fr/profil/abcdefg</i>, enter <i>abcdefg</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Escape Roomers (Italy) userid</mat-label>
            <input type="text" matInput formControlName="escapeRoomersUserId">
            <mat-hint>
              To determine your userid, visit your Escape-Roomers.com profile page.
              If the URL is <i>escape-roomers.com/utente_home/123x456</i>, enter <i>123x456</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.escapeRoomersUserId.hasError('pattern')">
              Your Escape-Roomers.com userid is invalid. To determine your userid, visit your Escape-Roomers.com profile page.
              If the URL is <i>escape-roomers.com/utente_home/123x456</i>, enter <i>123x456</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>EscapeTalk.nl userid</mat-label>
            <input type="text" matInput formControlName="escapeTalkNlUserId">
            <mat-hint>
              To determine your userid, visit your EscapeTalk.nl profile page.
              If the URL is <i>escapetalk.nl/profiel/johndoe7</i>, enter <i>johndoe7</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.escapeTalkNlUserId.hasError('pattern')">
              Your EscapeTalk.nl userid is invalid. To determine your userid, visit your EscapeTalk.nl profile page.
              If the URL is <i>escapetalk.nl/profiel/johndoe7</i>, enter <i>johndoe7</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Lockme userid</mat-label>
            <input type="text" matInput formControlName="lockMeUserId">
            <mat-hint>
              To determine your userid, visit your Lockme profile page.
              If the URL is <i>lock.me/pl/user/123456</i>, enter <i>123456</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.lockMeUserId.hasError('pattern')">
              Your Lockme userid is invalid. To determine your userid, visit your Lockme profile page.
              If the URL is <i>lock.me/pl/user/123456</i>, enter <i>123456</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Morty username</mat-label>
            <input type="text" matInput formControlName="mortyAppUsername">
            <mat-hint>
              To determine your username, visit your Morty profile page.
              If the URL is <i>morty.app/&#64;johndoe7</i>, enter <i>johndoe7</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.mortyAppUsername.hasError('pattern')">
              Your Morty username is invalid. To determine your username, visit your Morty profile page.
              If the URL is <i>morty.app/&#64;johndoe7</i>, enter <i>johndoe7</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>The Escapers (France) userid</mat-label>
            <input type="text" matInput formControlName="theEscapersUserId">
            <mat-hint>
              To determine your userid, visit your The Escapers profile page.
              If the URL is <i>the-escapers.com/profil/john-doe-7</i>, enter <i>john-doe-7</i> here.
            </mat-hint>
            <mat-error *ngIf="secondFormGroup.controls.theEscapersUserId.hasError('pattern')">
              Your The Escapers userid is invalid. To determine your userid, visit your The Escapers profile page.
              If the URL is <i>the-escapers.com/profil/john-doe-7</i>, enter <i>john-doe-7</i> here.
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <button mat-raised-button matStepperPrevious color="primary" (click)="save(secondFormGroup)">Back</button>
          <button mat-raised-button color="primary" [disabled]="!secondFormGroup.dirty" (click)="save(secondFormGroup)">Save</button>
          <button *ngIf="secondFormGroup.dirty" mat-raised-button matStepperNext color="primary" [disabled]="secondFormGroup.invalid"
                  (click)="save(secondFormGroup)">Save and Next</button>
          <button *ngIf="!secondFormGroup.dirty" mat-raised-button matStepperNext color="primary" [disabled]="secondFormGroup.invalid"
                  (click)="save(secondFormGroup)">Next</button>
        </p>
      </form>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup">
      <form [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>
          Preferences
        </ng-template>
        <p>
          <mat-form-field appearance="fill">
            <mat-label>Favorite rooms</mat-label>
            <textarea matInput formControlName="favoriteRooms" required
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
            <mat-hint>List your three favorite escape rooms, and describe <i>without spoilers</i> what makes them your favorite.</mat-hint>
          </mat-form-field>
        </p>
        <p>
          <app-horrorpreferencepicker [formGroup]="thirdFormGroup"></app-horrorpreferencepicker>
        </p>
        <p>
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Preference ranking</mat-label>
            <div cdkDropList class="sortable-list" (cdkDropListDropped)="prefRanksChanged($event)">
              <div class="sortable-box" *ngFor="let pref of thirdFormGroup.value.prefsList; let i = index" cdkDrag>
                <div class="flex-row justify-start align-center gap-5">
                  <mat-icon cdkDragHandle>drag_handle</mat-icon>
                  <div>{{ i + 1 }}.</div>
                  {{pref}}
                </div>
              </div>
            </div>
            <input matInput formControlName="prefsList" hidden required>
            <mat-hint>Rank these categories from MOST important to LEAST important, according to your tastes. Don't stress
              about this, it is just for fun and mostly just here to give you practice with the ranking UI for later.
            </mat-hint>
          </mat-form-field>
        </p>
        <p>
          <button mat-raised-button matStepperPrevious color="primary" (click)="save(thirdFormGroup)">Back</button>
          <button mat-raised-button color="primary" [disabled]="!thirdFormGroup.dirty" (click)="save(thirdFormGroup)">Save</button>
          <button *ngIf="user.status === Status.VOTER"
                  mat-raised-button color="primary" [disabled]="thirdFormGroup.invalid" (click)="submit()">Submit Upgrade Request</button>
          <button *ngIf="user.status !== Status.VOTER"
                  mat-raised-button color="primary" [disabled]="thirdFormGroup.invalid" (click)="submit()">Submit Application</button>
        </p>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>
<mat-card *ngIf="user.status === Status.PENDING && !expanded">
  <h3>
    Your application has been submitted!
  </h3>
  <p>
    We will review your application and get back to you soon with further instructions.
  </p>
  <p>
    If you want to make any changes while it is waiting to be reviewed, you may feel free to do so.
  </p>
  <p>
    If you have any questions, check out the <a routerLink="/faq">FAQ</a>.
  </p>
  <button mat-raised-button color="primary" (click)="expanded = true">Edit Application</button>
</mat-card>
