import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import app from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TerpecaRanking } from 'src/app/models/ranking.model';
import { NominationId, TerpecaCategory } from 'src/app/models/room.model';
import {
    ApplicationResubmitRequestPayload, ApplicationStatus, TerpecaUser, UserAuditLogEntry, UserAuditLogEntryType
} from 'src/app/models/user.model';
import { trimInPlace } from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

import { AppComponent } from '../app.component';
import { TerpecaNomination } from '../models/nomination.model';
import { TerpecaQuote, maxQuotesPerYear } from '../models/quote.model';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  currentUser: TerpecaUser;
  user: Observable<TerpecaUser>;
  private firebaseUser: app.User;
  private firestoreDoc: AngularFirestoreDocument<TerpecaUser>;

  constructor(private settings: SettingsService, private afAuth: AngularFireAuth, private db: AngularFirestore, private router: Router) {
    this.user = this.afAuth.authState.pipe(switchMap((firebaseUser: app.User) => {
      this.firebaseUser = firebaseUser;
      if (firebaseUser) {
        this.firestoreDoc = this.userDoc(firebaseUser.uid);
        return this.firestoreDoc.valueChanges();
      } else {
        this.firestoreDoc = null;
        return of(null);
      }
    }));
    this.user.subscribe(async (currentUser: TerpecaUser) => {
      console.log(`current user is ${currentUser?.email || 'unknown'}`);
      this.currentUser = currentUser;
      if (this.firebaseUser && !currentUser) {
        // We have to be super sure that the doc doesn't exist so we don't overwrite good data.
        await this.firestoreDoc.ref.get().then(async doc => {
          if (!doc.exists) {
            const newUser: TerpecaUser = {
              createTime: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
              uid: this.firebaseUser.uid,
              email: this.firebaseUser.email,
              displayName: this.firebaseUser.displayName,
              appEmail: this.firebaseUser.email,
              realName: this.firebaseUser.displayName,
              isOwner: false,
              status: ApplicationStatus.NONE
            };
            await this.firestoreDoc.set(newUser, { merge: true });
          }
        });
      }
    });
  }

  async updateUser(partialUser: Partial<TerpecaUser>) {
    trimInPlace(partialUser);
    await this.firestoreDoc.update(partialUser);
  }

  async requestUpgrade(partialUser: Partial<TerpecaUser>) {
    await this.updateUser(partialUser);
    await this.firestoreDoc.update({
      upgradeRequested: true,
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.UPGRADE_REQUESTED, null)
    });
  }

  async denyUpgrade(uidToSet: string, payload: ApplicationResubmitRequestPayload) {
    await this.userDoc(uidToSet).update({
      upgradeRequested: <boolean><unknown>app.firestore.FieldValue.delete(),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.UPGRADE_DENIED, payload)
    });
  }

  async addReviewNote(uidToSet: string, note: string) {
    await this.userDoc(uidToSet).update({
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.REVIEW_NOTE_ADDED, note)
    });
  }

  async setShadowBan(uidToSet: string, on: boolean, note?: string) {
    const batch = this.db.firestore.batch();
    await this.db.collection<TerpecaRanking>('rankings').ref
    .where('year', '==', environment.currentAwardYear)
    .where('userId', '==', uidToSet).get()
    .then((snapshot: QuerySnapshot<TerpecaRanking>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaRanking>) => {
        batch.update(doc.ref, {
          shadowbanned: on
        });
      });
    });
    batch.update(this.userDoc(uidToSet).ref, {
      shadowbanned: on,
      auditLogEntry: this.newAuditLogEntry(on ? UserAuditLogEntryType.SHADOW_BAN_ON : UserAuditLogEntryType.SHADOW_BAN_OFF, note)
    });
    await batch.commit();
  }

  async submitApplication(partialUser: Partial<TerpecaUser>) {
    await this.updateUser(partialUser);
    await this.setApplicationStatus(this.currentUser.uid, ApplicationStatus.PENDING);
  }

  async reapply() {
    await this.setApplicationStatus(this.currentUser.uid, ApplicationStatus.NONE);
  }

  async setApplicationStatus(uidToSet: string, statusOrPayload: (ApplicationStatus | ApplicationResubmitRequestPayload)) {
    let applyStatus: ApplicationStatus;
    let payload: any;
    if (typeof statusOrPayload === 'number') {
      applyStatus = statusOrPayload;
      payload = { status: applyStatus };
    } else {
      applyStatus = statusOrPayload.status;
      payload = statusOrPayload;
    }
    const partialUser: Partial<TerpecaUser> = {
      status: applyStatus,
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.APPLICATION_STATUS_CHANGED, payload)
    };
    if (applyStatus === ApplicationStatus.DENIED) {
      partialUser.applicationDenied = <number[]><unknown>app.firestore.FieldValue.arrayUnion(environment.currentAwardYear);
    }
    if (applyStatus >= ApplicationStatus.NOMINATOR) {
      partialUser.upgradeRequested = <boolean><unknown>app.firestore.FieldValue.delete();
    }
    if (applyStatus === ApplicationStatus.REVIEWER) {
      // We turn notifications on by default for new ambassadors; they can then turn them off if they like.
      partialUser.notifyOnNewApplicant = true;
      partialUser.notifyOnNewRoom = true;
    }
    await this.userDoc(uidToSet).update(partialUser);
  }

  async addVouch(uidToSet: string, note: string) {
    await this.userDoc(uidToSet).update({
      vouchList: <string[]><unknown>app.firestore.FieldValue.arrayUnion(this.currentUser.uid),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.VOUCH_ADDED, note)
    });
  }

  async removeVouch(uidToSet: string, note: string) {
    await this.userDoc(uidToSet).update({
      vouchList: <string[]><unknown>app.firestore.FieldValue.arrayRemove(this.currentUser.uid),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.VOUCH_REMOVED, note)
    });
  }

  async addUpgradeVouch(uidToSet: string, note: string) {
    await this.userDoc(uidToSet).update({
      upgradeVouchList: <string[]><unknown>app.firestore.FieldValue.arrayUnion(this.currentUser.uid),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.UPGRADE_VOUCH_ADDED, note)
    });
  }

  async removeUpgradeVouch(uidToSet: string, note: string) {
    await this.userDoc(uidToSet).update({
      upgradeVouchList: <string[]><unknown>app.firestore.FieldValue.arrayRemove(this.currentUser.uid),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.UPGRADE_VOUCH_REMOVED, note)
    });
  }

  async confirmNominationRules() {
    await this.firestoreDoc.update({
      nominationRulesConfirmed: <number[]><unknown>app.firestore.FieldValue.arrayUnion(environment.currentAwardYear)
    });
  }

  async confirmRankingRules() {
    await this.firestoreDoc.update({
      rankingRulesConfirmed: <number[]><unknown>app.firestore.FieldValue.arrayUnion(environment.currentAwardYear)
    });
  }

  canChangeNominationStatus(user: TerpecaUser) {
    return this.currentUser.isOwner && user.status >= ApplicationStatus.NOMINATOR && this.settings.isNominationOpen();
  }

  async submitNominations(nominator?: TerpecaUser) {
    if (nominator && !this.canChangeNominationStatus(nominator)) {
      console.log(`Unable to submit nominations for ${nominator.realName}`);
      return;
    }
    const batch = this.db.firestore.batch();
    await this.db.collection<TerpecaNomination>('nominations').ref
    .where('year', '==', environment.currentAwardYear)
    .where('userId', '==', nominator ? nominator.uid : this.currentUser.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaNomination>) => {
        batch.update(doc.ref, {
          pending: false
        });
        const nom: TerpecaNomination = doc.data();
        if (nom.roomId) {
          const nomId: NominationId = { nominationId: doc.id, year: nom.year };
          batch.update(this.db.firestore.collection('rooms').doc(nom.roomId), {
            nominations: <NominationId[]><unknown>app.firestore.FieldValue.arrayUnion(nomId)
          });
        }
      });
    });
    batch.update(nominator ? this.userDoc(nominator.uid).ref : this.firestoreDoc.ref, {
      nominationsSubmitted: <number[]><unknown>app.firestore.FieldValue.arrayUnion(environment.currentAwardYear),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.NOMINATIONS_SUBMITTED, environment.currentAwardYear)
    });
    await batch.commit();
  }

  async reopenNominations(nominator?: TerpecaUser) {
    if (nominator && !this.canChangeNominationStatus(nominator)) {
      console.log(`Unable to reopen nominations for ${nominator.realName}`);
      return;
    }
    const batch = this.db.firestore.batch();
    await this.db.collection<TerpecaNomination>('nominations').ref
    .where('year', '==', environment.currentAwardYear)
    .where('userId', '==', nominator ? nominator.uid : this.currentUser.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaNomination>) => {
        batch.update(doc.ref, {
          pending: true
        });
        const nom: TerpecaNomination = doc.data();
        if (nom.roomId) {
          const nomId: NominationId = { nominationId: doc.id, year: nom.year };
          batch.update(this.db.firestore.collection('rooms').doc(nom.roomId), {
            nominations: <NominationId[]><unknown>app.firestore.FieldValue.arrayRemove(nomId)
          });
        }
      });
    });
    batch.update(nominator ? this.userDoc(nominator.uid).ref : this.firestoreDoc.ref, {
      nominationsSubmitted: <number[]><unknown>app.firestore.FieldValue.arrayRemove(environment.currentAwardYear),
      auditLogEntry: this.newAuditLogEntry(
        ((!nominator || this.currentUser.uid === nominator.uid) && !this.settings.isPastNominationDeadline()) ?
        UserAuditLogEntryType.NOMINATIONS_REOPENED :
        UserAuditLogEntryType.NOMINATIONS_REVOKED, environment.currentAwardYear)
    });
    await batch.commit();
  }

  canChangeRankingStatus(user: TerpecaUser) {
    return this.currentUser.isOwner && user.status >= ApplicationStatus.VOTER && this.settings.isVotingOpen();
  }

  async submitRankings(voter?: TerpecaUser) {
    if (voter && !this.canChangeRankingStatus(voter)) {
      console.log(`Unable to submit rankings for ${voter.realName}`);
      return;
    }
    const batch = this.db.firestore.batch();
    let regularRanking: TerpecaRanking;
    await this.db.collection<TerpecaRanking>('rankings').ref
    .where('year', '==', environment.currentAwardYear)
    .where('userId', '==', voter ? voter.uid : this.currentUser.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaRanking>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaRanking>) => {
        batch.update(doc.ref, {
          submitted: true
        });
        const ranking: TerpecaRanking = doc.data();
        if (ranking?.category === TerpecaCategory.TOP_ROOM) {
          regularRanking = ranking;
        }
      });
    });
    if (environment.currentAwardYear >= 2023 && regularRanking) {
      await this.db.collection<TerpecaQuote>('quotes').ref
      .where('year', '==', environment.currentAwardYear)
      .where('userId', '==', voter ? voter.uid : this.currentUser.uid).get()
      .then((snapshot: QuerySnapshot<TerpecaQuote>) => {
        snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaQuote>) => {
          const quote: TerpecaQuote = doc.data();
          if (quote && regularRanking.rankedIds?.slice(0, maxQuotesPerYear).includes(quote.roomId)) {
            batch.update(doc.ref, {
              submitted: true
            });
          }
        });
      });
    }
    batch.update(voter ? this.userDoc(voter.uid).ref : this.firestoreDoc.ref, {
      rankingsSubmitted: <number[]><unknown>app.firestore.FieldValue.arrayUnion(environment.currentAwardYear),
      auditLogEntry: this.newAuditLogEntry(UserAuditLogEntryType.RANKINGS_SUBMITTED, environment.currentAwardYear)
    });
    await batch.commit();
  }

  async reopenRankings(voter?: TerpecaUser) {
    if (voter && !this.canChangeRankingStatus(voter)) {
      console.log(`Unable to reopen rankings for ${voter.realName}`);
      return;
    }
    const batch = this.db.firestore.batch();
    await this.db.collection<TerpecaRanking>('rankings').ref
    .where('year', '==', environment.currentAwardYear)
    .where('userId', '==', voter ? voter.uid : this.currentUser.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaRanking>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaRanking>) => {
        batch.update(doc.ref, {
          submitted: false
        });
      });
    });
    if (environment.currentAwardYear >= 2023) {
      await this.db.collection<TerpecaQuote>('quotes').ref
      .where('year', '==', environment.currentAwardYear)
      .where('userId', '==', voter ? voter.uid : this.currentUser.uid).get()
      .then((snapshot: QuerySnapshot<TerpecaQuote>) => {
        snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaQuote>) => {
          batch.update(doc.ref, {
            submitted: false
          });
        });
      });
    }
    batch.update(voter ? this.userDoc(voter.uid).ref : this.firestoreDoc.ref, {
      rankingsSubmitted: <number[]><unknown>app.firestore.FieldValue.arrayRemove(environment.currentAwardYear),
      auditLogEntry: this.newAuditLogEntry(
        ((!voter || this.currentUser.uid === voter.uid) && !this.settings.isPastVotingDeadline()) ?
        UserAuditLogEntryType.RANKINGS_REOPENED :
        UserAuditLogEntryType.RANKINGS_REVOKED, environment.currentAwardYear)
    });
    await batch.commit();
  }

  userDoc(uid: string): AngularFirestoreDocument<TerpecaUser> {
    return this.db.doc<TerpecaUser>(`users/${uid}`);
  }

  newAuditLogEntry(type: UserAuditLogEntryType, data?: any): UserAuditLogEntry {
    return {
      uid: this.currentUser.uid,
      name: this.currentUser.realName || this.currentUser.displayName,
      entryType: type,
      payload: data,
      timestamp: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
      ipAddress: AppComponent.ipAddress || ''
    };
  }

  logout(): void {
    this.afAuth.signOut().then(async (_res) => { await this.router.navigate(['/login']); });
  }
}
