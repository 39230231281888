import { Component } from '@angular/core';

import { ApplicationStatus } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { isRoomFinderPage, roomFinderUrl, voterPortalUrl } from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  collapsed: boolean = true;
  Status = ApplicationStatus;
  projectTitle = environment.projectTitle;
  toolbarColor = environment.toolbarColor;
  year = environment.currentAwardYear;

  isRoomFinder = isRoomFinderPage();
  roomFinderUrl = roomFinderUrl();
  voterPortalUrl = voterPortalUrl();

  constructor(public auth: AuthService, public settings: SettingsService) { }
}
