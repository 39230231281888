<section *ngIf="auth.currentUser as user">
  <div *ngIf="user.isOwner || user.status >= Status.REVIEWER" class="flex-col justify-center align-center gap-5">
    <h3 class="flex-row justify-center align-center">
      TERPECA Data Analyzer
    </h3>
    <form [formGroup]="formGroup" class="flex-col justify-center align-center gap-5">
      <div class="flex-row flex-wrap justify-center gap-5">
        <mat-form-field appearance="fill">
          <mat-label>Year</mat-label>
          <select matNativeControl formControlName="year">
            <option *ngFor="let y of allYears()" [ngValue]=y>{{ y }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Category</mat-label>
          <select matNativeControl formControlName="category">
            <option *ngFor="let c of allCategories(formGroup.value.year)" [ngValue]=c>{{ categoryName(c) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label><span *ngIf="!hadDirectCompanyVoting(formGroup.value.year)">IRL Room</span> Algorithm</mat-label>
          <select matNativeControl formControlName="irlApproach">
            <option *ngFor="let a of allResultsApproaches" [ngValue]=a>{{ approachName(a, Category.TOP_ROOM) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="hadOnlineRoomVoting(formGroup.value.year)" appearance="fill">
          <mat-label>Online Room Algorithm</mat-label>
          <select matNativeControl formControlName="onlineApproach">
            <option *ngFor="let a of allResultsApproaches" [ngValue]=a>{{ approachName(a, Category.TOP_ONLINE_ROOM) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Confidence</mat-label>
          <select matNativeControl formControlName="p">
            <option *ngFor="let pVal of [0.05, 0.1, 0.5, 1.0]" [ngValue]=pVal>{{ pValueName(pVal) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Vote Weighting</mat-label>
          <select matNativeControl formControlName="voteWeightingApproach">
            <option *ngFor="let a of allVoteWeightingApproaches" [ngValue]=a>{{ voteWeightingApproachName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Local Adj</mat-label>
          <select matNativeControl formControlName="doLocalAdjustments">
            <option *ngFor="let a of [true, false]" [ngValue]=a>{{ a ? 'yes (2022)' : 'no (2018-2021,2023)' }}</option>
          </select>
        </mat-form-field>
      </div>
      <div *ngIf="!hadDirectCompanyVoting(formGroup.value.year)" class="flex-row flex-wrap justify-center gap-5">
        <mat-form-field appearance="fill">
          <mat-label>Company Algorithm</mat-label>
          <select matNativeControl formControlName="companyAlgorithm">
            <option *ngFor="let a of allCompanyAlgorithms" [ngValue]=a>{{ companyAlgorithmName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="formGroup.value.companyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING" appearance="fill">
          <mat-label>Inflection Point Rank</mat-label>
          <select matNativeControl formControlName="inflectionRank">
            <option *ngFor="let ipr of [50, 75, 100, 125, 150]" [ngValue]=ipr>#{{ ipr }} = 0.5 credit</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="formGroup.value.companyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING" appearance="fill">
          <mat-label>Tail Reference Credit</mat-label>
          <select matNativeControl formControlName="tailReferenceCredit">
            <option *ngFor="let trc of [.01, .05, .1, .15, .2]" [ngValue]=trc>#{{ 2 * formGroup.value.inflectionRank }} = {{ trc }} credit</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="hadOnlineRoomVoting(formGroup.value.year) &&
                               formGroup.value.companyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING" appearance="fill">
          <mat-label>Online Equivalent Rank</mat-label>
          <select matNativeControl formControlName="topOnlineEquivalentRank">
            <option *ngFor="let tor of [50, 75, 100, 125, 150, 175, 200]" [ngValue]=tor>#{{ tor }} IRL = #1 online</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="hadOnlineRoomVoting(formGroup.value.year)" appearance="fill">
          <mat-label>Online/IRL Pairs</mat-label>
          <select matNativeControl formControlName="onlineIrlPairWeight">
            <option [ngValue]=1>1 game total</option>
            <option [ngValue]=1.5>1.5 games total</option>
            <option [ngValue]=2>2 games total</option>
          </select>
        </mat-form-field>
      </div>
      <div class="flex-row flex-wrap justify-center gap-5">
        <mat-form-field appearance="fill">
          <mat-label>User Country Filter</mat-label>
          <select matNativeControl formControlName="userCountryFilter">
            <option *ngFor="let c of allCountryFilters()" [ngValue]=c>{{ countryFilterLabel(c) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="min-width: 200px;">
          <mat-label>Horror Preference Filter</mat-label>
          <mat-select matNativeControl formControlName="horrorPreferenceFilter" multiple>
          <mat-select-trigger>
            <span *ngIf="formGroup.value.horrorPreferenceFilter?.length < allHorrorPreferences.length">
              <span *ngFor="let p of formGroup.value.horrorPreferenceFilter">
                <app-horrorpreference [pref]=p [showShortText]=true></app-horrorpreference>
              </span>
            </span>
            <span *ngIf="formGroup.value.horrorPreferenceFilter?.length === allHorrorPreferences.length">
              all horror prefs
            </span>
          </mat-select-trigger>
            <mat-option *ngFor="let p of allHorrorPreferences" [value]=p>
              <app-horrorpreference [pref]=p [showShortText]=true></app-horrorpreference>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Room Country Filter</mat-label>
          <select matNativeControl formControlName="roomCountryFilter">
            <option *ngFor="let c of allCountryFilters()" [ngValue]=c>{{ countryFilterLabel(c) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="min-width: 200px;">
          <mat-label>Horror Level Filter</mat-label>
          <mat-select matNativeControl formControlName="horrorLevelFilter" multiple>
          <mat-select-trigger>
            <span *ngIf="formGroup.value.horrorLevelFilter?.length < allHorrorLevels.length">
              <span *ngFor="let h of formGroup.value.horrorLevelFilter">
                <app-horrorlevel [level]=h [showShortText]=true></app-horrorlevel>
              </span>
            </span>
            <span *ngIf="formGroup.value.horrorLevelFilter?.length === allHorrorLevels.length">
              all horror levels
            </span>
          </mat-select-trigger>
            <mat-option *ngFor="let h of allHorrorLevels" [value]=h>
              <app-horrorlevel [level]=h [showShortText]=true></app-horrorlevel>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="wasDeepInsideAFinalist(formGroup.value.year)" appearance="fill">
          <mat-label>Deep Inside</mat-label>
          <select matNativeControl formControlName="deepInsideApproach">
            <option *ngFor="let a of allRoomVersionApproaches" [ngValue]=a>{{ roomVersionApproachName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="wasDoorsOfDivergenceAFinalist(formGroup.value.year)" appearance="fill">
          <mat-label>Doors of Divergence</mat-label>
          <select matNativeControl formControlName="doorsOfDivergenceApproach">
            <option *ngFor="let a of allRoomVersionApproaches" [ngValue]=a>{{ roomVersionApproachName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="wasPetraAFinalist(formGroup.value.year)" appearance="fill">
          <mat-label>Petra</mat-label>
          <select matNativeControl formControlName="petraApproach">
            <option *ngFor="let a of allRoomVersionApproaches" [ngValue]=a>{{ roomVersionApproachName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="wasSanatoriumAFinalist(formGroup.value.year)" appearance="fill">
          <mat-label>Sanatorium</mat-label>
          <select matNativeControl formControlName="sanatoriumApproach">
            <option *ngFor="let a of allRoomVersionApproaches" [ngValue]=a>{{ roomVersionApproachName(a) }}</option>
          </select>
        </mat-form-field>
        <mat-form-field *ngIf="wasDeepInsideAFinalist(formGroup.value.year)" appearance="fill">
          <mat-label>Deep Inside (Company)</mat-label>
          <select matNativeControl formControlName="deepInsideCompanyApproach">
            <option *ngFor="let a of allCompanyVersionApproaches" [ngValue]=a>{{ companyVersionApproachName(a) }}</option>
          </select>
        </mat-form-field>
      </div>
      <div *ngIf="user.isOwner" class="gap-5">
        <mat-checkbox formControlName="findAnomalies">Find Anomalous Users</mat-checkbox>
      </div>
    </form>
    <div class="flex-row flex-wrap justify-center gap-5">
      <button mat-raised-button color="primary" [disabled]="!canLoad()" (click)="reload()">
        Reload Data
      </button>
      <button mat-raised-button color="primary" [disabled]="!canAnalyze()" (click)="analyze()">
        Run Analysis
      </button>
      <button *ngIf="user.isOwner && ALLOW_CAPTURE_RESULTS" mat-raised-button color="primary" [disabled]="!canCaptureResults()" (click)="captureResults()">
        Capture Results
      </button>
    </div>
    <div *ngFor="let line of log">
      {{ line }}
    </div>
    <div *ngIf="canShowResults()" class="flex-col justify-center align-center">
      <div>
        {{ yearLoaded }} {{ categoryName(lastCategoryAnalyzed) }}
      </div>
      <div *ngIf="hadDirectCompanyVoting(formGroup.value.year)">
        Algorithm: {{ approachName(lastIrlApproach, lastCategoryAnalyzed) }}, p = {{ lastPValue }},
        vote weighting = {{ voteWeightingApproachName(lastVoteWeightingApproach)}}
      </div>
      <div *ngIf="!hadDirectCompanyVoting(formGroup.value.year)">
        IRL Room Algorithm: {{ approachName(lastIrlApproach, Category.TOP_ROOM) }}, p = {{ lastPValue }},
        vote weighting = {{ voteWeightingApproachName(lastVoteWeightingApproach) }},
        {{ lastDoLocalAdjustments ? 'local adjustments' : 'no adjustments' }}
      </div>
      <div *ngIf="hadOnlineRoomVoting(formGroup.value.year)">
        Online Room Algorithm: {{ approachName(lastOnlineApproach, Category.TOP_ONLINE_ROOM) }}, p = {{ lastPValue }},
        vote weighting = {{ voteWeightingApproachName(lastVoteWeightingApproach) }},
        {{ lastDoLocalAdjustments ? 'local adjustments' : 'no adjustments' }}
      </div>
      <div *ngIf="wasDeepInsideAFinalist(formGroup.value.year) || wasDoorsOfDivergenceAFinalist(formGroup.value.year) || wasPetraAFinalist(formGroup.value.year) || wasSanatoriumAFinalist(formGroup.value.year)">
        Room-Specific Options:
        <span *ngIf="wasDeepInsideAFinalist(formGroup.value.year)">
          Deep Inside {{ roomVersionApproachName(lastDeepInsideApproach) }},
        </span>
        <span *ngIf="wasDoorsOfDivergenceAFinalist(formGroup.value.year)">
          Doors of Divergence {{ roomVersionApproachName(lastDoorsOfDivergenceApproach) }},
        </span>
        <span *ngIf="wasPetraAFinalist(formGroup.value.year)">
          Petra {{ roomVersionApproachName(lastPetraApproach) }},
        </span>
        <span *ngIf="wasSanatoriumAFinalist(formGroup.value.year)">
          Sanatorium {{ roomVersionApproachName(lastSanatoriumApproach) }}
        </span>
      </div>
      <div *ngIf="!hadDirectCompanyVoting(formGroup.value.year)">
        Company Algorithm: {{ companyAlgorithmName(lastCompanyAlgorithm)}},
        <span *ngIf="lastCompanyAlgorithm === CompanyAlgorithm.SIGMOID_FUNCTION_MAPPING">
          #{{ lastInflectionRank }} = 0.5 credit,
          #{{ 2 * lastInflectionRank }} = {{ lastTailReferenceCredit }} credit,
          #{{ lastTopOnlineEquivalentRank }} IRL = #1 online,
        </span>
        <span *ngIf="hadOnlineRoomVoting(formGroup.value.year)">
          online/IRL pair weight = {{ lastOnlineIrlPairWeight }},
        </span>
      </div>
      <div *ngIf="wasDeepInsideAFinalist(formGroup.value.year)">
        Company-Specific Options:
        <span>
          Deep Inside {{ companyVersionApproachName(lastDeepInsideCompanyApproach) }},
        </span>
      </div>
      <div>
        Unweighted Vote Pairs Metric: {{ percentFromMetric(unweightedVotePairsMetric) | number: '0.4-4' }}
        ({{ unweightedVotePairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ unweightedVotePairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
      <div>
        Experienced (>50 Ranked Rooms) Vote Pairs Metric: {{ percentFromMetric(experiencedVotePairsMetric) | number: '0.4-4' }}
        ({{ experiencedVotePairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ experiencedVotePairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
      <div>
        Inexperienced (&lt;=50 Ranked Rooms) Vote Pairs Metric: {{ percentFromMetric(inexperiencedVotePairsMetric) | number: '0.4-4' }}
        ({{ inexperiencedVotePairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ inexperiencedVotePairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
      <div>
        Unweighted Room Pairs Metric: {{ percentFromMetric(unweightedRoomPairsMetric) | number: '0.4-4' }}
        ({{ unweightedRoomPairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ unweightedRoomPairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
      <div>
        High Percentage (>60%) Room Pairs Metric: {{ percentFromMetric(highPercentageRoomPairsMetric) | number: '0.4-4' }}
        ({{ highPercentageRoomPairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ highPercentageRoomPairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
      <div>
        Super High Percentage (>80%) Room Pairs Metric: {{ percentFromMetric(superHighPercentageRoomPairsMetric) | number: '0.4-4' }}
        ({{ superHighPercentageRoomPairsMetric.pairsPredicted | number: '0.0-1' }} predicted, {{ superHighPercentageRoomPairsMetric.pairsViolated | number: '0.0-1' }} violated)
      </div>
    </div>
    <div *ngIf="canShowRoomResults()" class="flex-default" style="width: 95%; overflow-x: auto;">
      <table mat-table [dataSource]="roomDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="rank">
          <th mat-header-cell *matHeaderCellDef>Rank</th>
          <td mat-cell *matCellDef="let entity">{{entity.unranked ? 'NR' : entity.rank}}{{ALLOW_CAPTURE_RESULTS && entity.originalRank && entity.rank !== entity.originalRank ? '*' : ''}}</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef>Room</th>
          <td mat-cell *matCellDef="let entity"><app-simpleroom [room]=roomMap.get(entity.docId)
                                                                [hideCategoryLogo]=true
                                                                [showNameOnly]=true
                                                                [showNewLogo]=showNewLogo(user,entity.docId)
                                                                [canShowWinnerLogo]=true
                                                                [year]=yearLoaded></app-simpleroom></td>
        </ng-container>
        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let entity">{{roomMap.get(entity.docId).company}}</td>
        </ng-container>
        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let entity">{{roomMap.get(entity.docId).country}}</td>
        </ng-container>
        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let entity">
            <app-horrorlevel [level]="horrorLevel(entity.docId)"></app-horrorlevel>
            <span *ngFor="let l of languages(entity.docId)">{{' '}}<app-language [alpha2]="l"></app-language></span>
          </td>
        </ng-container>

        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef>Room</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let entity">{{getLocationString(roomMap.get(entity.docId)) || ''}}</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let entity">{{roomMap.get(entity.docId).email || ''}}</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef>URL</th>
          <td mat-cell *matCellDef="let entity">{{roomMap.get(entity.docId).link || ''}}</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef>URL</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>

        <ng-container matColumnDef="plays">
          <th mat-header-cell *matHeaderCellDef>Players</th>
          <td mat-cell *matCellDef="let entity">{{entity.plays}}</td>
        </ng-container>
        <ng-container matColumnDef="directComps">
          <th mat-header-cell *matHeaderCellDef>Coverage</th>
          <td mat-cell *matCellDef="let entity">{{entity.coverage}}</td>
        </ng-container>
        <ng-container matColumnDef="allComps">
          <th mat-header-cell *matHeaderCellDef>Comps</th>
          <td mat-cell *matCellDef="let entity">{{entity.allComps | number: '0.0-2'}}</td>
        </ng-container>
        <ng-container matColumnDef="abstains">
          <th mat-header-cell *matHeaderCellDef>Abstains</th>
          <td mat-cell *matCellDef="let entity">{{entity.unrankedReasonEntries?.length || 0}}</td>
        </ng-container>
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef>Score</th>
          <td mat-cell *matCellDef="let entity">{{entity.score | number: '0.6-6'}}</td>
        </ng-container>
        <ng-container matColumnDef="credit">
          <th mat-header-cell *matHeaderCellDef>Credit</th>
          <td mat-cell *matCellDef="let entity">{{entity.companyCredit | number: '0.6-6'}}</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="lastRank">
          <th mat-header-cell *matHeaderCellDef>Last Rank</th>
          <td mat-cell *matCellDef="let entity">{{lastRank(entity.docId)}}</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="lastRank">
          <th mat-header-cell *matHeaderCellDef>Last Rank</th>
          <td mat-cell *matCellDef="let entity">redacted</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let entity">
            <button mat-raised-button class="table-button" color="secondary" (click)="showDetails(entity)">
              Details
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="roomColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: roomColumns;"
            [ngClass]="{'non-winner-row': !isWinnerByRank(row.rank, lastCategoryAnalyzed, yearLoaded)}"></tr>
      </table>
    </div>

    <div *ngIf="canShowCompanyResults()" class="flex-default">
      <table mat-table [dataSource]="companyDataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="rank">
          <th mat-header-cell *matHeaderCellDef>Rank</th>
          <td mat-cell *matCellDef="let company">{{company.rank}}</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded)"  matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let company">{{company.name}} ({{getCommonLocation(company.rooms)}})</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded)"  matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let company">redacted</td>
        </ng-container>

        <ng-container matColumnDef="top25rooms">
          <th mat-header-cell *matHeaderCellDef>Top 25 Rooms</th>
          <td mat-cell *matCellDef="let company">{{company.top25rooms.length}}</td>
        </ng-container>
        <ng-container matColumnDef="top50rooms">
          <th mat-header-cell *matHeaderCellDef>Top 50 Rooms</th>
          <td mat-cell *matCellDef="let company">{{company.top50rooms.length}}</td>
        </ng-container>
        <ng-container matColumnDef="top100rooms">
          <th mat-header-cell *matHeaderCellDef>Top 100 Rooms /<br>Top 10 Online Rooms</th>
          <td mat-cell *matCellDef="let company">{{company.top100rooms.length + (company.top10onlinerooms.length > 0 ? ' + ' + company.top10onlinerooms.length : '')}}</td>
        </ng-container>
        <ng-container matColumnDef="top200rooms">
          <th mat-header-cell *matHeaderCellDef>Top 200 Rooms /<br>Top 20 Online Rooms</th>
          <td mat-cell *matCellDef="let company">{{company.top200rooms.length + (company.top20onlinerooms.length > 0 ? ' + ' + company.top20onlinerooms.length : '')}}</td>
        </ng-container>
        <ng-container matColumnDef="finalists">
          <th mat-header-cell *matHeaderCellDef>Finalists</th>
          <td mat-cell *matCellDef="let company">{{company.finalists.length}}</td>
        </ng-container>
        <ng-container matColumnDef="nominees">
          <th mat-header-cell *matHeaderCellDef>Nominees</th>
          <td mat-cell *matCellDef="let company">{{company.nominees.length}}</td>
        </ng-container>
        <ng-container matColumnDef="credit">
          <th mat-header-cell *matHeaderCellDef>Total Credit</th>
          <td mat-cell *matCellDef="let company">{{company.score | number: '0.8-8'}}</td>
        </ng-container>
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef>Norm Score</th>
          <td mat-cell *matCellDef="let company">{{company.normScore | number: '0.8-8'}}</td>
        </ng-container>

        <ng-container *ngIf="canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let company">{{getEmails(company.rooms)}}</td>
        </ng-container>
        <ng-container *ngIf="!canShowRoomTitles(user, yearLoaded) && SHOW_ALL_DATA_IN_TABLE"  matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let company">redacted</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef>Details</th>
          <td mat-cell *matCellDef="let company">
            <button mat-raised-button class="table-button" color="secondary" (click)="showCompanyDetails(company)">
              Details
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="companyColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: companyColumns;"
            [ngClass]="{'non-winner-row': !isWinnerByRank(row.rank, lastCategoryAnalyzed, yearLoaded)}"></tr>
      </table>
    </div>
    <h4 *ngIf="anomalousUsers?.length">
      Anomalous Users
    </h4>
    <div *ngFor="let u of anomalousUsers">
      {{u[0].userId}}{{ u[0].shadowbanned ? ' 🕶️ ' : ''}} - {{u[1]*100 | number: '0.2-2'}}% uncorrelated of {{u[0].rankedIds.length}} rooms
      <button mat-raised-button class="table-button" color="secondary" (click)="showAnonymizedRankings(u[0])">
        Details
      </button>
    </div>
  </div>
</section>
