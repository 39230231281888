import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApplicationResubmitReason, ApplicationResubmitRequestPayload, ApplicationStatus } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-requestresubmitdialog',
  templateUrl: './requestresubmitdialog.component.html',
  styleUrl: './requestresubmitdialog.component.css'
})
export class RequestResubmitDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<RequestResubmitDialogComponent>, private auth: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      onePersonOnly: new UntypedFormControl(''),
      insufficientIdentityInfo: new UntypedFormControl(''),
      insufficientGameCount: new UntypedFormControl(''),
      insufficientExperienceInfo: new UntypedFormControl(''),
      insufficientConflictDisclosure: new UntypedFormControl(''),
      invalidName: new UntypedFormControl(''),
      invalidCity: new UntypedFormControl(''),
      invalidFacebook: new UntypedFormControl(''),
      insufficientBio: new UntypedFormControl(''),
      custom: new UntypedFormControl(''),
      customInstructions: new UntypedFormControl(''),
      ccMe: new UntypedFormControl(true)
    });
  }

  getPayload(): ApplicationResubmitRequestPayload {
    const reasons: ApplicationResubmitReason[] = [];
    if (this.form.value.onePersonOnly) {
      reasons.push(ApplicationResubmitReason.ONE_PERSON_ONLY);
    }
    if (this.form.value.insufficientIdentityInfo) {
      reasons.push(ApplicationResubmitReason.INSUFFICIENT_IDENTITY_INFO);
    }
    if (this.form.value.insufficientGameCount) {
      reasons.push(ApplicationResubmitReason.INSUFFICIENT_GAME_COUNT);
    }
    if (this.form.value.insufficientExperienceInfo) {
      reasons.push(ApplicationResubmitReason.INSUFFICIENT_EXPERIENCE_INFO);
    }
    if (this.form.value.insufficientConflictDisclosure) {
      reasons.push(ApplicationResubmitReason.INSUFFICIENT_CONFLICT_DISCLOSURE);
    }
    if (this.form.value.invalidName) {
      reasons.push(ApplicationResubmitReason.INVALID_NAME);
    }
    if (this.form.value.invalidCity) {
      reasons.push(ApplicationResubmitReason.INVALID_CITY);
    }
    if (this.form.value.invalidFacebook) {
      reasons.push(ApplicationResubmitReason.INVALID_FACEBOOK);
    }
    if (this.form.value.insufficientBio) {
      reasons.push(ApplicationResubmitReason.INSUFFICIENT_BIO);
    }
    if (this.form.value.custom && this.form.value.customInstructions) {
      reasons.push(ApplicationResubmitReason.CUSTOM);
    }
    const payload: ApplicationResubmitRequestPayload = {
      status: this.data.applicant.user.upgradeRequested ? this.data.applicant.user.status : ApplicationStatus.NONE,
      reasons
    };
    if (this.form.value.custom && this.form.value.customInstructions) {
      payload.customInstructions = this.form.value.customInstructions;
    }
    if (this.form.value.ccMe) {
      payload.ccEmail = this.auth.currentUser.appEmail || this.auth.currentUser.email;
    }
    return payload;
  }

  formIsValid(): boolean {
    return this.getPayload().reasons.length > 0;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async submit() {
    if (this.data.applicant.user.upgradeRequested) {
      await this.data.applicant.auth.denyUpgrade(this.data.applicant.user.uid, this.getPayload());
    } else {
      await this.data.applicant.auth.setApplicationStatus(this.data.applicant.user.uid, this.getPayload());
    }
    this.dialogRef.close(true);
    this.data.applicant.resubmitRequested();
  }
}
