<section>
  <div class="flex-col justify-center align-center">
  <mat-card>
    <h3>
      Congratulations, you solved the puzzle!
    </h3>
    <p>
      To learn more about participating in the Top Escape Rooms Project, visit the
      <a routerLink="/faq">FAQ</a>, or if you like, just <a routerLink="/">apply</a>!
    </p>
  </mat-card>
  </div>
</section>
