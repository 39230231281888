import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// This simple service makes a query to a webservice that returns the IP address of the client.
// TODO: Switch this to use a Cloud Function to improve security and robustness.

@Injectable({
  providedIn: 'root'
})
export class IpService {
  constructor(private http: HttpClient) { }

  // Returns an observable that provides the IP address as a string.
  public getIPAddress() {
    return this.http.get('https://api.ipify.org/', { responseType: 'text' });
  }
}
