import { Component, Inject, NgZone, OnInit, Optional } from '@angular/core';
import { FIREBASE_APP_NAME, FIREBASE_OPTIONS, FirebaseApp, ɵfirebaseAppFactory } from '@angular/fire/compat';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FirebaseAppSettings, FirebaseOptions } from 'firebase/app';
import app from 'firebase/compat/app';
import { auth as firebaseuiauth } from 'firebaseui';

import { environment } from 'src/environments/environment';

import { SimpleDialogComponent } from '../simpledialog/simpledialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  ui: firebaseuiauth.AuthUI;
  showSpinner = true;

  constructor(@Inject(FIREBASE_OPTIONS) private options: FirebaseOptions,
              @Optional() @Inject(FIREBASE_APP_NAME) private nameOrConfig: string | FirebaseAppSettings | null | undefined,
              private router: Router, private ngZone: NgZone, private dialog: MatDialog) { }

  ngOnInit() {
    const application: FirebaseApp = ɵfirebaseAppFactory(this.options, this.ngZone, this.nameOrConfig);
    const uiConfig = {
      signInFlow: environment.production ? 'redirect' : 'popup',
      signInOptions: [
        {
          provider: app.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: app.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
        },
        {
          provider: app.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account'
          }
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: this.onLoginSuccessful.bind(this),
        uiShown: () => { this.showSpinner = this.ui.isPendingRedirect(); }
      }
    };
    this.ui = firebaseuiauth.AuthUI.getInstance() || new firebaseuiauth.AuthUI(application.auth());
    this.ui.start('#firebaseui-auth-container', uiConfig);
  }

  onLoginSuccessful() {
    this.ngZone.run(() => {
      this.router.navigate(['']);
    });
  }

  async whereIsFacebookDialog() {
    this.dialog.open(SimpleDialogComponent, {
      data: {
        title: 'Where is "Sign in with Facebook"?',
        message: 'Unfortunately Facebook Login is no longer supported for non-businesses, so we had to remove the feature.<br><br>' +
        'If you originally signed up using Facebook, you should be able to sign in with one of our other options using the same email ' +
        'address that was associated with your account, but if you run into issues or just want to switch to a different email address, ' +
        'please contact admin@terpeca.com and we\'ll get you sorted out.',
        hideCancel: true,
      }
    });
  }
}
