import { Component, Input } from '@angular/core';

import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { TerpecaUserDisclosure } from 'src/app/models/user.model';
import { getLanguages, getLocationString, isAutomaticFinalist } from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-roomdisclosure',
  templateUrl: './roomdisclosure.component.html',
  styleUrl: './roomdisclosure.component.css'
})
export class RoomDisclosureComponent {
  @Input() room: TerpecaRoom;
  @Input() userMap: Map<string, TerpecaUserDisclosure>;
  @Input() rankingUserIdsMap: Map<string, string[]>;
  affiliatedUsers: TerpecaUserDisclosure[];
  rankingUsers: TerpecaUserDisclosure[];
  getLocationString = getLocationString;
  year = environment.currentAwardYear;

  constructor() { }

  panelOpened() {
    const affiliatedUsers: TerpecaUserDisclosure[] = [];
    if (this.room.affiliatedUserIds) {
      for (const userId of this.room.affiliatedUserIds) {
        if (this.userMap && this.userMap.has(userId)) {
          const user = this.userMap.get(userId);
          if (user && user.rankingSubmitted) {
            affiliatedUsers.push(this.userMap.get(userId));
          }
        }
      }
    }
    affiliatedUsers.sort(compareEntitiesByLocation);
    this.affiliatedUsers = affiliatedUsers;

    const rankingUsers: TerpecaUserDisclosure[] = [];
    if (this.rankingUserIdsMap.has(this.room.docId)) {
      for (const userId of this.rankingUserIdsMap.get(this.room.docId)) {
        if (this.userMap && this.userMap.has(userId)) {
          const user = this.userMap.get(userId);
          if (user && user.rankingSubmitted) {
            rankingUsers.push(this.userMap.get(userId));
          }
        }
      }
    }
    rankingUsers.sort(compareEntitiesByLocation);
    this.rankingUsers = rankingUsers;
  }

  numAffiliatedUsers() {
    if (this.room.affiliatedUserIds) {
      return this.room.affiliatedUserIds.length;
    }
    return 0;
  }

  numRankingUsers() {
    if (this.rankingUserIdsMap.has(this.room.docId)) {
      return this.rankingUserIdsMap.get(this.room.docId).length;
    }
    return 0;
  }

  isOnlineRoom() {
    return this.room.category === TerpecaCategory.TOP_ONLINE_ROOM;
  }

  isInPersonRoom() {
    return this.room.category === TerpecaCategory.TOP_ROOM;
  }

  isCompany() {
    return this.room.category === TerpecaCategory.TOP_COMPANY;
  }

  isFinalist() {
    return this.room.isFinalist && this.room.isFinalist.includes(this.year);
  }

  isAutomaticFinalist() {
    return isAutomaticFinalist(this.room, this.year);
  }

  languages() {
    return getLanguages(this.room);
  }
}
