<h1 *ngIf="data.entity" mat-dialog-title>Details for {{data.entity.unranked ? 'NR' : '#' + data.entity.rank}} <span *ngIf="data.canShowRoomTitles">{{data.name}}</span></h1>
<mat-dialog-content>
  <div *ngIf="data.entity?.score">
    Score: {{ data.entity.score | number: '0.6-6' }}
    <span *ngIf="data.entity.normScore">, Normalized Score: {{ data.entity.normScore | number: '0.6-6' }}</span>
    <span *ngIf="data.entity.companyCredit">, Company Credit: {{ data.entity.companyCredit | number: '0.6-6' }}</span>
  </div>
  <div *ngFor="let opponent of data.opponents">
    <div *ngIf="data.entity.docId === opponent.docId" class="this">
      This room ranked {{opponent.unranked ? 'NR' : '#' + opponent.rank}}
    </div>
    <div *ngIf="data.entity.docId !== opponent.docId && !data.showUnweightedVotes && opponent.record as record"
         [ngStyle]="{'color': color(opponent.record), 'font-weight': fontWeight(opponent.record)}">
      {{record.directWins | number: '0.0-1'}} of {{record.directComps | number: '0.0-1'}} ({{(100*record.directWinFraction) | number: '1.0-0'}}%, {{ record.directConfidence | number: '0.3-3'}}) direct,
      {{record.secondaryWins | number: '0.0-1'}} of {{record.secondaryComps | number: '0.0-1'}} ({{(100*record.secondaryWinFraction) | number: '1.0-0'}}%, {{ record.secondaryConfidence | number: '0.3-3'}},
      {{record.secondaryPivots}} pivots ({{record.secondaryPivotsScaled | number: '0.0-1'}} scaled)) secondary (combined: {{(100*record.winFraction) | number: '1.0-0'}}%, {{ record.confidence | number: '0.3-3'}})
      preferred this to {{opponent.unranked ? 'NR' : '#' + opponent.rank}} <span *ngIf="data.canShowRoomTitles">{{opponent.name}}</span>
    </div>
    <div *ngIf="data.entity.docId !== opponent.docId && data.showUnweightedVotes && opponent.record as record"
         [ngStyle]="{'color': color(opponent.record), 'font-weight': fontWeight(opponent.record)}">
      {{record.unweightedWins | number: '0.0-1'}} of {{record.unweightedComps | number: '0.0-1'}} ({{record.directWins | number: '0.0-1'}} of {{record.directComps | number: '0.0-1'}} weighted, {{(100*record.directWinFraction) | number: '1.0-0'}}%, {{ record.directConfidence | number: '0.3-3'}}) direct,
      {{record.secondaryWins | number: '0.0-1'}} of {{record.secondaryComps | number: '0.0-1'}} ({{(100*record.secondaryWinFraction) | number: '1.0-0'}}%, {{ record.secondaryConfidence | number: '0.3-3'}},
      {{record.secondaryPivots}} pivots ({{record.secondaryPivotsScaled | number: '0.0-1'}} scaled)) secondary (combined: {{(100*record.winFraction) | number: '1.0-0'}}%, {{ record.confidence | number: '0.3-3'}})
      preferred this to {{opponent.unranked ? 'NR' : '#' + opponent.rank}} <span *ngIf="data.canShowRoomTitles">{{opponent.name}}</span>
    </div>
  </div>
  <div *ngIf="data.rooms">
    <div *ngFor="let room of data.rooms">
      <span *ngIf="room.category === Category.TOP_ROOM" matTooltip="In-person room">🔐</span>
      <span *ngIf="room.category === Category.TOP_ONLINE_ROOM" matTooltip="Online room">💻</span>
      <span *ngIf="room.category === Category.TOP_COMPANY" matTooltip="Company">🏢</span>
      <span *ngIf="data.entityMap.get(room.docId) as entity">
        <strong>{{ entity.unranked ? 'NR' : '#' + entity.rank }}</strong>
      </span>
      <span *ngIf="!data.entityMap.get(room.docId)">
        <strong>nom✖️{{ numNominationsForRoom(room) }}</strong>
      </span>
      - <app-simpleroom *ngIf="data.canShowRoomTitles" [room]="room" [hideCategoryLogo]="true"></app-simpleroom>
      <span *ngIf="!data.canShowRoomTitles">redacted</span>
      <span *ngIf="data.creditMap && data.creditMap[room.docId]?.raw">
        <span *ngIf="data.creditMap[room.docId].raw === data.creditMap[room.docId].adjusted">
          (credit: {{ data.creditMap[room.docId].raw | number: '0.6-6' }})
        </span>
        <span *ngIf="data.creditMap[room.docId].raw !== data.creditMap[room.docId].adjusted">
          (credit: <del>{{ data.creditMap[room.docId].raw | number: '0.6-6' }}</del> {{ data.creditMap[room.docId].adjusted | number: '0.6-6' }})
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="data.user.isOwner && data.rankings">
    <div *ngFor="let ranking of rankingsForRoom(data.entity?.docId)">
      <mat-divider *ngIf="data.entity"></mat-divider>
      <div>
        <strong>{{ ranking.userId }}</strong>
        <app-horrorpreference [pref]="ranking.horrorPreference"></app-horrorpreference>
        <span *ngIf="data.userMap?.get(ranking.userId) as u">
          - {{ u.realName }} ({{ u.country }}) <span *ngIf="u.videoContributionInterest"> - 🎥 {{ u.email }}</span>
        </span>
        {{ ranking.shadowbanned ? ' 🕶️' : ''}}
      </div>
      <div *ngFor="let docId of ranking.rankedIds; let i = index">
        <span *ngIf="data.entityMap.get(getRemappedId(docId)) as entity"
              [ngStyle]="{'font-weight': getRemappedId(data.entity?.docId) === getRemappedId(docId) ? 'bold' : 'normal'}">
          {{ i + 1 }}. {{ entity.unranked ? 'NR' : '#' + entity.rank }} {{ data.roomMap.get(docId).englishName || data.roomMap.get(docId).name}} - {{ data.roomMap.get(docId).country }}
        </span>
      </div>
      <div *ngFor="let docId of unrankedRoomIds(ranking)">
        <span *ngIf="data.entityMap.get(getRemappedId(docId)) as entity"
              [ngStyle]="{'font-weight': getRemappedId(data.entity?.docId) === getRemappedId(docId) ? 'bold' : 'normal'}">
          NR. {{ entity.unranked ? 'NR' : '#' + entity.rank }} {{ data.roomMap.get(docId).englishName || data.roomMap.get(docId).name}} - {{ data.roomMap.get(docId).country }} : {{ getUnrankedReasonInfo(ranking, docId)}}
        </span>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="close()">
    Close
  </button>
</div>
