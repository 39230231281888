import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TerpecaRanking, UnrankedReasonEntry, getUnrankedReasonDescription, getUnrankedReasonEmoji } from 'src/app/models/ranking.model';
import { PairwiseComparison } from 'src/app/models/results.model';
import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { ApplicationStatus } from 'src/app/models/user.model';
import { getNominationCount } from 'src/app/utils/misc.utils';

@Component({
  selector: 'app-analyzedialog',
  templateUrl: './analyzedialog.component.html',
  styleUrl: './analyzedialog.component.css'
})
export class AnalyzeDialogComponent {
  Category = TerpecaCategory;
  Status = ApplicationStatus;

  constructor(
    public dialogRef: MatDialogRef<AnalyzeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  rankingsForRoom(roomId: string) {
    const rankings: TerpecaRanking[] = [];
    if (this.data.user && this.data.user.isOwner && this.data.rankings) {
      for (const ranking of this.data.rankings) {
        if (ranking.unsortedIds &&
            (!roomId || ranking.unsortedIds.map((id: string) => this.getRemappedId(id)).includes(roomId))) {
          rankings.push(ranking);
        }
      }
    }
    return rankings;
  }

  numNominationsForRoom(room: TerpecaRoom) {
    return getNominationCount(room, this.data.year);
  }

  getRemappedId(id: string) {
    return this.data.roomIdRemap.has(id) ? this.data.roomIdRemap.get(id) : id;
  }

  unrankedRoomIds(ranking: TerpecaRanking) {
    return Object.keys(ranking.unrankedReasonMap || {});
  }

  getUnrankedReasonInfo(ranking: TerpecaRanking, roomId: string) {
    if ((ranking.unrankedReasonMap || {})[roomId]) {
      const entry: UnrankedReasonEntry = ranking.unrankedReasonMap[roomId];
      return `${getUnrankedReasonEmoji(entry)} ${getUnrankedReasonDescription(entry)}`;
    }
    return '';
  }

  color(record: PairwiseComparison) {
    if (record.confidence > 0.5) {
      return `rgb(0, 150, 0)`;
    }
    if (record.confidence < 0.5) {
      return `rgb(150, 0, 0)`;
    }
    return 'inherit';
  }

  fontWeight(record: PairwiseComparison) {
    if (record.directComps + record.secondaryComps === 0) {
      return 100;
    }
    return 200 + Math.round(1000 * Math.abs(record.confidence - 0.5));
  }

  close() {
    this.dialogRef.close(false);
  }
}
