import { Component, Input } from '@angular/core';

import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { getLocationString, isWinner } from 'src/app/utils/misc.utils';

@Component({
  selector: 'app-simpleroom',
  templateUrl: './simpleroom.component.html',
  styleUrl: './simpleroom.component.css'
})
export class SimpleRoomComponent {
  @Input() room: TerpecaRoom;
  @Input() hideCategoryLogo: false;
  @Input() showNewLogo: false;
  @Input() showNameOnly: false;
  @Input() year: number;
  @Input() canShowWinnerLogo: false;
  getLocationString = getLocationString;

  constructor() { }

  categoryLogo() {
    if (!this.room) {
      return '';
    }
    switch (this.room.category) {
      case TerpecaCategory.TOP_ROOM:
        return '🔐';
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return '💻';
      case TerpecaCategory.TOP_COMPANY:
        return '🏢';
    }
  }

  categoryTooltip() {
    if (!this.room) {
      return '';
    }
    switch (this.room.category) {
      case TerpecaCategory.TOP_ROOM:
        return 'In-person room';
      case TerpecaCategory.TOP_ONLINE_ROOM:
        return 'Online room';
      case TerpecaCategory.TOP_COMPANY:
        return 'Company';
    }
  }

  isCompany() {
    return this.room?.category === TerpecaCategory.TOP_COMPANY;
  }

  showWinnerLogo() {
    return this.canShowWinnerLogo && this.year && isWinner(this.room, this.year);
  }
}
