<mat-expansion-panel (opened)="panelOpened()">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title class="flex-row justify-space-between align-center">
      <div class="flex-col">
        <div>
          <span *ngIf="isOnlineRoom()" matTooltip="Online room">💻</span>
          <span *ngIf="isInPersonRoom()" matTooltip="In-person room">🔐</span>
          <span *ngIf="isCompany()" matTooltip="Company">🏢</span>
          <span *ngIf="isFinalist()" matTooltip="Finalist">🏅</span>
          <strong>{{ room.englishName || room.name }}</strong>
          <span *ngFor="let l of languages()">
            {{' '}}<app-language [alpha2]="l"></app-language>
          </span>
        </div>
        <div *ngIf="room.englishName">
          <i><strong>{{ room.name }}</strong></i>
        </div>
        <div>
          {{ room.company }}
        </div>
        <div>
          <i>{{ getLocationString(room) }}</i>
        </div>
      </div>
      <div class="flex-col justify-start align-end flex-noshrink">
        <div>
          <span matTooltip="Affiliated users">👔 {{ numAffiliatedUsers() }}</span>
          <span *ngIf="isFinalist()"> &middot; <span matTooltip="Ranking users">🗒️ {{ numRankingUsers() }}</span></span>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="flex-col gap-10">
    <mat-divider></mat-divider>
    <div class="flex-col gap-5">
      <div>👔 <i><b>Affiliated Users:</b></i> {{ numAffiliatedUsers() }}</div>
      <div *ngFor="let user of affiliatedUsers">
        <strong>{{ user.realName }}</strong> - <i>{{ getLocationString(user) }}</i>
      </div>
      <div *ngIf="(room.affiliatedUserIds?.length || 0) > (affiliatedUsers?.length || 0)">
        <i>not showing {{ (room.affiliatedUserIds?.length || 0) - (affiliatedUsers?.length || 0) }} affiliated
           user{{ (room.affiliatedUserIds?.length || 0) - (affiliatedUsers?.length || 0) > 1 ? 's' : '' }} that did not contribute this year</i>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="flex-col gap-5">
      <div>🗒️ <i><b>Ranking Users:</b></i> {{ numRankingUsers() }}</div>
      <div *ngFor="let user of rankingUsers">
        <strong>{{ user.realName }}</strong> - <i>{{ getLocationString(user) }}</i>
      </div>
    </div>
  </div>
</mat-expansion-panel>
