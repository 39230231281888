import {
    FormControl, FormGroupDirective, NgForm, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const roomCountValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const roomCount = control.get('roomCount').value || 0;
  const virtualRoomCount = control.get('virtualRoomCount').value || 0;
  return (virtualRoomCount > roomCount) ? { invalidRoomCounts: true } : null;
};

export class RoomCountErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return Boolean(control && (control.value || control.dirty) && (control?.errors?.invalidRoomCounts || form?.errors?.invalidRoomCounts));
  }
}

export const facebookUsernameValidator = Validators.pattern('[a-zA-Z0-9.]*');
export const instagramUsernameValidator = Validators.pattern('[a-zA-Z0-9._]*');
export const discordUsernameValidator: ValidatorFn = (c: FormControl): ValidationErrors | null => {
  const OLD_DISCORD_USERNAME_REGEX = /^.*#[0-9]{4}$/;
  const NEW_DISCORD_USERNAME_REGEX = /^[a-z0-9._]*$/;
  return ((OLD_DISCORD_USERNAME_REGEX.test(c.value) || NEW_DISCORD_USERNAME_REGEX.test(c.value)) ? null :
    { pattern: { valid: false } });
};
export const escapeTheReviewUserIdValidator = Validators.pattern('[0-9]*');
export const escapeAllGrUserIdValidator = Validators.pattern('[a-f0-9-]*');
export const escapeGameFrUserIdValidator = Validators.pattern('[a-zA-Z0-9._-]*');
export const escapeRoomersUserIdValidator = Validators.pattern('[0-9]+x[0-9]+');
export const escapeTalkNlUserIdValidator = Validators.pattern('[a-z0-9]*');
export const lockMeUserIdValidator = Validators.pattern('[0-9]*');
export const mortyAppUsernameValidator = Validators.pattern('[a-zA-Z0-9]*');
export const theEscapersUserIdValidator = Validators.pattern('[a-z0-9-]*');
