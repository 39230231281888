<section *ngIf="auth.currentUser as user">
  <div *ngIf="user.isOwner || user.status >= Status.APPROVER" class="flex-col justify-center align-center gap-10">
    <h3 class="flex-row justify-center align-center">
      {{ year }} Data Validator
    </h3>
    <div class="flex-row gap-5">
      <button mat-raised-button color="primary" (click)="validate(false)" [disabled]="!allowValidate">
        Run Validation
      </button>
      <button *ngIf="user.isOwner" mat-raised-button color="primary" (click)="validate(true)" [disabled]="!allowFix">
        Fix Data
      </button>
    </div>
    <div class="flex-col justify-center align-center">
      <div *ngFor="let line of log">
        {{ line }}
      </div>
      <div *ngFor="let issue of fixableIssues">
        🚧 {{ issue }}
      </div>
      <div *ngFor="let fix of fixes">
        ✅ {{ fix }}
      </div>
      <div *ngFor="let issue of issues">
        ❌ {{ issue }}
      </div>
      <div *ngFor="let warning of warnings">
        ⚠️ {{ warning }}
      </div>
    </div>
  </div>
</section>
