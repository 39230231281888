<span *ngIf="level">
  <span *ngIf="!showLongText && !showShortText" matTooltip="{{text()}}: {{longText()}}">
    {{emoji()}}
    <span *ngIf="showText">{{' '}}{{text()}}</span>
  </span>
  <span *ngIf="showShortText">
    {{emoji()}} {{shortText()}}
  </span>
  <span *ngIf="showLongText">
    {{emoji()}} {{text()}}: {{longText()}}
  </span>
</span>
