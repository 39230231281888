<h1 mat-dialog-title>Don't Rank Room: {{data.room.name}}</h1>
<mat-dialog-content [formGroup]="form">
  <p>Select the reason that you do not want to rank this room:</p>
  <div class="flex-col gap-5">
    <mat-radio-group class="flex-col flex-wrap gap-10" appearance="fill" formControlName="reason" (change)="reasonChanged()">
      <mat-radio-button [value]="UnrankedReason.CANT_REMEMBER_ROOM">🤷 I don't remember this room well enough</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.CANT_COMPARE_ROOM">🤔 This room is unlike other rooms and hard to compare</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.ROOM_HAS_CHANGED">♻️ This room has changed too much since I played it</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.ATYPICAL_EXPERIENCE">🤪 My experience in this room was not typical</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.DID_NOT_PLAY">❌ I did not play this room (remove from list)</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.CONFLICT_OF_INTEREST">⚔️ I have a conflict of interest (add to affiliations)</mat-radio-button>
      <mat-radio-button [value]="UnrankedReason.OTHER">🗒️ I have some other reason (explain below)</mat-radio-button>
    </mat-radio-group>
    <mat-form-field appearance="fill" (click)="textClicked()">
      <input type="text" #otherTextInput="matInput" matInput formControlName="note">
    </mat-form-field>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="!formIsValid()">
    {{ submitText() }}
  </button>
</div>
