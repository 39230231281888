<section *ngIf="auth.currentUser as user">
  <div *ngIf="user.isOwner || user.status >= Status.REVIEWER" class="flex-col justify-center align-stretch gap-10">
    <h3 class="flex-row justify-center align-center">
      Applicant Review Dashboard
    </h3>
    <div class="flex-col justify-center align-center">
      <ul>
        <li><b>Vouch</b> for an applicant if you can verify their identity and room count, and their application is fully complete and ready to approve.</li>
        <li><b>Don't Vouch</b> for an applicant that still has any changes to make on their application, or if you have any questions about them.</li>
        <li><b>Request Resubmit</b> for an applicant that didn't fully fill out the application or has correctable issues that can be addressed by resubmitting.</li>
      </ul>
    </div>
    <div class="flex-row flex-wrap justify-center align-center gap-25">
      <div>✍️ = nominations submitted <span *ngIf="isReady() && nominators">({{ nominatorsNominated }}/{{ nominators.length }})</span></div>
      <div>🗳️ = rankings submitted <span *ngIf="isReady() && nominators && voters">({{ nominatorsVoted + votersVoted }}/{{ nominators.length + voters.length }})</span></div>
    </div>
    <div *ngIf="!isReady()" class="flex-col justify-center align-center">
      Loading... Please be patient, this page can take a while to load.
    </div>
    <div *ngIf="isReady()" class="flex-row flex-wrap justify-center align-center gap-25">
      <form [formGroup]="formGroup" class="flex-row flex-wrap justify-center align-center gap-25">
        <div class="search-and-filter-section flex-col justify-center align-center gap-5">
          <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-10">
            <div>sort&nbsp;by:</div>
            <mat-radio-button value="1" color="primary" [checked]=true (click)="currentSortFn = userRoomCountSort">count</mat-radio-button>
            <mat-radio-button value="2" color="primary" (click)="currentSortFn = userLocationSort">location</mat-radio-button>
            <mat-radio-button value="3" color="primary" (click)="currentSortFn = userNameSort">name</mat-radio-button>
            <mat-radio-button value="4" color="primary" (click)="currentSortFn = userVouchSort">vouches</mat-radio-button>
          </mat-radio-group>
          <div class="flex-row justify-center align-center gap-10">
            <div>region:</div>
            <select formControlName="region">
              <option *ngFor="let r of regions" [ngValue]=r>{{ optionLabelForRegion(r) }}</option>
            </select>
          </div>
          <div class="flex-row justify-center align-center gap-10">
            <div>filter&nbsp;by:</div>
            <input class="search-box" type="string" matInput formControlName="searchText">
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isReady()" class="flex-row flex-wrap justify-center align-center gap-10">
      <button *ngIf="showAllCategories" mat-raised-button color="primary" (click)="showAllCategories = false">Show Pending Applicants Only</button>
      <button *ngIf="!showAllCategories" mat-raised-button color="primary" (click)="showAllCategories = true">Show All Categories</button>
      <button mat-raised-button color="primary" (click)="refreshData()">Refresh</button>
    </div>
    <div *ngIf="isReady() && loadingInProgress" class="flex-col justify-center align-center">
      Loading...
    </div>
    <div *ngIf="isReady() && !loadingInProgress" class="flex-row flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
      <div class="flex-col flex-default">
        <div *ngIf="(upgradeRequestApplicants | userFilter:region:searchText:currentSortFn) as filteredResults"
             class="match-column flex-col flex-default gap-5">
          <p class="flex-row justify-center align-center">Pending Upgrade Requests {{ '(' + (upgradeRequestApplicants?.length || 0) + ')' }}</p>
          <div *ngIf="upgradeRequestApplicants?.length && (region?.length || searchText)" class="flex-row justify-center align-center">
            <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
          </div>
          <mat-accordion>
            <ng-container *ngFor="let applicant of filteredResults">
              <app-applicant [user]="applicant" [reviewer]="user" [parent]="this"></app-applicant>
            </ng-container>
          </mat-accordion>
        </div>
        <div *ngIf="(pendingApplicants | userFilter:region:searchText:currentSortFn) as filteredResults"
             class="match-column flex-col flex-default gap-5">
          <p class="flex-row justify-center align-center">Pending Applicants {{ '(' + (pendingApplicants?.length || 0)  + ')' }}</p>
          <div *ngIf="pendingApplicants?.length && (searchText || region?.length)" class="flex-row justify-center align-center">
            <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
          </div>
          <mat-accordion>
            <ng-container *ngFor="let applicant of filteredResults">
              <app-applicant [user]="applicant" [reviewer]="user" [parent]="this"></app-applicant>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="showAllCategories" class="flex-col flex-default">
        <div *ngIf="(nominators | userFilter:region:searchText:currentSortFn) as filteredResults"
             class="match-column flex-col flex-default gap-5">
          <p class="flex-row justify-center align-center">Approved Nominators {{ '(' + (nominators?.length || 0)  + ')' }}</p>
          <div *ngIf="nominators?.length && (searchText || region?.length)" class="flex-row justify-center align-center">
            <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
          </div>
          <mat-accordion>
            <ng-container *ngFor="let applicant of filteredResults">
              <app-applicant [user]="applicant" [reviewer]="user" [parent]="this"></app-applicant>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="showAllCategories" class="flex-col flex-default">
        <div *ngIf="(voters | userFilter:region:searchText:currentSortFn) as filteredResults"
             class="match-column flex-col flex-default gap-5">
          <p class="flex-row justify-center align-center">Approved Voters {{ '(' + (voters?.length || 0)  + ')' }}</p>
          <div *ngIf="voters?.length && (searchText || region?.length)" class="flex-row justify-center align-center">
            <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
          </div>
          <mat-accordion>
            <ng-container *ngFor="let applicant of filteredResults">
              <app-applicant [user]="applicant" [reviewer]="user" [parent]="this"></app-applicant>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="showAllCategories" class="flex-col flex-default">
        <div *ngIf="(deniedApplicants | userFilter:region:searchText:currentSortFn) as filteredResults"
             class="match-column flex-col flex-default gap-5">
          <p class="flex-row justify-center align-center">Applicants Denied {{ '(' + (deniedApplicants?.length || 0)  + ')' }}</p>
          <div *ngIf="deniedApplicants?.length && (searchText || region?.length)" class="flex-row justify-center align-center">
            <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
          </div>
          <mat-accordion>
            <ng-container *ngFor="let applicant of filteredResults">
              <app-applicant [user]="applicant" [reviewer]="user" [parent]="this"></app-applicant>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</section>
