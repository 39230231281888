import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';

import { CanComponentDeactivate } from 'src/app/can-deactivate.guard';
import { ApplicationStatus, TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import {
    RoomCountErrorStateMatcher, discordUsernameValidator, escapeAllGrUserIdValidator, escapeGameFrUserIdValidator,
    escapeRoomersUserIdValidator, escapeTalkNlUserIdValidator, escapeTheReviewUserIdValidator, facebookUsernameValidator,
    instagramUsernameValidator, lockMeUserIdValidator, mortyAppUsernameValidator, theEscapersUserIdValidator
} from 'src/app/utils/form.utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @Input() user: TerpecaUser;
  Status = ApplicationStatus;
  formGroup: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  roomCountErrorMatcher = new RoomCountErrorStateMatcher();

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.user = this.auth.currentUser;
    this.formGroup = new UntypedFormGroup({
      realName: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl(''),
      country: new UntypedFormControl('', Validators.required),
      roomCount: new UntypedFormControl('', Validators.required),
      virtualRoomCount: new UntypedFormControl('', Validators.required),
      bio: new UntypedFormControl('', Validators.required),

      appEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
      facebookUsername: new UntypedFormControl('', facebookUsernameValidator),
      instagramUsername: new UntypedFormControl('', instagramUsernameValidator),
      discordUsername: new UntypedFormControl('', discordUsernameValidator),
      bestContactMethod: new UntypedFormControl('', Validators.required),
      horrorPreference: new UntypedFormControl('', Validators.required),

      escapeTheReviewUserId: new UntypedFormControl('', escapeTheReviewUserIdValidator),
      escapeAllGrUserId: new UntypedFormControl('', escapeAllGrUserIdValidator),
      escapeGameFrUserId: new UntypedFormControl('', escapeGameFrUserIdValidator),
      escapeRoomersUserId: new UntypedFormControl('', escapeRoomersUserIdValidator),
      escapeTalkNlUserId: new UntypedFormControl('', escapeTalkNlUserIdValidator),
      lockMeUserId: new UntypedFormControl('', lockMeUserIdValidator),
      mortyAppUsername: new UntypedFormControl('', mortyAppUsernameValidator),
      theEscapersUserId: new UntypedFormControl('', theEscapersUserIdValidator),

      notifyOnNewApplicant: new UntypedFormControl(null),
      notifyOnNewRoom: new UntypedFormControl(null)
    });
    this.subscriptions.push(
      this.auth.user.subscribe((value: TerpecaUser) => {
        if (value) {
          this.formGroup.patchValue(value, { emitEvent: false });
          if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
          }
        }
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.formGroup.dirty) {
      return true;
    }
    return confirm('Discard changes?');
  }

  async save(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }
    if (formGroup.value.notifyOnNewApplicant === null) {
      delete formGroup.value.notifyOnNewApplicant;
    }
    if (formGroup.value.notifyOnNewRoom === null) {
      delete formGroup.value.notifyOnNewRoom;
    }
    await this.auth.updateUser(formGroup.value);
    formGroup.markAsPristine();
  }
}
