<div [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>Languages</mat-label>
    <mat-select formControlName="languages" multiple required>
      <mat-select-trigger>
        <span *ngFor="let l of formGroup.value.languages; let last=last">
          <app-language [alpha2]="l" [showName]="true">
          </app-language>{{last ? '' : ', '}}
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let l of localChoices" [value]="l">
        <app-language [alpha2]="l" [showEmoji]="false" [showName]="true"></app-language>
        <span *ngIf="l === 'en'"> (required for TERPECA)</span>
      </mat-option>
      <mat-optgroup label="Other common TERPECA languages">
        <mat-option *ngFor="let l of commonChoices" [value]="l">
          <app-language [alpha2]="l" [showEmoji]="false" [showName]="true"></app-language>
        </mat-option>
      </mat-optgroup>
      <mat-optgroup label="Other languages">
        <mat-option *ngFor="let l of otherChoices" [value]="l">
          <app-language [alpha2]="l" [showEmoji]="false" [showName]="true"></app-language>
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>
