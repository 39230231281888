import { TerpecaUser, TerpecaUserDisclosure } from 'src/app/models/user.model';

import { canonicalize } from './misc.utils';

export function compareUsingMap(map: Map<string, any>, compareFn: (a: any, b: any) => number) {
  return (a: any, b: any) => compareFn(map.get(a), map.get(b));
}

export function compareEntitiesByLocation(a: any, b: any) {
  a.sortingName = a.englishName || a.name || a.room || a.realName;
  b.sortingName = b.englishName || b.name || b.room || b.realName;
  for (const field of ['country', 'state', 'city', 'company', 'sortingName']) {
    const result = canonicalize(a, field).localeCompare(canonicalize(b, field));
    if (result !== 0) {
      return result;
    }
  }
  return 0;
}

export function compareEntitiesByName(a: any, b: any) {
  a.sortingName = a.englishName || a.name || a.room || a.realName;
  b.sortingName = b.englishName || b.name || b.room || b.realName;
  for (const field of ['sortingName', 'company', 'country', 'state', 'city']) {
    const result = canonicalize(a, field).localeCompare(canonicalize(b, field));
    if (result !== 0) {
      return result;
    }
  }
  return 0;
}

export function compareRoomsByCompany(a: any, b: any) {
  a.sortingName = a.englishName || a.name || a.room || a.realName;
  b.sortingName = b.englishName || b.name || b.room || b.realName;
  for (const field of ['company', 'country', 'state', 'city', 'sortingName']) {
    const result = canonicalize(a, field).localeCompare(canonicalize(b, field));
    if (result !== 0) {
      return result;
    }
  }
  return 0;
}

export function compareUsersByLocation(a: TerpecaUser | TerpecaUserDisclosure, b: TerpecaUser | TerpecaUserDisclosure) {
  for (const field of ['country', 'state', 'city']) {
    const result = canonicalize(a, field).localeCompare(canonicalize(b, field));
    if (result !== 0) {
      return result;
    }
  }
  if ((a.roomCount || 0) !== (b.roomCount || 0)) {
    return (b.roomCount || 0) - (a.roomCount || 0);
  }
  const result2 = canonicalize(a, 'realName').localeCompare(canonicalize(b, 'realName'));
  if (result2 !== 0) {
    return result2;
  }
  return 0;
}

export function compareUsersByRoomCount(a: TerpecaUser | TerpecaUserDisclosure, b: TerpecaUser | TerpecaUserDisclosure) {
  if ((a.roomCount || 0) !== (b.roomCount || 0)) {
    return (b.roomCount || 0) - (a.roomCount || 0);
  }
  const result = canonicalize(a, 'realName').localeCompare(canonicalize(b, 'realName'));
  if (result !== 0) {
    return result;
  }
  return 0;
}

export function compareUsersByName(a: TerpecaUser | TerpecaUserDisclosure, b: TerpecaUser | TerpecaUserDisclosure) {
  const result = canonicalize(a, 'realName').localeCompare(canonicalize(b, 'realName'));
  if (result !== 0) {
    return result;
  }
  return 0;
}

export function compareUsersByVouchesAndRoomCount(a: TerpecaUser, b: TerpecaUser) {
  const aVouches = a.upgradeRequested ? (a.upgradeVouchList ? a.upgradeVouchList.length : 0) : (a.vouchList ? a.vouchList.length : 0);
  const bVouches = b.upgradeRequested ? (b.upgradeVouchList ? b.upgradeVouchList.length : 0) : (b.vouchList ? b.vouchList.length : 0);
  if (aVouches !== bVouches) {
    return bVouches - aVouches;
  }
  return (b.roomCount || 0) - (a.roomCount || 0);
}
