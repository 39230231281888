export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyA7Y65YMsFQgT67L69rNYBtyACkuBsanBo',
    authDomain: 'voters.terpeca.com',
    databaseURL: 'https://terpeca-voter-portal.firebaseio.com',
    projectId: 'terpeca-voter-portal',
    storageBucket: 'terpeca-voter-portal.appspot.com',
    messagingSenderId: '773402090327',
    appId: '1:773402090327:web:a1fda8e06a22a0e41fbc12'
  },
  projectTitle: 'TERPECA',
  voterPortalHostname: 'voters.terpeca.com',
  roomFinderHostname: 'roomfinder.terpeca.com',
  rollbarAccessToken: '9fb99a9a563b4da6b13e3fa1793691ff',
  toolbarColor: 'primary',
  googleAnalyticsMeasurementId: 'G-QQZGH6MGKG',
  currentAwardYear: 2024
};
