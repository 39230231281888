import { Component, OnInit } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { TerpecaRanking, getUnrankedIds, getUnrankedReasonDescription, getUnrankedReasonEmoji } from 'src/app/models/ranking.model';
import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrl: './history.component.css'
})
export class HistoryComponent implements OnInit {
  ready = false;
  user: TerpecaUser;
  regularNomsMap: Map<number, TerpecaNomination[]>;
  onlineNomsMap: Map<number, TerpecaNomination[]>;
  companyNomsMap: Map<number, TerpecaNomination[]>;
  regularRankingsMap: Map<number, TerpecaRanking>;
  onlineRankingsMap: Map<number, TerpecaRanking>;
  companyRankingsMap: Map<number, TerpecaRanking>;
  roomMap: Map<string, TerpecaRoom>;
  utils = utils;
  Category = TerpecaCategory;
  getUnrankedIds = getUnrankedIds;

  constructor(private auth: AuthService, public settings: SettingsService, private db: AngularFirestore) { }

  ngOnInit() {
    this.user = this.auth.currentUser;
    const promises: any[] = [];
    for (const year of this.settings.allYears) {
      if (year < environment.currentAwardYear) {
        if (this.user.nominationsSubmitted?.includes(year)) {
          promises.push(this.loadNominations(year));
        }
        if (this.user.rankingsSubmitted?.includes(year)) {
          promises.push(this.loadRankings(year));
        }
      }
    }
    promises.push(this.loadRooms());
    Promise.all(promises).then(() => {
      this.ready = true;
    });
  }

  async loadNominations(year: number) {
    this.regularNomsMap = new Map<number, TerpecaNomination[]>();
    this.onlineNomsMap = new Map<number, TerpecaNomination[]>();
    this.companyNomsMap = new Map<number, TerpecaNomination[]>();
    const regularNoms: TerpecaNomination[] = [];
    const onlineNoms: TerpecaNomination[] = [];
    const companyNoms: TerpecaNomination[] = [];
    await this.db.collection<TerpecaNomination>('nominations').ref
    .where('year', '==', year)
    .where('userId', '==', this.user.uid).get()
    .then((snapshot: QuerySnapshot<TerpecaNomination>) => {
      for (const doc of snapshot.docs) {
        const nom: TerpecaNomination = doc.data();
        nom.docId = doc.id;
        if (nom.category === TerpecaCategory.TOP_ROOM) {
          regularNoms.push(nom);
        } else if (nom.category === TerpecaCategory.TOP_ONLINE_ROOM) {
          onlineNoms.push(nom);
        } else if (nom.category === TerpecaCategory.TOP_COMPANY) {
          companyNoms.push(nom);
        }
      }
      if (regularNoms.length > 0) {
        regularNoms.sort(compareEntitiesByLocation);
        this.regularNomsMap.set(year, regularNoms);
        console.log(`loaded ${regularNoms.length} regular noms from ${year}`);
      }
      if (onlineNoms.length > 0) {
        onlineNoms.sort(compareEntitiesByLocation);
        this.onlineNomsMap.set(year, onlineNoms);
        console.log(`loaded ${onlineNoms.length} online noms from ${year}`);
      }
      if (companyNoms.length > 0) {
        companyNoms.sort(compareEntitiesByLocation);
        this.companyNomsMap.set(year, companyNoms);
        console.log(`loaded ${companyNoms.length} company noms from ${year}`);
      }
    });
  }

  async loadRankings(year: number) {
    this.regularRankingsMap = new Map<number, TerpecaRanking>();
    this.onlineRankingsMap = new Map<number, TerpecaRanking>();
    this.companyRankingsMap = new Map<number, TerpecaRanking>();
    await this.db.collection<TerpecaRanking>('rankings').doc(`${this.user.uid}-${year}-${TerpecaCategory.TOP_ROOM}`).ref
    .get().then(doc => {
      if (doc.exists) {
        const rankings: TerpecaRanking = <TerpecaRanking>doc.data();
        this.regularRankingsMap.set(year, rankings);
        console.log(`loaded ${rankings.rankedIds?.length || 0} regular rankings from ${year}`);
      }
    });
    await this.db.collection<TerpecaRanking>('rankings').doc(`${this.user.uid}-${year}-${TerpecaCategory.TOP_ONLINE_ROOM}`).ref
    .get().then(doc => {
      if (doc.exists) {
        const rankings: TerpecaRanking = <TerpecaRanking>doc.data();
        this.onlineRankingsMap.set(year, rankings);
        console.log(`loaded ${rankings.rankedIds?.length || 0} online rankings from ${year}`);
      }
    });
    await this.db.collection<TerpecaRanking>('rankings').doc(`${this.user.uid}-${year}-${TerpecaCategory.TOP_COMPANY}`).ref
    .get().then(doc => {
      if (doc.exists) {
        const rankings: TerpecaRanking = <TerpecaRanking>doc.data();
        this.companyRankingsMap.set(year, rankings);
        console.log(`loaded ${rankings.rankedIds?.length || 0} company rankings from ${year}`);
      }
    });
  }

  async loadRooms() {
    this.roomMap = new Map<string, TerpecaRoom>();
    await this.db.collection<TerpecaRoom>('rooms').ref.get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        room.docId = doc.id;
        this.roomMap.set(room.docId, room);
      }
      console.log(`loaded ${this.roomMap.size} rooms`);
    });
  }

  getUnrankedEmoji(category: TerpecaCategory, year: number, roomId: string) {
    const ranking: TerpecaRanking = (category === TerpecaCategory.TOP_ROOM ? this.regularRankingsMap.get(year) :
                                     this.onlineRankingsMap.get(year));
    return ranking?.unrankedReasonMap ? getUnrankedReasonEmoji(ranking.unrankedReasonMap[roomId]) : '';
  }

  getUnrankedReason(category: TerpecaCategory, year: number, roomId: string) {
    const ranking: TerpecaRanking = (category === TerpecaCategory.TOP_ROOM ? this.regularRankingsMap.get(year) :
                                     this.onlineRankingsMap.get(year));
    return ranking?.unrankedReasonMap ? getUnrankedReasonDescription(ranking.unrankedReasonMap[roomId]) : '';
  }
}
