import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-locationpicker',
  templateUrl: './locationpicker.component.html',
  styleUrl: './locationpicker.component.css'
})
export class LocationPickerComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() forUser: boolean;
  @Input() requireCity: boolean = true;
  subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.formGroup.valueChanges.subscribe(async () => {
      if (!['United States', 'Canada'].includes(this.formGroup.value.country)) {
        this.formGroup.patchValue({ state: '' }, { emitEvent: false });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  countryTitle() {
    return this.forUser ? 'Home country' : 'Country';
  }

  stateTitle() {
    return this.forUser ? 'Home state' : 'State';
  }

  provinceTitle() {
    return this.forUser ? 'Home province' : 'Province';
  }

  cityTitle() {
    return this.forUser ? 'Home city' : 'City';
  }
}
