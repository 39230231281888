import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { countries, languagesAll } from 'countries-list';

// Country name/alpha2 => alpha2 list of national languages
const countryLanguages = new Map<string, string[]>();

// Alpha2 list of TERPECA-frequent languages
const commonLanguages: string[] = [];

// Alpha2 list of all languages not in commonLanguages
const otherLanguages: string[] = [];

// Angular component with a drop down multi-selector of languages.
// If the 'country' input is provided, a short group of that country's
// official languages will head the list for convenience.
@Component({
  selector: 'app-languagepicker',
  templateUrl: './languagepicker.component.html',
  styleUrl: './languagepicker.component.css'
})
export class LanguagePickerComponent implements OnInit {
  constructor() { }

  @Input() formGroup: UntypedFormGroup;

  @Input() set country(country: string) {
    this.languages = countryLanguages.get(country) || [];
    this.localChoices = ['en', ...this.languages.filter(a => (a !== 'en'))];

    const filt = (alpha2) => !this.localChoices.includes(alpha2);
    this.commonChoices = commonLanguages.filter(filt);
    this.otherChoices = otherLanguages.filter(filt);
    this.maybeUpdateDefault();
  }

  // Alpha2 list of the country's official languages.
  private languages: string[] = [];

  // Alpha2 list of English + languages.
  localChoices: string[] = ['en'];

  // Alpha2 list of TERPECA-frequent languages not in localChoices.
  commonChoices: string[] = commonLanguages;

  // Alpha2 list of all languages not in localChoices or commonChoices.
  otherChoices: string[] = otherLanguages;

  // If the selection was empty at init time
  private initiallyEmpty: boolean = false;

  ngOnInit(): void {
    if (!this.formGroup.value.languages) {
      this.formGroup.patchValue({ languages: [] });
      this.initiallyEmpty = true;
    } else {
      this.initiallyEmpty = !this.formGroup.value.languages.length;
    }
    this.maybeUpdateDefault();
  }

  // If the control was empty and never edited, set country-based defaults.
  // (This will reset previous country defaults if the country changes.)
  private maybeUpdateDefault(): void {
    if (this.initiallyEmpty && !this.formGroup.controls.languages.dirty) {
      if (this.languages.length <= 1) {
        // One national language: check it by default (along with English).
        this.formGroup.patchValue({ 'languages': this.localChoices });
      } else {
        // Multiple national languages: don't check any, make the user pick.
        this.formGroup.patchValue({ 'languages': [] });
      }
    }
  }
}

function compareLanguages(a, b) {
  return languagesAll[a].name.localeCompare(languagesAll[b].name);
}

// Populate countryLanguages
for (const [countryAlpha2, countryData] of Object.entries(countries)) {
  const languages: string[] = [...countryData.languages];
  languages.sort(compareLanguages);
  countryLanguages.set(countryData.name, languages);
  countryLanguages.set(countryData.native, languages);
  countryLanguages.set(countryAlpha2, languages);
}

// Populate commonLanguages from a hardcoded list
// TODO: Have there been top rooms playable in e.g. Catalan or Basque?
for (const alpha2 of ['cs', 'nl', 'en', 'fr', 'de', 'el', 'he', 'pl', 'es']) {
  commonLanguages.push(alpha2);
}
commonLanguages.sort(compareLanguages);

// Populate otherLanguages
for (const alpha2 of Object.keys(languagesAll)) {
  if (commonLanguages.includes(alpha2)) {
    continue;
  }
  otherLanguages.push(alpha2);
}
otherLanguages.sort(compareLanguages);
