<mat-expansion-panel (opened)="panelOpened()" (closed)="panelClosed()" [style.background-color]="user.upgradeRequested ? 'lightyellow': 'inherit'">
  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title class="flex-row justify-space-between align-center">
      <div>
        <div>
          <strong>{{ user.realName || user.displayName }}</strong>
          <span *ngIf="user.nominationsSubmitted?.includes(year)"> ✍️</span>
          <span *ngIf="user.rankingsSubmitted?.includes(year)"> 🗳️</span>
        </div>
        <div>
          {{ getLocationString(user) || '&nbsp;' }}
        </div>
      </div>
      <div class="flex-col justify-start align-end flex-noshrink">
        <div *ngIf="user.status === Status.PENDING && !user.disabled">
          <span *ngIf="hasPendingReviewNote()">📝</span>
          {{ (user.vouchList && user.vouchList.length) || 0 }}
          {{ (user.vouchList && user.vouchList.length) === 1 ? 'vouch' : 'vouches' }}
        </div>
        <div *ngIf="user.status > Status.PENDING && !user.disabled && !user.upgradeRequested" [class]="getStatusString(user.status)">
          {{ getStatusString(user.status) }}
        </div>
        <div *ngIf="user.status > Status.PENDING && !user.disabled && user.upgradeRequested" [class]="getStatusString(user.status)">
          <span *ngIf="hasPendingReviewNote()">📝</span>
          {{ (user.upgradeVouchList && user.upgradeVouchList.length) || 0 }}
          {{ (user.upgradeVouchList && user.upgradeVouchList.length) === 1 ? 'vouch' : 'vouches' }}
        </div>
        <div *ngIf="user.disabled">
          inactive
        </div>
        <div>
          <span *ngIf="user.virtualRoomCount >= 0">💻 {{ user.virtualRoomCount }} &bull;</span>
          🧩 {{ user.roomCount || '&nbsp;' }}
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="flex-col gap-10">
    <div *ngIf="collisions && (collisions['realName']?.length || collisions['email']?.length || collisions['appEmail']?.length ||
                collisions['facebookUsername']?.length || collisions['instagramUsername']?.length || collisions['discordUsername']?.length ||
                collisions['escapeTheReviewUserId']?.length || collisions['escapeAllGrUserId']?.length || collisions['escapeGameFrUserId']?.length ||
                collisions['escapeRoomersUserId']?.length || collisions['escapeTalkNlUserId']?.length || collisions['lockMeUserId']?.length ||
                collisions['mortyAppUsername']?.length || collisions['theEscapersUserId']?.length)"
         class="warning-box flex-col">
      <span><strong>This appears to be a duplicate application!</strong></span>
      <span *ngFor="let dupUser of collisions['realName']">Name matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['email']">Email matches verified email of {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['appEmail']">Email matches provided email of  {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['facebookUsername']">Facebook ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['instagramUsername']">Instagram ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['discordUsername']">Discord ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['escapeTheReviewUserId']">Escape the Review ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['escapeAllGrUserId']">EscapeAll.gr ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['escapeGameFrUserId']">EscapeGame.fr ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['escapeRoomersUserId']">Escape-Roomers.com ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['escapeTalkNlUserId']">EscapeTalk.nl ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['lockMeUserId']">Lockme ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['mortyAppUsername']">Morty ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
      <span *ngFor="let dupUser of collisions['theEscapersUserId']">The Escapers ID matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
    </div>
    <div *ngIf="collisions && collisions['ipAddresses']?.length" class="warning-box flex-col">
      <span><strong>This user shares an IP address with another user! (new method)</strong></span>
      <span *ngFor="let dupUser of collisions['ipAddresses']">IP {{ getSharedIps(user, dupUser) }} matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
    </div>
    <div *ngIf="collisions && !collisions['ipAddresses']?.length && collisions['auditLogEntry.ipAddress']?.length" class="warning-box flex-col">
      <span><strong>This user shares an IP address with another user! (old method)</strong></span>
      <span *ngFor="let dupUser of collisions['auditLogEntry.ipAddress']">IP {{ dupUser.auditLogEntry.ipAddress }} matches {{ dupUser.realName }} ({{ getLocationString(dupUser) }})</span>
    </div>
    <div *ngIf="user.status >= Status.REVIEWER && user.ambassadorCountries" class="flex-col">
      <div><i><b>Ambassador to {{ user.ambassadorCountries.join(', ') }}</b></i></div>
    </div>
    <div *ngIf="reviewer?.isOwner" class="flex-col">
      <div><i><b>Nominations:</b></i> {{ nominations?.length || 0 }}/{{ onlineNominations?.length }}<span *ngIf="user.nominationsSubmitted?.includes(year)"> ✍️</span></div>
      <div><i><b>Rankings:</b></i> {{ rankings?.rankedIds?.length || 0 }}/{{ onlineRankings?.rankedIds?.length || 0}}<span *ngIf="user.rankingsSubmitted?.includes(year)"> 🗳️</span><span *ngIf="user.shadowbanned"> 🕶️</span></div>
      <div><i><b>Affiliations:</b></i> {{ user.affiliatedRoomIds?.length || 0 }}</div>
    </div>
    <div *ngIf="hasPastExperience()">
      <i><b>Past Experience:</b></i>
      <ng-container *ngFor="let y of pastYears().slice().reverse()">
        <div *ngIf="user.nominationsSubmitted?.includes(y) || user.rankingsSubmitted?.includes(y)">
          {{ y }}:
          <span *ngIf="user.nominationsSubmitted?.includes(y)">✍️</span>
          <span *ngIf="user.rankingsSubmitted?.includes(y)">🗳️</span>
        </div>
      </ng-container>
    </div>
    <div class="flex-row flex-wrap justify-space-between align-start">
      <div class="flex-col">
        <div><i><b>Home:</b></i> {{ getLocationString(user) }}<br></div>
        <div><i><b>Total Rooms:</b></i> {{ user.roomCount }}</div>
        <div *ngIf="user.virtualRoomCount >= 0"><i><b>Online Rooms:</b></i> {{ user.virtualRoomCount }}</div>
      </div>
      <div class="flex-col">
        <div><i><b>Email:</b></i> {{ user.appEmail }}
          <span *ngIf="user.bestContactMethod === 'email'">*</span>
        </div>
        <div *ngIf="user.email !== user.appEmail"><i><b>Verified Email:</b></i> {{ user.email }}
          <span *ngIf="user.bestContactMethod === 'verifiedemail'">*</span>
        </div>
        <div *ngIf="user.facebookUsername">
          <i><b>Facebook: </b></i>
          <a href="https://www.facebook.com/{{ user.facebookUsername }}" target="_blank">{{ user.facebookUsername }}</a>
          <span *ngIf="user.bestContactMethod === 'facebook'">*</span>
        </div>
        <div *ngIf="user.instagramUsername">
          <i><b>Instagram: </b></i>
          <a href="https://www.instagram.com/{{ user.instagramUsername }}" target="_blank">{{ user.instagramUsername }}</a>
          <span *ngIf="user.bestContactMethod === 'instagram'">*</span>
        </div>
        <div *ngIf="user.discordUsername"><i><b>Discord: </b></i> {{ user.discordUsername }}
          <span *ngIf="user.bestContactMethod === 'discord'">*</span>
        </div>
        <div *ngIf="user.slackUsername"><i><b>Slack: </b></i> {{ user.slackUsername }}
          <span *ngIf="user.bestContactMethod === 'slack'">*</span>
        </div>
      </div>
    </div>
    <div class="flex-col">
      <div><i><b>Regions Played:</b></i> {{ user.regionsPlayed }}</div>
      <div *ngIf="user.roomCountEvidence" class="terpeca-multiline"><i><b>Evidence:</b></i> {{ user.roomCountEvidence }}</div>
      <div *ngIf="user.escapeTheReviewUserId">
        <i><b>EscapeTheReview.co.uk: </b></i>
        <a href="https://www.escapethereview.co.uk/profile/?user_id={{ user.escapeTheReviewUserId }}" target="_blank">{{ user.escapeTheReviewUserId }}</a>
      </div>
      <div *ngIf="user.escapeAllGrUserId">
        <i><b>EscapeAll.gr: </b></i>
        <a href="https://www.escapeall.gr/en/User/Profile/{{ user.escapeAllGrUserId }}" target="_blank">{{ user.escapeAllGrUserId }}</a>
      </div>
      <div *ngIf="user.escapeGameFrUserId">
        <i><b>EscapeGame.fr: </b></i>
        <a href="https://www.escapegame.fr/profil/{{ user.escapeGameFrUserId }}" target="_blank">{{ user.escapeGameFrUserId }}</a>
      </div>
      <div *ngIf="user.escapeRoomersUserId">
        <i><b>Escape-Roomers.com: </b></i>
        <a href="https://escape-roomers.com/utente_home/{{ user.escapeRoomersUserId }}" target="_blank">{{ user.escapeRoomersUserId }}</a>
      </div>
      <div *ngIf="user.escapeTalkNlUserId">
        <i><b>EscapeTalk.nl: </b></i>
        <a href="https://escapetalk.nl/profiel/{{ user.escapeTalkNlUserId }}" target="_blank">{{ user.escapeTalkNlUserId }}</a>
      </div>
      <div *ngIf="user.lockMeUserId">
        <i><b>Lockme: </b></i>
        <a href="https://lock.me/pl/user/{{ user.lockMeUserId }}" target="_blank">{{ user.lockMeUserId }}</a>
      </div>
      <div *ngIf="user.mortyAppUsername">
        <i><b>Morty: </b></i>
        <a href="https://morty.app/@{{ user.mortyAppUsername }}" target="_blank">{{ user.mortyAppUsername }}</a>
      </div>
      <div *ngIf="user.theEscapersUserId">
        <i><b>The-Escapers.com: </b></i>
        <a href="https://www.the-escapers.com/profil/{{ user.theEscapersUserId }}" target="_blank">{{ user.theEscapersUserId }}</a>
      </div>
      <div *ngIf="user.affiliations" class="terpeca-multiline"><i><b>Affiliations:</b></i> {{ user.affiliations }}</div>
      <div *ngIf="user.onlinePresence" class="terpeca-multiline"><i><b>Presence:</b></i> {{ user.onlinePresence }}</div>
      <div *ngIf="user.otherExperience" class="terpeca-multiline"><i><b>Other:</b></i> {{ user.otherExperience }}</div>
      <div class="terpeca-multiline"><i><b>Favorite Rooms:</b></i> {{ user.favoriteRooms }}</div>
      <div *ngIf="user.horrorPreference"><i><b>Horror Preference:</b></i><app-horrorpreference [pref]="user.horrorPreference" [showText]="true"></app-horrorpreference></div>
    </div>
    <div class="flex-col">
      <div class="terpeca-multiline"><i><b>Bio:</b></i> {{ user.bio }}</div>
    </div>
    <div class="flex-col">
      <div *ngIf="user.references"><i><b>References:</b></i> {{ user.references }}</div>
    </div>
    <div class="flex-col gap-10">
      <div class="flex-row flex-wrap gap-10">
        <button mat-raised-button (click)="showAddReviewNoteDialog()">Add Review Note</button>
      </div>
      <div class="flex-row flex-wrap gap-10"
           *ngIf="(reviewer.isOwner || reviewer.status >= Status.REVIEWER) && (user.status === Status.PENDING || user.upgradeRequested)">
        <button *ngIf="!user.upgradeRequested" [disabled]="user.vouchList?.includes(reviewer.uid)"
                mat-raised-button color="primary" (click)="showAddVouchDialog()">Add Vouch</button>
        <button *ngIf="!user.upgradeRequested" [disabled]="!user.vouchList?.includes(reviewer.uid)"
                mat-raised-button color="warn" (click)="showRemoveVouchDialog()">Remove Vouch</button>
        <button *ngIf="user.upgradeRequested" [disabled]="user.upgradeVouchList?.includes(reviewer.uid)"
                mat-raised-button color="primary" (click)="showAddUpgradeVouchDialog()">Add Vouch</button>
        <button *ngIf="user.upgradeRequested" [disabled]="!user.upgradeVouchList?.includes(reviewer.uid)"
                mat-raised-button color="warn" (click)="showRemoveUpgradeVouchDialog()">Remove Vouch</button>
        <button mat-raised-button color="warn" (click)="requestResubmit()">Request Resubmit</button>
      </div>
      <div class="flex-row flex-wrap gap-10" *ngIf="(reviewer.isOwner || reviewer.status >= Status.APPROVER) && user.upgradeRequested">
        <button mat-raised-button color="{{ user.roomCount >= 200 ? 'primary' : 'warn' }}"
                (click)="setStatus(Status.NOMINATOR)">Approve Upgrade</button>
        <button mat-raised-button color="{{ user.roomCount < 200 ? 'primary' : 'warn' }}"
                (click)="requestResubmit()">Deny Upgrade</button>
      </div>
      <div class="flex-row flex-wrap gap-10"
           *ngIf="(reviewer.isOwner || reviewer.status >= Status.APPROVER) && user.status >= Status.PENDING && !user.upgradeRequested">
        <button *ngIf="user.status !== Status.NOMINATOR"
                mat-raised-button color="{{ user.roomCount >= 200 && user.status === Status.PENDING ? 'primary' : 'warn' }}"
                (click)="setStatus(Status.NOMINATOR)">Approve Nominator</button>
        <button *ngIf="user.status !== Status.VOTER"
                mat-raised-button color="{{ user.roomCount < 200 && user.roomCount >= 100 && user.status === Status.PENDING ? 'primary' : 'warn' }}"
                (click)="setStatus(Status.VOTER)">Approve Voter</button>
        <button *ngIf="user.status !== Status.DENIED"
                mat-raised-button color="{{ user.roomCount < 100 && user.status === Status.PENDING ? 'primary' : 'warn' }}"
                (click)="setStatus(Status.DENIED)">Deny Application</button>
      </div>
      <div class="flex-row flex-wrap gap-10"
           *ngIf="(reviewer.isOwner || reviewer.status >= Status.APPROVER) && (user.status > Status.PENDING && !user.upgradeRequested)">
        <button mat-raised-button *ngIf="user.status !== Status.PENDING"
                (click)="setStatus(Status.PENDING)">Back to Pending</button>
        <button mat-raised-button *ngIf="(reviewer.isOwner || reviewer.status >= Status.APPROVER) && user.status !== Status.REVIEWER"
                (click)="setStatus(Status.REVIEWER)">Approve Reviewer</button>
        <button mat-raised-button *ngIf="reviewer.isOwner && user.status !== Status.APPROVER"
                (click)="setStatus(Status.APPROVER)">Approve Approver</button>
      </div>
      <div class="flex-row flex-wrap gap-10" *ngIf="canChangeNominationStatus() || canChangeRankingStatus()">
        <button mat-raised-button *ngIf="canChangeNominationStatus() && user.nominationsSubmitted?.includes(year)" color="warn"
                (click)="showRevokeNominationsStatusDialog()">Revoke Nominations</button>
        <button mat-raised-button *ngIf="canChangeNominationStatus() &&!user.nominationsSubmitted?.includes(year) && (nominations?.length || onlineNominations?.length)"
                (click)="submitNominations()">Submit Pending Nominations</button>
        <button mat-raised-button *ngIf="canChangeRankingStatus() && user.rankingsSubmitted?.includes(year)" color="warn"
                (click)="showRevokeRankingsStatusDialog()">Revoke Rankings</button>
        <button mat-raised-button *ngIf="canChangeRankingStatus() && !user.rankingsSubmitted?.includes(year) && (rankings || onlineRankings)"
                (click)="submitRankings()">Submit Pending Rankings</button>
      </div>
      <div class="flex-row flex-wrap gap-10"
           *ngIf="(reviewer.isOwner || reviewer.status >= Status.APPROVER) && user.status > Status.PENDING">
        <button mat-raised-button *ngIf="!user.shadowbanned" color="warn"
                (click)="showUpdateShadowbanStatusDialog(true)">Add Shadow Ban</button>
        <button mat-raised-button *ngIf="user.shadowbanned"
                (click)="showUpdateShadowbanStatusDialog(false)">Remove Shadow Ban</button>
      </div>
      <div *ngIf="user.auditLogHistory" class="audit-log flex-col-reverse">
        <div *ngFor="let entry of user.auditLogHistory | slice:-10" class="terpeca-multiline">
          {{ getUserAuditLogString(entry) }}
        </div>
        <div><strong>Audit Log</strong></div>
      </div>
      <div *ngIf="reviewer.isOwner && user.ipAddresses" class="audit-log flex-col-reverse">
        <div *ngFor="let ip of user.ipAddresses" class="terpeca-multiline">
          {{ ip }}
        </div>
        <div><strong>IP Addresses</strong></div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
