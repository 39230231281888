import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './services/auth.service';
import { LoginComponent } from './views/login/login.component';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private auth: AuthService, private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
  boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.auth.user.pipe(map(user => {
      if (route.component === LoginComponent) {
        if (user) {
          this.router.navigate(['/']);
          return false;
        }
        return true;
      }
      if (!user) {
        this.router.navigate(['/login']);
        return false;
      }
      if (user.isOwner && route.data.allowOwner) {
        return true;
      }
      if (route.data.status && (user.status < route.data.status || user.disabled)) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }));
  }
}
