<div [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>Horror level</mat-label>
    <mat-select formControlName="horrorLevel" required>
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let level of allLevels" [value]="level">
        <app-horrorlevel [level]="level" [showText]="true"></app-horrorlevel>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
