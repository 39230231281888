<mat-toolbar *ngIf="isRoomFinder" color={{toolbarColor}} class="flex-row">
  <img src="assets/toproomslogo.png" width="33px" height="33px">
  <a mat-button routerLink="/"><h1>{{ projectTitle }} Room Finder</h1></a>
  <div class="hide-sm flex-row-reverse justify-space-between align-center flex-default">
    <div class="flex-row">
      <div>
        <a mat-button href="https://www.terpeca.com" target="_blank">TERPECA Website</a>
        <a mat-button href="{{voterPortalUrl}}">{{ projectTitle }} Voter Portal</a>
      </div>
    </div>
  </div>
</mat-toolbar>
<mat-toolbar *ngIf="!isRoomFinder" color={{toolbarColor}} class="flex-row">
  <img src="assets/toproomslogo.png" width="33px" height="33px">
  <a mat-button routerLink="/"><h1>{{ projectTitle }} Voter Portal</h1></a>
  <div class="hide-sm flex-row-reverse justify-space-between align-center flex-default">
    <div class="flex-row">
      <div>
        <a mat-button routerLink="/faq">FAQ</a>
      </div>
      <div *ngIf="!auth.currentUser">
        <a mat-button routerLink="/">Login</a>
      </div>
      <div *ngIf="auth.currentUser as user">
        <a mat-button [matMenuTriggerFor]="userMenu">
          {{ user.realName }}
          <mat-icon>arrow_drop_down</mat-icon>
        </a>
        <mat-menu #userMenu="matMenu">
          <ng-container *ngIf="user.status >= Status.VOTER && !user.disabled">
            <a mat-menu-item routerLink="/profile">Edit Profile</a>
            <a mat-menu-item routerLink="/history">See History</a>
            <mat-divider></mat-divider>
            <a mat-menu-item href="{{roomFinderUrl}}" *ngIf="user.status >= Status.REVIEWER">Room Finder</a>
            <mat-divider></mat-divider>
          </ng-container>
          <a mat-menu-item (click)="auth.logout()">Logout</a>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="auth.currentUser as user" class="flex-row">
      <ng-container *ngIf="!user.disabled">
        <a mat-button routerLink="/" *ngIf="user.status <= Status.PENDING">Apply</a>
        <a mat-button routerLink="/" *ngIf="user.status > Status.PENDING && user.status < Status.REVIEWER">Home</a>
        <a mat-button routerLink="/nominate" *ngIf="user.status >= Status.NOMINATOR">Nominate Rooms</a>
        <a mat-button routerLink="/rank" *ngIf="user.status >= Status.VOTER">Rank Rooms</a>
        <a mat-button routerLink="/review" *ngIf="user.status >= Status.REVIEWER || (user.status >= Status.VOTER && (user.rankingsSubmitted?.includes(year) ||
          settings.isPastVotingDeadline()))">Review Public Data</a>
        <div *ngIf="user.status >= Status.REVIEWER || user.isOwner" class="admin-buttons flex-row hide-md">
          <a mat-button routerLink="/approve" *ngIf="user.status >= Status.REVIEWER || user.isOwner">Review Applicants</a>
          <a mat-button routerLink="/match" *ngIf="user.status >= Status.REVIEWER">Process Nominations</a>
          <a mat-button routerLink="/validate" *ngIf="user.status >= Status.APPROVER">Validate Data</a>
          <a mat-button routerLink="/analyze" *ngIf="user.status >= Status.APPROVER">Analyze Data</a>
        </div>
        <div *ngIf="user.status >= Status.REVIEWER || user.isOwner" class="admin-buttons flex-row hide-gt-md">
          <a mat-button [matMenuTriggerFor]="adminMenu">
            Admin <mat-icon>arrow_drop_down</mat-icon>
          </a>
          <mat-menu #adminMenu="matMenu">
            <a mat-menu-item routerLink="/approve" *ngIf="user.status >= Status.REVIEWER || user.isOwner">Review Applicants</a>
            <a mat-menu-item routerLink="/match" *ngIf="user.status >= Status.REVIEWER">Process Nominations</a>
            <mat-divider></mat-divider>
            <a mat-menu-item routerLink="/validate" *ngIf="user.status >= Status.APPROVER">Validate Data</a>
            <a mat-menu-item routerLink="/analyze" *ngIf="user.status >= Status.APPROVER">Analyze Data</a>
          </mat-menu>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="hide-gt-sm flex-row justify-end align-center flex-default">
    <a mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </a>
    <mat-menu #menu="matMenu" xPosition="before">
      <div *ngIf="!auth.currentUser">
        <a mat-menu-item routerLink="/">Login</a>
      </div>
      <div *ngIf="auth.currentUser as user">
        <ng-container *ngIf="!user.disabled">
          <a mat-menu-item routerLink="/" *ngIf="user.status <= Status.PENDING">Apply</a>
          <a mat-menu-item routerLink="/" *ngIf="user.status > Status.PENDING">Home</a>
          <a mat-menu-item routerLink="/nominate" *ngIf="user.status >= Status.NOMINATOR">Nominate Rooms</a>
          <a mat-menu-item routerLink="/rank" *ngIf="user.status >= Status.VOTER">Rank Rooms</a>
          <a mat-menu-item routerLink="/review" *ngIf="user.status >= Status.REVIEWER || (user.status >= Status.VOTER && (user.rankingsSubmitted?.includes(year) ||
            settings.isPastVotingDeadline()))">Review Public Data</a>
          <a mat-menu-item routerLink="/profile" *ngIf="user.status >= Status.VOTER">Edit Profile</a>
          <a mat-menu-item routerLink="/history" *ngIf="user.status >= Status.VOTER">See History</a>
          <mat-divider></mat-divider>
          <a mat-menu-item routerLink="/approve" *ngIf="user.status >= Status.REVIEWER || user.isOwner">Review Applicants</a>
          <a mat-menu-item routerLink="/match" *ngIf="user.status >= Status.REVIEWER">Process Nominations</a>
          <a mat-menu-item routerLink="/validate" *ngIf="user.status >= Status.APPROVER">Validate Data</a>
          <a mat-menu-item routerLink="/analyze" *ngIf="user.status >= Status.APPROVER">Analyze Data</a>
          <mat-divider></mat-divider>
          <a mat-menu-item href="{{roomFinderUrl}}" *ngIf="user.status >= Status.REVIEWER">Room Finder</a>
          <mat-divider></mat-divider>
        </ng-container>
      </div>
      <a mat-menu-item routerLink="/faq">FAQ</a>
      <mat-divider></mat-divider>
      <div *ngIf="auth.currentUser as user">
        <a mat-menu-item (click)="auth.logout()">Logout</a>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
