import { Component, Input } from '@angular/core';

import { TerpecaCategory, TerpecaRoom } from 'src/app/models/room.model';
import { TerpecaUserDisclosure } from 'src/app/models/user.model';
import { SettingsService } from 'src/app/services/settings.service';
import { getLocationString } from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';

@Component({
  selector: 'app-userdisclosure',
  templateUrl: './userdisclosure.component.html',
  styleUrl: './userdisclosure.component.css'
})
export class UserDisclosureComponent {
  @Input() user: TerpecaUserDisclosure;
  @Input() roomMap: Map<string, TerpecaRoom>;
  affiliatedRooms: TerpecaRoom[];
  rankedRooms: TerpecaRoom[];
  rankedOnlineRooms: TerpecaRoom[];
  getLocationString = getLocationString;

  constructor(public settings: SettingsService) { }

  panelOpened() {
    const affiliatedRooms: TerpecaRoom[] = [];
    if (this.user.affiliatedRoomIds) {
      for (const roomId of this.user.affiliatedRoomIds) {
        if (this.roomMap && this.roomMap.has(roomId)) {
          affiliatedRooms.push(this.roomMap.get(roomId));
        }
      }
    }
    affiliatedRooms.sort(compareEntitiesByLocation);
    this.affiliatedRooms = affiliatedRooms;

    const rankedRoomIds: string[] = (this.user.rankedRoomIds || []).concat(this.user.rankedOnlineRoomIds || []);
    const rankedRooms: TerpecaRoom[] = [];
    const rankedOnlineRooms: TerpecaRoom[] = [];
    for (const roomId of rankedRoomIds) {
      if (this.roomMap && this.roomMap.has(roomId)) {
        const room = this.roomMap.get(roomId);
        if (room.category === TerpecaCategory.TOP_ROOM) {
          rankedRooms.push(room);
        } else {
          rankedOnlineRooms.push(room);
        }
      }
    }
    rankedRooms.sort(compareEntitiesByLocation);
    this.rankedRooms = rankedRooms;
    rankedOnlineRooms.sort(compareEntitiesByLocation);
    this.rankedOnlineRooms = rankedOnlineRooms;
  }

  rankingsComplete() {
    return this.user.rankingSubmitted || this.settings.isPastVotingDeadline();
  }

  tbdOrZero() {
    return this.settings.isPastVotingDeadline() ? 0 : 'TBD';
  }
}
