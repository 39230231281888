import { Component, Input } from '@angular/core';

import { countries } from 'countries-list';

import { languageName } from 'src/app/utils/misc.utils';

// Language alpha2 => country alpha2 for flag
const languageFlags = new Map<string, string>();

// Angular component to indicate a language with a combination
// of emoji flag, language code, and/or language name.
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.css',
})
export class LanguageComponent {
  @Input() alpha2: string;
  @Input() showEmoji: boolean = true;
  @Input() showAlpha2: boolean = false;
  @Input() showName: boolean = false;

  constructor() { }

  emoji(): string {
    const countryAlpha2 = languageFlags.get(this.alpha2);
    return countryAlpha2 ? countries[countryAlpha2].emoji : '';
  }

  name(): string {
    return languageName(this.alpha2);
  }
}

// Populate languageFlags for languages which are one-to-one with countries
for (const [countryAlpha2, country] of Object.entries(countries)) {
  for (const languageAlpha2 of country.languages) {
    if (languageFlags.has(languageAlpha2) || country.languages.length > 1) {
      languageFlags.set(languageAlpha2, 'XX');
    } else {
      languageFlags.set(languageAlpha2, countryAlpha2);
    }
  }
}

// If the country alpha2 has a language alpha2, assume they're linked
for (const [countryAlpha2, country] of Object.entries(countries)) {
  const lowercaseAlpha2 = countryAlpha2.toLowerCase();
  if (country.languages.includes(lowercaseAlpha2)) {
    languageFlags.set(lowercaseAlpha2, countryAlpha2);
  }
}

// Designate flags for languages with ambiguous country relationships
for (const [languageAlpha2, countryAlpha2] of [
  ['af', 'ZA'], ['ar', 'YE'], ['be', 'BY'], ['bi', 'VU'], ['bs', 'BA'],
  ['ch', 'GU'], ['cs', 'CZ'], ['el', 'GR'], ['en', 'GB'], ['ga', 'IE'],
  ['gv', 'IM'], ['he', 'IL'], ['hi', 'IN'], ['hy', 'AM'], ['kk', 'KE'],
  ['ko', 'KR'], ['ky', 'KG'], ['la', 'VA'], ['lb', 'LU'], ['mi', 'NZ'],
  ['ms', 'MY'], ['na', 'NR'], ['pa', 'PK'], ['qu', 'PE'], ['rn', 'BI'],
  ['sg', 'CF'], ['si', 'LK'], ['sm', 'WS'], ['sq', 'AL'], ['sr', 'RS'],
  ['ss', 'SZ'], ['st', 'LS'], ['sv', 'SE'], ['sw', 'TZ'], ['tg', 'TJ'],
  ['ti', 'ER'], ['tk', 'TM'], ['zh', 'CN'],
]) {
  languageFlags.set(languageAlpha2, countryAlpha2);
}

// We have no flag for these languages
for (const alpha2 of [
  'ay', 'ca', 'eu', 'ff', 'gl', 'gn', 'kg', 'ku', 'ln', 'lu', 'nb', 'nd', 'nn',
  'nr', 'ny', 'oc', 'ps', 'sn', 'ta', 'tn', 'ts', 'ur', 've', 'xh', 'zu',
]) {
  languageFlags.set(alpha2, '');
}

// Check that all country languages have been covered
for (const country of Object.values(countries)) {
  for (const alpha2 of country.languages) {
    if (!languageFlags.has(alpha2) || languageFlags.get(alpha2) === 'XX') {
      console.error('Language ' + alpha2 + ' not assigned a flag!');
    }
  }
}
