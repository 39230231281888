import { UnrankedReasonEntry } from './ranking.model';
import { TerpecaCategory, TerpecaRoom } from './room.model';

// These are the general approaches we use to analyze the ranking data to estimate the relative strength
// between two entities, which are then used as input to the model.
export enum ResultsApproach {
  // The default approach uses only direct comparisons betweeen entities.
  // This approach was used in 2018.
  DEFAULT,

  // V1 introduces the concept of secondary comparisons in addition to direct comparisons when comparing
  // entities. It directly uses win confidence calculations between "pivot" entities (those which have
  // direct comparisons to each of the two rooms being compared) as secondary wins and losses, but scales
  // the total number of secondary wins and losses by the square root of the number of pivots.
  // This approach was used in 2019.
  DEFAULT_PLUS_SECONDARY_SQRT_V1,

  // V2 uses the same general approach as V1, but tries to be smarter about how the win confidence
  // calculations are used by weighting the strength of each pivot by how many of the secondary
  // comparisons suggest that the pivot is in the middle of the two entities being compared.
  // The SQRT version scales the total number of secondary wins and losses by the square root of the number
  // of pivots, while the FULL version uses the full strength of all pivots found without any scaling.
  // DEFAULT_PLUS_SECONDARY_SQRT_V2 was used for everything in 2020, and for online rooms only in 2021.
  // DEFAULT_PLUS_SECONDARY_FULL_V2 was used for in-person rooms only in 2021.
  DEFAULT_PLUS_SECONDARY_SQRT_V2,
  DEFAULT_PLUS_SECONDARY_FULL_V2,

  // V3 uses the same basic approach as V1 and V2, but with two new enhancements.  The first is that
  // it doesn't use a pivot _at all_ unless both sides of the pivot suggest it is in fact in the middle
  // of the two entities being compared. The second is that it attempts to convert the secondary
  // comparisons, which are initially generated from confidence calculations, back into the equivalent
  // number of _real_ wins and losses, before they are combined with the direct comparisons.
  // This approach was used starting in 2022.
  DEFAULT_PLUS_SECONDARY_FULL_V3
}

export const allResultsApproaches =
  (<(keyof typeof ResultsApproach)[]>Object.keys(ResultsApproach))
    .filter(key => isNaN(Number(key)))
    .map(key => ResultsApproach[key]);

// These are strategies we use in weighting a comparison between two games, which depend on how many
// rooms a given voter is comparing.  The reason to scale this at all is because the number of comparisons
// generated from a single set of rankings is proportional to the square of the number of rooms being
// ranked.
export enum VoteWeightingApproach {
  // The default is that every comparison gets equal weight.
  // This allows a user comparing n rooms to have the power of n^2 votes.
  // This was used from 2018-2021.
  DEFAULT,

  // This divides the weight of each vote by the square root of the number of rooms being ranked.
  // This allows a user comparing n rooms to have the power of n^1.5 votes.
  // This was used in 2022.
  DEFAULT_DIVIDED_BY_SQRT_N,

  // This is the same as DEFAULT_DIVIDED_BY_SQRT_N, but reinflates the total number of weighted
  // votes back to the total number of unweighted votes, so that the Wilson confidence calculation
  // is more appropriate.
  // This was used starting in 2023.
  DEFAULT_DIVIDED_BY_SQRT_N_REINFLATED
}

export const allVoteWeightingApproaches =
  (<(keyof typeof VoteWeightingApproach)[]>Object.keys(VoteWeightingApproach))
    .filter(key => isNaN(Number(key)))
    .map(key => VoteWeightingApproach[key]);

// These are the basic strategies we have used to generate company rankings from room rankings.
export enum CompanyAlgorithm {
  // This approach uses discrete pre-determined buckets, like "number of top 5 rooms", "number of top 10 rooms",
  // and hard-coded formulas using those buckets to generate company rankings.
  // This was used in 2019 and 2020.
  DISCRETE_BUCKETS,

  // This approach uses a sigmoid function to provide a smooth curve for mapping room strength to credits
  // toward a company strength.
  // This was used in 2021.
  SIGMOID_FUNCTION_MAPPING

  // Note that companies were voted on directly in 2018, and the award was discontinued in 2022,
  // which is why those years are not mentioned here.
}

export const allCompanyAlgorithms =
  (<(keyof typeof CompanyAlgorithm)[]>Object.keys(CompanyAlgorithm))
    .filter(key => isNaN(Number(key)))
    .map(key => CompanyAlgorithm[key]);

// These are the various approaches we support for dealing with rooms that have multiple entities for different
// versions of the room.
export enum RoomVersionApproach {
  SEPARATE,  // treat them all separately
  BEST,      // assign a rank to the highest finisher, mark the rest as NR
  COMBINED   // combine the versions into a single entity and aggregate their comparisons
}

export const allRoomVersionApproaches =
  (<(keyof typeof RoomVersionApproach)[]>Object.keys(RoomVersionApproach))
    .filter(key => isNaN(Number(key)))
    .map(key => RoomVersionApproach[key]);

// These are the various approaches we support for dealing with rooms that have a "combined" version as well
// as constituent "standalone" versions, and how they contribute to the room's company ranking.
export enum CompanyVersionApproach {
  DEFAULT,               // treat "combined" and "standalone" rooms as independent entities
  IGNORE_COMBINED_GAMES  // ignored "combined" rooms and only use the "standalone" rooms for company credit
}

export const allCompanyVersionApproaches =
  (<(keyof typeof CompanyVersionApproach)[]>Object.keys(CompanyVersionApproach))
    .filter(key => isNaN(Number(key)))
    .map(key => CompanyVersionApproach[key]);

export interface RankingMetric {
  pairsPredicted: number;
  pairsViolated: number;
}

export interface TerpecaRankedEntity {
  docId: string;
  category: TerpecaCategory;
  name: string;
  company: string;
  plays: number;
  coverage: number;
  allComps: number;
  unrankedReasonEntries?: UnrankedReasonEntry[];
  resultsMap?: ResultsMap;
  strength?: number;
  score?: number;
  companyCredit?: number;
  rank?: number;
  originalRank?: number;  // used only when rankings are changed after being published
  unranked?: boolean;
  winner?: boolean;
}

export interface ResultsMap {
  [opponentId: string]: PairwiseComparison;
}

// Results of pairwise analysis between one entity and a second one.
// "Wins" mean the first entity is preferred, "Losses" mean the second one is.
export interface PairwiseComparison {
  unweightedWins: number;
  unweightedLosses: number;
  unweightedComps: number;
  unweightedWinFraction: number;
  unweightedConfidence: number;
  directWins: number;
  directLosses: number;
  directComps: number;
  directWinFraction: number;
  directConfidence: number;
  directWinVoters: Map<string, number>;
  directLossVoters: Map<string, number>;
  directVoters: Set<string>;
  secondaryWins: number;
  secondaryLosses: number;
  secondaryComps: number;
  secondaryPivots: number;
  secondaryPivotsScaled: number;
  secondaryWinFraction: number;
  secondaryConfidence: number;
  winFraction: number;
  confidence: number;
}

export interface Opponent {
  rank: number;
  docId: string;
  name: string;
  record: PairwiseComparison;
}

export interface RankedCompany {
  name: string;
  rooms: TerpecaRoom[];
  top25rooms: string[];
  top50rooms: string[];
  top100rooms: string[];
  top200rooms: string[];
  top10onlinerooms: string[];
  top20onlinerooms: string[];
  finalists: string[];
  nominees: string[];
  totalScore: number;
  rank?: number;
  score?: number;
  normScore?: number;
  creditMap?: CreditMap;
  winner?: boolean;
}

export interface CreditMap {
  [roomId: string]: CompanyCredit;
}

export interface CompanyCredit {
  raw: number;
  adjusted: number;
}
