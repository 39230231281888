import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { allHorrorLevels } from 'src/app/models/room.model';

// Angular component with a drop down selector of horror levels.
@Component({
  selector: 'app-horrorlevelpicker',
  templateUrl: './horrorlevelpicker.component.html',
  styleUrl: './horrorlevelpicker.component.css'
})
export class HorrorLevelPickerComponent {
  constructor() { }

  @Input() formGroup: UntypedFormGroup;

  allLevels = allHorrorLevels;
}
