import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TerpecaQuote } from 'src/app/models/quote.model';
import { TerpecaRoom } from 'src/app/models/room.model';
import { TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrl: './quote.component.css'
})
export class QuoteComponent implements OnInit, OnDestroy {
  ready = false;
  year = environment.currentAwardYear;
  @Input() user: TerpecaUser;
  @Input() room: TerpecaRoom;
  @Input() allowEdit: boolean = false;
  editable = false;
  pastQuotes: TerpecaQuote[];
  currentQuote: TerpecaQuote;
  formGroup: UntypedFormGroup;

  private quoteSubscription: Subscription;

  constructor(private auth: AuthService, public settings: SettingsService, private db: AngularFirestore) { }

  ngOnInit() {
    if (this.allowEdit) {
      this.formGroup = new UntypedFormGroup({
        userId: new UntypedFormControl(this.user.uid),
        roomId: new UntypedFormControl(this.room.docId),
        year: new UntypedFormControl(this.year),
        quote: new UntypedFormControl('', Validators.required),
        videoInterest: new UntypedFormControl(null),
      });
    }
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe();
    }
    this.quoteSubscription = this.db.collection<TerpecaQuote>('quotes',
      ref => ref.where('userId', '==', this.user.uid)
                .where('roomId', '==', this.room.docId)).valueChanges({ idField: 'docId' })
    .subscribe((value: TerpecaQuote[]) => {
      let currentQuote = null;
      const pastQuotes = [];
      for (const quote of (value || [])) {
        if (quote.year === this.year) {
          currentQuote = quote;
          if (this.formGroup) {
            this.formGroup.patchValue(quote, { emitEvent: false });
          }
        } else if (quote.year < this.year) {
          pastQuotes.push(quote);
        }
      }
      pastQuotes.sort(this.compareByYear);
      this.currentQuote = currentQuote;
      this.pastQuotes = pastQuotes;
      this.ready = true;
    });
  }

  compareByYear(a: TerpecaQuote, b: TerpecaQuote) {
    return b.year - a.year;
  }

  ngOnDestroy() {
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe();
    }
  }

  reset() {
    if (this.currentQuote) {
      this.formGroup.patchValue(this.currentQuote, { emitEvent: false });
    } else {
      this.formGroup.patchValue({ 'quote': '', 'videoInterest': null }, { emitEvent: false });
    }
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  toggleEditable() {
    const x = window.scrollX;
    const y = window.scrollY;
    this.editable = !this.editable;
    if (this.editable) {
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    }
    setTimeout(() => {
      window.onscroll = () => { };
    });
  }

  async save() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (!this.currentQuote) {
      const newQuote = <TerpecaQuote>this.formGroup.value;
      newQuote.submitted = false;
      utils.trimInPlace(newQuote);
      await this.db.collection<TerpecaQuote>('quotes').doc(this.db.createId()).set(newQuote);
    } else {
      utils.trimInPlace(this.formGroup.value);
      await this.db.collection<TerpecaQuote>('quotes').doc(this.currentQuote.docId).update(this.formGroup.value);
    }
    this.formGroup.markAsPristine();
    this.editable = false;
  }

  async delete() {
    if (this.currentQuote) {
      await this.db.collection<TerpecaQuote>('quotes').doc(this.currentQuote.docId).delete();
    }
    this.formGroup.reset({
      userId: this.user.uid,
      roomId: this.room.docId,
      year: this.year
    });
    this.editable = false;
  }
}
