import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { allHorrorPreferences } from 'src/app/models/user.model';

// Angular component with a drop down selector of horror preferences.
@Component({
  selector: 'app-horrorpreferencepicker',
  templateUrl: './horrorpreferencepicker.component.html',
  styleUrl: './horrorpreferencepicker.component.css'
})
export class HorrorPreferencePickerComponent {
  constructor() { }

  @Input() formGroup: UntypedFormGroup;

  allPreferences = allHorrorPreferences;
}
