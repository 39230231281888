import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import app from 'firebase/compat/app';
import { Subscription } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { TerpecaQuote } from 'src/app/models/quote.model';
import { RoomAuditLogEntry, RoomAuditLogEntryType, TerpecaRoom, getRoomAuditLogString } from 'src/app/models/room.model';
import { TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getLocationString, isConfirmedEnglish, isConfirmedOpen, isEnglishSpeaking, trimInPlace } from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

import { RoomComponent } from '../room/room.component';
import { SimpleDialogComponent } from '../simpledialog/simpledialog.component';

@Component({
  selector: 'app-roomeditor',
  templateUrl: './roomeditor.component.html',
  styleUrl: './roomeditor.component.css'
})
export class RoomEditorComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  parent: RoomComponent;
  year: number;
  totalNomsCount: number;
  nomsByYearMap: Map<number, number>;
  pendingNomsByYearMap: Map<number, number>;
  currentNoms: TerpecaNomination[];
  currentQuotes: TerpecaQuote[];
  userMap: Map<string, TerpecaUser>;
  getRoomAuditLogString = getRoomAuditLogString;

  private nominationsSubscription: Subscription;
  private quotesSubscription: Subscription;
  private valueSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<RoomEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore,
    private auth: AuthService,
    private subdialog: MatDialog,
    public settings: SettingsService) { }

  ngOnInit() {
    this.year = environment.currentAwardYear;

    // Always-present form controls (see updateFields() for conditional ones)
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      company: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl('', null),
      country: new UntypedFormControl('', Validators.required),
      horrorLevel: new UntypedFormControl('', Validators.required),
      link: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.email),
      ineligibilityReason: new UntypedFormControl(''),
      confirmedOpen: new UntypedFormControl('', Validators.requiredTrue),
      notes: new UntypedFormControl('')
    });

    this.parent = this.data.parent;

    if (this.parent.room.reviewerNotes) {
      this.db.collection<TerpecaRoom>('rooms').doc(this.parent.room.docId).update({
        reviewerNotes: <string><unknown>app.firestore.FieldValue.delete(),
        auditLogEntry: <RoomAuditLogEntry>{
          entryType: RoomAuditLogEntryType.LEGACY_NOTES,
          note: this.parent.room.reviewerNotes,
        }
      });
    }

    // Subscribe to value changes to update which fields are present
    this.valueSubscription = this.formGroup.valueChanges.subscribe(
        value => { this.updateFields(value); });

    // Start with {} to trigger updateFields() to create all fields
    this.formGroup.patchValue({});
    this.formGroup.patchValue(this.parent.room);  // Load actual data

    // Pre-check the confirmation boxes only if appropriate
    this.formGroup.patchValue({
      confirmedOpen: isConfirmedOpen(this.parent.room, this.year),
      confirmedEnglish: isConfirmedEnglish(this.parent.room, this.year)
    });

    // Collect all matching nominations for the sidebar
    this.nominationsSubscription = this.db.collection<TerpecaNomination>(
        'nominations', ref => ref.where('roomId', '==', this.parent.room.docId)
    ).valueChanges({ idField: 'docId' }).subscribe(
        (noms: TerpecaNomination[]) => { this.updateNominations(noms); });

    // Collect all matching quotes for the sidebar
    this.quotesSubscription = this.db.collection<TerpecaQuote>(
      'quotes', ref => ref.where('roomId', '==', this.parent.room.docId).where('submitted', '==', true)
    ).valueChanges({ idField: 'docId' }).subscribe(
        (quotes: TerpecaQuote[]) => { this.updateQuotes(quotes); });
  }

  // Updates the set of available fields based on data (country specifically)
  updateFields(value) {
    const noe = { emitEvent: false };  // Use to avoid looping
    if (this.year === 2018) {
      if (this.formGroup.get('confirmedOpen')) {
        this.formGroup.removeControl('confirmedOpen', noe);
      }
      if (this.formGroup.get('confirmedEnglish')) {
        this.formGroup.removeControl('confirmedEnglish', noe);
      }
      if (this.formGroup.get('email')) {
        this.formGroup.removeControl('email', noe);
      }
      if (this.formGroup.get('horrorLevel')) {
        this.formGroup.removeControl('horrorLevel', noe);
      }
      if (isEnglishSpeaking(value.country, value.state)) {
        if (this.formGroup.get('englishName')) {
          this.formGroup.removeControl('englishName', noe);
        }
      } else {
        if (!this.formGroup.get('englishName')) {
          const FC = UntypedFormControl;
          const form = this.formGroup;
          form.addControl('englishName', new FC('', null), noe);
        }
      }
    } else if (isEnglishSpeaking(value.country, value.state)) {
      // English-only region -- remove language related fields
      if (this.formGroup.get('confirmedEnglish')) {
        this.formGroup.removeControl('confirmedEnglish', noe);
        this.formGroup.removeControl('englishLink', noe);
        this.formGroup.removeControl('englishName', noe);
        this.formGroup.removeControl('languages', noe);
      }
    } else {
      // Non English-only region -- add language related fields
      if (!this.formGroup.get('confirmedEnglish')) {
        const FC = UntypedFormControl;
        const form = this.formGroup;
        form.addControl('confirmedEnglish', new FC('', Validators.requiredTrue), noe);
        form.addControl('englishLink', new FC('', Validators.required), noe);
        form.addControl('englishName', new FC('', null), noe);
        form.addControl('languages', new FC('', Validators.required), noe);
      }
    }
  }

  updateNominations(value: TerpecaNomination[]) {
    const nomsByYear = new Map<number, number>();
    const pendingNomsByYear = new Map<number, number>();
    const currentNoms = [];
    for (const y of this.settings.allYears) {
      nomsByYear[y] = 0;
      pendingNomsByYear[y] = 0;
    }
    for (const nom of value) {
      if (nom.pending) {
        ++pendingNomsByYear[nom.year];
      } else {
        ++nomsByYear[nom.year];
        if (nom.year === this.year || (this.canShowUserData() && this.settings.isPastVotingDeadline())) {
          currentNoms.push(nom);
        }
      }
    }
    this.nomsByYearMap = nomsByYear;
    this.pendingNomsByYearMap = pendingNomsByYear;
    this.currentNoms = currentNoms;
    this.totalNomsCount = value.length;
  }

  updateQuotes(value: TerpecaQuote[]) {
    if (this.canShowUserData() || this.settings.isPastVotingDeadline()) {
      if (!this.userMap) {
        this.userMap = new Map<string, TerpecaUser>();
      }
      const currentQuotes = [];
      for (const quote of value) {
        if (!this.userMap.has(quote.userId)) {
          this.db.collection<TerpecaUser>('users').doc(quote.userId).ref.get().then(snapshot => {
            if (snapshot.exists) {
              this.userMap.set(snapshot.id, snapshot.data());
            }
          });
        }
        currentQuotes.push(quote);
      }
      this.currentQuotes = currentQuotes;
    }
  }

  ngOnDestroy() {
    this.nominationsSubscription.unsubscribe();
    this.quotesSubscription.unsubscribe();
    this.valueSubscription.unsubscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  canEditCompanyNames() {
    return this.parent.canEditCompanyNames();
  }

  showEditCompanyAlert() {
    this.subdialog.open(SimpleDialogComponent, {
      data: {
        message: 'Company name edits are fragile and must be done by a site admin. If you need to make a company name edit, please contact Rich with specifics.',
        hideCancel: true,
      }
    });
  }

  canShowUserData() {
    return this.auth && this.auth.currentUser && this.auth.currentUser.isOwner;
  }

  userData(userId) {
    if (!this.canShowUserData() || !this.userMap.has(userId)) {
      return '';
    }
    const user = this.userMap.get(userId);
    return `${ user.realName || user.displayName } (${ user.roomCount }) - ${ getLocationString(user) } ${ user.videoContributionInterest ? '🎥' : '' }`;
  }

  canDisqualify() {
    return !this.parent.isApproved() && this.parent.canDisqualify() && !this.parent.isFinalized();
  }

  async disqualify() {
    if (!this.formGroup.value.ineligibilityReason || !this.canDisqualify()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.DISQUALIFIED);
  }

  canMarkClosed() {
    return this.parent.isIneligible() && !this.parent.isPermanentlyIneligible() && this.parent.canDisqualify();
  }

  async markClosed() {
    if (!this.formGroup.value.ineligibilityReason || !this.canMarkClosed()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.MARKED_CLOSED);
  }

  canReopen() {
    return this.parent.isPermanentlyIneligible() && this.parent.canDisqualify();
  }

  async reopen() {
    if (!this.canReopen()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.MARKED_REOPENED);
  }

  canUndisqualify() {
    return this.parent.isIneligible() && this.parent.canDisqualify();
  }

  async undisqualify() {
    if (!this.canUndisqualify()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.UNDISQUALIFIED);
  }

  canApprove() {
    return !this.parent.isApproved() && this.parent.canApprove() && !this.parent.isFinalized();
  }

  async approve() {
    if (!this.formGroup.valid || !this.canApprove()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.APPROVED);
  }

  canUnapprove() {
    return this.parent.isApproved() && this.parent.canApprove() && !this.parent.isFinalized();
  }

  async unapprove() {
    if (!this.canUnapprove()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.UNAPPROVED);
  }

  canFinalize() {
    return this.parent.isApproved() && this.parent.canFinalize() && !this.parent.isFinalized() && !this.formGroup.valid &&
      !this.formGroup.dirty;
  }

  async finalize() {
    if (!this.canFinalize()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.FINALIZED);
  }

  canUnfinalize() {
    return !this.parent.isIneligible() && this.parent.canUnfinalize() && this.parent.isFinalized() && !this.formGroup.dirty;
  }

  async unfinalize() {
    if (!this.canUnfinalize()) {
      return;
    }
    await this.save(RoomAuditLogEntryType.UNFINALIZED);
  }

  canSave() {
    return !this.parent.isApproved() || this.formGroup.valid;
  }

  async save(entryType?: RoomAuditLogEntryType) {
    trimInPlace(this.formGroup.value);
    await this.parent.save(this.formGroup.value, entryType || RoomAuditLogEntryType.UPDATED);
    this.dialogRef.close();
  }

  canDelete() {
    return this.parent.room && this.parent.room.docId && this.totalNomsCount === 0 && (this.parent.room.nominations?.length || 0) === 0 &&
      (this.parent.room.affiliatedUserIds?.length || 0) === 0;
  }

  async delete() {
    if (!this.canDelete()) {
      return;
    }
    await this.db.collection<TerpecaRoom>('rooms').doc(this.parent.room.docId).delete();
    this.dialogRef.close();
  }
}
