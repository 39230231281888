<section>
  <div class="flex-col justify-center align-center gap-10">
    <h3 class="flex-row justify-center align-center">
      Your Contribution History
    </h3>
    <div *ngIf="!ready" >
      Loading...
    </div>
    <div *ngIf="ready" class="flex-col justify-center align-center">
      <div *ngFor="let year of utils.getPastYearsContributed(user).slice().reverse()">
        <h3 class="flex-row justify-center align-center">
          - {{ year }} -
        </h3>
        <div class="flex-row flex-wrap gap-5">
          <mat-card *ngIf="regularNomsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Room Nominations
            </h3>
            <app-nomination *ngFor="let nomination of regularNomsMap.get(year)" [nomination]="nomination"
                            [room]="roomMap.get(nomination.roomId)" class="flex-row justify-center align-stretch"></app-nomination>
          </mat-card>
          <mat-card *ngIf="onlineNomsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Online Room Nominations
            </h3>
            <app-nomination *ngFor="let nomination of onlineNomsMap.get(year)" [nomination]="nomination"
                            [room]="roomMap.get(nomination.roomId)" class="flex-row justify-center align-stretch"></app-nomination>
          </mat-card>
          <mat-card *ngIf="companyNomsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Company Nominations
            </h3>
            <app-nomination *ngFor="let nomination of companyNomsMap.get(year)" [nomination]="nomination"
                            [room]="roomMap.get(nomination.roomId)" class="flex-row justify-center align-stretch"></app-nomination>
          </mat-card>
          <mat-card *ngIf="regularRankingsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Room Rankings
            </h3>
            <div></div>
            <div *ngFor="let roomId of regularRankingsMap.get(year)?.rankedIds; let i = index">
              {{ i+1 }}. <app-simpleroom [room]="roomMap.get(roomId)" [canShowWinnerLogo]=true [year]=year></app-simpleroom>
            </div>
            <h3 *ngIf="getUnrankedIds(regularRankingsMap.get(year)).length" class="flex-row justify-center align-center">
              Unranked Rooms
            </h3>
            <div *ngIf="getUnrankedIds(regularRankingsMap.get(year)).length"></div>
            <div *ngFor="let roomId of getUnrankedIds(regularRankingsMap.get(year)); let i = index">
              <span matTooltip="{{ getUnrankedReason(Category.TOP_ROOM, year, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ROOM, year, roomId) }}</span>
              <app-simpleroom [room]="roomMap.get(roomId)"></app-simpleroom>
            </div>
          </mat-card>
          <mat-card *ngIf="onlineRankingsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Online Room Rankings
            </h3>
            <div></div>
            <div *ngFor="let roomId of onlineRankingsMap.get(year)?.rankedIds; let i = index">
              {{ i+1 }}. <app-simpleroom [room]="roomMap.get(roomId)" [canShowWinnerLogo]=true [year]=year></app-simpleroom>
            </div>
            <h3 *ngIf="getUnrankedIds(onlineRankingsMap.get(year)).length" class="flex-row justify-center align-center">
              Unranked Online Rooms
            </h3>
            <div *ngIf="getUnrankedIds(onlineRankingsMap.get(year)).length"></div>
            <div *ngFor="let roomId of getUnrankedIds(onlineRankingsMap.get(year)); let i = index">
              <span matTooltip="{{ getUnrankedReason(Category.TOP_ONLINE_ROOM, year, roomId) }}">{{ getUnrankedEmoji(Category.TOP_ONLINE_ROOM, year, roomId) }}</span>
              <app-simpleroom [room]="roomMap.get(roomId)"></app-simpleroom>
            </div>
          </mat-card>
          <mat-card *ngIf="companyRankingsMap?.has(year)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">
              {{ year }} Top Company Rankings
            </h3>
            <div></div>
            <div *ngFor="let roomId of companyRankingsMap.get(year)?.rankedIds; let i = index">
              {{ i+1 }}. <app-simpleroom [room]="roomMap.get(roomId)" [canShowWinnerLogo]=true [year]=year></app-simpleroom>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>
