<div [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>How much do you like or dislike horror games?</mat-label>
    <mat-select formControlName="horrorPreference">
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let pref of allPreferences" [value]="pref">
        <app-horrorpreference [pref]="pref" [showText]="true"></app-horrorpreference>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
