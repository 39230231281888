<section class="flex-col justify-center align-center">
  <mat-card>
    <div *ngIf="showSpinner" class="flex-col justify-center align-center">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!showSpinner" class="flex-col justify-center align-center">
      <h3>Welcome to the TERPECA Voter Portal!</h3>
      <img src="assets/toproomslogo.png" height="150px" width="150px">
    </div>
    <div *ngIf="!showSpinner">
      <p>
        We are looking for the world's most experienced escape room
        enthusiasts to help identify the world's best escape rooms.
        If that sounds like you, you came to the right place!
      </p>
      <p>
        To get started, or to sign in to an existing account*, select one of
        the sign-in options below.
      </p>
      <p>
        If you want to learn more first, start with the <a routerLink="/faq">FAQ</a> or
        you can watch our <a href="https://www.youtube.com/watch?v=juX7x-2gOhU" target="_blank">How to Apply</a>
        video which will walk you through the application process.
      </p>
    </div>
    <div id="firebaseui-auth-container" [hidden]="showSpinner"></div>
    <p *ngIf="!showSpinner" class="flex-row justify-center align-center">
      <a routerLink="." (click)="whereIsFacebookDialog()">Where is "Sign in with Facebook"?</a>
    </p>
    <div *ngIf="!showSpinner" style="font-size: 80%;">
      * If you have applied or participated in the past, please do not create a new account!
      If you cannot figure out how to sign in to your existing account, or you
      want to change the email or sign-in method for your account, contact
      admin&#64;terpeca.com.
    </div>
  </mat-card>
</section>
