<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
  <span [innerHtml]="data.message"></span>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button *ngIf="!data.hideCancel" mat-raised-button color="secondary" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="ok()">
    {{data.okLabel || 'OK'}}
  </button>
</div>
