import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

import { UnrankedReason, UnrankedReasonEntry } from 'src/app/models/ranking.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-unrankroomdialog',
  templateUrl: './unrankroomdialog.component.html',
  styleUrl: './unrankroomdialog.component.css'
})
export class UnrankRoomDialogComponent implements OnInit {
  form: UntypedFormGroup;
  UnrankedReason = UnrankedReason;
  @ViewChild('otherTextInput') otherTextInput: MatInput;

  constructor(public dialogRef: MatDialogRef<UnrankRoomDialogComponent>, private auth: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      reason: new FormControl<UnrankedReason>(null),
      note: new FormControl<string>(null)
    });
  }

  textClicked() {
    this.form.patchValue({ reason: UnrankedReason.OTHER });
  }

  reasonChanged() {
    if (this.form.value.reason !== UnrankedReason.OTHER) {
      this.form.patchValue({ note: null });
    } else {
      this.otherTextInput.focus();
    }
  }

  submitText() {
    switch (this.form.value.reason) {
      case UnrankedReason.DID_NOT_PLAY:
        return 'Mark Not Played';
      case UnrankedReason.CONFLICT_OF_INTEREST:
        return 'Mark Affiliated';
      default:
        return 'Mark Unranked';
    }
  }

  formIsValid(): boolean {
    return this.form.value.reason !== null && (this.form.value.reason !== UnrankedReason.OTHER || this.form.value.note);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async submit() {
    const reason: UnrankedReasonEntry = { reason: this.form.value.reason };
    if (reason.reason === UnrankedReason.OTHER) {
      reason.note = this.form.value.note;
    }
    this.dialogRef.close(true);
    this.data.ranker.unrankRoom(this.data.room, reason);
  }
}
