import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-versionupdateddialog',
  templateUrl: './versionupdateddialog.component.html',
  styleUrl: './versionupdateddialog.component.css'
})
export class VersionUpdatedDialogComponent {

  constructor(public dialogRef: MatDialogRef<VersionUpdatedDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog) { }

  snooze() {
    this.dialogRef.close(false);
    setTimeout(() => {
      this.dialog.open(VersionUpdatedDialogComponent, null);
    }, 5 * 60 * 1000);
  }

  reload() {
    window.location.reload();
  }
}
