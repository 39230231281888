import { Component } from '@angular/core';

import { ApplicationStatus } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  requestUpgrade = false;
  year = environment.currentAwardYear;
  Status = ApplicationStatus;
  utils = utils;

  constructor(public auth: AuthService, public settings: SettingsService) { }

  nominatorBackground() {
    if (this.settings.isNominationOpen(this.auth.currentUser) && !this.settings.isPastNominationDeadline()) {
      return 'lightyellow';
    }
    return 'inherit';
  }

  voterBackground() {
    if (this.settings.isVotingOpen(this.auth.currentUser) && !this.settings.isPastVotingDeadline()) {
      return 'lightyellow';
    }
    return 'inherit';
  }
}
