!function (t, e) {
  "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? exports.rollbar = e() : t.rollbar = e();
}(this, () => {
  return t = {
    276: function () {
      !function (t) {
        "use strict";

        t.console || (t.console = {});
        for (var e, r, n = t.console, o = function () {}, i = ["memory"], s = "assert,clear,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profiles,profileEnd,show,table,time,timeEnd,timeline,timelineEnd,timeStamp,trace,warn".split(","); e = i.pop();) n[e] || (n[e] = {});
        for (; r = s.pop();) n[r] || (n[r] = o);
      }("undefined" == typeof window ? this : window);
    },
    180: function (t, e, r) {
      var n, o, i;
      !function (s, a) {
        "use strict";

        o = [r(124)], void 0 === (i = "function" == typeof (n = function (t) {
          var e = /(^|@)\S+:\d+/,
            r = /^\s*at .*(\S+:\d+|\(native\))/m,
            n = /^(eval@)?(\[native code])?$/;
          return {
            parse: function (t) {
              if (void 0 !== t.stacktrace || void 0 !== t["opera#sourceloc"]) return this.parseOpera(t);
              if (t.stack && t.stack.match(r)) return this.parseV8OrIE(t);
              if (t.stack) return this.parseFFOrSafari(t);
              throw new Error("Cannot parse given Error object");
            },
            extractLocation: function (t) {
              if (-1 === t.indexOf(":")) return [t];
              var e = /(.+?)(?::(\d+))?(?::(\d+))?$/.exec(t.replace(/[()]/g, ""));
              return [e[1], e[2] || void 0, e[3] || void 0];
            },
            parseV8OrIE: function (e) {
              return e.stack.split("\n").filter(function (t) {
                return !!t.match(r);
              }, this).map(function (e) {
                e.indexOf("(eval ") > -1 && (e = e.replace(/eval code/g, "eval").replace(/(\(eval at [^()]*)|(\),.*$)/g, ""));
                var r = e.replace(/^\s+/, "").replace(/\(eval code/g, "("),
                  n = r.match(/ (\((.+):(\d+):(\d+)\)$)/),
                  o = (r = n ? r.replace(n[0], "") : r).split(/\s+/).slice(1),
                  i = this.extractLocation(n ? n[1] : o.pop()),
                  s = o.join(" ") || void 0,
                  a = ["eval", "<anonymous>"].indexOf(i[0]) > -1 ? void 0 : i[0];
                return new t({
                  functionName: s,
                  fileName: a,
                  lineNumber: i[1],
                  columnNumber: i[2],
                  source: e
                });
              }, this);
            },
            parseFFOrSafari: function (e) {
              return e.stack.split("\n").filter(function (t) {
                return !t.match(n);
              }, this).map(function (e) {
                if (e.indexOf(" > eval") > -1 && (e = e.replace(/ line (\d+)(?: > eval line \d+)* > eval:\d+:\d+/g, ":$1")), -1 === e.indexOf("@") && -1 === e.indexOf(":")) return new t({
                  functionName: e
                });
                var r = /((.*".+"[^@]*)?[^@]*)(?:@)/,
                  n = e.match(r),
                  o = n && n[1] ? n[1] : void 0,
                  i = this.extractLocation(e.replace(r, ""));
                return new t({
                  functionName: o,
                  fileName: i[0],
                  lineNumber: i[1],
                  columnNumber: i[2],
                  source: e
                });
              }, this);
            },
            parseOpera: function (t) {
              return !t.stacktrace || t.message.indexOf("\n") > -1 && t.message.split("\n").length > t.stacktrace.split("\n").length ? this.parseOpera9(t) : t.stack ? this.parseOpera11(t) : this.parseOpera10(t);
            },
            parseOpera9: function (e) {
              for (var r = /Line (\d+).*script (?:in )?(\S+)/i, n = e.message.split("\n"), o = [], i = 2, s = n.length; i < s; i += 2) {
                var a = r.exec(n[i]);
                a && o.push(new t({
                  fileName: a[2],
                  lineNumber: a[1],
                  source: n[i]
                }));
              }
              return o;
            },
            parseOpera10: function (e) {
              for (var r = /Line (\d+).*script (?:in )?(\S+)(?:: In function (\S+))?$/i, n = e.stacktrace.split("\n"), o = [], i = 0, s = n.length; i < s; i += 2) {
                var a = r.exec(n[i]);
                a && o.push(new t({
                  functionName: a[3] || void 0,
                  fileName: a[2],
                  lineNumber: a[1],
                  source: n[i]
                }));
              }
              return o;
            },
            parseOpera11: function (r) {
              return r.stack.split("\n").filter(function (t) {
                return !!t.match(e) && !t.match(/^Error created at/);
              }, this).map(function (e) {
                var r,
                  n = e.split("@"),
                  o = this.extractLocation(n.pop()),
                  i = n.shift() || "",
                  s = i.replace(/<anonymous function(: (\w+))?>/, "$2").replace(/\([^)]*\)/g, "") || void 0;
                i.match(/\(([^)]*)\)/) && (r = i.replace(/^[^(]+\(([^)]*)\)$/, "$1"));
                var a = void 0 === r || "[arguments not available]" === r ? void 0 : r.split(",");
                return new t({
                  functionName: s,
                  args: a,
                  fileName: o[0],
                  lineNumber: o[1],
                  columnNumber: o[2],
                  source: e
                });
              }, this);
            }
          };
        }) ? n.apply(e, o) : n) || (t.exports = i);
      }();
    },
    124: function (t, e) {
      var r, n, o;
      !function (i, s) {
        "use strict";

        n = [], void 0 === (o = "function" == typeof (r = function () {
          function t(t) {
            return !isNaN(parseFloat(t)) && isFinite(t);
          }
          function e(t) {
            return t.charAt(0).toUpperCase() + t.substring(1);
          }
          function r(t) {
            return function () {
              return this[t];
            };
          }
          var n = ["isConstructor", "isEval", "isNative", "isToplevel"],
            o = ["columnNumber", "lineNumber"],
            i = ["fileName", "functionName", "source"],
            s = ["args"],
            a = ["evalOrigin"],
            u = n.concat(o, i, s, a);
          function c(t) {
            if (t) for (var r = 0; r < u.length; r++) void 0 !== t[u[r]] && this["set" + e(u[r])](t[u[r]]);
          }
          c.prototype = {
            getArgs: function () {
              return this.args;
            },
            setArgs: function (t) {
              if ("[object Array]" !== Object.prototype.toString.call(t)) throw new TypeError("Args must be an Array");
              this.args = t;
            },
            getEvalOrigin: function () {
              return this.evalOrigin;
            },
            setEvalOrigin: function (t) {
              if (t instanceof c) this.evalOrigin = t;else {
                if (!(t instanceof Object)) throw new TypeError("Eval Origin must be an Object or StackFrame");
                this.evalOrigin = new c(t);
              }
            },
            toString: function () {
              var t = this.getFileName() || "",
                e = this.getLineNumber() || "",
                r = this.getColumnNumber() || "",
                n = this.getFunctionName() || "";
              return this.getIsEval() ? t ? "[eval] (" + t + ":" + e + ":" + r + ")" : "[eval]:" + e + ":" + r : n ? n + " (" + t + ":" + e + ":" + r + ")" : t + ":" + e + ":" + r;
            }
          }, c.fromString = function (t) {
            var e = t.indexOf("("),
              r = t.lastIndexOf(")"),
              n = t.substring(0, e),
              o = t.substring(e + 1, r).split(","),
              i = t.substring(r + 1);
            if (0 === i.indexOf("@")) var s = /@(.+?)(?::(\d+))?(?::(\d+))?$/.exec(i, ""),
              a = s[1],
              u = s[2],
              l = s[3];
            return new c({
              functionName: n,
              args: o || void 0,
              fileName: a,
              lineNumber: u || void 0,
              columnNumber: l || void 0
            });
          };
          for (var l = 0; l < n.length; l++) c.prototype["get" + e(n[l])] = r(n[l]), c.prototype["set" + e(n[l])] = function (t) {
            return function (e) {
              this[t] = Boolean(e);
            };
          }(n[l]);
          for (var p = 0; p < o.length; p++) c.prototype["get" + e(o[p])] = r(o[p]), c.prototype["set" + e(o[p])] = function (e) {
            return function (r) {
              if (!t(r)) throw new TypeError(e + " must be a Number");
              this[e] = Number(r);
            };
          }(o[p]);
          for (var h = 0; h < i.length; h++) c.prototype["get" + e(i[h])] = r(i[h]), c.prototype["set" + e(i[h])] = function (t) {
            return function (e) {
              this[t] = String(e);
            };
          }(i[h]);
          return c;
        }) ? r.apply(e, n) : r) || (t.exports = o);
      }();
    },
    815: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(817),
        i = {
          hostname: "api.rollbar.com",
          path: "/api/1/item/",
          search: null,
          version: "1",
          protocol: "https:",
          port: 443
        };
      function s(t, e, r, n, o) {
        this.options = t, this.transport = e, this.url = r, this.truncation = n, this.jsonBackup = o, this.accessToken = t.accessToken, this.transportOptions = a(t, r);
      }
      function a(t, e) {
        return o.getTransportFromOptions(t, i, e);
      }
      s.prototype.postItem = function (t, e) {
        var r = o.transportOptions(this.transportOptions, "POST"),
          n = o.buildPayload(this.accessToken, t, this.jsonBackup),
          i = this;
        setTimeout(function () {
          i.transport.post(i.accessToken, r, n, e);
        }, 0);
      }, s.prototype.buildJsonPayload = function (t, e) {
        var r,
          i = o.buildPayload(this.accessToken, t, this.jsonBackup);
        return (r = this.truncation ? this.truncation.truncate(i) : n.stringify(i)).error ? (e && e(r.error), null) : r.value;
      }, s.prototype.postJsonPayload = function (t, e) {
        var r = o.transportOptions(this.transportOptions, "POST");
        this.transport.postJsonPayload(this.accessToken, r, t, e);
      }, s.prototype.configure = function (t) {
        var e = this.oldOptions;
        return this.options = n.merge(e, t), this.transportOptions = a(this.options, this.url), void 0 !== this.options.accessToken && (this.accessToken = this.options.accessToken), this;
      }, t.exports = s;
    },
    817: (t, e, r) => {
      "use strict";

      var n = r(702);
      t.exports = {
        buildPayload: function (t, e, r) {
          if (!n.isType(e.context, "string")) {
            var o = n.stringify(e.context, r);
            o.error ? e.context = "Error: could not serialize 'context'" : e.context = o.value || "", e.context.length > 255 && (e.context = e.context.substr(0, 255));
          }
          return {
            access_token: t,
            data: e
          };
        },
        getTransportFromOptions: function (t, e, r) {
          var n = e.hostname,
            o = e.protocol,
            i = e.port,
            s = e.path,
            a = e.search,
            u = t.timeout,
            c = function (t) {
              var e = "undefined" != typeof window && window || "undefined" != typeof self && self,
                r = t.defaultTransport || "xhr";
              return void 0 === e.fetch && (r = "xhr"), void 0 === e.XMLHttpRequest && (r = "fetch"), r;
            }(t),
            l = t.proxy;
          if (t.endpoint) {
            var p = r.parse(t.endpoint);
            n = p.hostname, o = p.protocol, i = p.port, s = p.pathname, a = p.search;
          }
          return {
            timeout: u,
            hostname: n,
            protocol: o,
            port: i,
            path: s,
            search: a,
            proxy: l,
            transport: c
          };
        },
        transportOptions: function (t, e) {
          var r = t.protocol || "https:",
            n = t.port || ("http:" === r ? 80 : "https:" === r ? 443 : void 0),
            o = t.hostname,
            i = t.path,
            s = t.timeout,
            a = t.transport;
          return t.search && (i += t.search), t.proxy && (i = r + "//" + o + i, o = t.proxy.host || t.proxy.hostname, n = t.proxy.port, r = t.proxy.protocol || r), {
            timeout: s,
            protocol: r,
            hostname: o,
            path: i,
            port: n,
            method: e,
            transport: a
          };
        },
        appendPathToPath: function (t, e) {
          var r = /\/$/.test(t),
            n = /^\//.test(e);
          return r && n ? e = e.substring(1) : r || n || (e = "/" + e), t + e;
        }
      };
    },
    409: (t, e, r) => {
      "use strict";

      var n = r(343),
        o = "undefined" != typeof window && window._rollbarConfig,
        i = o && o.globalAlias || "Rollbar",
        s = "undefined" != typeof window && window[i] && "function" == typeof window[i].shimId && void 0 !== window[i].shimId();
      if ("undefined" == typeof window || window._rollbarStartTime || (window._rollbarStartTime = new Date().getTime()), !s && o) {
        var a = new n(o);
        window[i] = a;
      } else "undefined" != typeof window ? (window.rollbar = n, window._rollbarDidLoad = !0) : "undefined" != typeof self && (self.rollbar = n, self._rollbarDidLoad = !0);
      t.exports = n;
    },
    403: (t, e, r) => {
      "use strict";

      var n = r(562),
        o = r(702),
        i = r(815),
        s = r(802),
        a = r(349),
        u = r(477),
        c = r(509),
        l = r(860),
        p = r(417),
        h = r(172),
        f = r(61),
        d = r(303);
      function m(t, e) {
        this.options = o.handleOptions(x, t, null, s), this.options._configuredOptions = t;
        var r = this.components.telemeter,
          a = this.components.instrumenter,
          d = this.components.polyfillJSON;
        this.wrapGlobals = this.components.wrapGlobals, this.scrub = this.components.scrub;
        var m = this.components.truncation,
          g = new u(m),
          v = new i(this.options, g, c, m);
        r && (this.telemeter = new r(this.options)), this.client = e || new n(this.options, v, s, this.telemeter, "browser");
        var y = b(),
          w = "undefined" != typeof document && document;
        this.isChrome = y.chrome && y.chrome.runtime, this.anonymousErrorsPending = 0, function (t, e, r) {
          t.addTransform(l.handleDomException).addTransform(l.handleItemWithError).addTransform(l.ensureItemHasSomethingToSay).addTransform(l.addBaseInfo).addTransform(l.addRequestInfo(r)).addTransform(l.addClientInfo(r)).addTransform(l.addPluginInfo(r)).addTransform(l.addBody).addTransform(p.addMessageWithError).addTransform(p.addTelemetryData).addTransform(p.addConfigToPayload).addTransform(l.addScrubber(e.scrub)).addTransform(p.addPayloadOptions).addTransform(p.userTransform(s)).addTransform(p.addConfiguredOptions).addTransform(p.addDiagnosticKeys).addTransform(p.itemToPayload);
        }(this.client.notifier, this, y), this.client.queue.addPredicate(f.checkLevel).addPredicate(h.checkIgnore).addPredicate(f.userCheckIgnore(s)).addPredicate(f.urlIsNotBlockListed(s)).addPredicate(f.urlIsSafeListed(s)).addPredicate(f.messageIsIgnored(s)), this.setupUnhandledCapture(), a && (this.instrumenter = new a(this.options, this.client.telemeter, this, y, w), this.instrumenter.instrument()), o.setupJSON(d), this.rollbar = this;
      }
      var g = null;
      function v(t) {
        var e = "Rollbar is not initialized";
        s.error(e), t && t(new Error(e));
      }
      function y(t) {
        for (var e = 0, r = t.length; e < r; ++e) if (o.isFunction(t[e])) return t[e];
      }
      function b() {
        return "undefined" != typeof window && window || "undefined" != typeof self && self;
      }
      m.init = function (t, e) {
        return g ? g.global(t).configure(t) : g = new m(t, e);
      }, m.prototype.components = {}, m.setComponents = function (t) {
        m.prototype.components = t;
      }, m.prototype.global = function (t) {
        return this.client.global(t), this;
      }, m.global = function (t) {
        if (g) return g.global(t);
        v();
      }, m.prototype.configure = function (t, e) {
        var r = this.options,
          n = {};
        return e && (n = {
          payload: e
        }), this.options = o.handleOptions(r, t, n, s), this.options._configuredOptions = o.handleOptions(r._configuredOptions, t, n), this.client.configure(this.options, e), this.instrumenter && this.instrumenter.configure(this.options), this.setupUnhandledCapture(), this;
      }, m.configure = function (t, e) {
        if (g) return g.configure(t, e);
        v();
      }, m.prototype.lastError = function () {
        return this.client.lastError;
      }, m.lastError = function () {
        if (g) return g.lastError();
        v();
      }, m.prototype.log = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.log(t), {
          uuid: e
        };
      }, m.log = function () {
        if (g) return g.log.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.debug = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.debug(t), {
          uuid: e
        };
      }, m.debug = function () {
        if (g) return g.debug.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.info = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.info(t), {
          uuid: e
        };
      }, m.info = function () {
        if (g) return g.info.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.warn = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.warn(t), {
          uuid: e
        };
      }, m.warn = function () {
        if (g) return g.warn.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.warning = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.warning(t), {
          uuid: e
        };
      }, m.warning = function () {
        if (g) return g.warning.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.error = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.error(t), {
          uuid: e
        };
      }, m.error = function () {
        if (g) return g.error.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.critical = function () {
        var t = this._createItem(arguments),
          e = t.uuid;
        return this.client.critical(t), {
          uuid: e
        };
      }, m.critical = function () {
        if (g) return g.critical.apply(g, arguments);
        v(y(arguments));
      }, m.prototype.buildJsonPayload = function (t) {
        return this.client.buildJsonPayload(t);
      }, m.buildJsonPayload = function () {
        if (g) return g.buildJsonPayload.apply(g, arguments);
        v();
      }, m.prototype.sendJsonPayload = function (t) {
        return this.client.sendJsonPayload(t);
      }, m.sendJsonPayload = function () {
        if (g) return g.sendJsonPayload.apply(g, arguments);
        v();
      }, m.prototype.setupUnhandledCapture = function () {
        var t = b();
        this.unhandledExceptionsInitialized || (this.options.captureUncaught || this.options.handleUncaughtExceptions) && (a.captureUncaughtExceptions(t, this), this.wrapGlobals && this.options.wrapGlobalEventHandlers && this.wrapGlobals(t, this), this.unhandledExceptionsInitialized = !0), this.unhandledRejectionsInitialized || (this.options.captureUnhandledRejections || this.options.handleUnhandledRejections) && (a.captureUnhandledRejections(t, this), this.unhandledRejectionsInitialized = !0);
      }, m.prototype.handleUncaughtException = function (t, e, r, n, i, s) {
        if (this.options.captureUncaught || this.options.handleUncaughtExceptions) {
          if (this.options.inspectAnonymousErrors && this.isChrome && null === i && "" === e) return "anonymous";
          var a,
            u = o.makeUnhandledStackInfo(t, e, r, n, i, "onerror", "uncaught exception", d);
          o.isError(i) ? (a = this._createItem([t, i, s]))._unhandledStackInfo = u : o.isError(e) ? (a = this._createItem([t, e, s]))._unhandledStackInfo = u : (a = this._createItem([t, s])).stackInfo = u, a.level = this.options.uncaughtErrorLevel, a._isUncaught = !0, this.client.log(a);
        }
      }, m.prototype.handleAnonymousErrors = function () {
        if (this.options.inspectAnonymousErrors && this.isChrome) {
          var t = this;
          try {
            Error.prepareStackTrace = function (e, r) {
              if (t.options.inspectAnonymousErrors && t.anonymousErrorsPending) {
                if (t.anonymousErrorsPending -= 1, !e) return;
                e._isAnonymous = !0, t.handleUncaughtException(e.message, null, null, null, e);
              }
              return e.stack;
            };
          } catch (t) {
            this.options.inspectAnonymousErrors = !1, this.error("anonymous error handler failed", t);
          }
        }
      }, m.prototype.handleUnhandledRejection = function (t, e) {
        if (this.options.captureUnhandledRejections || this.options.handleUnhandledRejections) {
          var r = "unhandled rejection was null or undefined!";
          if (t) if (t.message) r = t.message;else {
            var n = o.stringify(t);
            n.value && (r = n.value);
          }
          var i,
            s = t && t._rollbarContext || e && e._rollbarContext;
          o.isError(t) ? i = this._createItem([r, t, s]) : (i = this._createItem([r, t, s])).stackInfo = o.makeUnhandledStackInfo(r, "", 0, 0, null, "unhandledrejection", "", d), i.level = this.options.uncaughtErrorLevel, i._isUncaught = !0, i._originalArgs = i._originalArgs || [], i._originalArgs.push(e), this.client.log(i);
        }
      }, m.prototype.wrap = function (t, e, r) {
        try {
          var n;
          if (n = o.isFunction(e) ? e : function () {
            return e || {};
          }, !o.isFunction(t)) return t;
          if (t._isWrap) return t;
          if (!t._rollbar_wrapped && (t._rollbar_wrapped = function () {
            r && o.isFunction(r) && r.apply(this, arguments);
            try {
              return t.apply(this, arguments);
            } catch (r) {
              var e = r;
              throw e && window._rollbarWrappedError !== e && (o.isType(e, "string") && (e = new String(e)), e._rollbarContext = n() || {}, e._rollbarContext._wrappedSource = t.toString(), window._rollbarWrappedError = e), e;
            }
          }, t._rollbar_wrapped._isWrap = !0, t.hasOwnProperty)) for (var i in t) t.hasOwnProperty(i) && "_rollbar_wrapped" !== i && (t._rollbar_wrapped[i] = t[i]);
          return t._rollbar_wrapped;
        } catch (e) {
          return t;
        }
      }, m.wrap = function (t, e) {
        if (g) return g.wrap(t, e);
        v();
      }, m.prototype.captureEvent = function () {
        var t = o.createTelemetryEvent(arguments);
        return this.client.captureEvent(t.type, t.metadata, t.level);
      }, m.captureEvent = function () {
        if (g) return g.captureEvent.apply(g, arguments);
        v();
      }, m.prototype.captureDomContentLoaded = function (t, e) {
        return e || (e = new Date()), this.client.captureDomContentLoaded(e);
      }, m.prototype.captureLoad = function (t, e) {
        return e || (e = new Date()), this.client.captureLoad(e);
      }, m.prototype.loadFull = function () {
        s.info("Unexpected Rollbar.loadFull() called on a Notifier instance. This can happen when Rollbar is loaded multiple times.");
      }, m.prototype._createItem = function (t) {
        return o.createItem(t, s, this);
      };
      var w = r(948),
        _ = r(677),
        x = {
          version: w.version,
          scrubFields: _.scrubFields,
          logLevel: w.logLevel,
          reportLevel: w.reportLevel,
          uncaughtErrorLevel: w.uncaughtErrorLevel,
          endpoint: w.endpoint,
          verbose: !1,
          enabled: !0,
          transmit: !0,
          sendConfig: !1,
          includeItemsInTelemetry: !0,
          captureIp: !0,
          inspectAnonymousErrors: !0,
          ignoreDuplicateErrors: !0,
          wrapGlobalEventHandlers: !1
        };
      t.exports = m;
    },
    677: t => {
      "use strict";

      t.exports = {
        scrubFields: ["pw", "pass", "passwd", "password", "secret", "confirm_password", "confirmPassword", "password_confirmation", "passwordConfirmation", "access_token", "accessToken", "X-Rollbar-Access-Token", "secret_key", "secretKey", "secretToken", "cc-number", "card number", "cardnumber", "cardnum", "ccnum", "ccnumber", "cc num", "creditcardnumber", "credit card number", "newcreditcardnumber", "new credit card", "creditcardno", "credit card no", "card#", "card #", "cc-csc", "cvc", "cvc2", "cvv2", "ccv2", "security code", "card verification", "name on credit card", "name on card", "nameoncard", "cardholder", "card holder", "name des karteninhabers", "ccname", "card type", "cardtype", "cc type", "cctype", "payment type", "expiration date", "expirationdate", "expdate", "cc-exp", "ccmonth", "ccyear"]
      };
    },
    616: t => {
      "use strict";

      var e = {
        ieVersion: function () {
          var t;
          if ("undefined" == typeof document) return t;
          for (var e = 3, r = document.createElement("div"), n = r.getElementsByTagName("i"); r.innerHTML = "\x3c!--[if gt IE " + ++e + "]><i></i><![endif]--\x3e", n[0];);
          return e > 4 ? e : t;
        }
      };
      t.exports = e;
    },
    300: t => {
      "use strict";

      function e(t) {
        return (t.getAttribute("type") || "").toLowerCase();
      }
      function r(t) {
        if (!t || !t.tagName) return "";
        var e = [t.tagName];
        t.id && e.push("#" + t.id), t.classes && e.push("." + t.classes.join("."));
        for (var r = 0; r < t.attributes.length; r++) e.push("[" + t.attributes[r].key + '="' + t.attributes[r].value + '"]');
        return e.join("");
      }
      function n(t) {
        if (!t || !t.tagName) return null;
        var e,
          r,
          n,
          o,
          i = {};
        i.tagName = t.tagName.toLowerCase(), t.id && (i.id = t.id), (e = t.className) && "string" == typeof e && (i.classes = e.split(/\s+/));
        var s = ["type", "name", "title", "alt"];
        for (i.attributes = [], o = 0; o < s.length; o++) r = s[o], (n = t.getAttribute(r)) && i.attributes.push({
          key: r,
          value: n
        });
        return i;
      }
      t.exports = {
        describeElement: n,
        descriptionToString: r,
        elementArrayToString: function (t) {
          for (var e, n, o = [], i = 0, s = t.length - 1; s >= 0; s--) {
            if (e = r(t[s]), n = i + 3 * o.length + e.length, s < t.length - 1 && n >= 83) {
              o.unshift("...");
              break;
            }
            o.unshift(e), i += e.length;
          }
          return o.join(" > ");
        },
        treeToArray: function (t) {
          for (var e, r = [], o = 0; t && o < 5 && "html" !== (e = n(t)).tagName; o++) r.unshift(e), t = t.parentNode;
          return r;
        },
        getElementFromEvent: function (t, e) {
          return t.target ? t.target : e && e.elementFromPoint ? e.elementFromPoint(t.clientX, t.clientY) : void 0;
        },
        isDescribedElement: function (t, r, n) {
          if (t.tagName.toLowerCase() !== r.toLowerCase()) return !1;
          if (!n) return !0;
          t = e(t);
          for (var o = 0; o < n.length; o++) if (n[o] === t) return !0;
          return !1;
        },
        getElementType: e
      };
    },
    349: t => {
      "use strict";

      t.exports = {
        captureUncaughtExceptions: function (t, e, r) {
          if (t) {
            var n;
            if ("function" == typeof e._rollbarOldOnError) n = e._rollbarOldOnError;else if (t.onerror) {
              for (n = t.onerror; n._rollbarOldOnError;) n = n._rollbarOldOnError;
              e._rollbarOldOnError = n;
            }
            e.handleAnonymousErrors();
            var o = function () {
              var r = Array.prototype.slice.call(arguments, 0);
              !function (t, e, r, n) {
                t._rollbarWrappedError && (n[4] || (n[4] = t._rollbarWrappedError), n[5] || (n[5] = t._rollbarWrappedError._rollbarContext), t._rollbarWrappedError = null);
                var o = e.handleUncaughtException.apply(e, n);
                r && r.apply(t, n), "anonymous" === o && (e.anonymousErrorsPending += 1);
              }(t, e, n, r);
            };
            r && (o._rollbarOldOnError = n), t.onerror = o;
          }
        },
        captureUnhandledRejections: function (t, e, r) {
          if (t) {
            "function" == typeof t._rollbarURH && t._rollbarURH.belongsToShim && t.removeEventListener("unhandledrejection", t._rollbarURH);
            var n = function (t) {
              var r, n, o;
              try {
                r = t.reason;
              } catch (t) {
                r = void 0;
              }
              try {
                n = t.promise;
              } catch (t) {
                n = "[unhandledrejection] error getting `promise` from event";
              }
              try {
                o = t.detail, !r && o && (r = o.reason, n = o.promise);
              } catch (t) {}
              r || (r = "[unhandledrejection] error getting `reason` from event"), e && e.handleUnhandledRejection && e.handleUnhandledRejection(r, n);
            };
            n.belongsToShim = r, t._rollbarURH = n, t.addEventListener("unhandledrejection", n);
          }
        }
      };
    },
    802: (t, e, r) => {
      "use strict";

      r(276);
      var n = r(616),
        o = r(702);
      t.exports = {
        error: function () {
          var t = Array.prototype.slice.call(arguments, 0);
          t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.error(o.formatArgsAsString(t)) : console.error.apply(console, t);
        },
        info: function () {
          var t = Array.prototype.slice.call(arguments, 0);
          t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.info(o.formatArgsAsString(t)) : console.info.apply(console, t);
        },
        log: function () {
          var t = Array.prototype.slice.call(arguments, 0);
          t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.log(o.formatArgsAsString(t)) : console.log.apply(console, t);
        }
      };
    },
    172: (t, e, r) => {
      "use strict";

      var n = r(702);
      t.exports = {
        checkIgnore: function (t, e) {
          return !n.get(e, "plugins.jquery.ignoreAjaxErrors") || !n.get(t, "body.message.extra.isAjax");
        }
      };
    },
    343: (t, e, r) => {
      "use strict";

      var n = r(403),
        o = r(773),
        i = r(568),
        s = r(876),
        a = r(965),
        u = r(369),
        c = r(473);
      n.setComponents({
        telemeter: o,
        instrumenter: i,
        polyfillJSON: s,
        wrapGlobals: a,
        scrub: u,
        truncation: c
      }), t.exports = n;
    },
    568: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(650),
        i = r(297),
        s = r(369),
        a = r(509),
        u = r(300),
        c = {
          network: !0,
          networkResponseHeaders: !1,
          networkResponseBody: !1,
          networkRequestHeaders: !1,
          networkRequestBody: !1,
          networkErrorOnHttp5xx: !1,
          networkErrorOnHttp4xx: !1,
          networkErrorOnHttp0: !1,
          log: !0,
          dom: !0,
          navigation: !0,
          connectivity: !0,
          contentSecurityPolicy: !0,
          errorOnContentSecurityPolicy: !1
        };
      function l(t, e) {
        for (var r; t[e].length;) (r = t[e].shift())[0][r[1]] = r[2];
      }
      function p(t, e, r, o, i) {
        this.options = t;
        var s = t.autoInstrument;
        !1 === t.enabled || !1 === s ? this.autoInstrument = {} : (n.isType(s, "object") || (s = c), this.autoInstrument = n.merge(c, s)), this.scrubTelemetryInputs = !!t.scrubTelemetryInputs, this.telemetryScrubber = t.telemetryScrubber, this.defaultValueScrubber = function (t) {
          for (var e = [], r = 0; r < t.length; ++r) e.push(new RegExp(t[r], "i"));
          return function (t) {
            var r = function (t) {
              if (!t || !t.attributes) return null;
              for (var e = t.attributes, r = 0; r < e.length; ++r) if ("name" === e[r].key) return e[r].value;
              return null;
            }(t);
            if (!r) return !1;
            for (var n = 0; n < e.length; ++n) if (e[n].test(r)) return !0;
            return !1;
          };
        }(t.scrubFields), this.telemeter = e, this.rollbar = r, this.diagnostic = r.client.notifier.diagnostic, this._window = o || {}, this._document = i || {}, this.replacements = {
          network: [],
          log: [],
          navigation: [],
          connectivity: []
        }, this.eventRemovers = {
          dom: [],
          connectivity: [],
          contentsecuritypolicy: []
        }, this._location = this._window.location, this._lastHref = this._location && this._location.href;
      }
      function h(t) {
        return "undefined" != typeof URL && t instanceof URL;
      }
      p.prototype.configure = function (t) {
        this.options = n.merge(this.options, t);
        var e = t.autoInstrument,
          r = n.merge(this.autoInstrument);
        !1 === t.enabled || !1 === e ? this.autoInstrument = {} : (n.isType(e, "object") || (e = c), this.autoInstrument = n.merge(c, e)), this.instrument(r), void 0 !== t.scrubTelemetryInputs && (this.scrubTelemetryInputs = !!t.scrubTelemetryInputs), void 0 !== t.telemetryScrubber && (this.telemetryScrubber = t.telemetryScrubber);
      }, p.prototype.instrument = function (t) {
        !this.autoInstrument.network || t && t.network ? !this.autoInstrument.network && t && t.network && this.deinstrumentNetwork() : this.instrumentNetwork(), !this.autoInstrument.log || t && t.log ? !this.autoInstrument.log && t && t.log && this.deinstrumentConsole() : this.instrumentConsole(), !this.autoInstrument.dom || t && t.dom ? !this.autoInstrument.dom && t && t.dom && this.deinstrumentDom() : this.instrumentDom(), !this.autoInstrument.navigation || t && t.navigation ? !this.autoInstrument.navigation && t && t.navigation && this.deinstrumentNavigation() : this.instrumentNavigation(), !this.autoInstrument.connectivity || t && t.connectivity ? !this.autoInstrument.connectivity && t && t.connectivity && this.deinstrumentConnectivity() : this.instrumentConnectivity(), !this.autoInstrument.contentSecurityPolicy || t && t.contentSecurityPolicy ? !this.autoInstrument.contentSecurityPolicy && t && t.contentSecurityPolicy && this.deinstrumentContentSecurityPolicy() : this.instrumentContentSecurityPolicy();
      }, p.prototype.deinstrumentNetwork = function () {
        l(this.replacements, "network");
      }, p.prototype.instrumentNetwork = function () {
        var t = this;
        function e(e, r) {
          e in r && n.isFunction(r[e]) && i(r, e, function (e) {
            return t.rollbar.wrap(e);
          });
        }
        if ("XMLHttpRequest" in this._window) {
          var r = this._window.XMLHttpRequest.prototype;
          i(r, "open", function (t) {
            return function (e, r) {
              var o = h(r);
              return (n.isType(r, "string") || o) && (r = o ? r.toString() : r, this.__rollbar_xhr ? (this.__rollbar_xhr.method = e, this.__rollbar_xhr.url = r, this.__rollbar_xhr.status_code = null, this.__rollbar_xhr.start_time_ms = n.now(), this.__rollbar_xhr.end_time_ms = null) : this.__rollbar_xhr = {
                method: e,
                url: r,
                status_code: null,
                start_time_ms: n.now(),
                end_time_ms: null
              }), t.apply(this, arguments);
            };
          }, this.replacements, "network"), i(r, "setRequestHeader", function (e) {
            return function (r, o) {
              return this.__rollbar_xhr || (this.__rollbar_xhr = {}), n.isType(r, "string") && n.isType(o, "string") && (t.autoInstrument.networkRequestHeaders && (this.__rollbar_xhr.request_headers || (this.__rollbar_xhr.request_headers = {}), this.__rollbar_xhr.request_headers[r] = o), "content-type" === r.toLowerCase() && (this.__rollbar_xhr.request_content_type = o)), e.apply(this, arguments);
            };
          }, this.replacements, "network"), i(r, "send", function (r) {
            return function (o) {
              var s = this;
              function a() {
                if (s.__rollbar_xhr && (null === s.__rollbar_xhr.status_code && (s.__rollbar_xhr.status_code = 0, t.autoInstrument.networkRequestBody && (s.__rollbar_xhr.request = o), s.__rollbar_event = t.captureNetwork(s.__rollbar_xhr, "xhr", void 0)), s.readyState < 2 && (s.__rollbar_xhr.start_time_ms = n.now()), s.readyState > 3)) {
                  s.__rollbar_xhr.end_time_ms = n.now();
                  var e = null;
                  if (s.__rollbar_xhr.response_content_type = s.getResponseHeader("Content-Type"), t.autoInstrument.networkResponseHeaders) {
                    var r = t.autoInstrument.networkResponseHeaders;
                    e = {};
                    try {
                      var i, a;
                      if (!0 === r) {
                        var u = s.getAllResponseHeaders();
                        if (u) {
                          var c,
                            l,
                            p = u.trim().split(/[\r\n]+/);
                          for (a = 0; a < p.length; a++) i = (c = p[a].split(": ")).shift(), l = c.join(": "), e[i] = l;
                        }
                      } else for (a = 0; a < r.length; a++) e[i = r[a]] = s.getResponseHeader(i);
                    } catch (t) {}
                  }
                  var h = null;
                  if (t.autoInstrument.networkResponseBody) try {
                    h = s.responseText;
                  } catch (t) {}
                  var f = null;
                  (h || e) && (f = {}, h && (t.isJsonContentType(s.__rollbar_xhr.response_content_type) ? f.body = t.scrubJson(h) : f.body = h), e && (f.headers = e)), f && (s.__rollbar_xhr.response = f);
                  try {
                    var d = s.status;
                    d = 1223 === d ? 204 : d, s.__rollbar_xhr.status_code = d, s.__rollbar_event.level = t.telemeter.levelFromStatus(d), t.errorOnHttpStatus(s.__rollbar_xhr);
                  } catch (t) {}
                }
              }
              return e("onload", s), e("onerror", s), e("onprogress", s), "onreadystatechange" in s && n.isFunction(s.onreadystatechange) ? i(s, "onreadystatechange", function (e) {
                return t.rollbar.wrap(e, void 0, a);
              }) : s.onreadystatechange = a, s.__rollbar_xhr && t.trackHttpErrors() && (s.__rollbar_xhr.stack = new Error().stack), r.apply(this, arguments);
            };
          }, this.replacements, "network");
        }
        "fetch" in this._window && i(this._window, "fetch", function (e) {
          return function (r, i) {
            for (var s = new Array(arguments.length), a = 0, u = s.length; a < u; a++) s[a] = arguments[a];
            var c,
              l = s[0],
              p = "GET",
              f = h(l);
            n.isType(l, "string") || f ? c = f ? l.toString() : l : l && (c = l.url, l.method && (p = l.method)), s[1] && s[1].method && (p = s[1].method);
            var d = {
              method: p,
              url: c,
              status_code: null,
              start_time_ms: n.now(),
              end_time_ms: null
            };
            if (s[1] && s[1].headers) {
              var m = o(s[1].headers);
              d.request_content_type = m.get("Content-Type"), t.autoInstrument.networkRequestHeaders && (d.request_headers = t.fetchHeaders(m, t.autoInstrument.networkRequestHeaders));
            }
            return t.autoInstrument.networkRequestBody && (s[1] && s[1].body ? d.request = s[1].body : s[0] && !n.isType(s[0], "string") && s[0].body && (d.request = s[0].body)), t.captureNetwork(d, "fetch", void 0), t.trackHttpErrors() && (d.stack = new Error().stack), e.apply(this, s).then(function (e) {
              d.end_time_ms = n.now(), d.status_code = e.status, d.response_content_type = e.headers.get("Content-Type");
              var r = null;
              t.autoInstrument.networkResponseHeaders && (r = t.fetchHeaders(e.headers, t.autoInstrument.networkResponseHeaders));
              var o = null;
              return t.autoInstrument.networkResponseBody && "function" == typeof e.text && (o = e.clone().text()), (r || o) && (d.response = {}, o && ("function" == typeof o.then ? o.then(function (e) {
                e && t.isJsonContentType(d.response_content_type) ? d.response.body = t.scrubJson(e) : d.response.body = e;
              }) : d.response.body = o), r && (d.response.headers = r)), t.errorOnHttpStatus(d), e;
            });
          };
        }, this.replacements, "network");
      }, p.prototype.captureNetwork = function (t, e, r) {
        return t.request && this.isJsonContentType(t.request_content_type) && (t.request = this.scrubJson(t.request)), this.telemeter.captureNetwork(t, e, r);
      }, p.prototype.isJsonContentType = function (t) {
        return !!(t && n.isType(t, "string") && t.toLowerCase().includes("json"));
      }, p.prototype.scrubJson = function (t) {
        return JSON.stringify(s(JSON.parse(t), this.options.scrubFields));
      }, p.prototype.fetchHeaders = function (t, e) {
        var r = {};
        try {
          var n;
          if (!0 === e) {
            if ("function" == typeof t.entries) for (var o = t.entries(), i = o.next(); !i.done;) r[i.value[0]] = i.value[1], i = o.next();
          } else for (n = 0; n < e.length; n++) {
            var s = e[n];
            r[s] = t.get(s);
          }
        } catch (t) {}
        return r;
      }, p.prototype.trackHttpErrors = function () {
        return this.autoInstrument.networkErrorOnHttp5xx || this.autoInstrument.networkErrorOnHttp4xx || this.autoInstrument.networkErrorOnHttp0;
      }, p.prototype.errorOnHttpStatus = function (t) {
        var e = t.status_code;
        if (e >= 500 && this.autoInstrument.networkErrorOnHttp5xx || e >= 400 && this.autoInstrument.networkErrorOnHttp4xx || 0 === e && this.autoInstrument.networkErrorOnHttp0) {
          var r = new Error("HTTP request failed with Status " + e);
          r.stack = t.stack, this.rollbar.error(r, {
            skipFrames: 1
          });
        }
      }, p.prototype.deinstrumentConsole = function () {
        if ("console" in this._window && this._window.console.log) for (var t; this.replacements.log.length;) t = this.replacements.log.shift(), this._window.console[t[0]] = t[1];
      }, p.prototype.instrumentConsole = function () {
        if ("console" in this._window && this._window.console.log) {
          var t = this,
            e = this._window.console,
            r = ["debug", "info", "warn", "error", "log"];
          try {
            for (var o = 0, i = r.length; o < i; o++) s(r[o]);
          } catch (t) {
            this.diagnostic.instrumentConsole = {
              error: t.message
            };
          }
        }
        function s(r) {
          var o = e[r],
            i = e,
            s = "warn" === r ? "warning" : r;
          e[r] = function () {
            var e = Array.prototype.slice.call(arguments),
              r = n.formatArgsAsString(e);
            t.telemeter.captureLog(r, s), o && Function.prototype.apply.call(o, i, e);
          }, t.replacements.log.push([r, o]);
        }
      }, p.prototype.deinstrumentDom = function () {
        ("addEventListener" in this._window || "attachEvent" in this._window) && this.removeListeners("dom");
      }, p.prototype.instrumentDom = function () {
        if ("addEventListener" in this._window || "attachEvent" in this._window) {
          var t = this.handleClick.bind(this),
            e = this.handleBlur.bind(this);
          this.addListener("dom", this._window, "click", "onclick", t, !0), this.addListener("dom", this._window, "blur", "onfocusout", e, !0);
        }
      }, p.prototype.handleClick = function (t) {
        try {
          var e = u.getElementFromEvent(t, this._document),
            r = e && e.tagName,
            n = u.isDescribedElement(e, "a") || u.isDescribedElement(e, "button");
          r && (n || u.isDescribedElement(e, "input", ["button", "submit"])) ? this.captureDomEvent("click", e) : u.isDescribedElement(e, "input", ["checkbox", "radio"]) && this.captureDomEvent("input", e, e.value, e.checked);
        } catch (t) {}
      }, p.prototype.handleBlur = function (t) {
        try {
          var e = u.getElementFromEvent(t, this._document);
          e && e.tagName && (u.isDescribedElement(e, "textarea") ? this.captureDomEvent("input", e, e.value) : u.isDescribedElement(e, "select") && e.options && e.options.length ? this.handleSelectInputChanged(e) : u.isDescribedElement(e, "input") && !u.isDescribedElement(e, "input", ["button", "submit", "hidden", "checkbox", "radio"]) && this.captureDomEvent("input", e, e.value));
        } catch (t) {}
      }, p.prototype.handleSelectInputChanged = function (t) {
        if (t.multiple) for (var e = 0; e < t.options.length; e++) t.options[e].selected && this.captureDomEvent("input", t, t.options[e].value);else t.selectedIndex >= 0 && t.options[t.selectedIndex] && this.captureDomEvent("input", t, t.options[t.selectedIndex].value);
      }, p.prototype.captureDomEvent = function (t, e, r, n) {
        if (void 0 !== r) if (this.scrubTelemetryInputs || "password" === u.getElementType(e)) r = "[scrubbed]";else {
          var o = u.describeElement(e);
          this.telemetryScrubber ? this.telemetryScrubber(o) && (r = "[scrubbed]") : this.defaultValueScrubber(o) && (r = "[scrubbed]");
        }
        var i = u.elementArrayToString(u.treeToArray(e));
        this.telemeter.captureDom(t, i, r, n);
      }, p.prototype.deinstrumentNavigation = function () {
        var t = this._window.chrome;
        !(t && t.app && t.app.runtime) && this._window.history && this._window.history.pushState && l(this.replacements, "navigation");
      }, p.prototype.instrumentNavigation = function () {
        var t = this._window.chrome;
        if (!(t && t.app && t.app.runtime) && this._window.history && this._window.history.pushState) {
          var e = this;
          i(this._window, "onpopstate", function (t) {
            return function () {
              var r = e._location.href;
              e.handleUrlChange(e._lastHref, r), t && t.apply(this, arguments);
            };
          }, this.replacements, "navigation"), i(this._window.history, "pushState", function (t) {
            return function () {
              var r = arguments.length > 2 ? arguments[2] : void 0;
              return r && e.handleUrlChange(e._lastHref, r + ""), t.apply(this, arguments);
            };
          }, this.replacements, "navigation");
        }
      }, p.prototype.handleUrlChange = function (t, e) {
        var r = a.parse(this._location.href),
          n = a.parse(e),
          o = a.parse(t);
        this._lastHref = e, r.protocol === n.protocol && r.host === n.host && (e = n.path + (n.hash || "")), r.protocol === o.protocol && r.host === o.host && (t = o.path + (o.hash || "")), this.telemeter.captureNavigation(t, e);
      }, p.prototype.deinstrumentConnectivity = function () {
        ("addEventListener" in this._window || "body" in this._document) && (this._window.addEventListener ? this.removeListeners("connectivity") : l(this.replacements, "connectivity"));
      }, p.prototype.instrumentConnectivity = function () {
        if ("addEventListener" in this._window || "body" in this._document) if (this._window.addEventListener) this.addListener("connectivity", this._window, "online", void 0, function () {
          this.telemeter.captureConnectivityChange("online");
        }.bind(this), !0), this.addListener("connectivity", this._window, "offline", void 0, function () {
          this.telemeter.captureConnectivityChange("offline");
        }.bind(this), !0);else {
          var t = this;
          i(this._document.body, "ononline", function (e) {
            return function () {
              t.telemeter.captureConnectivityChange("online"), e && e.apply(this, arguments);
            };
          }, this.replacements, "connectivity"), i(this._document.body, "onoffline", function (e) {
            return function () {
              t.telemeter.captureConnectivityChange("offline"), e && e.apply(this, arguments);
            };
          }, this.replacements, "connectivity");
        }
      }, p.prototype.handleCspEvent = function (t) {
        var e = "Security Policy Violation: blockedURI: " + t.blockedURI + ", violatedDirective: " + t.violatedDirective + ", effectiveDirective: " + t.effectiveDirective + ", ";
        t.sourceFile && (e += "location: " + t.sourceFile + ", line: " + t.lineNumber + ", col: " + t.columnNumber + ", "), e += "originalPolicy: " + t.originalPolicy, this.telemeter.captureLog(e, "error"), this.handleCspError(e);
      }, p.prototype.handleCspError = function (t) {
        this.autoInstrument.errorOnContentSecurityPolicy && this.rollbar.error(t);
      }, p.prototype.deinstrumentContentSecurityPolicy = function () {
        "addEventListener" in this._document && this.removeListeners("contentsecuritypolicy");
      }, p.prototype.instrumentContentSecurityPolicy = function () {
        if ("addEventListener" in this._document) {
          var t = this.handleCspEvent.bind(this);
          this.addListener("contentsecuritypolicy", this._document, "securitypolicyviolation", null, t, !1);
        }
      }, p.prototype.addListener = function (t, e, r, n, o, i) {
        e.addEventListener ? (e.addEventListener(r, o, i), this.eventRemovers[t].push(function () {
          e.removeEventListener(r, o, i);
        })) : n && (e.attachEvent(n, o), this.eventRemovers[t].push(function () {
          e.detachEvent(n, o);
        }));
      }, p.prototype.removeListeners = function (t) {
        for (; this.eventRemovers[t].length;) this.eventRemovers[t].shift()();
      }, t.exports = p;
    },
    860: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(303),
        i = r(802);
      function s(t, e, r) {
        var o = t.message,
          i = t.custom;
        o || (o = "Item sent with null or missing arguments.");
        var s = {
          body: o
        };
        i && (s.extra = n.merge(i)), n.set(t, "data.body", {
          message: s
        }), r(null, t);
      }
      function a(t) {
        var e = t.stackInfo.stack;
        return e && 0 === e.length && t._unhandledStackInfo && t._unhandledStackInfo.stack && (e = t._unhandledStackInfo.stack), e;
      }
      function u(t, e, r) {
        var i = t && t.data.description,
          s = t && t.custom,
          u = a(t),
          l = o.guessErrorClass(e.message),
          p = {
            exception: {
              class: c(e, l[0], r),
              message: l[1]
            }
          };
        if (i && (p.exception.description = i), u) {
          var h, f, d, m, g, v, y, b;
          for (0 === u.length && (p.exception.stack = e.rawStack, p.exception.raw = String(e.rawException)), p.frames = [], y = 0; y < u.length; ++y) f = {
            filename: (h = u[y]).url ? n.sanitizeUrl(h.url) : "(unknown)",
            lineno: h.line || null,
            method: h.func && "?" !== h.func ? h.func : "[anonymous]",
            colno: h.column
          }, r.sendFrameUrl && (f.url = h.url), f.method && f.method.endsWith && f.method.endsWith("_rollbar_wrapped") || (d = m = g = null, (v = h.context ? h.context.length : 0) && (b = Math.floor(v / 2), m = h.context.slice(0, b), d = h.context[b], g = h.context.slice(b)), d && (f.code = d), (m || g) && (f.context = {}, m && m.length && (f.context.pre = m), g && g.length && (f.context.post = g)), h.args && (f.args = h.args), p.frames.push(f));
          p.frames.reverse(), s && (p.extra = n.merge(s));
        }
        return p;
      }
      function c(t, e, r) {
        return t.name ? t.name : r.guessErrorClass ? e : "(unknown)";
      }
      t.exports = {
        handleDomException: function (t, e, r) {
          if (t.err && "DOMException" === o.Stack(t.err).name) {
            var n = new Error();
            n.name = t.err.name, n.message = t.err.message, n.stack = t.err.stack, n.nested = t.err, t.err = n;
          }
          r(null, t);
        },
        handleItemWithError: function (t, e, r) {
          if (t.data = t.data || {}, t.err) try {
            t.stackInfo = t.err._savedStackTrace || o.parse(t.err, t.skipFrames), e.addErrorContext && function (t) {
              var e = [],
                r = t.err;
              for (e.push(r); r.nested || r.cause;) r = r.nested || r.cause, e.push(r);
              n.addErrorContext(t, e);
            }(t);
          } catch (e) {
            i.error("Error while parsing the error object.", e);
            try {
              t.message = t.err.message || t.err.description || t.message || String(t.err);
            } catch (e) {
              t.message = String(t.err) || String(e);
            }
            delete t.err;
          }
          r(null, t);
        },
        ensureItemHasSomethingToSay: function (t, e, r) {
          t.message || t.stackInfo || t.custom || r(new Error("No message, stack info, or custom data"), null), r(null, t);
        },
        addBaseInfo: function (t, e, r) {
          var o = e.payload && e.payload.environment || e.environment;
          t.data = n.merge(t.data, {
            environment: o,
            level: t.level,
            endpoint: e.endpoint,
            platform: "browser",
            framework: "browser-js",
            language: "javascript",
            server: {},
            uuid: t.uuid,
            notifier: {
              name: "rollbar-browser-js",
              version: e.version
            },
            custom: t.custom
          }), r(null, t);
        },
        addRequestInfo: function (t) {
          return function (e, r, o) {
            var i = {};
            t && t.location && (i.url = t.location.href, i.query_string = t.location.search);
            var s = "$remote_ip";
            r.captureIp ? !0 !== r.captureIp && (s += "_anonymize") : s = null, s && (i.user_ip = s), Object.keys(i).length > 0 && n.set(e, "data.request", i), o(null, e);
          };
        },
        addClientInfo: function (t) {
          return function (e, r, o) {
            if (!t) return o(null, e);
            var i = t.navigator || {},
              s = t.screen || {};
            n.set(e, "data.client", {
              runtime_ms: e.timestamp - t._rollbarStartTime,
              timestamp: Math.round(e.timestamp / 1e3),
              javascript: {
                browser: i.userAgent,
                language: i.language,
                cookie_enabled: i.cookieEnabled,
                screen: {
                  width: s.width,
                  height: s.height
                }
              }
            }), o(null, e);
          };
        },
        addPluginInfo: function (t) {
          return function (e, r, o) {
            if (!t || !t.navigator) return o(null, e);
            for (var i, s = [], a = t.navigator.plugins || [], u = 0, c = a.length; u < c; ++u) i = a[u], s.push({
              name: i.name,
              description: i.description
            });
            n.set(e, "data.client.javascript.plugins", s), o(null, e);
          };
        },
        addBody: function (t, e, r) {
          t.stackInfo ? t.stackInfo.traceChain ? function (t, e, r) {
            for (var o = t.stackInfo.traceChain, i = [], s = o.length, a = 0; a < s; a++) {
              var c = u(t, o[a], e);
              i.push(c);
            }
            n.set(t, "data.body", {
              trace_chain: i
            }), r(null, t);
          }(t, e, r) : function (t, e, r) {
            var i = a(t);
            if (i) {
              var l = u(t, t.stackInfo, e);
              n.set(t, "data.body", {
                trace: l
              }), r(null, t);
            } else {
              var p = t.stackInfo,
                h = o.guessErrorClass(p.message),
                f = c(p, h[0], e),
                d = h[1];
              t.message = f + ": " + d, s(t, 0, r);
            }
          }(t, e, r) : s(t, 0, r);
        },
        addScrubber: function (t) {
          return function (e, r, n) {
            if (t) {
              var o = r.scrubFields || [],
                i = r.scrubPaths || [];
              e.data = t(e.data, o, i);
            }
            n(null, e);
          };
        }
      };
    },
    477: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(656),
        i = r(86);
      function s(t) {
        this.truncation = t;
      }
      s.prototype.get = function (t, e, r, o, i) {
        o && n.isFunction(o) || (o = function () {}), n.addParamsAndAccessTokenToPath(t, e, r);
        var s = n.formatUrl(e);
        this._makeZoneRequest(t, s, "GET", null, o, i, e.timeout, e.transport);
      }, s.prototype.post = function (t, e, r, o, i) {
        if (o && n.isFunction(o) || (o = function () {}), !r) return o(new Error("Cannot send empty request"));
        var s;
        if ((s = this.truncation ? this.truncation.truncate(r) : n.stringify(r)).error) return o(s.error);
        var a = s.value,
          u = n.formatUrl(e);
        this._makeZoneRequest(t, u, "POST", a, o, i, e.timeout, e.transport);
      }, s.prototype.postJsonPayload = function (t, e, r, o, i) {
        o && n.isFunction(o) || (o = function () {});
        var s = n.formatUrl(e);
        this._makeZoneRequest(t, s, "POST", r, o, i, e.timeout, e.transport);
      }, s.prototype._makeZoneRequest = function () {
        var t = "undefined" != typeof window && window || void 0 !== o && o,
          e = t && t.Zone && t.Zone.current,
          r = Array.prototype.slice.call(arguments);
        if (e && "angular" === e._name) {
          var n = e._parent,
            o = this;
          n.run(function () {
            o._makeRequest.apply(void 0, r);
          });
        } else this._makeRequest.apply(void 0, r);
      }, s.prototype._makeRequest = function (t, e, r, n, s, a, u, c) {
        if ("undefined" != typeof RollbarProxy) return function (t, e) {
          new RollbarProxy().sendJsonPayload(t, function (t) {}, function (t) {
            e(new Error(t));
          });
        }(n, s);
        "fetch" === c ? o(t, e, r, n, s, u) : i(t, e, r, n, s, a, u);
      }, t.exports = s;
    },
    656: (t, e, r) => {
      "use strict";

      var n = r(802),
        o = r(702);
      t.exports = function (t, e, r, i, s, a) {
        var u, c;
        o.isFiniteNumber(a) && (u = new AbortController(), c = setTimeout(function () {
          u.abort();
        }, a)), fetch(e, {
          method: r,
          headers: {
            "Content-Type": "application/json",
            "X-Rollbar-Access-Token": t,
            signal: u && u.signal
          },
          body: i
        }).then(function (t) {
          return c && clearTimeout(c), t.json();
        }).then(function (t) {
          s(null, t);
        }).catch(function (t) {
          n.error(t.message), s(t);
        });
      };
    },
    86: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(802);
      function i(t, e) {
        var r = new Error(t);
        return r.code = e || "ENOTFOUND", r;
      }
      t.exports = function (t, e, r, s, a, u, c) {
        var l;
        if (!(l = u ? u() : function () {
          var t,
            e,
            r = [function () {
              return new XMLHttpRequest();
            }, function () {
              return new ActiveXObject("Msxml2.XMLHTTP");
            }, function () {
              return new ActiveXObject("Msxml3.XMLHTTP");
            }, function () {
              return new ActiveXObject("Microsoft.XMLHTTP");
            }],
            n = r.length;
          for (e = 0; e < n; e++) try {
            t = r[e]();
            break;
          } catch (t) {}
          return t;
        }())) return a(new Error("No way to send a request"));
        try {
          try {
            var p = function () {
              try {
                if (p && 4 === l.readyState) {
                  p = void 0;
                  var t = n.jsonParse(l.responseText);
                  if ((s = l) && s.status && 200 === s.status) return void a(t.error, t.value);
                  if (function (t) {
                    return t && n.isType(t.status, "number") && t.status >= 400 && t.status < 600;
                  }(l)) {
                    if (403 === l.status) {
                      var e = t.value && t.value.message;
                      o.error(e);
                    }
                    a(new Error(String(l.status)));
                  } else a(i("XHR response had no status code (likely connection failure)"));
                }
              } catch (t) {
                var r;
                r = t && t.stack ? t : new Error(t), a(r);
              }
              var s;
            };
            l.open(r, e, !0), l.setRequestHeader && (l.setRequestHeader("Content-Type", "application/json"), l.setRequestHeader("X-Rollbar-Access-Token", t)), n.isFiniteNumber(c) && (l.timeout = c), l.onreadystatechange = p, l.send(s);
          } catch (t) {
            if ("undefined" != typeof XDomainRequest) {
              if (!window || !window.location) return a(new Error("No window available during request, unknown environment"));
              "http:" === window.location.href.substring(0, 5) && "https" === e.substring(0, 5) && (e = "http" + e.substring(5));
              var h = new XDomainRequest();
              h.onprogress = function () {}, h.ontimeout = function () {
                a(i("Request timed out", "ETIMEDOUT"));
              }, h.onerror = function () {
                a(new Error("Error during request"));
              }, h.onload = function () {
                var t = n.jsonParse(h.responseText);
                a(t.error, t.value);
              }, h.open(r, e, !0), h.send(s);
            } else a(new Error("Cannot find a method to transport a request"));
          }
        } catch (t) {
          a(t);
        }
      };
    },
    509: t => {
      "use strict";

      t.exports = {
        parse: function (t) {
          var e,
            r,
            n = {
              protocol: null,
              auth: null,
              host: null,
              path: null,
              hash: null,
              href: t,
              hostname: null,
              port: null,
              pathname: null,
              search: null,
              query: null
            };
          if (-1 !== (e = t.indexOf("//")) ? (n.protocol = t.substring(0, e), r = e + 2) : r = 0, -1 !== (e = t.indexOf("@", r)) && (n.auth = t.substring(r, e), r = e + 1), -1 === (e = t.indexOf("/", r))) {
            if (-1 === (e = t.indexOf("?", r))) return -1 === (e = t.indexOf("#", r)) ? n.host = t.substring(r) : (n.host = t.substring(r, e), n.hash = t.substring(e)), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), n;
            n.host = t.substring(r, e), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), r = e;
          } else n.host = t.substring(r, e), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), r = e;
          if (-1 === (e = t.indexOf("#", r)) ? n.path = t.substring(r) : (n.path = t.substring(r, e), n.hash = t.substring(e)), n.path) {
            var o = n.path.split("?");
            n.pathname = o[0], n.query = o[1], n.search = n.query ? "?" + n.query : null;
          }
          return n;
        }
      };
    },
    965: t => {
      "use strict";

      function e(t, e, r) {
        if (e.hasOwnProperty && e.hasOwnProperty("addEventListener")) {
          for (var n = e.addEventListener; n._rollbarOldAdd && n.belongsToShim;) n = n._rollbarOldAdd;
          var o = function (e, r, o) {
            n.call(this, e, t.wrap(r), o);
          };
          o._rollbarOldAdd = n, o.belongsToShim = r, e.addEventListener = o;
          for (var i = e.removeEventListener; i._rollbarOldRemove && i.belongsToShim;) i = i._rollbarOldRemove;
          var s = function (t, e, r) {
            i.call(this, t, e && e._rollbar_wrapped || e, r);
          };
          s._rollbarOldRemove = i, s.belongsToShim = r, e.removeEventListener = s;
        }
      }
      t.exports = function (t, r, n) {
        if (t) {
          var o,
            i,
            s = "EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload".split(",");
          for (o = 0; o < s.length; ++o) t[i = s[o]] && t[i].prototype && e(r, t[i].prototype, n);
        }
      };
    },
    948: t => {
      "use strict";

      t.exports = {
        version: "2.26.4",
        endpoint: "api.rollbar.com/api/1/item/",
        logLevel: "debug",
        reportLevel: "debug",
        uncaughtErrorLevel: "error",
        maxItems: 0,
        itemsPerMin: 60
      };
    },
    303: (t, e, r) => {
      "use strict";

      var n = r(180),
        o = new RegExp("^(([a-zA-Z0-9-_$ ]*): *)?(Uncaught )?([a-zA-Z0-9-_$ ]*): ");
      function i() {
        return null;
      }
      function s(t) {
        var e = {};
        return e._stackFrame = t, e.url = t.fileName, e.line = t.lineNumber, e.func = t.functionName, e.column = t.columnNumber, e.args = t.args, e.context = null, e;
      }
      function a(t, e) {
        return {
          stack: function () {
            var r = [];
            e = e || 0;
            try {
              r = n.parse(t);
            } catch (t) {
              r = [];
            }
            for (var o = [], i = e; i < r.length; i++) o.push(new s(r[i]));
            return o;
          }(),
          message: t.message,
          name: (r = t, o = r.name && r.name.length && r.name, i = r.constructor.name && r.constructor.name.length && r.constructor.name, o && i ? "Error" === o ? i : o : o || i),
          rawStack: t.stack,
          rawException: t
        };
        var r, o, i;
      }
      t.exports = {
        guessFunctionName: function () {
          return "?";
        },
        guessErrorClass: function (t) {
          if (!t || !t.match) return ["Unknown error. There was no error message to display.", ""];
          var e = t.match(o),
            r = "(unknown)";
          return e && (r = e[e.length - 1], t = (t = t.replace((e[e.length - 2] || "") + r + ":", "")).replace(/(^[\s]+|[\s]+$)/g, "")), [r, t];
        },
        gatherContext: i,
        parse: function (t, e) {
          var r = t;
          if (r.nested || r.cause) {
            for (var n = []; r;) n.push(new a(r, e)), r = r.nested || r.cause, e = 0;
            return n[0].traceChain = n, n[0];
          }
          return new a(r, e);
        },
        Stack: a,
        Frame: s
      };
    },
    420: t => {
      "use strict";

      var e = Object.prototype.hasOwnProperty,
        r = Object.prototype.toString,
        n = function (t) {
          if (!t || "[object Object]" !== r.call(t)) return !1;
          var n,
            o = e.call(t, "constructor"),
            i = t.constructor && t.constructor.prototype && e.call(t.constructor.prototype, "isPrototypeOf");
          if (t.constructor && !o && !i) return !1;
          for (n in t);
          return void 0 === n || e.call(t, n);
        };
      t.exports = function t() {
        var e,
          r,
          o,
          i,
          s,
          a = {},
          u = null,
          c = arguments.length;
        for (e = 0; e < c; e++) if (null != (u = arguments[e])) for (s in u) r = a[s], a !== (o = u[s]) && (o && n(o) ? (i = r && n(r) ? r : {}, a[s] = t(i, o)) : void 0 !== o && (a[s] = o));
        return a;
      };
    },
    314: (t, e, r) => {
      "use strict";

      var n = r(702);
      function o(t, e) {
        this.queue = t, this.options = e, this.transforms = [], this.diagnostic = {};
      }
      o.prototype.configure = function (t) {
        this.queue && this.queue.configure(t);
        var e = this.options;
        return this.options = n.merge(e, t), this;
      }, o.prototype.addTransform = function (t) {
        return n.isFunction(t) && this.transforms.push(t), this;
      }, o.prototype.log = function (t, e) {
        if (e && n.isFunction(e) || (e = function () {}), !this.options.enabled) return e(new Error("Rollbar is not enabled"));
        this.queue.addPendingItem(t);
        var r = t.err;
        this._applyTransforms(t, function (n, o) {
          if (n) return this.queue.removePendingItem(t), e(n, null);
          this.queue.addItem(o, e, r, t);
        }.bind(this));
      }, o.prototype._applyTransforms = function (t, e) {
        var r = -1,
          n = this.transforms.length,
          o = this.transforms,
          i = this.options,
          s = function (t, a) {
            t ? e(t, null) : ++r !== n ? o[r](a, i, s) : e(null, a);
          };
        s(null, t);
      }, t.exports = o;
    },
    61: (t, e, r) => {
      "use strict";

      var n = r(702);
      function o(t, e, r) {
        if (!t) return !r;
        var o,
          i,
          s = t.frames;
        if (!s || 0 === s.length) return !r;
        for (var a = e.length, u = s.length, c = 0; c < u; c++) {
          if (o = s[c].filename, !n.isType(o, "string")) return !r;
          for (var l = 0; l < a; l++) if (i = e[l], new RegExp(i).test(o)) return !0;
        }
        return !1;
      }
      function i(t, e, r, i) {
        var s,
          a,
          u = !1;
        "blocklist" === r && (u = !0);
        try {
          if (s = u ? e.hostBlockList : e.hostSafeList, a = n.get(t, "body.trace_chain") || [n.get(t, "body.trace")], !s || 0 === s.length) return !u;
          if (0 === a.length || !a[0]) return !u;
          for (var c = a.length, l = 0; l < c; l++) if (o(a[l], s, u)) return !0;
        } catch (t) {
          u ? e.hostBlockList = null : e.hostSafeList = null;
          var p = u ? "hostBlockList" : "hostSafeList";
          return i.error("Error while reading your configuration's " + p + " option. Removing custom " + p + ".", t), !u;
        }
        return !1;
      }
      t.exports = {
        checkLevel: function (t, e) {
          var r = t.level,
            o = n.LEVELS[r] || 0,
            i = e.reportLevel;
          return !(o < (n.LEVELS[i] || 0));
        },
        userCheckIgnore: function (t) {
          return function (e, r) {
            var o = !!e._isUncaught;
            delete e._isUncaught;
            var i = e._originalArgs;
            delete e._originalArgs;
            try {
              n.isFunction(r.onSendCallback) && r.onSendCallback(o, i, e);
            } catch (e) {
              r.onSendCallback = null, t.error("Error while calling onSendCallback, removing", e);
            }
            try {
              if (n.isFunction(r.checkIgnore) && r.checkIgnore(o, i, e)) return !1;
            } catch (e) {
              r.checkIgnore = null, t.error("Error while calling custom checkIgnore(), removing", e);
            }
            return !0;
          };
        },
        urlIsNotBlockListed: function (t) {
          return function (e, r) {
            return !i(e, r, "blocklist", t);
          };
        },
        urlIsSafeListed: function (t) {
          return function (e, r) {
            return i(e, r, "safelist", t);
          };
        },
        messageIsIgnored: function (t) {
          return function (e, r) {
            var o, i, s, a, u, c;
            try {
              if (!(s = r.ignoredMessages) || 0 === s.length) return !0;
              if (c = function (t) {
                var e = t.body,
                  r = [];
                if (e.trace_chain) for (var o = e.trace_chain, i = 0; i < o.length; i++) {
                  var s = o[i];
                  r.push(n.get(s, "exception.message"));
                }
                return e.trace && r.push(n.get(e, "trace.exception.message")), e.message && r.push(n.get(e, "message.body")), r;
              }(e), 0 === c.length) return !0;
              for (a = s.length, o = 0; o < a; o++) for (u = new RegExp(s[o], "gi"), i = 0; i < c.length; i++) if (u.test(c[i])) return !1;
            } catch (e) {
              r.ignoredMessages = null, t.error("Error while reading your configuration's ignoredMessages option. Removing custom ignoredMessages.");
            }
            return !0;
          };
        }
      };
    },
    358: (t, e, r) => {
      "use strict";

      var n = r(702);
      function o(t, e, r, n) {
        this.rateLimiter = t, this.api = e, this.logger = r, this.options = n, this.predicates = [], this.pendingItems = [], this.pendingRequests = [], this.retryQueue = [], this.retryHandle = null, this.waitCallback = null, this.waitIntervalID = null;
      }
      o.prototype.configure = function (t) {
        this.api && this.api.configure(t);
        var e = this.options;
        return this.options = n.merge(e, t), this;
      }, o.prototype.addPredicate = function (t) {
        return n.isFunction(t) && this.predicates.push(t), this;
      }, o.prototype.addPendingItem = function (t) {
        this.pendingItems.push(t);
      }, o.prototype.removePendingItem = function (t) {
        var e = this.pendingItems.indexOf(t);
        -1 !== e && this.pendingItems.splice(e, 1);
      }, o.prototype.addItem = function (t, e, r, o) {
        e && n.isFunction(e) || (e = function () {});
        var i = this._applyPredicates(t);
        if (i.stop) return this.removePendingItem(o), void e(i.err);
        if (this._maybeLog(t, r), this.removePendingItem(o), this.options.transmit) {
          this.pendingRequests.push(t);
          try {
            this._makeApiRequest(t, function (r, n) {
              this._dequeuePendingRequest(t), e(r, n);
            }.bind(this));
          } catch (r) {
            this._dequeuePendingRequest(t), e(r);
          }
        } else e(new Error("Transmit disabled"));
      }, o.prototype.wait = function (t) {
        n.isFunction(t) && (this.waitCallback = t, this._maybeCallWait() || (this.waitIntervalID && (this.waitIntervalID = clearInterval(this.waitIntervalID)), this.waitIntervalID = setInterval(function () {
          this._maybeCallWait();
        }.bind(this), 500)));
      }, o.prototype._applyPredicates = function (t) {
        for (var e = null, r = 0, n = this.predicates.length; r < n; r++) if (!(e = this.predicates[r](t, this.options)) || void 0 !== e.err) return {
          stop: !0,
          err: e.err
        };
        return {
          stop: !1,
          err: null
        };
      }, o.prototype._makeApiRequest = function (t, e) {
        var r = this.rateLimiter.shouldSend(t);
        r.shouldSend ? this.api.postItem(t, function (r, n) {
          r ? this._maybeRetry(r, t, e) : e(r, n);
        }.bind(this)) : r.error ? e(r.error) : this.api.postItem(r.payload, e);
      };
      var i = ["ECONNRESET", "ENOTFOUND", "ESOCKETTIMEDOUT", "ETIMEDOUT", "ECONNREFUSED", "EHOSTUNREACH", "EPIPE", "EAI_AGAIN"];
      o.prototype._maybeRetry = function (t, e, r) {
        var o = !1;
        if (this.options.retryInterval) {
          for (var s = 0, a = i.length; s < a; s++) if (t.code === i[s]) {
            o = !0;
            break;
          }
          o && n.isFiniteNumber(this.options.maxRetries) && (e.retries = e.retries ? e.retries + 1 : 1, e.retries > this.options.maxRetries && (o = !1));
        }
        o ? this._retryApiRequest(e, r) : r(t);
      }, o.prototype._retryApiRequest = function (t, e) {
        this.retryQueue.push({
          item: t,
          callback: e
        }), this.retryHandle || (this.retryHandle = setInterval(function () {
          for (; this.retryQueue.length;) {
            var t = this.retryQueue.shift();
            this._makeApiRequest(t.item, t.callback);
          }
        }.bind(this), this.options.retryInterval));
      }, o.prototype._dequeuePendingRequest = function (t) {
        var e = this.pendingRequests.indexOf(t);
        -1 !== e && (this.pendingRequests.splice(e, 1), this._maybeCallWait());
      }, o.prototype._maybeLog = function (t, e) {
        if (this.logger && this.options.verbose) {
          var r = e;
          if (r = (r = r || n.get(t, "body.trace.exception.message")) || n.get(t, "body.trace_chain.0.exception.message")) return void this.logger.error(r);
          (r = n.get(t, "body.message.body")) && this.logger.log(r);
        }
      }, o.prototype._maybeCallWait = function () {
        return !(!n.isFunction(this.waitCallback) || 0 !== this.pendingItems.length || 0 !== this.pendingRequests.length || (this.waitIntervalID && (this.waitIntervalID = clearInterval(this.waitIntervalID)), this.waitCallback(), 0));
      }, t.exports = o;
    },
    790: (t, e, r) => {
      "use strict";

      var n = r(702);
      function o(t) {
        this.startTime = n.now(), this.counter = 0, this.perMinCounter = 0, this.platform = null, this.platformOptions = {}, this.configureGlobal(t);
      }
      function i(t, e, r) {
        return !t.ignoreRateLimit && e >= 1 && r > e;
      }
      function s(t, e, r, n, o, i, s) {
        var a = null;
        return r && (r = new Error(r)), r || n || (a = function (t, e, r, n, o) {
          var i = e.environment || e.payload && e.payload.environment,
            s = {
              body: {
                message: {
                  body: o ? "item per minute limit reached, ignoring errors until timeout" : "maxItems has been hit, ignoring errors until reset.",
                  extra: {
                    maxItems: r,
                    itemsPerMinute: n
                  }
                }
              },
              language: "javascript",
              environment: i,
              notifier: {
                version: e.notifier && e.notifier.version || e.version
              }
            };
          return "browser" === t ? (s.platform = "browser", s.framework = "browser-js", s.notifier.name = "rollbar-browser-js") : "server" === t ? (s.framework = e.framework || "node-js", s.notifier.name = e.notifier.name) : "react-native" === t && (s.framework = e.framework || "react-native", s.notifier.name = e.notifier.name), s;
        }(t, e, o, i, s)), {
          error: r,
          shouldSend: n,
          payload: a
        };
      }
      o.globalSettings = {
        startTime: n.now(),
        maxItems: void 0,
        itemsPerMinute: void 0
      }, o.prototype.configureGlobal = function (t) {
        void 0 !== t.startTime && (o.globalSettings.startTime = t.startTime), void 0 !== t.maxItems && (o.globalSettings.maxItems = t.maxItems), void 0 !== t.itemsPerMinute && (o.globalSettings.itemsPerMinute = t.itemsPerMinute);
      }, o.prototype.shouldSend = function (t, e) {
        var r = (e = e || n.now()) - this.startTime;
        (r < 0 || r >= 6e4) && (this.startTime = e, this.perMinCounter = 0);
        var a = o.globalSettings.maxItems,
          u = o.globalSettings.itemsPerMinute;
        if (i(t, a, this.counter)) return s(this.platform, this.platformOptions, a + " max items reached", !1);
        if (i(t, u, this.perMinCounter)) return s(this.platform, this.platformOptions, u + " items per minute reached", !1);
        this.counter++, this.perMinCounter++;
        var c = !i(t, a, this.counter),
          l = c;
        return c = c && !i(t, u, this.perMinCounter), s(this.platform, this.platformOptions, null, c, a, u, l);
      }, o.prototype.setPlatformOptions = function (t, e) {
        this.platform = t, this.platformOptions = e;
      }, t.exports = o;
    },
    562: (t, e, r) => {
      "use strict";

      var n = r(790),
        o = r(358),
        i = r(314),
        s = r(702);
      function a(t, e, r, n, l) {
        this.options = s.merge(t), this.logger = r, a.rateLimiter.configureGlobal(this.options), a.rateLimiter.setPlatformOptions(l, this.options), this.api = e, this.queue = new o(a.rateLimiter, e, r, this.options);
        var p = this.options.tracer || null;
        c(p) ? (this.tracer = p, this.options.tracer = "opentracing-tracer-enabled", this.options._configuredOptions.tracer = "opentracing-tracer-enabled") : this.tracer = null, this.notifier = new i(this.queue, this.options), this.telemeter = n, u(t), this.lastError = null, this.lastErrorHash = "none";
      }
      function u(t) {
        t.stackTraceLimit && (Error.stackTraceLimit = t.stackTraceLimit);
      }
      function c(t) {
        if (!t) return !1;
        if (!t.scope || "function" != typeof t.scope) return !1;
        var e = t.scope();
        return !(!e || !e.active || "function" != typeof e.active);
      }
      a.rateLimiter = new n({
        maxItems: 0,
        itemsPerMinute: 60
      }), a.prototype.global = function (t) {
        return a.rateLimiter.configureGlobal(t), this;
      }, a.prototype.configure = function (t, e) {
        var r = this.options,
          n = {};
        e && (n = {
          payload: e
        }), this.options = s.merge(r, t, n);
        var o = this.options.tracer || null;
        return c(o) ? (this.tracer = o, this.options.tracer = "opentracing-tracer-enabled", this.options._configuredOptions.tracer = "opentracing-tracer-enabled") : this.tracer = null, this.notifier && this.notifier.configure(this.options), this.telemeter && this.telemeter.configure(this.options), u(t), this.global(this.options), c(t.tracer) && (this.tracer = t.tracer), this;
      }, a.prototype.log = function (t) {
        var e = this._defaultLogLevel();
        return this._log(e, t);
      }, a.prototype.debug = function (t) {
        this._log("debug", t);
      }, a.prototype.info = function (t) {
        this._log("info", t);
      }, a.prototype.warn = function (t) {
        this._log("warning", t);
      }, a.prototype.warning = function (t) {
        this._log("warning", t);
      }, a.prototype.error = function (t) {
        this._log("error", t);
      }, a.prototype.critical = function (t) {
        this._log("critical", t);
      }, a.prototype.wait = function (t) {
        this.queue.wait(t);
      }, a.prototype.captureEvent = function (t, e, r) {
        return this.telemeter && this.telemeter.captureEvent(t, e, r);
      }, a.prototype.captureDomContentLoaded = function (t) {
        return this.telemeter && this.telemeter.captureDomContentLoaded(t);
      }, a.prototype.captureLoad = function (t) {
        return this.telemeter && this.telemeter.captureLoad(t);
      }, a.prototype.buildJsonPayload = function (t) {
        return this.api.buildJsonPayload(t);
      }, a.prototype.sendJsonPayload = function (t) {
        this.api.postJsonPayload(t);
      }, a.prototype._log = function (t, e) {
        var r;
        if (e.callback && (r = e.callback, delete e.callback), this.options.ignoreDuplicateErrors && this._sameAsLastError(e)) {
          if (r) {
            var n = new Error("ignored identical item");
            n.item = e, r(n);
          }
        } else try {
          this._addTracingInfo(e), e.level = e.level || t, this.telemeter && this.telemeter._captureRollbarItem(e), e.telemetryEvents = this.telemeter && this.telemeter.copyEvents() || [], this.notifier.log(e, r);
        } catch (t) {
          r && r(t), this.logger.error(t);
        }
      }, a.prototype._defaultLogLevel = function () {
        return this.options.logLevel || "debug";
      }, a.prototype._sameAsLastError = function (t) {
        if (!t._isUncaught) return !1;
        var e = function (t) {
          var e = t.message || "",
            r = (t.err || {}).stack || String(t.err);
          return e + "::" + r;
        }(t);
        return this.lastErrorHash === e || (this.lastError = t.err, this.lastErrorHash = e, !1);
      }, a.prototype._addTracingInfo = function (t) {
        if (this.tracer) {
          var e = this.tracer.scope().active();
          if (function (t) {
            if (!t || !t.context || "function" != typeof t.context) return !1;
            var e = t.context();
            return !!(e && e.toSpanId && e.toTraceId && "function" == typeof e.toSpanId && "function" == typeof e.toTraceId);
          }(e)) {
            e.setTag("rollbar.error_uuid", t.uuid), e.setTag("rollbar.has_error", !0), e.setTag("error", !0), e.setTag("rollbar.item_url", `https://rollbar.com/item/uuid/?uuid=${t.uuid}`), e.setTag("rollbar.occurrence_url", `https://rollbar.com/occurrence/uuid/?uuid=${t.uuid}`);
            var r = e.context().toSpanId(),
              n = e.context().toTraceId();
            t.custom ? (t.custom.opentracing_span_id = r, t.custom.opentracing_trace_id = n) : t.custom = {
              opentracing_span_id: r,
              opentracing_trace_id: n
            };
          }
        }
      }, t.exports = a;
    },
    369: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(267);
      function i(t, e) {
        var r = e.split("."),
          o = r.length - 1;
        try {
          for (var i = 0; i <= o; ++i) i < o ? t = t[r[i]] : t[r[i]] = n.redact();
        } catch (t) {}
      }
      t.exports = function (t, e, r) {
        if (e = e || [], r) for (var s = 0; s < r.length; ++s) i(t, r[s]);
        var a = function (t) {
            for (var e, r = [], n = 0; n < t.length; ++n) e = "^\\[?(%5[bB])?" + t[n] + "\\[?(%5[bB])?\\]?(%5[dD])?$", r.push(new RegExp(e, "i"));
            return r;
          }(e),
          u = function (t) {
            for (var e, r = [], n = 0; n < t.length; ++n) e = "\\[?(%5[bB])?" + t[n] + "\\[?(%5[bB])?\\]?(%5[dD])?", r.push(new RegExp("(" + e + "=)([^&\\n]+)", "igm"));
            return r;
          }(e);
        function c(t, e) {
          return e + n.redact();
        }
        return o(t, function t(e, r, i) {
          var s = function (t, e) {
            var r;
            for (r = 0; r < a.length; ++r) if (a[r].test(t)) {
              e = n.redact();
              break;
            }
            return e;
          }(e, r);
          return s === r ? n.isType(r, "object") || n.isType(r, "array") ? o(r, t, i) : function (t) {
            var e;
            if (n.isType(t, "string")) for (e = 0; e < u.length; ++e) t = t.replace(u[e], c);
            return t;
          }(s) : s;
        });
      };
    },
    773: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = 100;
      function i(t) {
        this.queue = [], this.options = n.merge(t);
        var e = this.options.maxTelemetryEvents || o;
        this.maxQueueSize = Math.max(0, Math.min(e, o));
      }
      function s(t, e) {
        return e || {
          error: "error",
          manual: "info"
        }[t] || "info";
      }
      i.prototype.configure = function (t) {
        var e = this.options;
        this.options = n.merge(e, t);
        var r = this.options.maxTelemetryEvents || o,
          i = Math.max(0, Math.min(r, o)),
          s = 0;
        this.queue.length > i && (s = this.queue.length - i), this.maxQueueSize = i, this.queue.splice(0, s);
      }, i.prototype.copyEvents = function () {
        var t = Array.prototype.slice.call(this.queue, 0);
        if (n.isFunction(this.options.filterTelemetry)) try {
          for (var e = t.length; e--;) this.options.filterTelemetry(t[e]) && t.splice(e, 1);
        } catch (t) {
          this.options.filterTelemetry = null;
        }
        return t;
      }, i.prototype.capture = function (t, e, r, o, i) {
        var a = {
          level: s(t, r),
          type: t,
          timestamp_ms: i || n.now(),
          body: e,
          source: "client"
        };
        o && (a.uuid = o);
        try {
          if (n.isFunction(this.options.filterTelemetry) && this.options.filterTelemetry(a)) return !1;
        } catch (t) {
          this.options.filterTelemetry = null;
        }
        return this.push(a), a;
      }, i.prototype.captureEvent = function (t, e, r, n) {
        return this.capture(t, e, r, n);
      }, i.prototype.captureError = function (t, e, r, n) {
        var o = {
          message: t.message || String(t)
        };
        return t.stack && (o.stack = t.stack), this.capture("error", o, e, r, n);
      }, i.prototype.captureLog = function (t, e, r, n) {
        return this.capture("log", {
          message: t
        }, e, r, n);
      }, i.prototype.captureNetwork = function (t, e, r, n) {
        e = e || "xhr", t.subtype = t.subtype || e, n && (t.request = n);
        var o = this.levelFromStatus(t.status_code);
        return this.capture("network", t, o, r);
      }, i.prototype.levelFromStatus = function (t) {
        return t >= 200 && t < 400 ? "info" : 0 === t || t >= 400 ? "error" : "info";
      }, i.prototype.captureDom = function (t, e, r, n, o) {
        var i = {
          subtype: t,
          element: e
        };
        return void 0 !== r && (i.value = r), void 0 !== n && (i.checked = n), this.capture("dom", i, "info", o);
      }, i.prototype.captureNavigation = function (t, e, r) {
        return this.capture("navigation", {
          from: t,
          to: e
        }, "info", r);
      }, i.prototype.captureDomContentLoaded = function (t) {
        return this.capture("navigation", {
          subtype: "DOMContentLoaded"
        }, "info", void 0, t && t.getTime());
      }, i.prototype.captureLoad = function (t) {
        return this.capture("navigation", {
          subtype: "load"
        }, "info", void 0, t && t.getTime());
      }, i.prototype.captureConnectivityChange = function (t, e) {
        return this.captureNetwork({
          change: t
        }, "connectivity", e);
      }, i.prototype._captureRollbarItem = function (t) {
        if (this.options.includeItemsInTelemetry) return t.err ? this.captureError(t.err, t.level, t.uuid, t.timestamp) : t.message ? this.captureLog(t.message, t.level, t.uuid, t.timestamp) : t.custom ? this.capture("log", t.custom, t.level, t.uuid, t.timestamp) : void 0;
      }, i.prototype.push = function (t) {
        this.queue.push(t), this.queue.length > this.maxQueueSize && this.queue.shift();
      }, t.exports = i;
    },
    417: (t, e, r) => {
      "use strict";

      var n = r(702);
      function o(t, e) {
        n.isFunction(t[e]) && (t[e] = t[e].toString());
      }
      t.exports = {
        itemToPayload: function (t, e, r) {
          var n = t.data;
          t._isUncaught && (n._isUncaught = !0), t._originalArgs && (n._originalArgs = t._originalArgs), r(null, n);
        },
        addPayloadOptions: function (t, e, r) {
          var o = e.payload || {};
          o.body && delete o.body, t.data = n.merge(t.data, o), r(null, t);
        },
        addTelemetryData: function (t, e, r) {
          t.telemetryEvents && n.set(t, "data.body.telemetry", t.telemetryEvents), r(null, t);
        },
        addMessageWithError: function (t, e, r) {
          if (t.message) {
            var o = "data.body.trace_chain.0",
              i = n.get(t, o);
            if (i || (o = "data.body.trace", i = n.get(t, o)), i) {
              if (!i.exception || !i.exception.description) return n.set(t, o + ".exception.description", t.message), void r(null, t);
              var s = n.get(t, o + ".extra") || {},
                a = n.merge(s, {
                  message: t.message
                });
              n.set(t, o + ".extra", a);
            }
            r(null, t);
          } else r(null, t);
        },
        userTransform: function (t) {
          return function (e, r, o) {
            var i = n.merge(e),
              s = null;
            try {
              n.isFunction(r.transform) && (s = r.transform(i.data, e));
            } catch (n) {
              return r.transform = null, t.error("Error while calling custom transform() function. Removing custom transform().", n), void o(null, e);
            }
            n.isPromise(s) ? s.then(function (t) {
              t && (i.data = t), o(null, i);
            }, function (t) {
              o(t, e);
            }) : o(null, i);
          };
        },
        addConfigToPayload: function (t, e, r) {
          if (!e.sendConfig) return r(null, t);
          var o = n.get(t, "data.custom") || {};
          o._rollbarConfig = e, t.data.custom = o, r(null, t);
        },
        addConfiguredOptions: function (t, e, r) {
          var n = e._configuredOptions;
          o(n, "transform"), o(n, "checkIgnore"), o(n, "onSendCallback"), delete n.accessToken, t.data.notifier.configured_options = n, r(null, t);
        },
        addDiagnosticKeys: function (t, e, r) {
          var o = n.merge(t.notifier.client.notifier.diagnostic, t.diagnostic);
          if (n.get(t, "err._isAnonymous") && (o.is_anonymous = !0), t._isUncaught && (o.is_uncaught = t._isUncaught), t.err) try {
            o.raw_error = {
              message: t.err.message,
              name: t.err.name,
              constructor_name: t.err.constructor && t.err.constructor.name,
              filename: t.err.fileName,
              line: t.err.lineNumber,
              column: t.err.columnNumber,
              stack: t.err.stack
            };
          } catch (t) {
            o.raw_error = {
              failed: String(t)
            };
          }
          t.data.notifier.diagnostic = n.merge(t.data.notifier.diagnostic, o), r(null, t);
        }
      };
    },
    473: (t, e, r) => {
      "use strict";

      var n = r(702),
        o = r(267);
      function i(t, e) {
        return [t, n.stringify(t, e)];
      }
      function s(t, e) {
        var r = t.length;
        return r > 2 * e ? t.slice(0, e).concat(t.slice(r - e)) : t;
      }
      function a(t, e, r) {
        r = void 0 === r ? 30 : r;
        var o,
          i = t.data.body;
        if (i.trace_chain) for (var a = i.trace_chain, u = 0; u < a.length; u++) o = s(o = a[u].frames, r), a[u].frames = o;else i.trace && (o = s(o = i.trace.frames, r), i.trace.frames = o);
        return [t, n.stringify(t, e)];
      }
      function u(t, e) {
        return e && e.length > t ? e.slice(0, t - 3).concat("...") : e;
      }
      function c(t, e, r) {
        return e = o(e, function e(r, i, s) {
          switch (n.typeName(i)) {
            case "string":
              return u(t, i);
            case "object":
            case "array":
              return o(i, e, s);
            default:
              return i;
          }
        }), [e, n.stringify(e, r)];
      }
      function l(t) {
        return t.exception && (delete t.exception.description, t.exception.message = u(255, t.exception.message)), t.frames = s(t.frames, 1), t;
      }
      function p(t, e) {
        var r = t.data.body;
        if (r.trace_chain) for (var o = r.trace_chain, i = 0; i < o.length; i++) o[i] = l(o[i]);else r.trace && (r.trace = l(r.trace));
        return [t, n.stringify(t, e)];
      }
      function h(t, e) {
        return n.maxByteSize(t) > e;
      }
      t.exports = {
        truncate: function (t, e, r) {
          r = void 0 === r ? 524288 : r;
          for (var n, o, s, u = [i, a, c.bind(null, 1024), c.bind(null, 512), c.bind(null, 256), p]; n = u.shift();) if (t = (o = n(t, e))[0], (s = o[1]).error || !h(s.value, r)) return s;
          return s;
        },
        raw: i,
        truncateFrames: a,
        truncateStrings: c,
        maybeTruncateValue: u
      };
    },
    702: (t, e, r) => {
      "use strict";

      var n = r(420),
        o = {};
      function i(t, e) {
        return e === s(t);
      }
      function s(t) {
        var e = typeof t;
        return "object" !== e ? e : t ? t instanceof Error ? "error" : {}.toString.call(t).match(/\s([a-zA-Z]+)/)[1].toLowerCase() : "null";
      }
      function a(t) {
        return i(t, "function");
      }
      function u(t) {
        var e = Function.prototype.toString.call(Object.prototype.hasOwnProperty).replace(/[\\^$.*+?()[\]{}|]/g, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?"),
          r = RegExp("^" + e + "$");
        return c(t) && r.test(t);
      }
      function c(t) {
        var e = typeof t;
        return null != t && ("object" == e || "function" == e);
      }
      function l() {
        var t = y();
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (e) {
          var r = (t + 16 * Math.random()) % 16 | 0;
          return t = Math.floor(t / 16), ("x" === e ? r : 7 & r | 8).toString(16);
        });
      }
      var p = {
        strictMode: !1,
        key: ["source", "protocol", "authority", "userInfo", "user", "password", "host", "port", "relative", "path", "directory", "file", "query", "anchor"],
        q: {
          name: "queryKey",
          parser: /(?:^|&)([^&=]*)=?([^&]*)/g
        },
        parser: {
          strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
          loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
        }
      };
      function h(t, e) {
        var r, n;
        try {
          r = o.stringify(t);
        } catch (o) {
          if (e && a(e)) try {
            r = e(t);
          } catch (t) {
            n = t;
          } else n = o;
        }
        return {
          error: n,
          value: r
        };
      }
      function f(t, e) {
        return function (r, n) {
          try {
            e(r, n);
          } catch (e) {
            t.error(e);
          }
        };
      }
      function d(t) {
        return function t(e, r) {
          var n,
            o,
            a,
            u = {};
          try {
            for (o in e) (n = e[o]) && (i(n, "object") || i(n, "array")) ? r.includes(n) ? u[o] = "Removed circular reference: " + s(n) : ((a = r.slice()).push(n), u[o] = t(n, a)) : u[o] = n;
          } catch (t) {
            u = "Failed cloning custom data: " + t.message;
          }
          return u;
        }(t, [t]);
      }
      var m = ["log", "network", "dom", "navigation", "error", "manual"],
        g = ["critical", "error", "warning", "info", "debug"];
      function v(t, e) {
        for (var r = 0; r < t.length; ++r) if (t[r] === e) return !0;
        return !1;
      }
      function y() {
        return Date.now ? +Date.now() : +new Date();
      }
      t.exports = {
        addParamsAndAccessTokenToPath: function (t, e, r) {
          (r = r || {}).access_token = t;
          var n,
            o = [];
          for (n in r) Object.prototype.hasOwnProperty.call(r, n) && o.push([n, r[n]].join("="));
          var i = "?" + o.sort().join("&");
          (e = e || {}).path = e.path || "";
          var s,
            a = e.path.indexOf("?"),
            u = e.path.indexOf("#");
          -1 !== a && (-1 === u || u > a) ? (s = e.path, e.path = s.substring(0, a) + i + "&" + s.substring(a + 1)) : -1 !== u ? (s = e.path, e.path = s.substring(0, u) + i + s.substring(u)) : e.path = e.path + i;
        },
        createItem: function (t, e, r, n, o) {
          for (var i, a, u, c, p, h, m = [], g = [], v = 0, b = t.length; v < b; ++v) {
            var w = s(h = t[v]);
            switch (g.push(w), w) {
              case "undefined":
                break;
              case "string":
                i ? m.push(h) : i = h;
                break;
              case "function":
                c = f(e, h);
                break;
              case "date":
                m.push(h);
                break;
              case "error":
              case "domexception":
              case "exception":
                a ? m.push(h) : a = h;
                break;
              case "object":
              case "array":
                if (h instanceof Error || "undefined" != typeof DOMException && h instanceof DOMException) {
                  a ? m.push(h) : a = h;
                  break;
                }
                if (n && "object" === w && !p) {
                  for (var _ = 0, x = n.length; _ < x; ++_) if (void 0 !== h[n[_]]) {
                    p = h;
                    break;
                  }
                  if (p) break;
                }
                u ? m.push(h) : u = h;
                break;
              default:
                if (h instanceof Error || "undefined" != typeof DOMException && h instanceof DOMException) {
                  a ? m.push(h) : a = h;
                  break;
                }
                m.push(h);
            }
          }
          u && (u = d(u)), m.length > 0 && (u || (u = d({})), u.extraArgs = d(m));
          var k = {
            message: i,
            err: a,
            custom: u,
            timestamp: y(),
            callback: c,
            notifier: r,
            diagnostic: {},
            uuid: l()
          };
          return function (t, e) {
            e && void 0 !== e.level && (t.level = e.level, delete e.level), e && void 0 !== e.skipFrames && (t.skipFrames = e.skipFrames, delete e.skipFrames);
          }(k, u), n && p && (k.request = p), o && (k.lambdaContext = o), k._originalArgs = t, k.diagnostic.original_arg_types = g, k;
        },
        addErrorContext: function (t, e) {
          var r = t.data.custom || {},
            o = !1;
          try {
            for (var i = 0; i < e.length; ++i) e[i].hasOwnProperty("rollbarContext") && (r = n(r, d(e[i].rollbarContext)), o = !0);
            o && (t.data.custom = r);
          } catch (e) {
            t.diagnostic.error_context = "Failed: " + e.message;
          }
        },
        createTelemetryEvent: function (t) {
          for (var e, r, n, o, i = 0, a = t.length; i < a; ++i) switch (s(o = t[i])) {
            case "string":
              !e && v(m, o) ? e = o : !n && v(g, o) && (n = o);
              break;
            case "object":
              r = o;
          }
          return {
            type: e || "manual",
            metadata: r || {},
            level: n
          };
        },
        filterIp: function (t, e) {
          if (t && t.user_ip && !0 !== e) {
            var r = t.user_ip;
            if (e) try {
              var n;
              if (-1 !== r.indexOf(".")) (n = r.split(".")).pop(), n.push("0"), r = n.join(".");else if (-1 !== r.indexOf(":")) {
                if ((n = r.split(":")).length > 2) {
                  var o = n.slice(0, 3),
                    i = o[2].indexOf("/");
                  -1 !== i && (o[2] = o[2].substring(0, i)), r = o.concat("0000:0000:0000:0000:0000").join(":");
                }
              } else r = null;
            } catch (t) {
              r = null;
            } else r = null;
            t.user_ip = r;
          }
        },
        formatArgsAsString: function (t) {
          var e,
            r,
            n,
            o = [];
          for (e = 0, r = t.length; e < r; ++e) {
            switch (s(n = t[e])) {
              case "object":
                (n = (n = h(n)).error || n.value).length > 500 && (n = n.substr(0, 497) + "...");
                break;
              case "null":
                n = "null";
                break;
              case "undefined":
                n = "undefined";
                break;
              case "symbol":
                n = n.toString();
            }
            o.push(n);
          }
          return o.join(" ");
        },
        formatUrl: function (t, e) {
          if (!(e = e || t.protocol) && t.port && (80 === t.port ? e = "http:" : 443 === t.port && (e = "https:")), e = e || "https:", !t.hostname) return null;
          var r = e + "//" + t.hostname;
          return t.port && (r = r + ":" + t.port), t.path && (r += t.path), r;
        },
        get: function (t, e) {
          if (t) {
            var r = e.split("."),
              n = t;
            try {
              for (var o = 0, i = r.length; o < i; ++o) n = n[r[o]];
            } catch (t) {
              n = void 0;
            }
            return n;
          }
        },
        handleOptions: function (t, e, r, o) {
          var i = n(t, e, r);
          return i = function (t, e) {
            return t.hostWhiteList && !t.hostSafeList && (t.hostSafeList = t.hostWhiteList, t.hostWhiteList = void 0, e && e.log("hostWhiteList is deprecated. Use hostSafeList.")), t.hostBlackList && !t.hostBlockList && (t.hostBlockList = t.hostBlackList, t.hostBlackList = void 0, e && e.log("hostBlackList is deprecated. Use hostBlockList.")), t;
          }(i, o), !e || e.overwriteScrubFields || e.scrubFields && (i.scrubFields = (t.scrubFields || []).concat(e.scrubFields)), i;
        },
        isError: function (t) {
          return i(t, "error") || i(t, "exception");
        },
        isFiniteNumber: function (t) {
          return Number.isFinite(t);
        },
        isFunction: a,
        isIterable: function (t) {
          var e = s(t);
          return "object" === e || "array" === e;
        },
        isNativeFunction: u,
        isObject: c,
        isString: function (t) {
          return "string" == typeof t || t instanceof String;
        },
        isType: i,
        isPromise: function (t) {
          return c(t) && i(t.then, "function");
        },
        jsonParse: function (t) {
          var e, r;
          try {
            e = o.parse(t);
          } catch (t) {
            r = t;
          }
          return {
            error: r,
            value: e
          };
        },
        LEVELS: {
          debug: 0,
          info: 1,
          warning: 2,
          error: 3,
          critical: 4
        },
        makeUnhandledStackInfo: function (t, e, r, n, o, i, s, a) {
          var u = {
            url: e || "",
            line: r,
            column: n
          };
          u.func = a.guessFunctionName(u.url, u.line), u.context = a.gatherContext(u.url, u.line);
          var c = "undefined" != typeof document && document && document.location && document.location.href,
            l = "undefined" != typeof window && window && window.navigator && window.navigator.userAgent;
          return {
            mode: i,
            message: o ? String(o) : t || s,
            url: c,
            stack: [u],
            useragent: l
          };
        },
        merge: n,
        now: y,
        redact: function () {
          return "********";
        },
        RollbarJSON: o,
        sanitizeUrl: function (t) {
          var e = function (t) {
            if (i(t, "string")) {
              for (var e = p, r = e.parser[e.strictMode ? "strict" : "loose"].exec(t), n = {}, o = 0, s = e.key.length; o < s; ++o) n[e.key[o]] = r[o] || "";
              return n[e.q.name] = {}, n[e.key[12]].replace(e.q.parser, function (t, r, o) {
                r && (n[e.q.name][r] = o);
              }), n;
            }
          }(t);
          return e ? ("" === e.anchor && (e.source = e.source.replace("#", "")), t = e.source.replace("?" + e.query, "")) : "(unknown)";
        },
        set: function (t, e, r) {
          if (t) {
            var n = e.split("."),
              o = n.length;
            if (!(o < 1)) if (1 !== o) try {
              for (var i = t[n[0]] || {}, s = i, a = 1; a < o - 1; ++a) i[n[a]] = i[n[a]] || {}, i = i[n[a]];
              i[n[o - 1]] = r, t[n[0]] = s;
            } catch (t) {
              return;
            } else t[n[0]] = r;
          }
        },
        setupJSON: function (t) {
          a(o.stringify) && a(o.parse) || (i(JSON, "undefined") || (t ? (u(JSON.stringify) && (o.stringify = JSON.stringify), u(JSON.parse) && (o.parse = JSON.parse)) : (a(JSON.stringify) && (o.stringify = JSON.stringify), a(JSON.parse) && (o.parse = JSON.parse))), a(o.stringify) && a(o.parse) || t && t(o));
        },
        stringify: h,
        maxByteSize: function (t) {
          for (var e = 0, r = t.length, n = 0; n < r; n++) {
            var o = t.charCodeAt(n);
            o < 128 ? e += 1 : o < 2048 ? e += 2 : o < 65536 && (e += 3);
          }
          return e;
        },
        typeName: s,
        uuid4: l
      };
    },
    650: t => {
      "use strict";

      function e(t) {
        return "string" != typeof t && (t = String(t)), t.toLowerCase();
      }
      function r(t) {
        this.map = {}, t instanceof r ? t.forEach(function (t, e) {
          this.append(e, t);
        }, this) : Array.isArray(t) ? t.forEach(function (t) {
          this.append(t[0], t[1]);
        }, this) : t && Object.getOwnPropertyNames(t).forEach(function (e) {
          this.append(e, t[e]);
        }, this);
      }
      r.prototype.append = function (t, r) {
        t = e(t), r = function (t) {
          return "string" != typeof t && (t = String(t)), t;
        }(r);
        var n = this.map[t];
        this.map[t] = n ? n + ", " + r : r;
      }, r.prototype.get = function (t) {
        return t = e(t), this.has(t) ? this.map[t] : null;
      }, r.prototype.has = function (t) {
        return this.map.hasOwnProperty(e(t));
      }, r.prototype.forEach = function (t, e) {
        for (var r in this.map) this.map.hasOwnProperty(r) && t.call(e, this.map[r], r, this);
      }, r.prototype.entries = function () {
        var t = [];
        return this.forEach(function (e, r) {
          t.push([r, e]);
        }), function (t) {
          return {
            next: function () {
              var e = t.shift();
              return {
                done: void 0 === e,
                value: e
              };
            }
          };
        }(t);
      }, t.exports = function (t) {
        return "undefined" == typeof Headers ? new r(t) : new Headers(t);
      };
    },
    876: (t, e, r) => {
      "use strict";

      var n = r(708);
      t.exports = n;
    },
    297: t => {
      "use strict";

      t.exports = function (t, e, r, n, o) {
        var i = t[e];
        t[e] = r(i), n && n[o].push([t, e, i]);
      };
    },
    267: (t, e, r) => {
      "use strict";

      var n = r(702);
      t.exports = function (t, e, r) {
        var o,
          i,
          s,
          a,
          u = n.isType(t, "object"),
          c = n.isType(t, "array"),
          l = [];
        if (r = r || {
          obj: [],
          mapped: []
        }, u) {
          if (a = r.obj.indexOf(t), u && -1 !== a) return r.mapped[a] || r.obj[a];
          r.obj.push(t), a = r.obj.length - 1;
        }
        if (u) for (o in t) Object.prototype.hasOwnProperty.call(t, o) && l.push(o);else if (c) for (s = 0; s < t.length; ++s) l.push(s);
        var p = u ? {} : [],
          h = !0;
        for (s = 0; s < l.length; ++s) i = t[o = l[s]], p[o] = e(o, i, r), h = h && p[o] === t[o];
        return u && !h && (r.mapped[a] = p), h ? t : p;
      };
    },
    708: t => {
      t.exports = function (t) {
        var e,
          r,
          n,
          o,
          i,
          s,
          a,
          u,
          c,
          l,
          p,
          h,
          f,
          d = /[\\"\u0000-\u001f\u007f-\u009f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
        function m(t) {
          return t < 10 ? "0" + t : t;
        }
        function g() {
          return this.valueOf();
        }
        function v(t) {
          return d.lastIndex = 0, d.test(t) ? '"' + t.replace(d, function (t) {
            var e = n[t];
            return "string" == typeof e ? e : "\\u" + ("0000" + t.charCodeAt(0).toString(16)).slice(-4);
          }) + '"' : '"' + t + '"';
        }
        function y(t, n) {
          var i,
            s,
            a,
            u,
            c,
            l = e,
            p = n[t];
          switch (p && "object" == typeof p && "function" == typeof p.toJSON && (p = p.toJSON(t)), "function" == typeof o && (p = o.call(n, t, p)), typeof p) {
            case "string":
              return v(p);
            case "number":
              return isFinite(p) ? String(p) : "null";
            case "boolean":
            case "null":
              return String(p);
            case "object":
              if (!p) return "null";
              if (e += r, c = [], "[object Array]" === Object.prototype.toString.apply(p)) {
                for (u = p.length, i = 0; i < u; i += 1) c[i] = y(i, p) || "null";
                return a = 0 === c.length ? "[]" : e ? "[\n" + e + c.join(",\n" + e) + "\n" + l + "]" : "[" + c.join(",") + "]", e = l, a;
              }
              if (o && "object" == typeof o) for (u = o.length, i = 0; i < u; i += 1) "string" == typeof o[i] && (a = y(s = o[i], p)) && c.push(v(s) + (e ? ": " : ":") + a);else for (s in p) Object.prototype.hasOwnProperty.call(p, s) && (a = y(s, p)) && c.push(v(s) + (e ? ": " : ":") + a);
              return a = 0 === c.length ? "{}" : e ? "{\n" + e + c.join(",\n" + e) + "\n" + l + "}" : "{" + c.join(",") + "}", e = l, a;
          }
        }
        "function" != typeof Date.prototype.toJSON && (Date.prototype.toJSON = function () {
          return isFinite(this.valueOf()) ? this.getUTCFullYear() + "-" + m(this.getUTCMonth() + 1) + "-" + m(this.getUTCDate()) + "T" + m(this.getUTCHours()) + ":" + m(this.getUTCMinutes()) + ":" + m(this.getUTCSeconds()) + "Z" : null;
        }, Boolean.prototype.toJSON = g, Number.prototype.toJSON = g, String.prototype.toJSON = g), "function" != typeof t.stringify && (n = {
          "\b": "\\b",
          "\t": "\\t",
          "\n": "\\n",
          "\f": "\\f",
          "\r": "\\r",
          '"': '\\"',
          "\\": "\\\\"
        }, t.stringify = function (t, n, i) {
          var s;
          if (e = "", r = "", "number" == typeof i) for (s = 0; s < i; s += 1) r += " ";else "string" == typeof i && (r = i);
          if (o = n, n && "function" != typeof n && ("object" != typeof n || "number" != typeof n.length)) throw new Error("JSON.stringify");
          return y("", {
            "": t
          });
        }), "function" != typeof t.parse && (t.parse = (l = {
          "\\": "\\",
          '"': '"',
          "/": "/",
          t: "\t",
          n: "\n",
          r: "\r",
          f: "\f",
          b: "\b"
        }, p = {
          go: function () {
            i = "ok";
          },
          firstokey: function () {
            u = c, i = "colon";
          },
          okey: function () {
            u = c, i = "colon";
          },
          ovalue: function () {
            i = "ocomma";
          },
          firstavalue: function () {
            i = "acomma";
          },
          avalue: function () {
            i = "acomma";
          }
        }, h = {
          go: function () {
            i = "ok";
          },
          ovalue: function () {
            i = "ocomma";
          },
          firstavalue: function () {
            i = "acomma";
          },
          avalue: function () {
            i = "acomma";
          }
        }, f = {
          "{": {
            go: function () {
              s.push({
                state: "ok"
              }), a = {}, i = "firstokey";
            },
            ovalue: function () {
              s.push({
                container: a,
                state: "ocomma",
                key: u
              }), a = {}, i = "firstokey";
            },
            firstavalue: function () {
              s.push({
                container: a,
                state: "acomma"
              }), a = {}, i = "firstokey";
            },
            avalue: function () {
              s.push({
                container: a,
                state: "acomma"
              }), a = {}, i = "firstokey";
            }
          },
          "}": {
            firstokey: function () {
              var t = s.pop();
              c = a, a = t.container, u = t.key, i = t.state;
            },
            ocomma: function () {
              var t = s.pop();
              a[u] = c, c = a, a = t.container, u = t.key, i = t.state;
            }
          },
          "[": {
            go: function () {
              s.push({
                state: "ok"
              }), a = [], i = "firstavalue";
            },
            ovalue: function () {
              s.push({
                container: a,
                state: "ocomma",
                key: u
              }), a = [], i = "firstavalue";
            },
            firstavalue: function () {
              s.push({
                container: a,
                state: "acomma"
              }), a = [], i = "firstavalue";
            },
            avalue: function () {
              s.push({
                container: a,
                state: "acomma"
              }), a = [], i = "firstavalue";
            }
          },
          "]": {
            firstavalue: function () {
              var t = s.pop();
              c = a, a = t.container, u = t.key, i = t.state;
            },
            acomma: function () {
              var t = s.pop();
              a.push(c), c = a, a = t.container, u = t.key, i = t.state;
            }
          },
          ":": {
            colon: function () {
              if (Object.hasOwnProperty.call(a, u)) throw new SyntaxError("Duplicate key '" + u + '"');
              i = "ovalue";
            }
          },
          ",": {
            ocomma: function () {
              a[u] = c, i = "okey";
            },
            acomma: function () {
              a.push(c), i = "avalue";
            }
          },
          true: {
            go: function () {
              c = !0, i = "ok";
            },
            ovalue: function () {
              c = !0, i = "ocomma";
            },
            firstavalue: function () {
              c = !0, i = "acomma";
            },
            avalue: function () {
              c = !0, i = "acomma";
            }
          },
          false: {
            go: function () {
              c = !1, i = "ok";
            },
            ovalue: function () {
              c = !1, i = "ocomma";
            },
            firstavalue: function () {
              c = !1, i = "acomma";
            },
            avalue: function () {
              c = !1, i = "acomma";
            }
          },
          null: {
            go: function () {
              c = null, i = "ok";
            },
            ovalue: function () {
              c = null, i = "ocomma";
            },
            firstavalue: function () {
              c = null, i = "acomma";
            },
            avalue: function () {
              c = null, i = "acomma";
            }
          }
        }, function (t, e) {
          var r,
            n,
            o = /^[\u0020\t\n\r]*(?:([,:\[\]{}]|true|false|null)|(-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)|"((?:[^\r\n\t\\\"]|\\(?:["\\\/trnfb]|u[0-9a-fA-F]{4}))*)")/;
          i = "go", s = [];
          try {
            for (; r = o.exec(t);) r[1] ? f[r[1]][i]() : r[2] ? (c = +r[2], h[i]()) : (n = r[3], c = n.replace(/\\(?:u(.{4})|([^u]))/g, function (t, e, r) {
              return e ? String.fromCharCode(parseInt(e, 16)) : l[r];
            }), p[i]()), t = t.slice(r[0].length);
          } catch (t) {
            i = t;
          }
          if ("ok" !== i || /[^\u0020\t\n\r]/.test(t)) throw i instanceof SyntaxError ? i : new SyntaxError("JSON");
          return "function" == typeof e ? function t(r, n) {
            var o,
              i,
              s = r[n];
            if (s && "object" == typeof s) for (o in c) Object.prototype.hasOwnProperty.call(s, o) && (void 0 !== (i = t(s, o)) ? s[o] = i : delete s[o]);
            return e.call(r, n, s);
          }({
            "": c
          }, "") : c;
        }));
      };
    }
  }, e = {}, function r(n) {
    var o = e[n];
    if (void 0 !== o) return o.exports;
    var i = e[n] = {
      exports: {}
    };
    return t[n].call(i.exports, i, i.exports, r), i.exports;
  }(409);
  var t, e;
});
