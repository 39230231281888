import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { allHorrorLevels } from 'src/app/models/room.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.css'
})
export class FaqComponent implements OnInit, AfterViewInit {
  year = environment.currentAwardYear;
  private fragment: string;
  eligibilityExpanded: boolean;
  whatCountsExpanded: boolean;
  whatCountsOnlineExpanded: boolean;
  experienceExpanded: boolean;
  bioExpanded: boolean;
  conflictsExpanded: boolean;
  privacyPolicyExpanded: boolean;
  horrorExpanded: boolean;

  allHorrorLevels = allHorrorLevels;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      this.scrollToFragment(this.fragment);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollToFragment(this.fragment);
    });
  }

  expanded(fragment: string) {
    if (this.fragment === fragment) {
      this.scrollToFragment(fragment);
    }
  }

  collapsed(fragment: string) {
    if (this.fragment === fragment) {
      this.fragment = null;
    }
  }

  scrollToFragment(fragment: string) {
    this.eligibilityExpanded = false;
    this.whatCountsExpanded = false;
    this.whatCountsOnlineExpanded = false;
    this.experienceExpanded = false;
    this.bioExpanded = false;
    this.conflictsExpanded = false;
    this.privacyPolicyExpanded = false;
    this.horrorExpanded = false;
    if (fragment === 'eligibility') {
      this.eligibilityExpanded = true;
    } else if (fragment === 'what-counts') {
      this.whatCountsExpanded = true;
    } else if (fragment === 'what-counts-online') {
      this.whatCountsOnlineExpanded = true;
    } else if (fragment === 'experience') {
      this.experienceExpanded = true;
    } else if (fragment === 'bio') {
      this.bioExpanded = true;
    } else if (fragment === 'conflicts') {
      this.conflictsExpanded = true;
    } else if (fragment === 'privacy-policy') {
      this.privacyPolicyExpanded = true;
    } else if (fragment === 'horror') {
      this.horrorExpanded = true;
    }
    if (fragment) {
      setTimeout(() => {
        const element = document.querySelector('#' + fragment);
        if (element) {
          element.scrollIntoView();
          window.scrollBy(0, -56);
        }
      });
    }
  }
}
