<mat-dialog-content>
  <p>
    This website has been updated and requires a page reload.
  </p>
  <p>
    If you have unsaved changes, save your work and then do a manual reload.
  </p>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="snooze()">
    Ask Again in 5 Minutes
  </button>
  <button mat-raised-button color="primary" (click)="reload()">
    Reload Now
  </button>
</div>
