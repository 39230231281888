import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Subscription, debounce, pairwise, startWith, timer } from 'rxjs';

import { ApplicationStatus, TerpecaUser, TerpecaUserDisclosure } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import {
    compareUsersByLocation, compareUsersByName, compareUsersByRoomCount, compareUsersByVouchesAndRoomCount
} from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrl: './approve.component.css'
})
export class ApproveComponent implements OnInit, OnDestroy {
  upgradeRequestApplicants: TerpecaUser[];
  pendingApplicants: TerpecaUser[];
  regions: string[];
  nominators: TerpecaUser[];
  voters: TerpecaUser[];
  deniedApplicants: TerpecaUser[];
  Status = ApplicationStatus;
  nominatorsNominated = 0;
  nominatorsVoted = 0;
  votersVoted = 0;
  showAllCategories = false;

  loadingInProgress = false;
  formGroup: UntypedFormGroup;
  currentSortFn: (a: (TerpecaUser | TerpecaUserDisclosure), b: (TerpecaUser | TerpecaUserDisclosure)) => number;
  userRoomCountSort = compareUsersByRoomCount;
  userNameSort = compareUsersByName;
  userLocationSort = compareUsersByLocation;
  userVouchSort = compareUsersByVouchesAndRoomCount;
  region = '';
  searchText = '';
  subscription: Subscription;

  private countryCountMap: Map<string, number>;

  constructor(public auth: AuthService, private db: AngularFirestore) {
    let defaultRegion = '';
    if (auth.currentUser.status < ApplicationStatus.APPROVER && auth.currentUser.ambassadorCountries) {
      defaultRegion = (auth.currentUser.ambassadorCountries || []).join(', ');
    }
    this.formGroup = new UntypedFormGroup({ region: new UntypedFormControl(defaultRegion),
                                            searchText: new UntypedFormControl('') });
    this.region = defaultRegion;
    this.currentSortFn = this.userRoomCountSort;
  }

  // eslint-disable-next-line @angular-eslint/no-async-lifecycle-method
  async ngOnInit() {
    await this.refreshData();
    this.subscription = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      pairwise(),
      debounce(([previous, current]) => (
        // eslint-disable-next-line no-constant-binary-expression
        (this.loadingInProgress = true) &&
        previous.searchText !== current.searchText ? timer(500) : timer(0)))
    ).subscribe(async () => {
      this.region = this.formGroup.value.region;
      this.searchText = this.formGroup.value.searchText;
      this.loadingInProgress = false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async refreshData() {
    this.upgradeRequestApplicants = null;
    const regionsAdded = new Set<string>();
    const countryCountMap = new Map<string, number>();

    await this.db.collection<TerpecaUser>('users').ref.where('upgradeRequested', '==', true).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      this.upgradeRequestApplicants = users;
    });

    this.pendingApplicants = null;
    await this.db.collection<TerpecaUser>('users').ref.where('status', '==', ApplicationStatus.PENDING).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      for (const user of users) {
        if (user.country) {
          regionsAdded.add(user.country);
          countryCountMap.set(user.country, (countryCountMap.get(user.country) || 0) + 1);
        }
      }
      this.pendingApplicants = users;
    });

    this.voters = null;
    await this.db.collection<TerpecaUser>('users').ref.where('status', '==', ApplicationStatus.VOTER).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      let voteCount = 0;
      for (const user of users) {
        if (user.rankingsSubmitted && user.rankingsSubmitted.includes(environment.currentAwardYear)) {
          voteCount++;
        }
        if (user.country) {
          regionsAdded.add(user.country);
          if (user.upgradeRequested) {
            countryCountMap.set(user.country, (countryCountMap.get(user.country) || 0) + 1);
          }
        }
      }
      this.voters = users;
      this.votersVoted = voteCount;
    });

    this.nominators = null;
    await this.db.collection<TerpecaUser>('users').ref.where('status', '>=', ApplicationStatus.NOMINATOR).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      let nomCount = 0;
      let voteCount = 0;
      for (const user of users) {
        if (user.nominationsSubmitted && user.nominationsSubmitted.includes(environment.currentAwardYear)) {
          nomCount++;
        }
        if (user.rankingsSubmitted && user.rankingsSubmitted.includes(environment.currentAwardYear)) {
          voteCount++;
        }
        if (user.country) {
          regionsAdded.add(user.country);
        }
      }
      this.nominators = users;
      this.nominatorsNominated = nomCount;
      this.nominatorsVoted = voteCount;
    });

    this.deniedApplicants = null;
    await this.db.collection<TerpecaUser>('users').ref.where('status', '==', ApplicationStatus.DENIED)
    .where('applicationDenied', 'array-contains', environment.currentAwardYear).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      for (const user of users) {
        if (user.country) {
          regionsAdded.add(user.country);
        }
      }
      this.deniedApplicants = users;
    });

    await this.db.collection<TerpecaUser>('users').ref.where('status', '>=', ApplicationStatus.REVIEWER).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      const users = this.getUsersFromSnapshot(snapshot);
      for (const user of users) {
        if (user.ambassadorCountries) {
          regionsAdded.add(user.ambassadorCountries.join(', '));
        }
      }
    });

    const regionsList = [...regionsAdded];
    regionsList.sort();
    regionsList.push('');
    if (this.regions?.join('|') !== regionsList.join('|')) {
      this.regions = regionsList;
    }
    this.countryCountMap = countryCountMap;
  }

  isReady() {
    return this.upgradeRequestApplicants !== null && this.pendingApplicants !== null && this.nominators !== null &&
    this.voters !== null && this.deniedApplicants !== null && this.regions !== null && this.countryCountMap !== null;
  }

  optionLabelForRegion(region: string) {
    if (region.length === 0) {
      return `all regions (slow) (${(this.pendingApplicants?.length || 0) + (this.upgradeRequestApplicants?.length || 0)})`;
    }
    const countries = region.split(', ');
    let count = 0;
    for (const country of countries) {
      count += (this.countryCountMap?.get(country) || 0);
    }
    if (count > 0) {
      return `${region} (${count})`;
    }
    return `${region}`;
  }

  private getUsersFromSnapshot(snapshot: QuerySnapshot<TerpecaUser>): TerpecaUser[] {
    const users: TerpecaUser[] = [];
    snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaUser>) => {
      const user: TerpecaUser = doc.data();
      if (user) {
        const filteredUser: Partial<TerpecaUser> = {
          uid: user.uid,
          realName: user.realName,
          displayName: user.displayName,
          nominationsSubmitted: user.nominationsSubmitted,
          rankingsSubmitted: user.rankingsSubmitted,
          homeCity: user.homeCity,
          city: user.city,
          state: user.state,
          country: user.country,
          vouchList: user.vouchList,
          upgradeVouchList: user.upgradeVouchList,
          status: user.status,
          disabled: user.disabled,
          roomCount: user.roomCount,
          upgradeRequested: user.upgradeRequested,
          virtualRoomCount: user.virtualRoomCount,
          ambassadorCountries: user.ambassadorCountries,
          auditLogEntry: user.auditLogEntry
        };
        users.push(<TerpecaUser>filteredUser);
      }
    });
    return users;
  }
}
