import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmlistdialog',
  templateUrl: './confirmlistdialog.component.html',
  styleUrl: './confirmlistdialog.component.css'
})
export class ConfirmListDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore) { }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
