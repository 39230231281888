<section *ngIf="auth.currentUser as user">
  <div *ngIf="user.status >= Status.REVIEWER || (user.status >= Status.VOTER && (user.rankingsSubmitted?.includes(year) ||
       settings.isPastVotingDeadline()))" class="flex-col justify-center align-stretch gap-10">
    <h3 class="flex-row justify-center align-center">
      {{ year }} Public Data Review Dashboard
    </h3>
    <div class="rules flex-col justify-center align-center gap-10">
      <div>
        The integrity of the results of the Top Escape Rooms Project depends on trust and transparency. The data here contains the full
        extent of the information that will eventually be made public for those who have submitted their rankings.  <b>It specifically
        does not contain nominations or rankings</b>, which are and always will be kept strictly private, however it does contain
        your name, your home city, your claimed room count, your bio, your disclosed affiliations, and an <b>unranked</b> list of games
        that you indicated you've played by ranking them, sorted by location.
      </div>
      <div>
        If you want to report an issue or if you have any questions not covered by the <a routerLink="/faq">FAQ</a>, contact Rich
        at rich&#64;terpeca.com.
      </div>
    </div>
    <div *ngIf="!ready()" class="flex-col justify-center align-center">
      Loading... Please be patient, this page takes a while to load.
    </div>
    <div *ngIf="ready()" class="flex-row flex-wrap justify-center align-center gap-10">
      <button mat-raised-button color="primary" [disabled]="!showRooms" (click)="showRooms = false">Contributors</button>
      <button mat-raised-button color="primary" [disabled]="showRooms" (click)="showRooms = true">Nominated Rooms</button>
    </div>
    <div *ngIf="ready()" class="flex-row flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
      <div *ngIf="!showRooms" class="flex-col flex-default justify-start align-stretch gap-10">
        <h4 class="flex-row justify-center align-center">Contributors {{ '(' + (users?.length || 0) + ')' }}</h4>
        <div class="flex-row flex-wrap justify-center align-center gap-25">
          <div>👔 = affiliated</div>
          <div>🗒️ = ranked</div>
          <div>🧩 = played</div>
        </div>
        <div class="flex-row flex-wrap justify-center align-center gap-25">
          <form [formGroup]="userFormGroup" class="flex-row flex-wrap justify-center align-center gap-25">
            <div class="search-and-filter-section flex-col justify-center align-center gap-5">
              <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
                <div>sort by:</div>
                <mat-radio-button value="1" color="primary" [checked]=true (click)="userSortFn = userLocationSort">location</mat-radio-button>
                <mat-radio-button value="2" color="primary" (click)="userSortFn = userNameSort">name</mat-radio-button>
                <mat-radio-button value="3" color="primary" (click)="userSortFn = userRoomCountSort">room count</mat-radio-button>
              </mat-radio-group>
              <div class="flex-row justify-center align-center gap-10">
                <div>country:</div>
                <select formControlName="country">
                  <option *ngFor="let c of userCountries" [ngValue]=c>{{ userCountryOptionLabel(c) }}</option>
                  <option value="">all countries ({{ users?.length || 0 }}) (slow)</option>
                </select>
              </div>
              <div class="flex-row justify-center align-center gap-25">
                <div>filter&nbsp;by:</div>
                <input class="search-box" type="string" matInput formControlName="searchText">
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="loadingInProgress" class="flex-col justify-center align-center">
          Loading...
        </div>
        <mat-accordion *ngIf="!loadingInProgress">
          <ng-container *ngFor="let user of users | userFilter:userCountry:userSearchText:userSortFn">
            <app-userdisclosure [user]="user" [roomMap]="roomMap"></app-userdisclosure>
          </ng-container>
        </mat-accordion>
      </div>
      <div *ngIf="showRooms" class="flex-col flex-default justify-start align-stretch gap-10">
        <h4 class="flex-row justify-center align-center">Nominated Rooms {{ '(' + (rooms?.length || 0) + ')' }}</h4>
        <div class="flex-row flex-wrap justify-center align-center gap-25">
          <div>🏅 = finalist</div>
          <div>👔 = affiliations</div>
          <div>🗒️ = rankers</div>
        </div>
        <div class="flex-row flex-wrap justify-center align-center gap-25">
          <form [formGroup]="roomFormGroup" class="flex-row flex-wrap justify-center align-center gap-25">
            <div class="search-and-filter-section flex-col justify-center align-center gap-5">
              <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
                <div>sort by:</div>
                <mat-radio-button value="1" color="primary" [checked]=true (click)="roomSortFn = roomLocationSort">location</mat-radio-button>
                <mat-radio-button value="2" color="primary" (click)="roomSortFn = roomNameSort">name</mat-radio-button>
                <mat-radio-button value="3" color="primary" (click)="roomSortFn = roomCompanySort">company</mat-radio-button>
              </mat-radio-group>
              <div class="flex-row justify-center align-center gap-10">
                <div>country:</div>
                <select formControlName="country">
                  <option *ngFor="let c of roomCountries" [ngValue]=c>{{ roomCountryOptionLabel(c) }}</option>
                  <option value="">all countries ({{ rooms?.length || 0 }}) (slow)</option>
                </select>
              </div>
              <div class="flex-row justify-center align-center gap-25">
                <div>filter&nbsp;by:</div>
                <input class="search-box" type="string" matInput formControlName="searchText">
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="loadingInProgress" class="flex-col justify-center align-center">
          Loading...
        </div>
        <mat-accordion  *ngIf="!loadingInProgress">
          <ng-container *ngFor="let room of rooms | roomFilter:roomCountry:'':roomSearchText:false:roomSortFn">
            <app-roomdisclosure [room]="room" [userMap]="userMap" [rankingUserIdsMap]="rankingUserIdsMap"></app-roomdisclosure>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
  </div>
</section>
