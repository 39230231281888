<h3 mat-dialog-title align="center">Edit Room: {{ parent.room?.name }}</h3>
<mat-dialog-content>
  <div class="flex-row-reverse flex-wrap justify-center align-start gap-10">
    <form [formGroup]="formGroup">
      <mat-form-field appearance="fill">
        <mat-label>Room name</mat-label>
        <input type="text" matInput formControlName="name" required>
      </mat-form-field>
      <mat-form-field *ngIf="formGroup.get('englishName')" appearance="fill">
        <mat-label>English translated room name</mat-label>
        <input type="text" matInput formControlName="englishName">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Company</mat-label>
        <input *ngIf="!canEditCompanyNames()" type="text" matInput formControlName="company" readonly (click)="showEditCompanyAlert()">
        <input *ngIf="canEditCompanyNames()" type="text" matInput formControlName="company" required>
      </mat-form-field>
      <app-locationpicker [formGroup]="formGroup"></app-locationpicker>
      <app-languagepicker
          *ngIf="formGroup.get('languages')"
          [formGroup]="formGroup" [country]="formGroup.value.country">
      </app-languagepicker>
      <app-horrorlevelpicker *ngIf="formGroup.get('horrorLevel')" [formGroup]="formGroup"></app-horrorlevelpicker>
      <mat-form-field appearance="fill">
        <mat-label>Website link</mat-label>
        <input type="text" matInput formControlName="link" required>
      </mat-form-field>
      <mat-form-field *ngIf="formGroup.get('email')" appearance="fill">
        <mat-label>Email contact</mat-label>
        <input type="email" matInput formControlName="email" required>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Ineligibility reason (required for Disqualify)</mat-label>
        <textarea matInput formControlName="ineligibilityReason"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
      </mat-form-field>
      <mat-checkbox *ngIf="formGroup.get('confirmedOpen')" formControlName="confirmedOpen" required>
        Confirmed open 30+ days for {{ year }} *
      </mat-checkbox>
      <mat-checkbox *ngIf="formGroup.get('confirmedEnglish')" formControlName="confirmedEnglish" required>
        Confirmed English availability for {{ year }} *
      </mat-checkbox>
      <mat-form-field *ngIf="formGroup.get('englishLink')" appearance="fill">
        <mat-label>English availability link</mat-label>
        <input type="text" matInput formControlName="englishLink" required>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"></textarea>
        <mat-hint>Please leave a note documenting any non-trivial changes.</mat-hint>
      </mat-form-field>
      <p *ngIf="parent.room.reviewerNotes || parent.room.auditLogHistory" class="terpeca-multiline flex-col-reverse">
        <i *ngIf="parent.room.reviewerNotes">📝 {{ parent.room.reviewerNotes }}</i>
        <i *ngFor="let entry of parent.room.auditLogHistory">{{ getRoomAuditLogString(entry) }}</i>
        <strong><i>Audit Log</i></strong>
      </p>
    </form>
    <div class="nom-column flex-col gap-5">
      <mat-divider></mat-divider>
      <ng-container *ngIf="parent.room?.affiliatedUserIds?.length">
        <div>👔 <i><b>Affiliated Users:</b></i> {{ parent.room.affiliatedUserIds.length }}</div>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngFor="let y of settings.allYears">
        <div *ngIf="nomsByYearMap && pendingNomsByYearMap">🎗️ <i><b>{{ y }} Nominations:</b></i> {{ nomsByYearMap[y] }}
          <ng-container *ngIf="pendingNomsByYearMap[y]">(+{{ pendingNomsByYearMap[y] }} pending)</ng-container>
          <span *ngIf="parent.isApproved(y)" matTooltip="Approved">✅</span>
          <span *ngIf="parent.isNominee(y)" matTooltip="Nominee">🎗️</span>
          <span *ngIf="parent.isFinalist(y)" matTooltip="Finalist">🏅</span>
          <span *ngIf="parent.isWinner(y) && y !== year" matTooltip="Winner">🏆</span>
          <span *ngIf="parent.isIneligible(y)" matTooltip="Ineligible">❌</span>
          <span *ngIf="parent.isPermanentlyIneligible(y)" matTooltip="Permanently Closed">⛔</span>
        </div>
        <ng-container *ngFor="let nomination of currentNoms">
          <app-nomination *ngIf="nomination.year === y" [showUserData]="canShowUserData()"
                          [matcher]="parent.matcher" [nomination]="nomination" [showDetails]=true
                          class="flex-row justify-center align-stretch"></app-nomination>
        </ng-container>
        <mat-divider></mat-divider>
      </ng-container>
      <ng-container *ngFor="let y of settings.allYears">
        <ng-container *ngIf="currentQuotes">
          <div>💬 <i><b>{{ y }} Quotes:</b></i></div>
          <ng-container *ngFor="let quote of currentQuotes">
            <mat-card *ngIf="quote.year === y" class="flex-col flex-default">
              <div class="flex-col justify-start align-start gap-10">
                <div>
                  💬 <i>{{ quote.quote }}</i>
                </div>
                <div *ngIf="userMap.has(quote.userId)">
                  {{ userData(quote.userId) }}
                  <span *ngIf="quote.videoInterest" matTooltip="This user wants to present this room!">🙋</span>
                </div>
              </div>
            </mat-card>
          </ng-container>
          <mat-divider></mat-divider>
        </ng-container>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <div class="flex-col gap-10">
    <div *ngIf="parent.allowProcess" class="flex-row flex-wrap justify-flex-end align-start gap-5">
      <button *ngIf="canDisqualify()" mat-raised-button color="warn" (click)="disqualify()" [disabled]="!formGroup.value.ineligibilityReason">
        Disqualify
      </button>
      <button *ngIf="canMarkClosed()" mat-raised-button color="warn" (click)="markClosed()" [disabled]="!formGroup.value.ineligibilityReason">
        Mark Closed
      </button>
      <button *ngIf="canReopen()" mat-raised-button color="secondary" (click)="reopen()">
        Mark Reopened
      </button>
      <button *ngIf="canUndisqualify()" mat-raised-button color="secondary" (click)="undisqualify()">
        Undisqualify
      </button>
      <button *ngIf="canApprove()" mat-raised-button color="primary" (click)="approve()" [disabled]="formGroup.invalid">
        Approve
      </button>
      <button *ngIf="canUnapprove()" mat-raised-button color="warn" (click)="unapprove()">
        Unapprove
      </button>
      <button *ngIf="canFinalize()" mat-raised-button color="primary" (click)="finalize()" [disabled]="formGroup.invalid">
        Finalize
      </button>
      <button *ngIf="canUnfinalize()" mat-raised-button color="warn" (click)="unfinalize()">
        Unfinalize
      </button>
    </div>
    <div class="flex-row justify-flex-end align-start gap-5">
      <button *ngIf="canDelete()" mat-raised-button color="warn" (click)="delete()">
        Delete
      </button>
      <button mat-raised-button color="secondary" (click)="cancel()">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!formGroup.dirty || !canSave()">
        Save
      </button>
    </div>
  </div>
</div>
