<h1 mat-dialog-title>Request Resubmit for {{data.applicant.user.realName}}</h1>
<mat-dialog-content [formGroup]="form">
  Select all of the reasons this {{data.applicant.user.upgradeRequested ? 'upgrade request' : 'application'}} needs to be resubmitted:
  <p>
    <i>Identity</i>
  </p>
  <ul>
    <li><mat-checkbox formControlName="onePersonOnly">The application is for more than one person.</mat-checkbox></li>
    <li><mat-checkbox formControlName="insufficientIdentityInfo">There is insufficient information to find this person online.</mat-checkbox></li>
  </ul>
  <p>
    <i>Experience</i>
  </p>
  <ul>
    <li><mat-checkbox formControlName="insufficientGameCount">The user has not reported to have played enough rooms.</mat-checkbox></li>
    <li><mat-checkbox formControlName="insufficientExperienceInfo">There is insufficient evidence to support the room count.</mat-checkbox></li>
  </ul>
  <p>
   <i>Transparency</i>
  </p>
  <ul>
    <li><mat-checkbox formControlName="insufficientConflictDisclosure">There is insufficient disclosure of conflicts of interest.</mat-checkbox></li>
  </ul>
  <p>
    <i>Data Quality</i>
  </p>
  <ul>
    <li><mat-checkbox formControlName="invalidName">The user's name is invalid or not capitalized properly.</mat-checkbox></li>
    <li><mat-checkbox formControlName="invalidCity">The user's city is invalid or not capitalized properly.</mat-checkbox></li>
    <li><mat-checkbox formControlName="invalidFacebook">The user's Facebook account ID is invalid.</mat-checkbox></li>
    <li><mat-checkbox formControlName="insufficientBio">The bio needs to be improved or translated.</mat-checkbox></li>
  </ul>
  <p>
    <i>Other</i>
  </p>
  <ul>
    <li>
      <mat-checkbox formControlName="custom">Add custom instructions</mat-checkbox><br>
      <mat-form-field *ngIf="form.value.custom" appearance="fill">
        <mat-label>Custom instructions</mat-label>
        <textarea matInput formControlName="customInstructions" [disabled]="!form.value.custom"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"></textarea>
      </mat-form-field>
    </li>
    <li>
      <mat-checkbox formControlName="ccMe">CC me on the email</mat-checkbox>
    </li>
  </ul>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="!formIsValid()">
    Submit
  </button>
</div>
