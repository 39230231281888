import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-simpledialog',
  templateUrl: './simpledialog.component.html',
  styleUrl: './simpledialog.component.css'
})
export class SimpleDialogComponent {
  constructor(public dialogRef: MatDialogRef<SimpleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ok() {
    if (this.data.okCallback) {
      this.data.okCallback();
    }
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
