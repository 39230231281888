import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

import { IpService } from './services/ip.service';
import { isRoomFinderPage } from './utils/misc.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  static ipAddress: string = '';

  constructor(private titleService: Title, private ip: IpService) {
  }

  ngOnInit() {
    this.titleService.setTitle(`${environment.projectTitle} ${isRoomFinderPage() ? 'Room Finder' : 'Voter Portal'}`);
    this.ip.getIPAddress().subscribe((result: string) => {
      AppComponent.ipAddress = result;
    });
  }
}
