import app from 'firebase/compat/app';

import { TerpecaCategory } from './room.model';
import { HorrorPreference } from './user.model';

// We allow users to not rank some of the rooms they've played if they so desire, but we keep track of the reasons why.
export enum UnrankedReason {
  DID_NOT_PLAY,          // player didn't actually play the room (this won't be stored, we'll just remove it from their list)
  CONFLICT_OF_INTEREST,  // player has a conflict of interest (this won't be stored, we'll just remove it from their list)
  CANT_REMEMBER_ROOM,    // player played the room long ago and doesn't remember enough to rank it fairly
  CANT_COMPARE_ROOM,     // player doesn't know how to compare this room to others, perhaps because it doesn't seem like an escape room
  ROOM_HAS_CHANGED,      // player believes the room has changed significantly since they played it
  ATYPICAL_EXPERIENCE,   // player believes their play of the room was not representative of a typical play and thus shouldn't count
  OTHER,                 // any other reason not enumerated, player will be prompted to provide a reason
}

export interface UnrankedReasonEntry {
  reason: UnrankedReason;
  note?: string;
}

export interface UnrankedReasonMap {
  [roomId: string]: UnrankedReasonEntry;
}

export interface TerpecaRanking {
  docId?: string;

  createTime: app.firestore.Timestamp;
  userId: string;
  year: number;
  category: TerpecaCategory;
  seededFrom?: number;

  // We store this with the ranking to reflect the mindset of the user at the time the rankings were done, in case it changes over time.
  horrorPreference?: HorrorPreference;

  // These are all the rooms the user has claimed to play, in random order, whether or not they've
  // been ranked or not. We keep this separate to ensure that ranked orders aren't accidentally leaked.
  unsortedIds?: string[];

  // These are all the rooms the user has ranked, in ranked order. It will not include rooms they've
  // played but chose not to rank.
  rankedIds?: string[];

  // These are all the rooms the user has chosen explicitly not to rank, along with the corresponding reasons.
  unrankedReasonMap?: UnrankedReasonMap;

  submitted: boolean;
  shadowbanned?: boolean;
}

export function getUnrankedIds(ranking: TerpecaRanking): string[] {
  return Object.keys(ranking?.unrankedReasonMap || {});
}

export function getUnrankedReasonEmoji(reasonEntry: UnrankedReasonEntry) {
  if (reasonEntry) {
    switch (reasonEntry.reason) {
      case UnrankedReason.DID_NOT_PLAY:
      case UnrankedReason.CONFLICT_OF_INTEREST:
        // These cases are not used, because we don't store these as reasons.
        return '';
      case UnrankedReason.CANT_REMEMBER_ROOM:
        return '🤷';
      case UnrankedReason.CANT_COMPARE_ROOM:
        return '🤔';
      case UnrankedReason.ROOM_HAS_CHANGED:
        return '♻️';
      case UnrankedReason.ATYPICAL_EXPERIENCE:
        return '🤪';
      case UnrankedReason.OTHER:
        return '🗒️';
    }
  }
  return '';
}

export function getUnrankedReasonDescription(reasonEntry: UnrankedReasonEntry) {
  if (reasonEntry) {
    switch (reasonEntry.reason) {
      case UnrankedReason.DID_NOT_PLAY:
      case UnrankedReason.CONFLICT_OF_INTEREST:
        // These cases are not used, because we don't store these as reasons.
        return '';
      case UnrankedReason.CANT_REMEMBER_ROOM:
        return 'could not remember';
      case UnrankedReason.CANT_COMPARE_ROOM:
        return 'could not compare';
      case UnrankedReason.ROOM_HAS_CHANGED:
        return 'room has changed';
      case UnrankedReason.ATYPICAL_EXPERIENCE:
        return 'atypical experience';
      case UnrankedReason.OTHER:
        return reasonEntry.note;
    }
  }
  return '';
}
