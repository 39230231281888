import { Component, Input } from '@angular/core';

import { HorrorPreference } from 'src/app/models/user.model';

// Angular component to indicate a horror level with emoji and/or description.
@Component({
  selector: 'app-horrorpreference',
  templateUrl: './horrorpreference.component.html',
  styleUrl: './horrorpreference.component.css',
})
export class HorrorPreferenceComponent {
  @Input() pref: HorrorPreference;
  @Input() showShortText: boolean = false;
  @Input() showText: boolean = false;

  constructor() { }

  emoji(): string {
    switch (this.pref) {
      case HorrorPreference.UNKNOWN: return '';
      case HorrorPreference.HATE: return '❌';
      case HorrorPreference.DISLIKE: return '👎';
      case HorrorPreference.NEUTRAL: return '🤷';
      case HorrorPreference.LIKE: return '👍';
      case HorrorPreference.LOVE: return '❤️';
      // No default case, Typescript will verify enum coverage
    }
  }

  shortText(): string {
    switch (this.pref) {
      case HorrorPreference.UNKNOWN: return '';
      case HorrorPreference.HATE: return 'Hate';
      case HorrorPreference.DISLIKE: return 'Dislike';
      case HorrorPreference.NEUTRAL: return 'Neutral';
      case HorrorPreference.LIKE: return 'Like';
      case HorrorPreference.LOVE: return 'Love';
      // No default case, Typescript will verify enum coverage
    }
  }

  text(): string {
    switch (this.pref) {
      case HorrorPreference.UNKNOWN: return '';
      case HorrorPreference.HATE: return 'I strongly dislike horror games.';
      case HorrorPreference.DISLIKE: return 'I dislike horror games, but enjoy them sometimes.';
      case HorrorPreference.NEUTRAL: return 'I have mixed feelings about horror games.';
      case HorrorPreference.LIKE: return 'I like horror games, with some reservations.';
      case HorrorPreference.LOVE: return 'I strongly like horror games.';
      // No default case, Typescript will verify enum coverage
    }
  }
}
