import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { ApplicationStatus } from './models/user.model';
import { isLocalhost, isRoomFinderApp } from './utils/misc.utils';

import { AnalyzeComponent } from './views/analyze/analyze.component';
import { ApproveComponent } from './views/approve/approve.component';
import { DisclosuresComponent } from './views/disclosures/disclosures.component';
import { FaqComponent } from './views/faq/faq.component';
import { HistoryComponent } from './views/history/history.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { MatchComponent } from './views/match/match.component';
import { NominateComponent } from './views/nominate/nominate.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RankComponent } from './views/rank/rank.component';
import { RoomFinderComponent } from './views/roomfinder/roomfinder.component';
import { SolutionComponent } from './views/solution/solution.component';
import { ValidateComponent } from './views/validate/validate.component';

/* eslint-disable no-multi-spaces */
const voterPortalRoutes: Routes = [
  { path: 'login',    component: LoginComponent,    canActivate: [AuthGuard] },
  { path: 'faq',      component: FaqComponent },
  { path: 'venerate', component: SolutionComponent },
  { path: '',         component: HomeComponent,     canActivate: [AuthGuard] },
  { path: 'approve',  component: ApproveComponent,  canActivate: [AuthGuard], data: { status: ApplicationStatus.REVIEWER,
                                                                                      allowOwner: true } },
  { path: 'match',    component: MatchComponent,    canActivate: [AuthGuard], data: { status: ApplicationStatus.REVIEWER } },
  { path: 'nominate', component: NominateComponent, canActivate: [AuthGuard], data: { status: ApplicationStatus.NOMINATOR } },
  { path: 'rank',     component: RankComponent,     canActivate: [AuthGuard], data: { status: ApplicationStatus.VOTER } },
  { path: 'review',   component: DisclosuresComponent, canActivate: [AuthGuard], data: { status: ApplicationStatus.VOTER } },
  { path: 'validate', component: ValidateComponent, canActivate: [AuthGuard], data: { status: ApplicationStatus.APPROVER } },
  { path: 'analyze',  component: AnalyzeComponent,  canActivate: [AuthGuard], data: { status: ApplicationStatus.REVIEWER } },
  { path: 'history',  component: HistoryComponent,  canActivate: [AuthGuard], data: { status: ApplicationStatus.VOTER } },
  { path: 'profile',  component: ProfileComponent,  canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard],
    data: { status: ApplicationStatus.VOTER } },
];

const roomFinderRoutes: Routes = [
  { path: '', component: RoomFinderComponent },
];

// For localhost testing, we give the room finder a dedicated path within the voter portal.
if (isLocalhost()) {
  voterPortalRoutes.push({ path: 'finder', component: RoomFinderComponent });
}


/* eslint-enable */

@NgModule({
  imports: [RouterModule.forRoot(isRoomFinderApp() ? roomFinderRoutes : voterPortalRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
