import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ApplicationStatus, TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import {
    RoomCountErrorStateMatcher, discordUsernameValidator, escapeAllGrUserIdValidator, escapeGameFrUserIdValidator,
    escapeRoomersUserIdValidator, escapeTalkNlUserIdValidator, escapeTheReviewUserIdValidator, facebookUsernameValidator,
    instagramUsernameValidator, lockMeUserIdValidator, mortyAppUsernameValidator, roomCountValidator, theEscapersUserIdValidator
} from 'src/app/utils/form.utils';

import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrl: './apply.component.css'
})
export class ApplyComponent implements OnInit, OnDestroy {
  @Input() home: HomeComponent;
  @Input() user: TerpecaUser;
  expanded = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  private subscriptions: Subscription[] = [];
  Status = ApplicationStatus;
  roomCountErrorMatcher = new RoomCountErrorStateMatcher();

  private defaultPrefsList: string[] = [
    'Adventure',
    'Customer service',
    'Narrative',
    'Puzzles',
    'Set design',
    'Size and scale',
    'Technology'
  ];

  constructor(private auth: AuthService, public settings: SettingsService) { }

  ngOnInit() {
    this.expanded = this.user.status === ApplicationStatus.NONE;
    this.firstFormGroup = new UntypedFormGroup({
      realName: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      state: new UntypedFormControl(''),
      country: new UntypedFormControl('', Validators.required),
      appEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
      facebookUsername: new UntypedFormControl('', facebookUsernameValidator),
      instagramUsername: new UntypedFormControl('', instagramUsernameValidator),
      discordUsername: new UntypedFormControl('', discordUsernameValidator),
      bestContactMethod: new UntypedFormControl('', Validators.required),
      bio: new UntypedFormControl('', Validators.required),
    });
    this.secondFormGroup = new UntypedFormGroup({
      roomCount: new UntypedFormControl('', Validators.required),
      virtualRoomCount: new UntypedFormControl('', Validators.required),
      roomCountEvidence: new UntypedFormControl('', Validators.required),
      escapeTheReviewUserId: new UntypedFormControl('', escapeTheReviewUserIdValidator),
      escapeAllGrUserId: new UntypedFormControl('', escapeAllGrUserIdValidator),
      escapeGameFrUserId: new UntypedFormControl('', escapeGameFrUserIdValidator),
      escapeRoomersUserId: new UntypedFormControl('', escapeRoomersUserIdValidator),
      escapeTalkNlUserId: new UntypedFormControl('', escapeTalkNlUserIdValidator),
      lockMeUserId: new UntypedFormControl('', lockMeUserIdValidator),
      mortyAppUsername: new UntypedFormControl('', mortyAppUsernameValidator),
      theEscapersUserId: new UntypedFormControl('', theEscapersUserIdValidator),
      regionsPlayed: new UntypedFormControl('', Validators.required),
      affiliations: new UntypedFormControl(''),
      onlinePresence: new UntypedFormControl(''),
      otherExperience: new UntypedFormControl(''),
      references: new UntypedFormControl(''),
    }, roomCountValidator);
    this.thirdFormGroup = new UntypedFormGroup({
      favoriteRooms: new UntypedFormControl('', Validators.required),
      horrorPreference: new UntypedFormControl('', Validators.required),
      prefsList: new UntypedFormControl([...this.defaultPrefsList], Validators.required),
    });
    this.subscriptions.push(
      this.auth.user.subscribe((value: TerpecaUser) => {
        if (value) {
          this.firstFormGroup.patchValue(value, { emitEvent: false });
          this.secondFormGroup.patchValue(value, { emitEvent: false });
          this.thirdFormGroup.patchValue(value, { emitEvent: false });
          if (this.user.status >= ApplicationStatus.VOTER) {
            if (!this.firstFormGroup.valid) {
              this.firstFormGroup.markAllAsTouched();
            }
            if (!this.secondFormGroup.valid) {
              this.secondFormGroup.markAllAsTouched();
            }
            if (!this.thirdFormGroup.valid) {
              this.thirdFormGroup.markAllAsTouched();
            }
          }
        }
      }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  async prefRanksChanged(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.thirdFormGroup.value.prefsList, event.previousIndex, event.currentIndex);
    this.thirdFormGroup.value.prefsList.valid = true;
    this.thirdFormGroup.markAsDirty();
  }

  async save(formGroup: UntypedFormGroup) {
    if (!formGroup.valid) {
      return;
    }
    await this.auth.updateUser(formGroup.value);
    formGroup.markAsPristine();
  }

  async submit() {
    if (!this.thirdFormGroup.valid) {
      return;
    }
    if (this.user.status === ApplicationStatus.NONE) {
      await this.auth.submitApplication(this.thirdFormGroup.value);
    } else if (this.user.status === ApplicationStatus.VOTER && !this.user.upgradeRequested) {
      await this.auth.requestUpgrade(this.thirdFormGroup.value);
    } else {
      await this.auth.updateUser(this.thirdFormGroup.value);
    }
    this.expanded = false;
    this.home.requestUpgrade = false;
  }
}
