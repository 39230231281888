import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';

import app from 'firebase/compat/app';

import { RoomAuditLogEntry, RoomAuditLogEntryType, TerpecaCategory, TerpecaRoom, getRoomAuditLogString } from 'src/app/models/room.model';
import { ApplicationStatus } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as utils from 'src/app/utils/misc.utils';
import { MatchComponent } from 'src/app/views/match/match.component';
import { RankComponent } from 'src/app/views/rank/rank.component';
import { RoomEditorComponent } from 'src/app/views/roomeditor/roomeditor.component';
import { environment } from 'src/environments/environment';

import { NominateComponent } from '../nominate/nominate.component';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrl: './room.component.css'
})
export class RoomComponent {
  @Input() room: TerpecaRoom;
  @Input() matcher: MatchComponent;
  @Input() ranker: RankComponent;
  @Input() nominate: NominateComponent;
  @Input() forDisclosure: boolean = false;
  @Input() allowSelect: boolean = false;
  @Input() disableSelect: boolean = false;
  @Input() allowEdit: boolean = false;
  @Input() allowProcess: boolean = false;
  @Input() selected: boolean = false;
  @Input() showNotes: boolean = false;
  @Input() selectLabel: string = 'Select';
  @Input() unselectLabel: string = 'Unselect';

  getRoomAuditLogString = getRoomAuditLogString;
  year = environment.currentAwardYear;
  editable = false;

  constructor(private auth: AuthService, private db: AngularFirestore, private settings: SettingsService, private dialog: MatDialog) { }

  backgroundColor() {
    if (this.selected) {
      return 'lightgrey';
    }
    if (this.matcher && this.isFinalized()) {
      return 'whitesmoke';
    }
    return 'inherit';
  }

  location() {
    return utils.getLocationString(this.room);
  }

  languages() {
    return utils.getLanguages(this.room);
  }

  previousYears() {
    const years: number[] = [];
    for (let y = 1998; y < this.year; ++y) {
      if (this.room.isApproved?.includes(y) || this.room.isIneligible?.includes(y)) {
        years.push(y);
      }
    }
    return years;
  }

  async save(formValue: any, entryType: RoomAuditLogEntryType) {
    const room = formValue || { };
    if (formValue?.name) {
      room.isConfirmedOpen = formValue.confirmedOpen ? <number><unknown>app.firestore.FieldValue.arrayUnion(this.year) :
            <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
      delete room.confirmedOpen;
      room.isConfirmedEnglish = formValue.confirmedEnglish ? <number><unknown>app.firestore.FieldValue.arrayUnion(this.year) :
            <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
      delete room.confirmedEnglish;
    }
    if (entryType !== RoomAuditLogEntryType.UPDATED || formValue?.notes) {
      room.auditLogEntry = this.newRoomAuditLogEntry(entryType, formValue?.notes || null);
      delete room.notes;
    }
    switch (entryType) {
      case RoomAuditLogEntryType.APPROVED:
        room.isApproved = <number><unknown>app.firestore.FieldValue.arrayUnion(this.year);
        break;
      case RoomAuditLogEntryType.UNAPPROVED:
        if (this.isFinalized()) {
          console.error(`can't unapprove a room that is already finalized!`);
          return;
        }
        room.isApproved = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        break;
      case RoomAuditLogEntryType.DISQUALIFIED:
        room.isApproved = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isNominee = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isFinalist = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isIneligible = <number><unknown>app.firestore.FieldValue.arrayUnion(this.year);
        break;
      case RoomAuditLogEntryType.MARKED_CLOSED:
        room.isApproved = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isNominee = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isFinalist = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isIneligible = <number><unknown>app.firestore.FieldValue.arrayUnion(this.year);
        room.isPermanentlyIneligible = [this.year];
        break;
      case RoomAuditLogEntryType.MARKED_REOPENED:
        room.isPermanentlyIneligible = [];
        break;
      case RoomAuditLogEntryType.FINALIZED:
        if (!this.isApproved()) {
          console.error(`can't finalize a room that isn't yet approved!`);
          return;
        }
        if (this.isIneligible()) {
          console.error(`can't finalize a room that has been disqualified!`);
          return;
        }
        if (!this.isAutomaticFinalist() && this.numCurrentNominations() === 0) {
          console.error(`can't finalize a room with no nominations!`);
          return;
        }
        room.isNominee = <number><unknown>app.firestore.FieldValue.arrayUnion(this.year);
        room.isFinalist = (!this.isAutomaticFinalist() && this.numCurrentNominations() < this.settings.finalistThreshold()) ?
          <number><unknown>app.firestore.FieldValue.arrayRemove(this.year) :
          <number><unknown>app.firestore.FieldValue.arrayUnion(this.year);
        break;
      case RoomAuditLogEntryType.UNDISQUALIFIED:
      case RoomAuditLogEntryType.UNFINALIZED:
        room.isNominee = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isFinalist = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isIneligible = <number><unknown>app.firestore.FieldValue.arrayRemove(this.year);
        room.isPermanentlyIneligible = [];
        break;
    }
    await this.db.collection<TerpecaRoom>('rooms').doc(this.room.docId).update(<Partial<TerpecaRoom>>room);
  }

  newRoomAuditLogEntry(type: RoomAuditLogEntryType, note?: string): RoomAuditLogEntry {
    return {
      uid: this.auth.currentUser.uid,
      name: this.auth.currentUser.realName || this.auth.currentUser.displayName,
      entryType: type,
      timestamp: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
      year: this.year,
      note,
    };
  }

  canApprove() {
    return this.auth.currentUser && this.auth.currentUser.status >= ApplicationStatus.REVIEWER && this.matcher;
  }

  canDisqualify() {
    return this.auth.currentUser && this.auth.currentUser.status >= ApplicationStatus.REVIEWER && this.matcher;
  }

  canEditCompanyNames() {
    return this.auth.currentUser && this.auth.currentUser.status >= ApplicationStatus.APPROVER && this.matcher;
  }

  canFinalize() {
    return this.settings.isPastNominationDeadline() && this.auth.currentUser &&
           this.auth.currentUser.status >= ApplicationStatus.APPROVER && this.matcher;
  }

  canUnfinalize() {
    return !this.settings.isVotingOpen(this.auth.currentUser) &&
      (this.isNominee() || this.isFinalist() || this.isIneligible()) &&
      this.auth.currentUser && this.auth.currentUser.status >= ApplicationStatus.REVIEWER && this.matcher;
  }

  isOnlineRoom() {
    return this.room.category === TerpecaCategory.TOP_ONLINE_ROOM;
  }

  isInPersonRoom() {
    return this.room.category === TerpecaCategory.TOP_ROOM;
  }

  isCompany() {
    return this.room.category === TerpecaCategory.TOP_COMPANY;
  }

  isApproved(year?: number) {
    return utils.isApproved(this.room, year);
  }

  isIneligible(year?: number) {
    return utils.isIneligible(this.room, year);
  }

  isPermanentlyIneligible(year?: number) {
    return utils.isPermanentlyIneligible(this.room, year);
  }

  isAutomaticFinalist(year?: number) {
    return utils.isAutomaticFinalist(this.room, year);
  }

  isNominee(year?: number) {
    return utils.isNominee(this.room, year);
  }

  isFinalist(year?: number) {
    return utils.isFinalist(this.room, year);
  }

  isWinner(year?: number) {
    return utils.isWinner(this.room, year);
  }

  isFinalized() {
    return this.isIneligible() || this.isNominee() || this.isFinalist() ||
        (this.isApproved() && !this.isAutomaticFinalist() && this.numCurrentNominations() === 0 &&
         this.settings.isPastNominationDeadline());
  }

  async finalize() {
    await this.save(null, RoomAuditLogEntryType.FINALIZED);
  }

  async unfinalize() {
    await this.save(null, RoomAuditLogEntryType.UNFINALIZED);
  }

  edit() {
    this.dialog.open(RoomEditorComponent, { data: { parent: this } });
  }

  toggleSelected() {
    if (this.matcher) {
      this.matcher.toggleSelectedRoom(this.room.docId);
    }
    if (this.ranker) {
      if (this.forDisclosure) {
        this.ranker.toggleAffiliatedRoom(this.room.docId);
      } else {
        this.ranker.togglePlayedRoom(this.room.category, this.room.docId);
      }
    }
    if (this.nominate) {
      this.nominate.nominateExistingRoom(this.room);
    }
  }

  numTotalNominations() {
    return utils.getNominationCount(this.room);
  }

  numCurrentNominations() {
    return utils.getNominationCount(this.room, this.year);
  }
}
