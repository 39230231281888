import Rollbar from 'rollbar';

// Angular imports
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';

// App imports (mostly components to declare)
import { AnalyzeComponent } from './views/analyze/analyze.component';
import { AnalyzeDialogComponent } from './views/analyzedialog/analyzedialog.component';
import { AppComponent } from './app.component';
import { ApplicantComponent } from './views/applicant/applicant.component';
import { ApplyComponent } from './views/apply/apply.component';
import { AppRoutingModule } from './app-routing.module';
import { ApproveComponent } from './views/approve/approve.component';
import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { ConfirmListDialogComponent } from './views/confirmlistdialog/confirmlistdialog.component';
import { DisclosuresComponent } from './views/disclosures/disclosures.component';
import { environment } from 'src/environments/environment';
import { FaqComponent } from './views/faq/faq.component';
import { HeaderComponent } from './views/header/header.component';
import { HistoryComponent } from './views/history/history.component';
import { HomeComponent } from './views/home/home.component';
import { HorrorLevelComponent } from './views/horrorlevel/horrorlevel.component';
import { HorrorLevelPickerComponent } from './views/horrorlevelpicker/horrorlevelpicker.component';
import { HorrorPreferenceComponent } from './views/horrorpreference/horrorpreference.component';
import { HorrorPreferencePickerComponent } from './views/horrorpreferencepicker/horrorpreferencepicker.component';
import { LanguageComponent } from './views/language/language.component';
import { LanguagePickerComponent } from './views/languagepicker/languagepicker.component';
import { LocationPickerComponent } from './views/locationpicker/locationpicker.component';
import { LoginComponent } from './views/login/login.component';
import { MatchComponent } from './views/match/match.component';
import { MaterialModule } from './material.module';
import { NominateComponent } from './views/nominate/nominate.component';
import { NominationComponent } from './views/nomination/nomination.component';
import { ProfileComponent } from './views/profile/profile.component';
import { QuoteComponent } from './views/quote/quote.component';
import { RankComponent } from './views/rank/rank.component';
import { RequestResubmitDialogComponent } from './views/requestresubmitdialog/requestresubmitdialog.component';
import { RoomComponent } from './views/room/room.component';
import { RoomDisclosureComponent } from './views/roomdisclosure/roomdisclosure.component';
import { RoomEditorComponent } from './views/roomeditor/roomeditor.component';
import { RoomFilter, UserFilter } from './utils/misc.utils';
import { RoomFinderComponent } from './views/roomfinder/roomfinder.component';
import { RoomFinderRankComponent } from './views/roomfinderrank/roomfinderrank.component';
import { SettingsService } from './services/settings.service';
import { SimpleDialogComponent } from './views/simpledialog/simpledialog.component';
import { SimpleRoomComponent } from './views/simpleroom/simpleroom.component';
import { SimpleTextInputDialogComponent } from './views/simpletextinputdialog/simpletextinputdialog.component';
import { SolutionComponent } from './views/solution/solution.component';
import { UnrankRoomDialogComponent } from './views/unrankroomdialog/unrankroomdialog.component';
import { UserDisclosureComponent } from './views/userdisclosure/userdisclosure.component';
import { ValidateComponent } from './views/validate/validate.component';
import { VersionUpdatedDialogComponent } from './views/versionupdateddialog/versionupdateddialog.component';

const rollbarConfig = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

@Injectable({ providedIn: 'any' })
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(ROLLBAR_SERVICE) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const ROLLBAR_SERVICE = new InjectionToken<Rollbar>('rollbar');

export function settingsFactory(settings: SettingsService) {
  return async () => { await settings.loadSettings(); };
}

@NgModule({ declarations: [
        AnalyzeComponent,
        AnalyzeDialogComponent,
        AppComponent,
        ApplicantComponent,
        ApplyComponent,
        ApproveComponent,
        ConfirmListDialogComponent,
        DisclosuresComponent,
        FaqComponent,
        HeaderComponent,
        HistoryComponent,
        HomeComponent,
        HorrorLevelComponent,
        HorrorLevelPickerComponent,
        HorrorPreferenceComponent,
        HorrorPreferencePickerComponent,
        LanguageComponent,
        LanguagePickerComponent,
        LocationPickerComponent,
        LoginComponent,
        MatchComponent,
        NominateComponent,
        NominationComponent,
        ProfileComponent,
        QuoteComponent,
        RankComponent,
        RequestResubmitDialogComponent,
        RoomComponent,
        RoomDisclosureComponent,
        RoomEditorComponent,
        RoomFilter,
        RoomFinderComponent,
        RoomFinderRankComponent,
        SimpleDialogComponent,
        SimpleRoomComponent,
        SimpleTextInputDialogComponent,
        SolutionComponent,
        UnrankRoomDialogComponent,
        UserDisclosureComponent,
        UserFilter,
        ValidateComponent,
        VersionUpdatedDialogComponent,
    ],
    bootstrap: [
        AppComponent,
    ], imports: [AngularFireAuthGuardModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.firebase, environment.firebase.projectId),
        AngularFirestoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DragDropModule,
        MaterialModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsMeasurementId),
        NgxGoogleAnalyticsRouterModule,
        ReactiveFormsModule,
        TextFieldModule], providers: [
        AuthService,
        CanDeactivateGuard,
        { provide: APP_INITIALIZER, useFactory: settingsFactory, deps: [SettingsService], multi: true },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: ROLLBAR_SERVICE, useFactory: rollbarFactory },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
