<section *ngIf="user && user.status >= Status.NOMINATOR">
  <div *ngIf="!settings.isNominationOpen(user) || settings.isPastNominationDeadline() || user.nominationsSubmitted?.includes(year)"
       class="flex-col justify-center align-center">
    <mat-card class="flex-col">
      <h3 *ngIf="!settings.isNominationOpen(user)">
        The nomination window for {{year}} is not yet open.
      </h3>
      <p *ngIf="!settings.isNominationOpen(user)">
        You will be notified by email when the window opens.
      </p>
      <h3 *ngIf="user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
        Your nominations have been submitted!
      </h3>
      <p *ngIf="user.nominationsSubmitted?.includes(year) && !settings.isPastNominationDeadline()">
        If you want to make any changes before the {{settings.nominationDeadlineString()}} deadline, you
        may reopen them below, but be sure to resubmit before the deadline or they won't be counted.
      </p>
      <h3 *ngIf="user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
        Your nominations were submitted!
      </h3>
      <p *ngIf="user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
        The nomination window for {{year}} is closed, and your nominations are final.
      </p>
      <h3 *ngIf="!user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
        The nomination deadline has passed.
      </h3>
      <p *ngIf="!user.nominationsSubmitted?.includes(year) && settings.isPastNominationDeadline()">
        You did not submit nominations this year. There's always next year!
      </p>
      <div class="flex-row gap-5">
        <a mat-raised-button color="secondary" routerLink="/">Back to Home</a>
        <a *ngIf="settings.isNominationOpen(user) && !settings.isPastNominationDeadline()"
           mat-raised-button color="primary" (click)="reopenNominations()">Reopen Nominations</a>
      </div>
    </mat-card>
    <mat-card *ngIf="user.nominationsSubmitted?.includes(year)" class="flex-col gap-5">
      <h3 class="flex-row justify-center align-center">
        Your {{ year }} Nominations
      </h3>
      <div *ngIf="settings.isVotingOpen(user)" class="flex-row flex-wrap justify-center align-center gap-25">
        <div>🏅 = finalist</div>
        <div>❌ = ineligible</div>
      </div>
      <div class="flex-row flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
        <div class="flex-col flex-default gap-5">
          <h3 class="flex-row justify-center align-center">Top Room Nominations ({{ numRegularNoms() }})</h3>
          <app-nomination *ngFor="let nominationId of regularNominationIds" [nominationId]="nominationId" [showEligibility]=true
                          class="flex-row justify-center align-stretch"></app-nomination>
        </div>
        <div *ngIf="settings.hasOnlineRoomCategory()" class="flex-col flex-default gap-5">
          <h3 class="flex-row justify-center align-center">Top Online Room Nominations ({{ numOnlineNoms() }})</h3>
          <app-nomination *ngFor="let nominationId of onlineNominationIds" [nominationId]="nominationId" [showEligibility]=true
                          class="flex-row justify-center align-stretch"></app-nomination>
        </div>
        <div *ngIf="settings.hasCompanyCategory()" class="flex-col flex-default gap-5">
          <h3 class="flex-row justify-center align-center">Top Company Nominations ({{ numCompanyNoms() }})</h3>
          <app-nomination *ngFor="let nominationId of companyNominationIds" [nominationId]="nominationId" [showEligibility]=true
                          class="flex-row justify-center align-stretch"></app-nomination>
        </div>
      </div>
    </mat-card>
  </div>
  <div *ngIf="settings.isNominationOpen(user) && !settings.isPastNominationDeadline() && !user.nominationsSubmitted?.includes(year)"
       class="flex-col justify-center align-stretch">
    <h3 class="flex-row justify-center align-center">
      {{ year }} TERPECA Nominations Form
    </h3>
    <div *ngIf="!hideRules" class="rules flex-col justify-center align-center">
      <b style="color: red">IMPORTANT: Read this first!</b>
      <ul>
        <li>
          <b>Nominations must be submitted by {{settings.nominationDeadlineString()}}.</b>
        </li>
        <li>
          <b>This is an individual process.</b> Your nominations are your own, and they should be
          unique to you. Do not discuss your specific nominations with anyone else until you've submitted,
          and do not submit nominations on behalf of anyone but yourself.
        </li>
        <li>
          <b>The goal here is to identify the world's best escape rooms</b> to both honor those who
          create them and to recommend the best experiences to other avid players. If you're here, it
          means you're an experienced player yourself, so in general <b>it is up to you to decide what counts as an
          escape room</b> and whether or not it deserves this form of recognition.
        </li>
        <li *ngIf="settings.hasOnlineRoomCategory()">
          <div class="flex-col gap-5">
            <div>
              <b>This year we will again have a category for online escape rooms!</b> This online escape room category
              intends to recognize <b>remote escape-room-like experiences</b>. We leave "escape-room-like" to the judgment
              of nominators and voters, but we suggest entries in this category honor the spirit of a time bounded, guided
              cooperative team journey of discovery and problem-solving through a (real or fictitious) space, in addition to
              being playable from home.
            </div>
            <div>
              Specifically, nominees for online escape rooms should generally fit the 'remote avatar' or 'actor/audio' categories on
              <a href="https://escapethereview.co.uk/location/play-at-home/" target="_blank">Escape the Review</a>.
              Hybrid or genre-busting experiences may be included if they match the spirit described above, but we don't intend
              to rank boxed-game, book-based, print-and-play, or pure-video-game experiences in this award.
            </div>
          </div>
        </li>
        <li>
          Any room you nominate <span *ngIf="settings.hasOnlineRoomCategory()">in either category</span> must have been <b>open
          AND available in English for at least 30 days between November 1, {{year-1}} and October 31, {{year}}.</b> You don't have
          to have played it during that window, but it needs to have been open during that window, and it is also fine if you did
          not play the room in English. For more details on this rule, see the <a routerLink="/faq#eligibility">FAQ section on
          eligibility</a>.
        </li>
        <li>
          <b>You absolutely may not nominate any room with which you have any direct affiliation, past or
          present.</b> This includes owners, designers, employees, investors, consultants, etc.
        </li>
        <li>
          <b>If the same game exists in multiple places, report where <i>you</i> played it.</b> If the game has multiple
          version, report the version(s) <i>you</i> played. We'll figure out how to sort out this issue in the next
          phase of the process.
        </li>
        <li>
          If the same game exists as both an in-person escape game and a online escape game, <b>you can only
          nominate that game if you played the in-person version of the game</b>.
        </li>
        <li>
          <b>Do not game the system</b> and assume any specific room will be nominated by others.  In most cases,
          <b>the rooms you nominate should end up being higher in your rankings than the rooms you chose not to nominate.</b>
        </li>
        <li>
          While you are limited to a maximum number of nominations, <b>do not feel obligated to use them all</b> if you
          feel like you don't have enough rooms that are worthy of this award.
        </li>
        <li>
          Your list will auto-save, but <b>it will not be officially submitted until you hit the Submit Nominations
          button.</b> You will also have the opportunity to change your nominations up until the deadline if you so
          desire.
        </li>
      </ul>
      <button *ngIf="!confirmedRules" mat-raised-button color="primary" (click)="toggleRules()">Confirm</button>
      <button *ngIf="confirmedRules && !hideRules" mat-raised-button color="primary" (click)="toggleRules()">Hide Rules</button>
    </div>
    <div *ngIf="hideRules" class="flex-col justify-center align-center gap-5">
      <span>Need some guidance? Check out the <a href="https://www.youtube.com/watch?v=gDaMt8iQNSk" target="_blank">How to Nominate</a> video.</span>
      <button mat-raised-button color="primary" (click)="toggleRules()">Show Rules</button>
      <b class="flex-default" style="color: red">Nominations are due in {{settings.timeToNominationDeadlineString()}}.</b>
      <b class="flex-default" style="color: red" *ngIf="!user.nominationsSubmitted?.includes(year)">You have not yet submitted.</b>
      <p *ngIf="!confirmedRules || !readyToStart()" class="flex-row justify-center align-center">
        Loading... Please be patient, this can take a little while.
      </p>
    </div>
    <mat-horizontal-stepper #stepper *ngIf="confirmedRules && hideRules && readyToStart()" [linear]=true labelPosition="bottom"
                            (selectionChange)="stepChanged($event, stepper)">
      <mat-step [completed]="firstFormGroup.valid && !firstFormGroup.dirty">
        <form [formGroup]="firstFormGroup" >
          <ng-template matStepLabel>
            Room Counts
          </ng-template>
          <h3>
            Step 1. Update Your Room Counts
          </h3>
          <p>
            If you're here, you've already been approved as a nominator, but to determine exactly how many nominations
            you are allowed this year, we need your latest room counts for both in-person and online games.
          </p>
          <p>
            Please enter your counts <b>as of today</b>, as you can always come back before the deadline and change these
            numbers if you play more. If you don't know an exact count, estimate by rounding <b>down</b> to the nearest
            round number that you have definitely reached.
          </p>
          <p>
            OK, are you ready to get started?  Let's go.
          </p>
          <p>
            <b>First, enter your count for total escape rooms played, including <i>both</i> traditional in-person escape rooms
            <i>and</i> hosted, online escape rooms.</b> If you aren't sure what to count as an escape room or an online
            escape room, consult the <a routerLink="/faq">FAQ</a>.
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Total escape rooms played (as of today)</mat-label>
              <input type="number" matInput formControlName="roomCount" required>
            </mat-form-field>
          </p>
          <p>
            <b>Second, enter your count for total <i>online</i> escape rooms played.</b> This should be a subset of your first
            number, since this is by definition included in the total above. If you aren't sure what to count as an online escape
            room, consult the <a routerLink="/faq">FAQ</a>.
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Total online escape rooms played (as of today)</mat-label>
              <input type="number" matInput formControlName="virtualRoomCount" required [errorStateMatcher]="roomCountErrorMatcher">
              <mat-error *ngIf="firstFormGroup.errors?.invalidRoomCounts">
                Online room count cannot exceed total room count.
              </mat-error>
            </mat-form-field>
          </p>
          <p>
            <button *ngIf="firstFormGroup.dirty" mat-raised-button color="primary" (click)="saveApplicationData()"
                    [disabled]="firstFormGroup.invalid">Save and Next</button>
            <button *ngIf="!firstFormGroup.dirty" mat-raised-button matStepperNext color="primary"
                    [disabled]="firstFormGroup.invalid">Next</button>
          </p>
        </form>
      </mat-step>
      <mat-step [completed]="firstFormGroup.valid && !firstFormGroup.dirty">
        <ng-template matStepLabel>
          Nominate Rooms
        </ng-template>
        <h3>
          Step 2. Nominate Your Top Escape Rooms
        </h3>
        <p>You are eligible to nominate up to {{ maxRegularNoms() }} top escape rooms.</p>
        <p>
          Rooms that already exist in our database can be added by choosing "search" under "Nominate a Room", while new rooms can be added manually
          using "add new".
        </p>
        <p>
          <b>Eligible rooms that won a Top Room award in {{ year - 1 }} will be automatically advanced to the voting round and cannot be nominated.</b>
          These show up in the search view labeled as "Automatic Finalist".
        </p>
        <p>
          If you aren't sure exactly what qualifies as an eligible escape room, revisit "Show Rules" above.
        </p>
        <div *ngIf="regularAutogenComplete && regularAutogenRoomIds?.size" class="info-box flex-col gap-10">
          <div *ngIf="hasAutogeneratedRepeatNoms">
            ⭐ Since you nominated rooms in a previous year, your starting list was autogenerated using your most recent nominations, along
            with any rooms you ranked higher than any you nominated.
          </div>
          <div *ngIf="hasAutogeneratedGapNoms">
            ⭐ One or more rooms that you nominated two or more years ago, but which were then subsequently ineligible until this year, have been added to
            your starting list. This can happen, for instance, for rooms that were closed for an extended time during the pandemic.
          </div>
          <div *ngIf="lastNominatorYear() && hasAutogeneratedSuggestedNoms">
            ⭐ One or more additional nominations have been added for you to consider based on rooms that you ranked highly last year but did not nominate.
          </div>
          <div *ngIf="!lastNominatorYear() && hasAutogeneratedSuggestedNoms">
            ⭐ Since this is your first time nominating, your nomination list has been seeded based on rooms that you ranked highly last year.
          </div>
          <div *ngIf="hasAutomaticFinalistNoms">
            ⭐ Some of the rooms you nominated last year are automatic finalists this year due to winning a Top Room award last year. As these rooms
            cannot be nominated this year, they have <b>not</b> been added to your starting list.
          </div>
          <div *ngIf="hasTooManyRegularNoms()">
            ❗ After autogenerating your nominations, you now have more nominations pending than you are allowed, so you will need to delete some
            before you are able to submit.
          </div>
          <div *ngIf="!allNominationsAreValid()">
            ❗ Some of your autogenerated nominations require additional data before you can submit.
          </div>
        </div>
        <h3 *ngIf="lastNominatorYear() && !regularAutogenComplete" class="flex-row justify-center align-center">Loading...</h3>
        <div *ngIf="!lastNominatorYear() || regularAutogenComplete"
             class="flex-row-reverse flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
          <div class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Pending Nominations {{ numRegularNoms() }} / {{ maxRegularNoms() }}</h3>
            <div *ngIf="hasTooManyRegularNoms()" class="warning-box">
              ❗ You have too many nominations.
            </div>
            <app-nomination *ngFor="let nominationId of regularNominationIds" [nominationId]="nominationId" [showDetails]=true [showAutogenReason]=true
                            [allowEdit]=true class="flex-row justify-center align-stretch"></app-nomination>
          </div>
          <div class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Nominate a Room</h3>
            <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
              <mat-radio-button value="1" color="primary" [checked]="!showingAddNew" (click)="showSearch()">search</mat-radio-button>
              <mat-radio-button value="2" color="primary" [checked]="showingAddNew" (click)="showAddNew()">add new</mat-radio-button>
            </mat-radio-group>
            <app-nomination *ngIf="showingAddNew" [allowEdit]=true [category]="Category.TOP_ROOM"
                            class="flex-row justify-center align-stretch"></app-nomination>
            <ng-container *ngIf="!showingAddNew">
              <ng-container *ngIf="regularRooms | roomFilter:'':'':secondFormGroup.value.searchText as filteredRooms">
                <form [formGroup]="secondFormGroup" >
                  <mat-form-field appearance="fill">
                    <mat-label>Search for room, company, or location</mat-label>
                    <input type="string" matInput formControlName="searchText">
                    <mat-hint *ngIf="!secondFormGroup.value.searchText" class="flex-row justify-center align-center">
                      {{ regularRooms.length }} total rooms to search
                    </mat-hint>
                    <mat-hint *ngIf="secondFormGroup.value.searchText" class="flex-row justify-center align-center">
                      {{ filteredRooms.length }} result<ng-container *ngIf="filteredRooms.length !== 1">s</ng-container>
                      found<ng-container *ngIf="filteredRooms.length > MAX_SEARCH_RESULTS">, showing first {{ MAX_SEARCH_RESULTS }}</ng-container>
                    </mat-hint>
                  </mat-form-field>
                </form>
                <ng-container *ngIf="secondFormGroup.value.searchText">
                  <app-room *ngFor="let room of filteredRooms | slice:0:MAX_SEARCH_RESULTS" [room]="room" [allowSelect]=true [nominate]="this"
                            [selectLabel]="roomNominateLabel(room)" [unselectLabel]="roomNominateLabel(room)" [selected]="!canNominateRoom(room)"
                            [disableSelect]="!canNominateRoom(room)" class="flex-row justify-start align-stretch"></app-room>
                  <div *ngIf="filteredRooms.length === 0" class="flex-row justify-center align-center">
                    <button mat-raised-button color="primary" (click)="showAddNew()">Add New Room</button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <p>
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </p>
      </mat-step>
      <mat-step *ngIf="settings.hasOnlineRoomCategory()" [completed]="firstFormGroup.valid && !firstFormGroup.dirty">
        <ng-template matStepLabel>
          Nominate Online Rooms
        </ng-template>
        <h3>
          Step 3. Nominate Your Top Online Escape Rooms
        </h3>
        <p *ngIf="maxOnlineNoms() > 1">
          You are eligible to nominate up to {{ maxOnlineNoms() }} online escape rooms.
        </p>
        <p *ngIf="maxOnlineNoms() === 1">
          You are eligible to nominate {{ maxOnlineNoms() }} online escape room.
        </p>
        <p *ngIf="maxOnlineNoms() > 0">
          Rooms that already exist in our database can be added by choosing "search" under "Nominate a Room", while
          new rooms can be added manually using "add new".
        </p>
        <p *ngIf="maxOnlineNoms() > 0">
          If you aren't sure exactly what qualifies as an eligible online escape room, revisit "Show Rules" above.
        </p>
        <p *ngIf="maxOnlineNoms() === 0">
          You are not eligible to nominate any online escape rooms this year.
          <span *ngIf="numOnlineNoms() === 0">Please continue to the next step.</span>
        </p>
        <div *ngIf="lastNominatorYear() && onlineAutogenComplete && onlineAutogenRoomIds?.size" class="info-box flex-col gap-10">
          <div>
            ⭐ Since you nominated rooms in a previous year, your starting list was autogenerated using your most recent nominations, along
            with any rooms you ranked higher than any you nominated.
          </div>
          <div *ngIf="hasTooManyOnlineNoms()">
            As a result, you current have more nominations pending than you are allowed, so you will need to delete some before you are able
            to submit.
          </div>
          <div>
            Your autogenerated nominations may require additional data before you can submit, but it should be much easier than last time.
          </div>
        </div>
        <h3 *ngIf="lastNominatorYear() && !onlineAutogenComplete" class="flex-row justify-center align-center">Loading...</h3>
        <div *ngIf="(maxOnlineNoms() > 0 || numOnlineNoms() > 0) && (!lastNominatorYear() || onlineAutogenComplete)"
             class="flex-row-reverse flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
          <div class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Pending Nominations {{ numOnlineNoms() }} / {{ maxOnlineNoms() }}</h3>
            <div *ngIf="hasTooManyOnlineNoms()" class="warning-box">
              ❗ You have too many nominations.
            </div>
            <app-nomination *ngFor="let nominationId of onlineNominationIds" [nominationId]="nominationId" [showDetails]=true
                            [showAutogenReason]=true [allowEdit]=true class="flex-row justify-center align-stretch"></app-nomination>
          </div>
          <div class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Nominate an Online Room</h3>
            <mat-radio-group class="flex-row flex-wrap justify-center align-center gap-25">
              <mat-radio-button value="1" color="primary" [checked]="!showingAddNew" (click)="showSearch()">search</mat-radio-button>
              <mat-radio-button value="2" color="primary" [checked]="showingAddNew" (click)="showAddNew()">add new</mat-radio-button>
            </mat-radio-group>
            <app-nomination *ngIf="showingAddNew" [allowEdit]=true [category]="Category.TOP_ONLINE_ROOM"
                            class="flex-row justify-center align-stretch"></app-nomination>
            <ng-container *ngIf="!showingAddNew">
              <ng-container *ngIf="onlineRooms | roomFilter:'':'':thirdFormGroup.value.searchText as filteredRooms">
                <form [formGroup]="thirdFormGroup" >
                  <mat-form-field appearance="fill">
                    <mat-label>Search for room, company, or location</mat-label>
                    <input type="string" matInput formControlName="searchText">
                    <mat-hint *ngIf="!thirdFormGroup.value.searchText" class="flex-row justify-center align-center">
                      {{ onlineRooms.length }} total online rooms to search
                    </mat-hint>
                    <mat-hint *ngIf="thirdFormGroup.value.searchText" class="flex-row justify-center align-center">
                      {{ filteredRooms.length }} result<ng-container *ngIf="filteredRooms.length !== 1">s</ng-container>
                      found<ng-container *ngIf="filteredRooms.length > MAX_SEARCH_RESULTS">, showing first {{ MAX_SEARCH_RESULTS }}</ng-container>
                    </mat-hint>
                  </mat-form-field>
                </form>
                <ng-container *ngIf="thirdFormGroup.value.searchText">
                  <app-room *ngFor="let room of filteredRooms | slice:0:MAX_SEARCH_RESULTS" [room]="room" [allowSelect]=true [nominate]="this"
                            [selectLabel]="roomNominateLabel(room)" [unselectLabel]="roomNominateLabel(room)" [selected]="!canNominateRoom(room)"
                            [disableSelect]="!canNominateRoom(room)" class="flex-row justify-start align-stretch"></app-room>
                  <div *ngIf="filteredRooms.length === 0" class="flex-row justify-center align-center">
                    <button mat-raised-button color="primary" (click)="showAddNew()">Add New Room</button>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <p>
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button mat-raised-button matStepperNext color="primary">Next</button>
        </p>
      </mat-step>
      <mat-step [completed]="allNominationsAreValid()">
        <ng-template matStepLabel>
          Review and Submit
        </ng-template>
        <h3>
          Step {{ settings.hasOnlineRoomCategory() ? 4 : 3 }}. Review Your Nominations
        </h3>
        <p *ngIf="allNominationsAreValid()">
          It looks like everything is in order. Please review below to ensure that your nominations are as you intend.
        </p>
        <p *ngIf="allNominationsAreValid()">
          Once you submit, you will still be allowed to reopen your nominations and make changes up until the deadline
          of {{ settings.nominationDeadlineString() }}.
        </p>
        <p *ngIf="!allNominationsAreValid()">
          You have some issues with your nominations that need to be addressed before you can submit.
        </p>
        <div class="flex-row flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
          <div class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Top Room Nominations ({{ numRegularNoms() }} / {{ maxRegularNoms() }})</h3>
            <div *ngIf="hasTooManyRegularNoms()" class="warning-box justify-center align-center">
              ❗ You have too many nominations.
            </div>
            <app-nomination *ngFor="let nominationId of regularNominationIds" [nominationId]="nominationId" [showDetails]=true
                            [allowEdit]=true class="flex-row justify-center align-stretch"></app-nomination>
          </div>
          <div *ngIf="settings.hasOnlineRoomCategory() && (maxOnlineNoms() > 0 || numOnlineNoms() > 0)" class="flex-col flex-default gap-5">
            <h3 class="flex-row justify-center align-center">Top Online Room Nominations ({{ numOnlineNoms() }} / {{ maxOnlineNoms() }})</h3>
            <div *ngIf="hasTooManyOnlineNoms()" class="warning-box justify-center align-center">
              ❗ You have too many nominations.
            </div>
            <app-nomination *ngFor="let nominationId of onlineNominationIds" [nominationId]="nominationId" [showDetails]=true
                            [allowEdit]=true class="flex-row justify-center align-stretch"></app-nomination>
          </div>
        </div>
        <p>
          <button mat-raised-button matStepperPrevious color="primary">Back</button>
          <button [disabled]="!allNominationsAreValid()" mat-raised-button color="primary" (click)="confirmSubmitNominations()">Submit Nominations</button>
        </p>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</section>
