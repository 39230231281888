import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Subscription, debounce, pairwise, startWith, timer } from 'rxjs';

import { TerpecaRoom } from 'src/app/models/room.model';
import { ApplicationStatus, TerpecaUserDisclosure } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getCountriesForRoom } from 'src/app/utils/misc.utils';
import {
    compareEntitiesByLocation, compareEntitiesByName, compareRoomsByCompany, compareUsersByLocation, compareUsersByName,
    compareUsersByRoomCount
} from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-disclosures',
  templateUrl: './disclosures.component.html',
  styleUrl: './disclosures.component.css'
})
export class DisclosuresComponent implements OnInit, OnDestroy {
  year = environment.currentAwardYear;
  Status = ApplicationStatus;
  rooms: TerpecaRoom[];
  users: TerpecaUserDisclosure[];
  userCountryMap = new Map<string, number>();
  roomCountryMap = new Map<string, number>();
  roomMap = new Map<string, TerpecaRoom>();
  rankingUserIdsMap = new Map<string, string[]>();
  userMap = new Map<string, TerpecaUserDisclosure>();
  userCountries: string[];
  roomCountries: string[];

  loadingInProgress = false;

  roomFormGroup: UntypedFormGroup;
  roomSortFn: (a: TerpecaRoom, b: TerpecaRoom) => number;
  roomLocationSort = compareEntitiesByLocation;
  roomNameSort = compareEntitiesByName;
  roomCompanySort = compareRoomsByCompany;
  roomCountry = '';
  roomSearchText = '';

  userFormGroup: UntypedFormGroup;
  userSortFn: (a: TerpecaUserDisclosure, b: TerpecaUserDisclosure) => number;
  userLocationSort = compareUsersByLocation;
  userNameSort = compareUsersByName;
  userRoomCountSort = compareUsersByRoomCount;
  userCountry = '';
  userSearchText = '';

  showRooms = false;

  private subscriptions: Subscription[] = [];

  constructor(public auth: AuthService, private db: AngularFirestore, public settings: SettingsService) {
    this.roomFormGroup = new UntypedFormGroup({ country: new UntypedFormControl(auth.currentUser.country || ''),
                                                searchText: new UntypedFormControl('') });
    this.roomCountry = this.roomFormGroup.value.country;
    this.roomSortFn = this.roomLocationSort;
    this.userFormGroup = new UntypedFormGroup({ country: new UntypedFormControl(auth.currentUser.country || ''),
                                                searchText: new UntypedFormControl('') });
    this.userCountry = this.userFormGroup.value.country;
    this.userSortFn = this.userLocationSort;
    if (auth.currentUser.country) {
      this.userCountryMap.set(auth.currentUser.country, 0);
      this.roomCountryMap.set(auth.currentUser.country, 0);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-async-lifecycle-method
  async ngOnInit() {
    await this.loadRooms();
    await this.loadUsers();
    this.subscriptions.push(this.roomFormGroup.valueChanges.pipe(
      startWith(this.roomFormGroup.value),
      pairwise(),
      debounce(([previous, current]) => (
        // eslint-disable-next-line no-constant-binary-expression
        (this.loadingInProgress = true) &&
        previous.searchText !== current.searchText ? timer(500) : timer(0)))
    ).subscribe(async () => {
      this.roomCountry = this.roomFormGroup.value.country;
      this.roomSearchText = this.roomFormGroup.value.searchText;
      this.loadingInProgress = false;
    }));
    this.subscriptions.push(this.userFormGroup.valueChanges.pipe(
      startWith(this.userFormGroup.value),
      pairwise(),
      debounce(([previous, current]) => (
        // eslint-disable-next-line no-constant-binary-expression
        (this.loadingInProgress = true) &&
        previous.searchText !== current.searchText ? timer(500) : timer(0)))
    ).subscribe(async () => {
      this.userCountry = this.userFormGroup.value.country;
      this.userSearchText = this.userFormGroup.value.searchText;
      this.loadingInProgress = false;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  async loadRooms() {
    await this.db.collection<TerpecaRoom>('rooms').ref
    .where('isNominee', 'array-contains', this.year).get()
    .then((snapshot: QuerySnapshot<TerpecaRoom>) => {
      const rooms: TerpecaRoom[] = [];
      for (const doc of snapshot.docs) {
        const room: TerpecaRoom = doc.data();
        room.docId = doc.id;
        rooms.push(room);
        this.roomMap.set(room.docId, room);
        const countries = getCountriesForRoom(room);
        for (const country of countries) {
          this.roomCountryMap.set(country, (this.roomCountryMap.get(country) || 0) + 1);
        }
      }
      this.roomCountries = Array.from(this.roomCountryMap.keys()).sort();
      this.rooms = rooms;
    });
  }

  async loadUsers() {
    await this.db.collection<TerpecaUserDisclosure>('disclosures').ref
    .where('year', '==', this.year)
    .where('isContributor', '==', true).get()
    .then((snapshot: QuerySnapshot<TerpecaUserDisclosure>) => {
      const users: TerpecaUserDisclosure[] = [];
      for (const doc of snapshot.docs) {
        const user: TerpecaUserDisclosure = doc.data();
        users.push(user);
        this.userMap.set(user.userId, user);
        this.userCountryMap.set(user.country, (this.userCountryMap.get(user.country) || 0) + 1);
        if (user.rankingSubmitted) {
          const rankedRoomIds: string[] = (user.rankedRoomIds || []).concat(user.rankedOnlineRoomIds || []);
          for (const roomId of rankedRoomIds) {
            if (this.rankingUserIdsMap.has(roomId)) {
              this.rankingUserIdsMap.get(roomId).push(user.userId);
            } else {
              this.rankingUserIdsMap.set(roomId, [user.userId]);
            }
          }
        }
      }
      this.userCountries = Array.from(this.userCountryMap.keys()).sort();
      this.users = users;
    });
  }

  ready() {
    return this.rooms && this.users;
  }

  roomCountryOptionLabel(country: string) {
    if (this.roomCountryMap.has(country)) {
      return `${country} (${this.roomCountryMap.get(country)})`;
    }
    return `${country} (0)`;
  }

  userCountryOptionLabel(country: string) {
    if (this.userCountryMap.has(country)) {
      return `${country} (${this.userCountryMap.get(country)})`;
    }
    return `${country} (0)`;
  }
}
