import app from 'firebase/compat/app';

import { TerpecaRankedEntity } from './results.model';

// Types of entity being considered (these are all treated as "rooms")
export enum TerpecaCategory {
  TOP_ROOM,        // IRL escape game
  TOP_COMPANY,     // Company that makes games
  TOP_ONLINE_ROOM  // Online escape game
}

// How horror-ific a room is. Sync with:
//  src/app/views/horrorlevel/horrorlevel.component.ts
//  src/app/views/horrorlevelpicker/horrorlevelpicker.component.ts
export enum HorrorLevel {
  UNKNOWN,
  NONE,
  SPOOKY,
  PASSIVE,
  ACTIVE
}

export const allHorrorLevels =
  (<(keyof typeof HorrorLevel)[]>Object.keys(HorrorLevel))
    .filter(key => isNaN(Number(key)) && HorrorLevel[key] > HorrorLevel.UNKNOWN)
    .map(key => HorrorLevel[key]);

// Basic information about a particular room (escape game) or company, used in both
// nominations (nomination.model.ts) and in confirmed room entries (below).
export interface RoomDescription {
  category?: TerpecaCategory;
  name: string;  // required for actual rooms, ignored when this is a company entity
  englishName?: string;
  company: string;
  city: string;
  state?: string;
  country: string;
  link: string;
  englishLink?: string;
  email?: string;
  languages?: string[];  // list of ISO 639-1 codes
  horrorLevel?: HorrorLevel;
}

// A confirmed room entry, kept in the Firestore 'rooms' collection,
// with basic metadata (RoomDescription) and status/workflow info.
// Note that even though it is poorly named, this same entity is also used for company entities.
export type TerpecaRoom = RoomDescription & {
  docId?: string;
  createTime?: app.firestore.Timestamp;

  // Nominations for this room across all years
  nominations: NominationId[];

  // Review history
  ineligibilityReason?: string;
  reviewerNotes?: string; // obsolete
  auditLogEntry?: RoomAuditLogEntry;
  auditLogHistory?: RoomAuditLogEntry[];

  // Review status decisions for each year
  isConfirmedOpen?: number[];
  isConfirmedEnglish?: number[];
  isApproved?: number[];
  isIneligible?: number[];
  isNominee?: number[];
  isFinalist?: number[];
  isWinner?: number[];
  isPermanentlyIneligible?: number[];

  // Detailed results data keyed by year.
  resultsData?: {
    [key: number]: TerpecaRankedEntity;
  };

  // Disclosed affiliations (conflicts of interest)
  affiliatedUserIds?: string[];
};

export interface NominationId {
  year: number;
  nominationId: string;
}

export interface RoomAuditLogEntry {
  entryType: RoomAuditLogEntryType;
  uid?: string;
  name?: string;
  timestamp?: app.firestore.Timestamp;
  year?: number;
  note?: string;
}

export enum RoomAuditLogEntryType {
  LEGACY_NOTES,
  UPDATED,
  APPROVED,
  UNAPPROVED,
  DISQUALIFIED,
  UNDISQUALIFIED,
  FINALIZED,
  UNFINALIZED,
  MARKED_CLOSED,
  MARKED_REOPENED
}

export function getRoomAuditLogString(entry: RoomAuditLogEntry | undefined) {
  let description = '';
  if (!entry) {
    return description;
  }
  switch (entry.entryType) {
    case RoomAuditLogEntryType.LEGACY_NOTES:
      return entry.note;
    case RoomAuditLogEntryType.UPDATED:
      break;
    case RoomAuditLogEntryType.APPROVED:
      description = `approved`;
      break;
    case RoomAuditLogEntryType.UNAPPROVED:
      description = `unapproved`;
      break;
    case RoomAuditLogEntryType.DISQUALIFIED:
      description = `disqualified`;
      break;
    case RoomAuditLogEntryType.UNDISQUALIFIED:
      description = `undisqualified`;
      break;
    case RoomAuditLogEntryType.FINALIZED:
      description = `finalized`;
      break;
    case RoomAuditLogEntryType.UNFINALIZED:
      description = `unfinalized`;
      break;
    case RoomAuditLogEntryType.MARKED_CLOSED:
      description = `marked permanently closed`;
      break;
    case RoomAuditLogEntryType.MARKED_REOPENED:
      description = `marked reopened`;
      break;
  }
  if (entry.entryType !== RoomAuditLogEntryType.UPDATED) {
    description = `${description} for ${entry.year}`;
  }
  if (entry.note) {
    description = description.length > 0 ? `${description} - ${entry.note}` : entry.note;
  }
  if (entry.timestamp) {
    description = `${description} - ${entry.name} ${entry.timestamp.toDate().toLocaleDateString()}`;
  }
  return description;
}
