import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { TerpecaRanking } from 'src/app/models/ranking.model';
import { TerpecaCategory } from 'src/app/models/room.model';
import {
    ApplicationStatus, TerpecaUser, UserAuditLogEntryType, getIpAddressesFromAuditLog, getStatusString, getUserAuditLogString
} from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getLocationString } from 'src/app/utils/misc.utils';
import { RequestResubmitDialogComponent } from 'src/app/views/requestresubmitdialog/requestresubmitdialog.component';
import { environment } from 'src/environments/environment';

import { ApproveComponent } from '../approve/approve.component';
import { SimpleTextInputDialogComponent } from '../simpletextinputdialog/simpletextinputdialog.component';

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrl: './applicant.component.css'
})
export class ApplicantComponent {
  @Input() parent: ApproveComponent;
  @Input() reviewer: TerpecaUser;
  @Input() user: TerpecaUser;
  nominations: TerpecaNomination[];
  onlineNominations: TerpecaNomination[];
  rankings: TerpecaRanking;
  onlineRankings: TerpecaRanking;
  collisions: Map<string, TerpecaUser>;
  private subscriptions: Subscription[] = [];

  Status = ApplicationStatus;
  getStatusString = getStatusString;
  getUserAuditLogString = getUserAuditLogString;
  getLocationString = getLocationString;
  year = environment.currentAwardYear;

  constructor(private auth: AuthService, public settings: SettingsService, private db: AngularFirestore,
              private dialog: MatDialog) { }

  panelOpened() {
    this.subscriptions.push(this.db.doc<TerpecaUser>(`users/${this.user.uid}`)
      .valueChanges().subscribe((user: TerpecaUser) => {
        this.user = user;
        this.collisions = new Map<string, TerpecaUser>();
        if (this.user.status === ApplicationStatus.PENDING || this.user.upgradeRequested) {
          const tuplesToCheck = [];
          tuplesToCheck.push(['realName', [this.user.realName]]);
          const emails = [this.user.email];
          if (this.user.appEmail) {
            emails.push(this.user.appEmail);
          }
          tuplesToCheck.push(['email', emails]);
          tuplesToCheck.push(['appEmail', emails]);
          if (this.user.facebookUsername) {
            tuplesToCheck.push(['facebookUsername', [this.user.facebookUsername]]);
          }
          if (this.user.instagramUsername) {
            tuplesToCheck.push(['instagramUsername', [this.user.instagramUsername]]);
          }
          if (this.user.discordUsername) {
            tuplesToCheck.push(['discordUsername', [this.user.discordUsername]]);
          }
          if (this.user.escapeTheReviewUserId) {
            tuplesToCheck.push(['escapeTheReviewUserId', [this.user.escapeTheReviewUserId]]);
          }
          if (this.user.escapeAllGrUserId) {
            tuplesToCheck.push(['escapeAllGrUserId', [this.user.escapeAllGrUserId]]);
          }
          if (this.user.escapeGameFrUserId) {
            tuplesToCheck.push(['escapeGameFrUserId', [this.user.escapeGameFrUserId]]);
          }
          if (this.user.escapeRoomersUserId) {
            tuplesToCheck.push(['escapeRoomersUserId', [this.user.escapeRoomersUserId]]);
          }
          if (this.user.escapeTalkNlUserId) {
            tuplesToCheck.push(['escapeTalkNlUserId', [this.user.escapeTalkNlUserId]]);
          }
          if (this.user.lockMeUserId) {
            tuplesToCheck.push(['lockMeUserId', [this.user.lockMeUserId]]);
          }
          if (this.user.mortyAppUsername) {
            tuplesToCheck.push(['mortyAppUsername', [this.user.mortyAppUsername]]);
          }
          if (this.user.theEscapersUserId) {
            tuplesToCheck.push(['theEscapersUserId', [this.user.theEscapersUserId]]);
          }
          const ipsToCheck = [];
          if (this.user.ipAddresses?.length) {
            ipsToCheck.push(...this.user.ipAddresses);
          } else {
            ipsToCheck.push(...getIpAddressesFromAuditLog(this.user));
          }
          if (ipsToCheck.length) {
            const fieldName = 'ipAddresses';
            this.subscriptions.push(this.db.collection<TerpecaUser>('users',
                    ref => ref.where(fieldName, 'array-contains-any', ipsToCheck)
                              .where('status', '>=', ApplicationStatus.PENDING))
              .valueChanges().subscribe((value: TerpecaUser[]) => {
                // We filter out this user's ID to prevent it from showing up as its own duplicate.
                value = value?.filter(u => u.uid !== this.user.uid) || [];
                if (value.length) {
                  this.collisions[fieldName] = value;
                }
              })
            );
            tuplesToCheck.push(['auditLogEntry.ipAddress', ipsToCheck]);
          }
          for (const tuple of tuplesToCheck) {
            this.subscriptions.push(this.db.collection<TerpecaUser>('users',
                    ref => ref.where(tuple[0], 'in', tuple[1])
                              .where('status', '>=', ApplicationStatus.PENDING))
              .valueChanges().subscribe((value: TerpecaUser[]) => {
                // We filter out this user's ID to prevent it from showing up as its own duplicate.
                value = value?.filter(u => u.uid !== this.user.uid) || [];
                if (tuple[0] === 'auditLogEntry.ipAddress') {
                  // We make sure that any IP address hits belong to the applicant and not the reviewer.
                  value = value?.filter(u => u.auditLogEntry.uid === u.uid);
                }
                if (value.length) {
                  this.collisions[tuple[0]] = value;
                }
              })
            );
          }
        }
      })
    );
    if (this.reviewer && this.reviewer.isOwner) {
      this.subscriptions.push(this.db.collection<TerpecaNomination>('nominations',
            ref => ref.where('year', '==', this.year)
                      .where('userId', '==', this.user.uid)
                      .where('category', '==', TerpecaCategory.TOP_ROOM)).valueChanges({ idField: 'docId' })
          .subscribe((value: TerpecaNomination[]) => {
            this.nominations = value;
          })
      );
      this.subscriptions.push(this.db.collection<TerpecaNomination>('nominations',
            ref => ref.where('year', '==', this.year)
                      .where('userId', '==', this.user.uid)
                      .where('category', '==', TerpecaCategory.TOP_ONLINE_ROOM)).valueChanges({ idField: 'docId' })
          .subscribe((value: TerpecaNomination[]) => {
            this.onlineNominations = value;
          })
      );
      this.subscriptions.push(this.db.doc<TerpecaRanking>(`rankings/${this.user.uid}-${this.year}-${TerpecaCategory.TOP_ROOM}`)
        .valueChanges().subscribe((rankings: TerpecaRanking) => {
          this.rankings = rankings;
        })
      );
      this.subscriptions.push(this.db.doc<TerpecaRanking>(`rankings/${this.user.uid}-${this.year}-${TerpecaCategory.TOP_ONLINE_ROOM}`)
        .valueChanges().subscribe((rankings: TerpecaRanking) => {
          this.onlineRankings = rankings;
        })
      );
    }
  }

  getSharedIps(u1: TerpecaUser, u2: TerpecaUser) {
    return (u1.ipAddresses || []).filter(value => (u2.ipAddresses || []).includes(value));
  }

  panelClosed() {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
    this.subscriptions = [];
  }

  pastYears() {
    return this.settings.allYears.filter(key => key < this.year);
  }

  hasPastExperience() {
    for (const y of this.pastYears()) {
      if (this.user.nominationsSubmitted?.includes(y) || this.user.rankingsSubmitted?.includes(y)) {
        return true;
      }
    }
    return false;
  }

  async setStatus(status: ApplicationStatus) {
    await this.auth.setApplicationStatus(this.user.uid, status);
    this.parent.refreshData();
  }

  requestResubmit() {
    const data: any = {
      applicant: this
    };
    this.dialog.open(RequestResubmitDialogComponent, { data });
  }

  resubmitRequested() {
    this.parent.refreshData();
  }

  showAddReviewNoteDialog() {
    const data: any = {
      title: 'Add Review Note',
      instructions: 'Add a private note about this applicant regarding their current status in the review process. This note will only be visible to other reviewers.',
      callback: async (note: string) => { await this.addReviewNote(note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showAddVouchDialog() {
    const data: any = {
      title: 'Add Vouch',
      instructions: `
      By vouching for this person, it means that you firmly believe, based on evidence, that:
      <ul>
        <li>they are a real person representing themself accurately</li>
        <li>they have played the number and type of rooms that they claim</li>
        <li>they have clearly disclosed any conflicts of interest and listed any relevant affiliations in their bio by name</li>
        <li>all of their public-facing data (name, city, bio) is complete, accurate, and properly formatted</li>
      </ul>
      Please indicate how you verified this person's identity and room count.`,
      callback: async (note: string) => { await this.addVouch(note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showAddUpgradeVouchDialog() {
    const data: any = {
      title: 'Add Vouch',
      instructions: 'Please indicate how you verified this person\'s room count to justify their upgrade to nominator status.',
      callback: async (note: string) => { await this.addUpgradeVouch(note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showRemoveVouchDialog() {
    const data: any = {
      title: 'Remove Vouch',
      instructions: 'Please indicate why you are removing your vouch.',
      callback: async (note: string) => { await this.removeVouch(note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showRemoveUpgradeVouchDialog() {
    const data: any = {
      title: 'Remove Upgrade Vouch',
      instructions: 'Please indicate why you are removing your upgrade vouch.',
      callback: async (note: string) => { await this.removeUpgradeVouch(note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showRevokeNominationsStatusDialog() {
    const data: any = {
      title: 'Revoke Nominations',
      instructions: 'Please indicate why you are revoking nominations for this user.',
      callback: async (note: string) => {
        await this.addReviewNote(note);
        await this.reopenNominations();
      }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showRevokeRankingsStatusDialog() {
    const data: any = {
      title: 'Revoke Rankings',
      instructions: 'Please indicate why you are revoking rankings for this user.',
      callback: async (note: string) => {
        await this.addReviewNote(note);
        await this.reopenRankings();
      }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  showUpdateShadowbanStatusDialog(on: boolean) {
    const data: any = {
      title: on ? 'Add Shadow Ban' : 'Remove Shadow Ban',
      instructions: `Please indicate why you are ${ on ? 'adding' : 'removing'} a shadow ban for this user.`,
      callback: async (note: string) => { await this.updateShadowbanStatus(on, note); }
    };
    this.dialog.open(SimpleTextInputDialogComponent, { data });
  }

  async addReviewNote(note: string) {
    await this.auth.addReviewNote(this.user.uid, note);
  }

  hasPendingReviewNote() {
    return this.user.auditLogEntry?.entryType === UserAuditLogEntryType.REVIEW_NOTE_ADDED;
  }

  async addVouch(note: string) {
    await this.auth.addVouch(this.user.uid, note);
  }

  async removeVouch(note: string) {
    await this.auth.removeVouch(this.user.uid, note);
  }

  async addUpgradeVouch(note: string) {
    await this.auth.addUpgradeVouch(this.user.uid, note);
  }

  async removeUpgradeVouch(note: string) {
    await this.auth.removeUpgradeVouch(this.user.uid, note);
  }

  async updateShadowbanStatus(on: boolean, note: string) {
    await this.auth.setShadowBan(this.user.uid, on, note);
  }

  canChangeNominationStatus() {
    return this.auth.canChangeNominationStatus(this.user);
  }

  async submitNominations() {
    if (!this.canChangeNominationStatus()) {
      return;
    }
    this.auth.submitNominations(this.user);
  }

  async reopenNominations() {
    if (!this.canChangeNominationStatus()) {
      return;
    }
    this.auth.reopenNominations(this.user);
  }

  canChangeRankingStatus() {
    return this.auth.canChangeRankingStatus(this.user);
  }

  async submitRankings() {
    if (!this.canChangeRankingStatus()) {
      return;
    }
    this.auth.submitRankings(this.user);
  }

  async reopenRankings() {
    if (!this.canChangeRankingStatus()) {
      return;
    }
    this.auth.reopenRankings(this.user);
  }
}
