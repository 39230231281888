<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content [formGroup]="form">
  <div [innerHtml]="data.instructions"></div>
  <mat-form-field appearance="fill">
    <textarea matInput formControlName="textData" required
              cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid">
    Submit
  </button>
</div>
