<span *ngIf="pref">
  <span *ngIf="!showShortText && !showText" matTooltip="{{text()}}">
    😱:{{emoji()}}
  </span>
  <span *ngIf="showShortText">
    {{emoji()}} {{shortText()}}
  </span>
  <span *ngIf="showText">
    {{emoji()}} {{text()}}
  </span>
</span>
