<section *ngIf="auth.currentUser as user">
  <div *ngIf="user.status >= Status.REVIEWER" class="flex-col justify-center align-stretch">
    <div class="header">
      <h3 class="flex-row justify-center align-center">
        {{ year }} Nomination Processing Dashboard
      </h3>
      <div *ngIf="!ready" class="body flex-col justify-center align-center">
        Loading...
      </div>
      <div *ngIf="ready" class="flex-row flex-wrap justify-center align-center gap-25">
        <form [formGroup]="filterFormGroup" class="flex-row flex-wrap justify-center align-center gap-25">
          <div class="search-and-filter-section flex-col justify-center align-center gap-5">
            <div class="flex-row justify-center align-center gap-10">
              <div>region:</div>
              <select formControlName="region">
                <option *ngFor="let r of regions" [ngValue]=r>{{ optionLabelForRegion(r) }}</option>
              </select>
            </div>
            <div class="flex-row justify-center align-center gap-10">
              <div>filter&nbsp;by&nbsp;room,&nbsp;company,&nbsp;or&nbsp;location:</div>
              <input class="search-box" type="string" matInput formControlName="searchText">
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="ready && canProcessRawNominations()" class="flex-col gap-5">
        <div *ngIf="numUnprocessedNominations > 0" class="selections flex-row flex-wrap justify-center align-start gap-0-sm gap-10-gt-sm">
          <div class="flex-col gap-5">
            <div class="flex-row justify-center align-center"><strong>Nominations</strong>&nbsp;({{ numSelectedNominations() }})</div>
            <div *ngIf="numSelectedNominations() === 0" class="flex-row justify-center align-center">- none -</div>
            <div *ngFor="let nomination of getSelectedNominations()" class="flex-row justify-center align-center">{{ nomination.room }}</div>
          </div>
          <div class="flex-col gap-5">
            <div class="flex-row justify-center align-center"><strong>Room</strong></div>
            <div *ngIf="hasSelectedRoom()" class="flex-row justify-center align-center">{{ getSelectedRoomName() }}</div>
            <button *ngIf="!hasSelectedRoom()" mat-raised-button color="primary" class="flex-row justify-center align-center"
                    [disabled]="numSelectedNominations() === 0" (click)="generateNewRoom()">
              Generate New Room
            </button>
            <button *ngIf="hasSelectedRoom()" mat-raised-button color="primary" class="flex-row justify-center align-center"
                    [disabled]="numSelectedNominations() === 0" (click)="addNominationsToRoom()">
              Add Nominations To Room
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="ready && loadingInProgress" class="flex-col justify-center align-center">
      Loading...
    </div>
    <div *ngIf="ready && !loadingInProgress" class="body flex-row flex-wrap justify-space-around align-start gap-0-sm gap-10-gt-sm">
      <div *ngIf="canProcessRawNominations() && (unprocessedNominations | roomFilter:region:'':searchText) as filteredResults"
           class="match-column flex-col flex-default gap-5">
        <div class="nom-column-title flex-row justify-center align-center"><strong>Unprocessed Nominations</strong>&nbsp;
           {{ '(' + numUnprocessedNominations + '/' + (numUnprocessedNominations + numProcessedNominations) + ')' }}
        </div>
        <div *ngIf="region?.length || searchText" class="flex-row justify-center align-center">
          <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
        </div>
        <app-nomination *ngFor="let nomination of filteredResults"
                        [nomination]="nomination" [matcher]="this" [allowSelect]="canProcessRawNominations()" [showDetails]=true
                        [selected]="isSelectedNomination(nomination.docId)" class="flex-row justify-center align-stretch"></app-nomination>
      </div>
      <div *ngIf="(unprocessedRooms | roomFilter:region:'':searchText:true) as filteredResults"
           class="match-column flex-col flex-default gap-5">
        <div class="nom-column-title">
          <div class="flex-row justify-center align-center">
            <strong>Unprocessed Rooms</strong>&nbsp;
            {{ '(' + unprocessedRooms?.length + '/' + (processedRooms?.length + unprocessedRooms?.length) + ')' }}
          </div>
          <div *ngIf="canProcessRawNominations()" class="flex-row justify-center align-center">
            <strong>Processed Nominations</strong>&nbsp;
            {{ '(' + numAssignedNominations + '/' + (numUnprocessedNominations + numAssignedNominations) + ')' }}
          </div>
        </div>
        <div *ngIf="region?.length || searchText" class="flex-row justify-center align-center">
          <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
        </div>
        <app-room *ngFor="let room of filteredResults" [showNotes]=true
                  [allowProcess]="!numSelectedNominations() && !hasSelectedRoom()"
                  [room]="room" [matcher]="this" [allowSelect]="canProcessRawNominations() && numUnprocessedNominations > 0" [allowEdit]=true
                  [selected]="isSelectedRoom(room.docId)" class="flex-row justify-center align-stretch"></app-room>
      </div>
      <div *ngIf="(processedRooms | roomFilter:region:'':searchText:true) as filteredResults"
           class="match-column flex-col flex-default gap-5">
        <div class="nom-column-title">
          <div *ngIf="!(unprocessedRooms?.length > 0)" class="flex-row justify-center align-center">
            <strong> Processed Nominations</strong>&nbsp;
            {{ '(' + numAssignedNominations + '/' + (numUnprocessedNominations + numAssignedNominations) + ')' }}
          </div>
          <div class="flex-row justify-center align-center">
            <strong>Processed Rooms</strong>&nbsp;{{ '(' + processedRooms?.length + '/' + (processedRooms?.length + unprocessedRooms?.length) + ')' }}
          </div>
          <div class="flex-row justify-center align-center">
            ✅&nbsp;<strong>Approved Rooms</strong>&nbsp;({{ numEligibleRooms + '/' + processedRooms?.length }})
          </div>
          <div class="flex-row justify-center align-center">
            ❌&nbsp;<strong>Disqualified Rooms</strong>&nbsp;({{ numIneligibleRooms + '/' + processedRooms?.length }})
          </div>
          <div *ngIf="settings.isPastNominationDeadline()" class="flex-row justify-center align-center">
            <strong>Finalized Rooms</strong>&nbsp;{{ '(' + numFinalizedRooms + '/' + numEligibleRooms + ')' }}
          </div>
          <div *ngIf="settings.isPastNominationDeadline()" class="flex-row justify-center align-center">
            🎗️&nbsp;<strong>Nominated Rooms</strong>&nbsp;({{ numNominatedRooms + '/' + numFinalizedRooms }})
          </div>
          <div *ngIf="settings.isPastNominationDeadline()" class="flex-row justify-center align-center">
            🏅&nbsp;<strong>Finalist Rooms</strong>&nbsp;({{ numFinalists + '/' + numNominatedRooms }})
          </div>
        </div>
        <div *ngIf="region?.length || searchText" class="flex-row justify-center align-center">
          <i>showing {{ filteredResults.length }} match{{ filteredResults.length !== 1 ? 'es' : ''}} for current filters</i>
        </div>
        <app-room *ngFor="let room of filteredResults" [showNotes]=true
                  [room]="room" [matcher]="this" [allowSelect]="canProcessRawNominations() && numUnprocessedNominations > 0" [allowEdit]=true
                  [allowProcess]="!numSelectedNominations() && !hasSelectedRoom()"
                  [selected]="isSelectedRoom(room.docId)" class="flex-row justify-center align-stretch"></app-room>
      </div>
    </div>
  </div>
</section>
