import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-simpletextinputdialog',
  templateUrl: './simpletextinputdialog.component.html',
  styleUrl: './simpletextinputdialog.component.css'
})
export class SimpleTextInputDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<SimpleTextInputDialogComponent>, private auth: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      textData: new UntypedFormControl('', Validators.required)
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }
    await this.data.callback(this.form.value.textData);
    this.dialogRef.close(true);
  }
}
