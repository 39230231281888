<h1 mat-dialog-title>Confirm {{ data.category }}</h1>
<mat-dialog-content>
  <p *ngIf="data.roomList.length === 0 && data.confirmNoneInstructions">
    {{ data.confirmNoneInstructions }}
  </p>
  <p *ngIf="data.roomList.length > 0 && data.confirmInstructions">
    {{ data.confirmInstructions }}
  </p>
  <div *ngIf="data.numbered">
    <div *ngFor="let room of data.roomList; let i = index">
      {{ i+1 }}. <app-simpleroom [room]=room></app-simpleroom>
    </div>
  </div>
  <div *ngIf="!data.numbered">
    <div *ngFor="let room of data.roomList">
      <app-simpleroom [room]=room></app-simpleroom>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="secondary" (click)="cancel()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="confirm()">
    Confirm
  </button>
</div>
