import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import app from 'firebase/compat/app';
import { Subscription, debounce, pairwise, startWith, timer } from 'rxjs';

import { TerpecaNomination } from 'src/app/models/nomination.model';
import { NominationId, TerpecaRoom } from 'src/app/models/room.model';
import { ApplicationStatus, TerpecaUser } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getCountriesForRoom, getNominationCount } from 'src/app/utils/misc.utils';
import { compareEntitiesByLocation } from 'src/app/utils/sorting.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrl: './match.component.css'
})
export class MatchComponent implements OnInit, OnDestroy {
  year = environment.currentAwardYear;
  Status = ApplicationStatus;
  regions: string[];
  unprocessedNominations: TerpecaNomination[];
  rooms: TerpecaRoom[];
  unprocessedRooms: TerpecaRoom[];
  processedRooms: TerpecaRoom[];

  loadingInProgress = false;
  filterFormGroup: UntypedFormGroup;
  region = '';
  searchText = '';
  numUnprocessedNominations: number = 0;
  numProcessedNominations: number = 0;
  numAssignedNominations: number = 0;
  numEligibleRooms: number = 0;
  numIneligibleRooms: number = 0;
  numFinalizedRooms: number = 0;
  numNominatedRooms: number = 0;
  numUnprocessedRooms: number = 0;
  numFinalists: number = 0;
  roomsReady = false;
  nomsReady = false;
  regionsReady = false;
  ready = false;

  private countryNomCountMap: Map<string, number>;
  private countryRoomCountMap: Map<string, number>;
  private regionSet = new Set<string>();
  private selectedNominationIds = new Set<string>();
  private selectedRoomId: string = null;
  private subscriptions: Subscription[] = [];

  constructor(public auth: AuthService, public settings: SettingsService, private db: AngularFirestore) {
    this.filterFormGroup = new UntypedFormGroup({
      region: new UntypedFormControl((auth.currentUser.ambassadorCountries || []).join(', ')),
      searchText: new UntypedFormControl('') });
    this.region = this.filterFormGroup.value.region;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterFormGroup.valueChanges.pipe(
        startWith(this.filterFormGroup.value),
        pairwise(),
        debounce(([previous, current]) => (
          // eslint-disable-next-line no-constant-binary-expression
          (this.loadingInProgress = true) &&
          previous.searchText !== current.searchText ? timer(500) : timer(0)))
      ).subscribe(async () => {
        this.region = this.filterFormGroup.value.region;
        this.searchText = this.filterFormGroup.value.searchText;
        this.loadingInProgress = false;
      })
    );
    this.subscriptions.push(
      this.db.collection<TerpecaRoom>('rooms', ref => ref
      .where('category', 'in', this.settings.votedAwardCategories()))
      .valueChanges({ idField: 'docId' }).subscribe((rooms: TerpecaRoom[]) => {
        rooms.sort(compareEntitiesByLocation);
        this.rooms = rooms;
        const unprocessedRooms: TerpecaRoom[] = [];
        const processedRooms: TerpecaRoom[] = [];
        let nomCount = 0;
        let nomineeCount = 0;
        let finalistCount = 0;
        let eligibleCount = 0;
        let ineligibleCount = 0;
        let finalizedCount = 0;
        const countryCountMap = new Map<string, number>();
        for (const room of rooms) {
          const roomNominations = getNominationCount(room, this.year);
          nomCount += roomNominations;
          if (room.country) {
            this.regionSet.add(room.country);
          }
          if (room.isPermanentlyIneligible?.length > 0) {
            if (roomNominations || room.isPermanentlyIneligible.includes(this.year)) {
              ineligibleCount++;
              processedRooms.push(room);
            }
          } else if (room.isIneligible && room.isIneligible.includes(this.year)) {
            ineligibleCount++;
            processedRooms.push(room);
          } else if (room.isFinalist && room.isFinalist.includes(this.year)) {
            finalistCount++;
            eligibleCount++;
            finalizedCount++;
            processedRooms.push(room);
          } else if (room.isNominee && room.isNominee.includes(this.year)) {
            nomineeCount++;
            eligibleCount++;
            finalizedCount++;
            processedRooms.push(room);
          } else if (room.isApproved && room.isApproved.includes(this.year)) {
            eligibleCount++;
            if (roomNominations === 0 && this.settings.isPastNominationDeadline()) {
              finalizedCount++;
            }
            processedRooms.push(room);
          } else {
            unprocessedRooms.push(room);
            const countries = getCountriesForRoom(room);
            for (const country of countries) {
              countryCountMap.set(country, (countryCountMap.get(country) || 0) + 1);
            }
          }
        }
        this.numAssignedNominations = nomCount;
        this.unprocessedRooms = unprocessedRooms;
        this.processedRooms = processedRooms;
        this.numFinalizedRooms = finalizedCount;
        this.numIneligibleRooms = ineligibleCount;
        this.numEligibleRooms = eligibleCount;
        this.numNominatedRooms = nomineeCount + finalistCount;
        this.numUnprocessedRooms = unprocessedRooms.length;
        this.numFinalists = finalistCount;
        this.maybeUpdateRegionsList();
        this.countryRoomCountMap = countryCountMap;
        this.roomsReady = true;
        this.ready = this.nomsReady && this.roomsReady && this.regionsReady;
      }));
    this.subscriptions.push(
      this.db.collection<TerpecaNomination>('nominations', ref => ref
      .where('year', '==', environment.currentAwardYear)
      .where('pending', '==', false))
      .valueChanges({ idField: 'docId' }).subscribe((noms: TerpecaNomination[]) => {
        const countryCountMap = new Map<string, number>();
        noms.sort(compareEntitiesByLocation);
        const unprocessed = [];
        let processedCount = 0;
        let unprocessedCount = 0;
        for (const nomination of noms) {
          if (nomination.country) {
            this.regionSet.add(nomination.country);
          }
          if (this.isNominationProcessed(nomination)) {
            processedCount += 1;
          } else {
            unprocessedCount += 1;
            unprocessed.push(nomination);
            const countries = getCountriesForRoom(nomination);
            for (const country of countries) {
              countryCountMap.set(country, (countryCountMap.get(country) || 0) + 1);
            }
          }
        }
        this.numProcessedNominations = processedCount;
        this.numUnprocessedNominations = unprocessedCount;
        this.unprocessedNominations = unprocessed;
        this.maybeUpdateRegionsList();
        this.countryNomCountMap = countryCountMap;
        this.nomsReady = true;
        this.ready = this.nomsReady && this.roomsReady && this.regionsReady;
      }));
    this.db.collection<TerpecaUser>('users').ref.where('status', '>=', ApplicationStatus.REVIEWER).get()
    .then((snapshot: QuerySnapshot<TerpecaUser>) => {
      snapshot.forEach((doc: QueryDocumentSnapshot<TerpecaUser>) => {
        const user: TerpecaUser = doc.data();
        if (user.ambassadorCountries) {
          this.regionSet.add(user.ambassadorCountries.join(', '));
        }
      });
      this.maybeUpdateRegionsList();
      this.regionsReady = true;
      this.ready = this.nomsReady && this.roomsReady && this.regionsReady;
    });
  }

  maybeUpdateRegionsList() {
    const regionsList = [...this.regionSet];
    regionsList.sort();
    regionsList.push('');
    if (this.regions?.join('|') !== regionsList.join('|')) {
      this.regions = regionsList;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  optionLabelForRegion(region: string) {
    if (region.length === 0) {
      let totalCount = this.numUnprocessedRooms;
      if (this.canProcessRawNominations()) {
        totalCount += this.numUnprocessedNominations;
      }
      return `all regions (slow) (${totalCount})`;
    }
    const countries = region.split(', ');
    let roomCount = 0;
    let nomCount = 0;
    for (const country of countries) {
      roomCount += (this.countryRoomCountMap?.get(country) || 0);
      if (this.canProcessRawNominations()) {
        nomCount += (this.countryNomCountMap?.get(country) || 0);
      }
    }
    if (roomCount + nomCount > 0) {
      if (nomCount > 0) {
        return `${region} (${roomCount} + ${nomCount})`;
      }
      return `${region} (${roomCount})`;
    }
    return `${region}`;
  }

  private hasNominationId(room: TerpecaRoom, nomId: string) {
    return room && room.nominations && room.nominations.some(nom => nom.nominationId === nomId);
  }

  private isNominationProcessed(nomination: TerpecaNomination) {
    if (nomination.roomId && this.rooms) {
      for (const room of this.rooms) {
        if (this.hasNominationId(room, nomination.docId)) {
          return true;
        }
      }
    }
    return false;
  }

  canProcessRawNominations() {
    return this.auth.currentUser.status >= ApplicationStatus.APPROVER;
  }

  numSelectedNominations() {
    return this.selectedNominationIds.size;
  }

  isSelectedNomination(nominationId: string) {
    return this.selectedNominationIds.has(nominationId);
  }

  toggleSelectedNomination(nominationId: string) {
    if (this.isSelectedNomination(nominationId)) {
      this.selectedNominationIds.delete(nominationId);
    } else {
      this.selectedNominationIds.add(nominationId);
    }
  }

  hasSelectedRoom() {
    return this.selectedRoomId;
  }

  toggleSelectedRoom(roomId: string) {
    if (this.selectedRoomId === roomId) {
      this.selectedRoomId = null;
    } else {
      this.selectedRoomId = roomId;
    }
  }

  getSelectedNominations(): TerpecaNomination[] {
    const noms: TerpecaNomination[] = [];
    if (this.selectedNominationIds.size > 0) {
      this.selectedNominationIds.forEach(nominationId => {
        for (const nomination of this.unprocessedNominations) {
          if (nomination.docId === nominationId) {
            noms.push(nomination);
            break;
          }
        }
      });
    }
    return noms;
  }

  isSelectedRoom(roomId: string) {
    return this.selectedRoomId === roomId;
  }

  getSelectedRoom(): TerpecaRoom {
    if (this.selectedRoomId) {
      for (const room of this.rooms) {
        if (room.docId === this.selectedRoomId) {
          return room;
        }
      }
    }
    return null;
  }

  getSelectedRoomName(): string {
    const room = this.getSelectedRoom();
    if (room) {
      return room.name;
    }
    return '- none -';
  }

  async generateNewRoom() {
    const nominations: TerpecaNomination[] = this.getSelectedNominations();
    if (nominations.length === 0) {
      console.log(`can't generate room without nominations!`);
      return;
    }
    const nominationIds: NominationId[] = [];
    for (const nom of nominations) {
      nominationIds.push(<NominationId>{ nominationId: nom.docId, year: this.year });
    }
    const newRoom: TerpecaRoom = {
      createTime: <app.firestore.Timestamp>app.firestore.FieldValue.serverTimestamp(),
      category: nominations[0].category,
      name: (nominations[0].room || '').trim(),
      company: (nominations[0].company || '').trim(),
      city: (nominations[0].city || '').trim(),
      country: (nominations[0].country || '').trim(),
      link: (nominations[0].link || '').trim(),
      englishLink: (nominations[0].englishLink || '').trim(),
      email: (nominations[0].email || '').trim(),
      nominations: nominationIds,
      languages: nominations[0].languages || [],
      horrorLevel: nominations[0].horrorLevel || null,
    };
    if (nominations[0].state) {
      newRoom.state = nominations[0].state.trim();
    }
    const roomId = this.db.createId();
    const batch = this.db.firestore.batch();
    for (const nomination of nominations) {
      batch.update(this.db.firestore.collection('nominations').doc(nomination.docId), { roomId });
    }
    batch.set(this.db.firestore.collection('rooms').doc(roomId), newRoom);
    await batch.commit();
    this.selectedNominationIds.clear();
    this.selectedRoomId = null;
  }

  async addNominationsToRoom() {
    const nominations: TerpecaNomination[] = this.getSelectedNominations();
    if (nominations.length === 0) {
      console.log('no nominations to add!');
      return;
    }
    const room: TerpecaRoom = this.getSelectedRoom();
    if (!room) {
      console.log('no selected room!');
      return;
    }
    const nominationIds: NominationId[] = [];
    for (const nom of nominations) {
      nominationIds.push(<NominationId>{ nominationId: nom.docId, year: this.year });
    }
    const batch = this.db.firestore.batch();
    for (const nomination of nominations) {
      batch.update(this.db.firestore.collection('nominations').doc(nomination.docId), {
        roomId: room.docId
      });
    }
    batch.update(this.db.firestore.collection('rooms').doc(room.docId), {
      nominations: <NominationId[]><unknown>app.firestore.FieldValue.arrayUnion(...nominationIds)
    });
    await batch.commit();
    this.selectedNominationIds.clear();
    this.selectedRoomId = null;
  }

  async unmatchNomination(nomination: TerpecaNomination) {
    if (!nomination || !nomination.docId || !nomination.roomId) {
      console.log('no nomination to unmatch!');
      return;
    }
    const nomId: NominationId = { nominationId: nomination.docId, year: this.year };
    const batch = this.db.firestore.batch();
    batch.update(this.db.firestore.collection('nominations').doc(nomination.docId), { roomId: null });
    batch.update(this.db.firestore.collection('rooms').doc(nomination.roomId), {
      nominations: <NominationId[]><unknown>app.firestore.FieldValue.arrayRemove(nomId)
    });
    await batch.commit();
  }
}
