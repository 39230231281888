import { Component, Input } from '@angular/core';

import { HorrorLevel } from 'src/app/models/room.model';

// Angular component to indicate a horror level with emoji and/or description.
@Component({
  selector: 'app-horrorlevel',
  templateUrl: './horrorlevel.component.html',
  styleUrl: './horrorlevel.component.css',
})
export class HorrorLevelComponent {
  @Input() level: HorrorLevel;
  @Input() showShortText: boolean = false;
  @Input() showText: boolean = false;
  @Input() showLongText: boolean = false;

  constructor() { }

  emoji(): string {
    switch (this.level) {
      case HorrorLevel.UNKNOWN: return '';
      case HorrorLevel.NONE: return '☀️';
      case HorrorLevel.SPOOKY: return '👻';
      case HorrorLevel.PASSIVE: return '🔦';
      case HorrorLevel.ACTIVE: return '😱';
      // No default case, Typescript will verify enum coverage
    }
  }

  shortText(): string {
    switch (this.level) {
      case HorrorLevel.UNKNOWN: return '';
      case HorrorLevel.NONE: return 'None';
      case HorrorLevel.SPOOKY: return 'Spooky';
      case HorrorLevel.PASSIVE: return 'Passive';
      case HorrorLevel.ACTIVE: return 'Active';
      // No default case, Typescript will verify enum coverage
    }
  }

  text(): string {
    switch (this.level) {
      case HorrorLevel.UNKNOWN: return '';
      case HorrorLevel.NONE: return 'No horror';
      case HorrorLevel.SPOOKY: return 'Spooky but not scary';
      case HorrorLevel.PASSIVE: return 'Passively scary';
      case HorrorLevel.ACTIVE: return 'Actively scary';
      // No default case, Typescript will verify enum coverage
    }
  }

  longText(): string {
    switch (this.level) {
      case HorrorLevel.UNKNOWN:
        return '';
      case HorrorLevel.NONE:
        return 'The game doesn\'t have horror-type theming or scary situations';
      case HorrorLevel.SPOOKY:
        return 'The game has horror-type theming (death, the supernatural, ' +
            'bodily harm, etc.), but doesn\'t focus on scaring players';
      case HorrorLevel.PASSIVE:
        return 'Passively scary: Regardless of theme, the game uses darkness, ' +
            'intimidation, simulated danger, hide & seek play, or other ' +
            'techniques to create anxiety, but doesn\'t startle players much';
      case HorrorLevel.ACTIVE:
        return 'Jump scares, simulated attacks or sudden actions ' +
            'repeatedly or seriously startle or frighten players';
      // No default case, Typescript will verify enum coverage
    }
  }
}
